import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
	CancelAction,
	ChangeNavbar,
	CloseShipmentTab,
	ConfirmPageLeave,
	CopyToClipboard,
	InitShipmentTabs,
	LoadAvailablesLanguages,
	LoadAvailablesLanguagesSuccess,
	OpenAvatarDialog,
	OpenShipmentTab,
	SetDeviceType,
	SetHeaderVisible,
	ShowMessage,
	UpdateCurrentLang,
	UpdateCurrentLangSuccess, UpdateLateralShipmentName,
} from './global.actions';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { isEqual } from 'lodash';
import { ModalService } from '@services/modal.service';
import { ModalConfirmMessageEnum } from '@shared/components/modal/modal-confirm/modal-confirm-message-enum';
import { catchError, EMPTY, finalize, map } from 'rxjs';
import { Shipment } from '@domain/shipment/shipment';
import { ShipmentService } from '@services/shipments/shipment.service';

export interface GlobalStateModel {
	navbarVisible: boolean;
	availableLangs: string[];
	currentLang: string;
	message?: Message;
	device: Device;
	headerVisible: boolean;
	shipmentTabs: Shipment[];
	loadingTabs: boolean;
}

export interface Message {
	text: string;
	level?: MessageLevel;
	timer?: number; // time in ms
}

export interface Notification {
	text: string;
	level?: MessageLevel;
}

export enum MessageLevel {
	ERROR = 'error',
	INFO = 'info',
	SUCCESS = 'success',
}

export interface Device {
	mobile: boolean;
	desktop: boolean;
	desktopShort: boolean;
}

@State<GlobalStateModel>({
	name: 'global',
	defaults: {
		navbarVisible: true,
		currentLang: getBrowserLang() || 'fr',
		availableLangs: [],
		device: {
			mobile: false,
			desktopShort: false,
			desktop: false,
		},
		headerVisible: true,
		shipmentTabs: [],
		loadingTabs: false,
	},
})
@Injectable()
export class GlobalState {
	constructor(private modalService: ModalService, private translocoService: TranslocoService, private shipmentService: ShipmentService) {}

	@Action(ShowMessage)
	showMessage(ctx: StateContext<GlobalStateModel>, { message, scope, data }: ShowMessage) {
		if (!message || !message.text) {
			return;
		}
		let title = '';
		if (message.level === undefined) {
			message.level = MessageLevel.ERROR;
		}
		if (scope) {
			title = 'messages.' + message.level.toLowerCase() + '.' + scope + '.' + message.text;
		}
		message.text = this.translocoService.translate(title, data);
		return ctx.setState(
				patch({
					message: message,
				}),
		);
	}

	@Action(LoadAvailablesLanguages)
	loadAvailablesLanguages(ctx: StateContext<GlobalStateModel>) {
		return ctx.dispatch(
				new LoadAvailablesLanguagesSuccess(this.translocoService.getAvailableLangs().map((a) => a.toString())),
		);
	}

	@Action(UpdateCurrentLang)
	updateCurrentLang(ctx: StateContext<GlobalStateModel>, { currentLang }: UpdateCurrentLang) {
		this.translocoService.setActiveLang(currentLang);
		ctx.patchState({
			currentLang: currentLang,
		});
		return ctx.dispatch(new UpdateCurrentLangSuccess());
	}

	@Action(ChangeNavbar)
	changeNavbar(ctx: StateContext<GlobalStateModel>) {
		const state = ctx.getState();
		ctx.patchState({
			navbarVisible: !state.navbarVisible,
		});
	}

	@Action(LoadAvailablesLanguagesSuccess)
	loadAvailablesLanguagesSuccess(ctx: StateContext<GlobalStateModel>, { languages }: LoadAvailablesLanguagesSuccess) {
		ctx.patchState({
			availableLangs: languages,
		});
	}

	@Action(SetDeviceType)
	setDeviceType(ctx: StateContext<GlobalStateModel>, { device }: SetDeviceType) {
		if (!isEqual(device, ctx.getState().device)) {
			return ctx.patchState({
				device,
			});
		}
		return;
	}

	@Action(ConfirmPageLeave)
	confirmePageLeave(ctx: StateContext<GlobalStateModel>) {
		return this.modalService
				.confirm(ModalConfirmMessageEnum.CONFIRM, ModalConfirmMessageEnum.CONFIRM)
				.pipe((confirm) => {
					return confirm;
				});
	}

	//region open avatar dialog
	@Action(OpenAvatarDialog)
	openAddressDialog(ctx: StateContext<GlobalStateModel>, { imageForm }: OpenAvatarDialog) {
		return this.modalService.uploadAvatar(imageForm).pipe(
				map((success) => {
					if (!success) {
						return ctx.dispatch(new CancelAction());
					}
					return;
				}),
				catchError(() => EMPTY),
		);
	}

	//endregion

	@Action(SetHeaderVisible)
	setHeaderVisible(ctx: StateContext<GlobalStateModel>, { headerVisible }: SetHeaderVisible) {
		ctx.patchState({
			headerVisible,
		});
	}

	@Action(InitShipmentTabs)
	initShipmentTabs(ctx: StateContext<GlobalStateModel>) {
		const ids: string[] = JSON.parse(localStorage.getItem('shipmentTabs') || '[]');
		if (!ids.length) {
			return;
		}
		ctx.patchState({
			loadingTabs: true,
		});
		return this.shipmentService.findByIds(ids).pipe(
				map((shipments) => ctx.patchState({
					shipmentTabs: shipments.content,
				})),
				finalize(() => ctx.patchState({
					loadingTabs: false,
				})),
		);
	}

	@Action(OpenShipmentTab)
	openShipmentTab(ctx: StateContext<GlobalStateModel>, { shipmentId }: OpenShipmentTab) {
		// Check both localstorage and state in case state is not yet updated
		const { shipmentTabs } = ctx.getState();
		const storage: string[] = JSON.parse(localStorage.getItem('shipmentTabs') || '[]');
		if (shipmentTabs.find((s) => s.id === shipmentId) || storage.includes(shipmentId)) {
			return;
		}
		this.updateTabsStorage([...shipmentTabs, { id: shipmentId }]);
		return this.shipmentService.findByIds([shipmentId]).pipe(
				map((shipment) => ctx.setState(patch({
					shipmentTabs: append(shipment.content),
				}))),
		);
	}

	@Action(CloseShipmentTab)
	closeShipmentTab(ctx: StateContext<GlobalStateModel>, { shipmentId }: CloseShipmentTab) {
		ctx.setState(patch({
			shipmentTabs: removeItem((s) => s?.id === shipmentId),
		}));
		return this.updateTabsStorage(ctx.getState().shipmentTabs.filter((s) => s.id !== shipmentId));
	}

	private updateTabsStorage(shipmentTabs: Shipment[]) {
		const ids = new Set(shipmentTabs.map((s) => s.id));
		localStorage.setItem('shipmentTabs', JSON.stringify(Array.from(ids)));
	}

	@Action(CopyToClipboard)
	copyToClipboard(ctx: StateContext<GlobalStateModel>, { text }: CopyToClipboard) {
		navigator.clipboard.writeText(text).then(() => {
			ctx.dispatch(
					new ShowMessage(
							{
								level: MessageLevel.SUCCESS,
								text: 'copy_clipboard_success',
							},
							'general',
					),
			);
		});
	}

	@Action(UpdateLateralShipmentName)
	updateLateralShipmentName(ctx: StateContext<GlobalStateModel>, { shipmentId, name }: UpdateLateralShipmentName) {
		ctx.setState(patch({
			shipmentTabs: updateItem((s) => s.id === shipmentId, patch({ name })),
		}));
	}
}
