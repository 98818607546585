import { Contact } from '@domain/contact/contact';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { GenericForm } from '@shared/types/generic.form';
import { GenderEnum } from '@domain/contact/gender.enum';
import { Phone } from '@domain/user/user';
import { NationalPhonePipe } from '@shared/pipes/national-phone.pipe';
import { Address } from '@domain/shared/address';
import { getBrowserLang } from '@ngneat/transloco';
import { MoralPerson } from '@domain/client/moral.person';
import { PersonTypeEnum } from '@domain/client/person.type.enum';
import { PhysicPerson } from '@domain/client/physic.person';
import { CustomerReponse } from '@domain/client/customer';
import { emailValidator } from '@shared/validators/email.validator';

export class CustomerUtils {

	public static createContactFormGroup(contact?: Contact): FormGroup<GenericForm<Contact | undefined>> {
		const form = new FormGroup<GenericForm<Contact | undefined>>({
			id: new FormControl<string | undefined>(contact?.id),
			gender: new FormControl<GenderEnum>(contact?.gender || GenderEnum.M),
			firstname: new FormControl<string | undefined>(contact?.firstname, Validators.required),
			lastname: new FormControl<string | undefined>(contact?.lastname, Validators.required),
			localization: new FormControl<string | undefined>(contact?.localization),
			mail: new FormControl<string | undefined>(contact?.mail, [Validators.required, emailValidator]),
			phones: new FormArray<FormGroup<GenericForm<Phone | undefined>>>([], [Validators.minLength(1)]
			),
			notifyMe: new FormControl<boolean>(contact?.notifyMe || false),
			role: new FormControl<string | undefined>(contact?.role),
			comments: new FormControl<string | undefined>(contact?.comments),
		})
		if (contact && contact.phones) {
			contact?.phones?.forEach(phone => {
				form.controls?.phones?.push(this.createPhoneNumberFormGroup(phone))
			})
		} else {
			form.controls?.phones?.push(this.createPhoneNumberFormGroup())
		}
		return form;
	}

	public static createPhoneNumberFormGroup(phone?: Phone): FormGroup<GenericForm<Phone | undefined>> {
		return new FormGroup<GenericForm<Phone | undefined>>({
			id: new FormControl<string | undefined>(phone?.id),
			number: new FormControl<string | undefined>(NationalPhonePipe.prototype.transform(phone), {
				validators: [Validators.required]
			}),
			type: new FormControl<string | null | undefined>(phone?.type, Validators.required),
			countryCode: new FormControl<string | undefined>(phone?.countryCode || getBrowserLang()?.toUpperCase() || 'FR', Validators.required),
		})
	}

	public static createAddressFormGroup(address?: Address): FormGroup<GenericForm<Address | undefined>> {
		return new FormGroup<GenericForm<Address | undefined>>({
			name: new FormControl<string | undefined>(address?.name, Validators.required),
			company: new FormControl<string | undefined>(address?.company),
			id: new FormControl<string | undefined>(address?.id),
			address1: new FormControl<string | undefined>(address?.address1, Validators.required),
			address2: new FormControl<string | undefined>(address?.address2),
			city: new FormControl<string | undefined>(address?.city, Validators.required),
			countryCode: new FormControl<string | undefined>(address?.countryCode, Validators.required),
			postalCode: new FormControl<string | undefined>(address?.postalCode, [Validators.required, Validators.maxLength(12)]),
			state: new FormControl<string | undefined>(address?.state, Validators.required),
			comments: new FormControl<string | undefined>(address?.comments)
		}, Validators.required);
	}

	public static createMoralPersonForm(customer?: CustomerReponse, address?: FormGroup<GenericForm<Address | undefined>>) {
		return new FormGroup<GenericForm<MoralPerson | undefined>>({
			name: new FormControl<string>(customer?.name || '', Validators.required),
			personType: new FormControl<PersonTypeEnum>(PersonTypeEnum.M),
			contacts: new FormArray<FormGroup<GenericForm<Contact | undefined>>>(
					customer?.contacts?.map(contact => CustomerUtils.createContactFormGroup(contact)) || []
			),
			countryCode: new FormControl<string>(customer?.countryCode || '', Validators.required),
			comments: new FormControl<string>(''),
			duns: new FormControl(customer?.duns, [
				Validators.required,
				Validators.pattern(/^[\d]{9}$/),
			]),
			mail: new FormControl<string>(customer?.mail || '', [
				emailValidator,
				Validators.required,
			]),
			fax: new FormControl<string>(customer?.fax || ''),
			phones: new FormArray<FormGroup<GenericForm<Phone | undefined>>>(
					customer?.phones?.map(phone => CustomerUtils.createPhoneNumberFormGroup(phone)) || [CustomerUtils.createPhoneNumberFormGroup()],
					Validators.minLength(1)
			),
			address: address || CustomerUtils.createAddressFormGroup(customer?.siteAddress),
		});
	}

	public static createPhysicPersonForm(customer?: CustomerReponse, address?: FormGroup<GenericForm<Address | undefined>>) {
		const [contact] = customer?.contacts || [];
		const { firstname = "", lastname = "", gender = ""} = contact || {};
		return new FormGroup<GenericForm<PhysicPerson | undefined>>({
			personType: new FormControl<PersonTypeEnum>(PersonTypeEnum.P),
			comments: new FormControl<string>(''),
			lastname: new FormControl<string>(lastname || '', Validators.required),
			firstname: new FormControl<string>(firstname || '', Validators.required),
			gender: new FormControl<GenderEnum>(gender || GenderEnum.M, { nonNullable: true }),
			notifyMe: new FormControl<boolean>(contact?.notifyMe || true),
			localization: new FormControl<string>(customer?.localization || '', Validators.required),
			countryCode: new FormControl<string>(customer?.countryCode || '', Validators.required),
			mail: new FormControl<string>(customer?.mail || '', [
				Validators.required,
				emailValidator,
			]),
			phones: new FormArray<FormGroup<GenericForm<Phone | undefined>>>(
					customer?.phones?.map(phone => CustomerUtils.createPhoneNumberFormGroup(phone)) || [CustomerUtils.createPhoneNumberFormGroup()]
			),
			address: address || CustomerUtils.createAddressFormGroup(customer?.siteAddress),
		});
	}

}
