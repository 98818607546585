import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserContact, UserContactFilter } from '@domain/address-book/address-book';
import { environment } from '@environment/environment';
import { CrudApiService } from '@services/generic/crud-api.service';
import { ObjectAccessPipe } from '@shared/pipes/object-access.pipe';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { PageOption } from '@shared/utils/query/page.options';
import { QueryBuilder, QueryParamType } from '@shared/utils/query/query.builder';
import { RequestParams } from '@shared/utils/query/request.params';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserContactService extends CrudApiService<UserContact, string> {

    constructor(public override http: HttpClient, public pipeAccess: ObjectAccessPipe) {
        super(http, UserContact, `user/my-address-book/contacts`);
    }

    findAllContacts(options?: PageOption, filter?: UserContactFilter): Observable<PaginatedEntity<UserContact>> {
        const params: RequestParams = {
            sort: 'firstname,lastname',
            format: options?.format,
            page: options?.page || 0,
            size: options?.size || 10,
        };

        if (filter) {
            params.q = new QueryBuilder()
                .addParam({ key: 'search', type: QueryParamType.like, value: filter.search })
                .addParam({ key: 'deleted', type: QueryParamType.equal, value: filter.deleted })
                .addParam({ key: 'group', type: QueryParamType.equal, value: filter.group })
                .get();
        }

        if (!params.q) {
            delete params.q;
        }

        return this.http.get<PaginatedEntity<UserContact>>(`${environment.api.bff}/${this.apiUrl}`, {
            params: {
                ...params,
            },
        });
    }

    addContactToGroup(contactId: string, groupId: string): Observable<void> {
        return this.http.post<void>(`${environment.api.bff}/${this.apiUrl}/${contactId}/group-contact/${groupId}`, null);
    }

    removeContactFromGroup(contactId: string, groupId: string): Observable<void> {
        return this.http.delete<void>(`${environment.api.bff}/${this.apiUrl}/${contactId}/group-contact/${groupId}`);
    }
}
