import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { Shipment, ShipmentCreate, ShipmentPatch, Shipments } from '@domain/shipment/shipment';
import { environment } from '@environment/environment';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { EquipmentRef } from '@domain/shipment/equipmentRef';
import { ObjectAccessPipe } from '@shared/pipes/object-access.pipe';
import { ShipmentFilter } from '@domain/shipment/shipment.filter';
import { PageOption } from '@shared/utils/query/page.options';
import { CrudApiService } from '@services/generic/crud-api.service';
import { ShipmentStatusEnum } from '@domain/shipment/shipment-status.enum';
import { ShipmentTracking } from '@domain/shipment/shipment-tracking';
import { ShipmentTrackingRelatedInfos } from "@domain/shipment/shipment.tracking.related.infos";
import { PrePostCarriage } from "@domain/shipment/route/pre-post-carriage";
import { IncidentCategory } from "@domain/shipment/incident";
import { SearchShipmentToPort } from '@domain/shipment/search-shipment-to-port';
import { RoutingStep } from "@domain/shipment/routing.step";
import { RoutingPlan } from "@domain/shipment/routing.plan";


@Injectable({
	providedIn: 'root',
})
export class ShipmentService extends CrudApiService<Shipment, string> {
	constructor(public override http: HttpClient, public pipeAccess: ObjectAccessPipe) {
		super(http, Shipment, `shipments`);
	}

	findAll(options: PageOption, filter?: ShipmentFilter): Observable<PaginatedEntity<Shipment>> {
		const params: any = {
			page: options.page,
			size: options.size || 10,
			sort: options.sort,
			format: options.format,
		};

		if (filter) {
			for (const value of Object.values(filter)) {
				if (value && value.value) {
					params[value.key] = value.value;
				}
			}
		}

		if (!params.format) {
			delete params.format;
		}

		return this.http.get<PaginatedEntity<Shipment>>(environment.api.bff + '/shipments', {
			params: {
				...params,
			},
		});
	}

	getCarriage(shipmentId: string, carriageId: string): Observable<PrePostCarriage> {
		return this.http.get<PrePostCarriage>(`${environment.api.bff}/shipments/${shipmentId}/carriage/${carriageId}`);
	}

	getIncidentCategories(): Observable<IncidentCategory[]> {
		return this.http.get<IncidentCategory[]>(`${environment.api.bff}/incidents/categories`);
	}

	findAllMyShipments(options: PageOption, filter?: ShipmentFilter): Observable<PaginatedEntity<Shipment>> {
		const params: any = {
			page: options.page,
			size: options.size || 10,
			sort: options.sort,
			format: options.format,
		};

		if (filter) {
			for (const value of Object.values(filter)) {
				if (value && value.value) {
					params[value.key] = value.value;
				}
			}
		}

		if (!params.format) {
			delete params.format;
		}

		return this.http.get<PaginatedEntity<Shipment>>(environment.api.bff + '/shipments/my-shipments', {
			params: {
				...params,
			},
		});
	}

	getTrackingRelatedInfos(): Observable<ShipmentTrackingRelatedInfos> {
		return this.http.get<ShipmentTrackingRelatedInfos>(`${environment.api.bff}/shipments/my-shipments/related-infos`);
	}

	createShipment(shipment: ShipmentCreate): Observable<Shipment> {
		return this.http.post<Shipment>(environment.api.bff + '/shipments', shipment);
	}

	deleteShipment(shipmentId: string): Observable<any> {
		return this.http.delete(environment.api.bff + '/shipments/' + shipmentId);
	}

	patchStatus(shipmentId: string, status: ShipmentStatusEnum): Observable<Shipment> {
		return this.http.patch(`${environment.api.bff}/shipments/${shipmentId}/status/${status}`, {});
	}

	getQuotes(shipmentId: string): Observable<RoutingPlan[]> {
		return this.http.get<RoutingPlan[]>(`${environment.api.bff}/shipments/${shipmentId}/get-quotes`, {});
	}

	selectQuote(shipmentId: string, quoteId: string): Observable<Shipment> {
		return this.http.put<Shipment>(`${environment.api.bff}/shipments/${shipmentId}/quote/${quoteId}`, {});
	}

	updateShipment(shipment: Shipment, shipmentId: string): Observable<Shipment> {
		return this.http.put<Shipment>(environment.api.bff + '/shipments/' + shipmentId,
				shipment,
		);
	}

	patchShipment(shipment: ShipmentPatch, shipmentId: string): Observable<Shipment> {
		return this.http.patch<Shipment>(environment.api.bff + '/shipments/' + shipmentId, shipment);
	}

	getEquipmentRef(): Observable<PaginatedEntity<EquipmentRef>> {
		return this.http.get<PaginatedEntity<EquipmentRef>>(environment.api.bff + '/shipments/equipmentRef');
	}

	private buildArray(objs: any[], keys: string): any {
		if (!objs || objs.length === 0) {
			return [];
		}
		const array: any[] = [];
		objs.forEach((obj) => {
			array.push(this.pipeAccess.transform(obj, [keys]));
		});
		return array;
	}

	countUserBookings(organizerId: string): Observable<number> {
		const params: any = {
			organizerId,
		};

		return this.http.get<number>(environment.api.bff + '/shipments/count', { params });
	}

	getShipmentTracking(id: string) {
		return this.http.get<ShipmentTracking[]>(`${environment.api.bff}/shipments/${id}/tracking`);
	}

	findByIds(ids: string[]): Observable<Shipments> {
		if (!ids.length) {
			return EMPTY;
		}
		return this.http.get<Shipments>(`${environment.api.bff}/shipments`, {
			params: {
				ids,
				format: 'LIGHT',
			}
		});
	}

	searchShipmentsToPort(search: SearchShipmentToPort): Observable<Shipments> {
		const params = {};
		Object.assign(params, search);
		return this.http.get<Shipments>(`${environment.api.bff}/shipments/to-port`, {
			params,
		});
	}

	getVessels(): Observable<string[]> {
		return this.http.get<string[]>(`${environment.api.bff}/shipments/vessels`);
	}
}
