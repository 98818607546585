import { Component, DestroyRef, OnInit } from '@angular/core';
import {
	ShipmentQuoteComponent
} from "@features/private/shipment/shipment-quotes/shipment-quote/shipment-quote.component";
import { TranslocoPipe } from "@ngneat/transloco";
import { AsyncPipe, NgClass } from "@angular/common";
import { ButtonCustomComponent } from "@shared/components/input/button/button-custom/button-custom.component";
import {
	LoadQuotes, LoadQuotesSuccess,
	LoadShipment,
	PatchShipmentStatus,
	PatchShipmentStatusSuccess
} from "@state/shipment/shipment.action";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";
import { ShipmentStatusEnum } from "@domain/shipment/shipment-status.enum";
import { ActivatedRoute, Router } from "@angular/router";
import { ShipmentSelectors } from "@state/shipment/shipment.selectors";
import { Shipment } from '@domain/shipment/shipment';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter } from "rxjs";
import { RoutingPlan } from "@domain/shipment/routing.plan";

export enum QuoteType {
	ECOLOGICAL = 'ECOLOGICAL',
	ECONOMICAL = 'ECONOMICAL',
	PERSONALIZED = 'PERSONALIZED'
}

@Component({
	selector: 'app-shipment-quotes',
	standalone: true,
	imports: [
		ShipmentQuoteComponent,
		TranslocoPipe,
		NgClass,
		ButtonCustomComponent,
		AsyncPipe
	],
	templateUrl: './shipment-quotes.component.html',
	styleUrl: './shipment-quotes.component.scss'
})
export class ShipmentQuotesComponent implements OnInit {

	shipment$ = this.store.select<Shipment | undefined>(ShipmentSelectors.shipment)
	shipment?: Shipment;

	plans$ = this.store.select<RoutingPlan[] | undefined>(ShipmentSelectors.routingPlans)

	constructor(private store: Store, private actions: Actions, private router: Router, private activatedRoute: ActivatedRoute, private destroy: DestroyRef) {
		this.activatedRoute.params.pipe(takeUntilDestroyed()).subscribe(params => {
			if(params['id']) {
				this.store.dispatch(new LoadShipment(params['id']))
				this.store.dispatch(new LoadQuotes(params['id']))
			}

		})
	}

	modifyDemand() {
		this.store.dispatch(new PatchShipmentStatus(ShipmentStatusEnum.DRAFT));
		this.actions.pipe(ofActionSuccessful(PatchShipmentStatusSuccess)).subscribe(() => {
			this.router.navigate(['shipments', this.shipment?.id])
		})
	}

	ngOnInit(): void {
		this.shipment$.pipe(takeUntilDestroyed(this.destroy), filter(Boolean)).subscribe(shipment => {
			this.shipment = shipment;
		});
	}
}
