import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[dragDropFile]',
	standalone: true,
})
export class DragDropFileDirective {
	private static ACTIVE_CLASS = 'drop-zone-active';

	@Input() preventBodyDrop: boolean = true;

	@Output() fileDrop = new EventEmitter<File[]>();

	constructor(private _element: ElementRef<HTMLElement>) {}

	get element() {
		return this._element.nativeElement;
	}

	@HostListener('drop', ['$event'])
	onDrop(e: DragEvent) {
		e.preventDefault();
		const { dataTransfer } = e;
		const files: File[] = [];
		if (dataTransfer?.files) {
			for (let i = 0; i < dataTransfer.files.length; i++) {
				files.push(dataTransfer.files.item(i) as File);
			}
		}
		this.fileDrop.emit(files);
		this.element.classList.remove(DragDropFileDirective.ACTIVE_CLASS);
	}

	@HostListener('window:dragover', ['$event'])
	onBodyDragOver(e: DragEvent) {
		if (e.dataTransfer?.items?.length) {
			this.element.classList.add(DragDropFileDirective.ACTIVE_CLASS);
		}
		if (this.preventBodyDrop) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	@HostListener('window:dragleave', ['$event'])
	onBodyDragLeave(e: DragEvent) {
		this.element.classList.remove(DragDropFileDirective.ACTIVE_CLASS);
		if (this.preventBodyDrop) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	@HostListener('window:drop', ['$event'])
	onBodyDrop(e: DragEvent) {
		this.element.classList.remove(DragDropFileDirective.ACTIVE_CLASS);
		if (this.preventBodyDrop) {
			e.preventDefault();
		}
	}
}
