import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { UnsubscribeOnDestroyComponent } from '@shared/components/abstract/unsubscribe-on-destroy-component.service';
import { SetAvatar } from '@state/global/global.actions';

@Component({
    selector: 'app-upload-avatar',
    templateUrl: './upload-avatar.component.html',
    styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent extends UnsubscribeOnDestroyComponent implements OnInit {
    base64Image: string;
    shake: boolean;
    LIMIT_SIZE = 5 * 1024 * 1024; // 5MB

    constructor(
        public dialogRef: MatDialogRef<UploadAvatarComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { imageForm: 'rect' | 'circle' },
        private store: Store,
        private actions$: Actions,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(this.actions$.pipe(ofActionSuccessful(SetAvatar)), () => this.dialogRef.close());
    }

    dragOverHandler(ev: Event) {
        ev.preventDefault();
    }

    async dropHandler(ev: any) {
        ev.preventDefault();
        const file = ev.dataTransfer?.files[0];
        await this.convertBase64(file);
    }

    async inputHandler(ev: Event) {
        const file = (ev.target as HTMLInputElement).files?.[0];
        if (!file) return;
        await this.convertBase64(file);
    }

    async convertBase64(file: File): Promise<void> {
        if ((file.type !== 'image/png' && file.type !== 'image/jpeg') || file.size > this.LIMIT_SIZE) {
            this.shake = true;
            return;
        }
        this.base64Image = await new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result as string);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }

    cancelUpload() {
        this.base64Image = '';
    }

    uploadAvatar() {
        this.store.dispatch(new SetAvatar(this.base64Image));
    }

    closeModal() {
        this.dialogRef.close();
    }
}
