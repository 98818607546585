import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Actions, Store } from "@ngxs/store";
import { AddAddressData } from "@shared/components/add-address/add-address.component";
import { ShipmentErrorEnum } from "@domain/shipment/shipment.error.enum";

export interface ShipmentErrorsData {
	errors: ShipmentErrorEnum[];
}

@Component({
  selector: 'app-shipment-errors',
  templateUrl: './shipment-errors.component.html',
  styleUrls: ['./shipment-errors.component.scss']
})
export class ShipmentErrorsComponent {

	constructor(
			public dialogRef: MatDialogRef<ShipmentErrorsComponent>,
			@Inject(MAT_DIALOG_DATA) public data: ShipmentErrorsData,
	) {
	}
}
