import { Countries } from "@domain/locations/country";
import { ReferentialsActions } from "./referentials.actions.enum";
import { Timezone } from '@domain/user/timezone';

const PREFIX = '[Country] '

export class LoadCountries {
    static readonly type = PREFIX + ReferentialsActions.LOAD_COUNTRIES;
}

export class LoadCountriesSuccess {
    static readonly type = PREFIX + ReferentialsActions.LOAD_COUNTRIES_SUCCESS;
    constructor(public countries: Countries) {}
}

export class LoadCountriesFailure {
    static readonly type = PREFIX + ReferentialsActions.LOAD_COUNTRIES_FAILURE;
    constructor(public error: any) {}
}

export class LoadPhoneTypes {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_TYPES;
}

export class LoadPhoneTypesSuccess {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_TYPES_SUCCESS;
    constructor(public phoneTypes: string[]) {}
}

export class LoadPhoneTypesFailure {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_TYPES_FAILURE;
    constructor(public error: any) {}
}

export class LoadPhoneVisibilities {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_VISIBILITIES;
}

export class LoadPhoneVisibilitiesSuccess {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_VISIBILITIES_SUCCESS;
    constructor(public phoneVisibilities: string[]) {}
}

export class LoadPhoneVisibilitiesFailure {
    static readonly type = PREFIX + ReferentialsActions.LOAD_PHONE_VISIBILITIES_FAILURE;
    constructor(public error: any) {}
}


export class UserLoadLanguages {
    static readonly type = PREFIX + ReferentialsActions.LOAD_LANGUAGES;
}

export class UserLoadLanguagesSuccess {
    static readonly type = PREFIX + ReferentialsActions.LOAD_LANGUAGES_SUCCESS;
    constructor(public languages: string[]) {}
}

export class UserLoadLanguagesFailure {
    static readonly type = PREFIX + ReferentialsActions.LOAD_LANGUAGES_FAILURE;
    constructor(public error: any) {}
}

export class UserLoadTimezones {
    static readonly type = PREFIX + ReferentialsActions.LOAD_TIMEZONES;
}

export class UserLoadTimezonesSuccess {
    static readonly type = PREFIX + ReferentialsActions.LOAD_TIMEZONES_SUCCESS;
    constructor(public timezones: Timezone[]) {}
}

export class UserLoadTimezonesFailure {
    static readonly type = PREFIX + ReferentialsActions.LOAD_TIMEZONES_FAILURE;
    constructor(public error: any) {}
}

export class UserLoadHomePages {
	static readonly type = PREFIX + 'Load Home Pages';
}

export class UserLoadHomePagesSuccess {
	static readonly type = PREFIX + 'Load Home Pages Success';
	constructor(public homePages: string[]) {}
}

export class UserLoadHomePagesFailure {
	static readonly type = PREFIX + 'Load Home Pages Failure';
	constructor(public error: any) {}
}
