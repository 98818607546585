import { Organization, Phone, User } from '@domain/user/user';
import { UserActions } from '@state/user/user.actions.enum';
import { KeycloakProfile } from 'keycloak-js';
import { NotificationPreference, UserPreferences } from '@domain/user/user-preferences';

const PREFIX = '[User] ';

export class UserLogin {
    static readonly type = PREFIX + UserActions.LOGIN_USER;
}

export class UserLoginSuccess {
    static readonly type = PREFIX + UserActions.LOGIN_USER_SUCCESS;
    constructor(public connected: boolean, public userDB: User) {}
}

export class UserLoginFailure {
    static readonly type = PREFIX + UserActions.LOGIN_USER_FAILURE;
}

export class UserSSOProfileLoadSuccess {
    static readonly type = PREFIX + UserActions.LOAD_USER_SSO_PROFILE_SUCCESS;
    constructor(public user: KeycloakProfile) {}
}

export class UserLogout {
    static readonly type = PREFIX + UserActions.USER_LOGOUT;
}

export class UserLogoutSuccess {
    static readonly type = PREFIX + UserActions.USER_LOGOUT_SUCCESS;
}

export class UserLoadProfile {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE;
}

export class UserProfileLoadSuccess {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE_SUCCESS;
    constructor(public user: User) {}
}

export class UserLoadProfileFailure {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE_FAILURE;
    constructor(public error: any) {}
}

export class UpdateUserProfile {
    static readonly type = PREFIX + UserActions.UPDATE_USER_PROFILE;
    constructor(public user: User) {}
}

export class UpdateUserProfileSuccess {
    static readonly type = PREFIX + UserActions.UPDATE_USER_PROFILE_SUCCESS;
    constructor(public user: User) {}
}

export class UpdateUserProfileFailure {
    static readonly type = PREFIX + UserActions.UPDATE_USER_PROFILE_FAILURE;
    constructor(public error: any) {}
}

export class UpdateOneUserPhone {
    static readonly type = PREFIX + UserActions.UPDATE_ONE_USER_PHONE;
    constructor(public phone: Phone) {}
}

export class UpdateOneUserPhoneSuccess {
    static readonly type = PREFIX + UserActions.UPDATE_ONE_USER_PHONE_SUCCESS;
}

export class UpdateOneUserPhoneFailure {
    static readonly type = PREFIX + UserActions.UPDATE_ONE_USER_PHONE_FAILURE;
    constructor(public error: any) {}
}

export class UpdateOrganization {
    static readonly type = PREFIX + UserActions.UPDATE_ORGANIZATION;
    constructor(public organization: Organization) {}
}

export class UpdateOrganizationSuccess {
    static readonly type = PREFIX + UserActions.UPDATE_ORGANIZATION_SUCCESS;
    constructor(public organization: Organization) {}
}

export class UpdateOrganizationFailure {
    static readonly type = PREFIX + UserActions.UPDATE_ORGANIZATION_FAILURE;
    constructor(public error: any) {}
}

export class AddOneEmptyUserPhone {
    static readonly type = PREFIX + UserActions.ADD_ONE_EMPTY_USER_PHONE;
}

export class DeleteOneUserPhone {
    static readonly type = PREFIX + UserActions.DELETE_ONE_USER_PHONE;
    constructor(public phoneIdx: number) {}
}

export class AddOneUserPhone {
    static readonly type = PREFIX + UserActions.ADD_ONE_USER_PHONE;
    constructor(public phone: Phone) {}
}

export class AddOneUserPhoneSuccess {
    static readonly type = PREFIX + UserActions.ADD_ONE_USER_PHONE_SUCCESS;
    constructor(public phone: Phone) {}
}

export class AddOneUserPhoneFailure {
    static readonly type = PREFIX + UserActions.ADD_ONE_USER_PHONE_FAILURE;
    constructor(public error: any) {}
}

export class UpdateUserEmail {
    static readonly type = PREFIX + UserActions.UPDATE_USER_EMAIL;
    constructor(public newEmail: string) {}
}

export class UpdateUserEmailSuccess {
    static readonly type = PREFIX + UserActions.UPDATE_USER_EMAIL_SUCCESS;
}

export class UpdateUserEmailFailure {
    static readonly type = PREFIX + UserActions.UPDATE_USER_EMAIL_FAILURE;
    constructor(public error: any) {}
}

export class ValidateUpdateUserEmail {
    static readonly type = PREFIX + UserActions.VALIDATE_UPDATE_USER_EMAIL;
    constructor(public code: string) {}
}

export class ValidateUpdateUserEmailSuccess {
    static readonly type = PREFIX + UserActions.VALIDATE_UPDATE_USER_EMAIL_SUCCESS;
}

export class ValidateUpdateUserEmailFailure {
    static readonly type = PREFIX + UserActions.VALIDATE_UPDATE_USER_EMAIL_FAILURE;
    constructor(public error: any) {}
}

export class ConfirmNewUserEmail {
    static readonly type = PREFIX + UserActions.CONFIRM_NEW_USER_EMAIL;
    constructor(public code: string) {}
}

export class ConfirmNewUserEmailSuccess {
    static readonly type = PREFIX + UserActions.CONFIRM_NEW_USER_EMAIL_SUCCESS;
}

export class ConfirmNewUserEmailFailure {
    static readonly type = PREFIX + UserActions.CONFIRM_NEW_USER_EMAIL_FAILURE;
    constructor(public error: any) {}
}

export class LoadUserProfileCompletion {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE_COMPLETION;
}

export class LoadUserProfileCompletionSuccess {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE_COMPLETION_SUCCESS;
    constructor(public profileCompletion: number) {}
}

export class LoadUserProfileCompletionFailure {
    static readonly type = PREFIX + UserActions.LOAD_USER_PROFILE_COMPLETION_FAILURE;
    constructor(public error: any) {}
}

// region Avatar

export class UploadAvatar {
    static readonly type = PREFIX + UserActions.UPLOAD_AVATAR;
    constructor(public avatar: string) {}
}

export class UploadAvatarSuccess {
    static readonly type = PREFIX + UserActions.UPLOAD_AVATAR_SUCCESS;
    constructor(public user: User) {}
}

export class UploadAvatarFailure {
    static readonly type = PREFIX + UserActions.UPLOAD_AVATAR_FAILURE;
    constructor(public error: any) {}
}

// endregion

//region Preferences

export class LoadPreferences {
    static readonly type = PREFIX + 'Load Preferences';
}

export class LoadPreferencesSuccess {
    static readonly type = PREFIX + 'Load Preferences Success';

    constructor(public preferences: UserPreferences) {}
}

export class LoadPreferencesFailure {
    static readonly type = PREFIX + 'Load Preferences Failure';

    constructor(public error: any) {}
}

export class UpdatePreferences {
    static readonly type = PREFIX + 'Update Preferences';

    constructor(public preferences: Partial<UserPreferences>) {}
}

export class UpdatePreferencesSuccess {
    static readonly type = PREFIX + 'Update Preferences Success';

    constructor(public preferences: UserPreferences, public languageUpdated?: boolean) {}
}

export class UpdatePreferencesFailure {
    static readonly type = PREFIX + 'Update Preferences Failure';

    constructor(public error: any) {}
}

export class AddNotificationsPreferences {
    static readonly type = PREFIX + 'Add Notifications Preferences';

    constructor(public notificationsPreferences: NotificationPreference[]) {}
}

export class AddNotificationsPreferencesSuccess {
    static readonly type = PREFIX + 'Add Notifications Preferences Success';

    constructor(public preferences: UserPreferences) {}
}

export class AddNotificationsPreferencesFailure {
    static readonly type = PREFIX + 'Add Notifications Preferences Failure';

    constructor(public error: any) {}
}

export class DeleteNotificationsPreferences {
    static readonly type = PREFIX + 'Delete Notifications Preferences';

    constructor(public notificationsPreferences: NotificationPreference[]) {}
}

export class DeleteNotificationsPreferencesSuccess {
    static readonly type = PREFIX + 'Delete Notifications Preferences Success';

    constructor(public preferences: UserPreferences) {}
}

export class DeleteNotificationsPreferencesFailure {
    static readonly type = PREFIX + 'Delete Notifications Preferences Failure';

    constructor(public error: any) {}
}

//endregion
