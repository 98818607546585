import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatListModule, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { SiriusTileLayer } from '@domain/leaflet/tile-layer';
import Leaflet from 'leaflet';
import { AddressItemComponent } from '../address-item/address-item.component';
import { Address } from '@domain/shared/address';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-address-creation',
    standalone: true,
    templateUrl: './address-creation.component.html',
    styleUrl: './address-creation.component.scss',
    imports: [
        MatListModule,
        AddressItemComponent,
        TranslocoModule
    ]
})
export class AddressCreationComponent implements AfterViewInit, OnChanges {
    @Input() public addresses: Address[] = [];

    @Output() public selectedAddress: EventEmitter<Address | undefined> = new EventEmitter();

    @ViewChild('matAddressList') matAddressList: MatSelectionList;

	map: Leaflet.Map;
	addressPointMarker: Leaflet.Marker;
	userPosition: {lat: number, lon: number};

    ngAfterViewInit() {
		this.initMap();
    }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['addresses']) {
			if (this.addresses.length > 0) {
				this.selectAddress(this.addresses[0]);
			} else {
				this.removeAddressMarker();
				this.moveToUserPosition();
			}
		}
	}

    private initMap() {		
		if (this.map) {
			this.map.off();
			this.map.clearAllEventListeners();
			this.map.remove();
		}
		this.map = Leaflet.map('map', {
			center: [46, 2],
			zoom: 5,
			zoomSnap: 0,
			attributionControl: false,
			zoomControl: false,
			fullscreenControl: {
				pseudoFullscreen: false,
			},
		});
		const tiles = new SiriusTileLayer();
		tiles.addTo(this.map);
		navigator.geolocation.getCurrentPosition(position => {
			this.userPosition = {lat: position.coords.latitude, lon: position.coords.longitude};
			this.moveToUserPosition();
		});
	}

	moveToUserPosition() {
		if (this.userPosition?.lat && this.userPosition?.lon) {
			this.map.setView([this.userPosition.lat, this.userPosition.lon], 5);
		}
	}
	
	
	onSelectedAddressChange(event: MatSelectionListChange) {
		if (event.source._value?.length) {
			this.selectAddress(event.source._value[0] as Address);
		}
	}

	selectAddress(address: Address) {
		if (address) {
			if (address.position?.coordinates.length == 2) {
				if (this.addressPointMarker) {
					this.removeAddressMarker();
				}
				const icon = new Leaflet.Icon.Default();
				icon.options.shadowSize = [0, 0];
				icon.options.iconUrl = 'assets/images/others/map-marker.png';
				icon.options.iconSize = [40, 40];
				this.addressPointMarker = Leaflet.marker(Leaflet.latLng(address.position.coordinates[1], address.position.coordinates[0]), {
					icon: icon
				});
				this.addressPointMarker.addTo(this.map);
				this.map.setView(this.addressPointMarker.getLatLng(), 13);
			}
			this.selectedAddress.emit(address);
		}
	}

	removeAddressMarker() {
		if (this.addressPointMarker) {
			this.addressPointMarker.removeFrom(this.map);
		}
	}
}
