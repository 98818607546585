import { environment } from '@environment/environment';
import * as L from 'leaflet'

export class SiriusTileLayer extends L.TileLayer {
	constructor() {

		switch(environment.mapTiles) {
			case 'google':
				super('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
					maxZoom: 18,
					minZoom: 3,
					subdomains:['mt0','mt1','mt2','mt3'],
					attribution: '&copy; <a href="https://www.google.fr/maps/">Google Maps</a>'
				});
				break;
			case 'googleWithoutPoi':
				super('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&apistyle=s.t%3A2%7Cs.e%3Al%7Cp.v%3Aoff', {
					maxZoom: 18,
					minZoom: 3,
					subdomains:['mt0','mt1','mt2','mt3'],
					attribution: '&copy; <a href="https://www.google.fr/maps/">Google Maps</a>'
				});
				break;
			default:
				super('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
					maxZoom: 18,
					minZoom: 3,
					attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
				});
				break;
		}
	}
}
