<mat-label *ngIf="!hideLabel" class="d-block" [class.required]="required">{{ 'countries.country' | transloco }}</mat-label>
<mat-form-field appearance="outline" [class.no-space-under]="noSpaceUnder">
	<mat-select *ngIf="control; else noFormControl"
				#select
				[formControl]="control"
				[placeholder]="(hideLabel ? 'countries.country' : 'app.selectPlaceholder') | transloco">
		<mat-option *ngIf="!required && select.value" [value]="null">{{ 'app.all' | transloco }}</mat-option>
		<ng-container *ngIf="countries$ | async as countries">
			<mat-option *ngFor="let country of countries.content" [value]="country.code">{{country.name}}</mat-option>
		</ng-container>
	</mat-select>
	<ng-template #noFormControl>
		<mat-select #select
					class="no-form-control"
					[placeholder]="(hideLabel ? 'countries.country' : 'app.selectPlaceholder') | transloco"
					[value]="value"
					(selectionChange)="onSelectionChange($event.value)">
			<mat-option *ngIf="!required && select.value" [value]="null">{{ 'app.all' | transloco }}</mat-option>
			<ng-container *ngIf="countries$ | async as countries">
				<mat-option *ngFor="let country of countries.content" [value]="country.code">{{country.name}}</mat-option>
			</ng-container>
		</mat-select>
	</ng-template>
	<mat-error *ngIf="required" id="required">{{ "app.requiredField" | transloco }}</mat-error>
</mat-form-field>
