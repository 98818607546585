import { Injectable } from '@angular/core';
import { State, Action, StateContext, StateToken } from '@ngxs/store';
import {
    AssignGroupToContact,
    AssignGroupToContactSuccess,
    CreateContact,
    CreateContactFailure,
    CreateContactSuccess,
    DeleteContact,
    DeleteContactConfirm,
    DeleteContactFailure,
    DeleteContactSuccess,
    LoadContact,
    LoadContactSuccess,
    LoadContacts,
    LoadContactsFailure,
    LoadContactsSuccess,
    OpenAssignGroupModal,
    SwitchViewMode,
    UpdateContact,
    UpdateContactFailure,
    UpdateContactSearch,
    UpdateContactSuccess,
    UpdateCurrentContact,
} from './user-contact.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError, EMPTY } from 'rxjs';
import { UserContactService } from '@services/address-book/user-contact.service';
import { UserContact, UserContactFilter, UserContactGroup } from '@domain/address-book/address-book';
import { PageOption } from '@shared/utils/query/page.options';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { CancelAction, ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { ModalService } from '@services/modal.service';
import { ModalConfirmMessageEnum } from '@shared/components/modal/modal-confirm/modal-confirm-message-enum';

export class UserContactStateModel {
    contacts?: PaginatedEntity<UserContact>;
    groups?: PaginatedEntity<UserContactGroup>;
    pageOption: PageOption;
    contactFilter: UserContactFilter;
    currentContact?: UserContact;
    viewMode: 'card' | 'list';
}

export const USER_CONTACT_STATE_TOKEN: StateToken<UserContactStateModel> = new StateToken<UserContactStateModel>(
    'addressBook',
);

@State<UserContactStateModel>({
    name: USER_CONTACT_STATE_TOKEN,
    defaults: {
        pageOption: {
            size: 0,
            page: 0,
            format: 'FULL',
            sort: '',
        },
        contactFilter: {
            search: '',
        },
        viewMode: 'list',
    },
})
@Injectable()
export class UserContactState {
    translationScope = 'address-book';

    constructor(private _userContactService: UserContactService, private _modalService: ModalService) {}

    @Action(LoadContacts)
    loadContacts(ctx: StateContext<UserContactStateModel>) {
        const pageOption = ctx.getState().pageOption;
        const filter = ctx.getState().contactFilter;
        return this._userContactService.findAllContacts(pageOption, filter).pipe(
            map((contacts) => {
                ctx.dispatch(new LoadContactsSuccess(contacts));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadContactsFailure(error.error.errorCode))),
        );
    }

    @Action(LoadContactsSuccess)
    loadContactsSuccess(ctx: StateContext<UserContactStateModel>, { contacts }: LoadContactsSuccess) {
        ctx.patchState({
            contacts: contacts,
        });
    }

    @Action(LoadContactsFailure)
    loadContactsFailure(ctx: StateContext<UserContactStateModel>, { error }: LoadContactsFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'load_address_book_failure',
                },
                this.translationScope,
            ),
        );
    }

    @Action(UpdateContactSearch)
    updateContactSearch(ctx: StateContext<UserContactStateModel>, { userContactFilter }: UpdateContactSearch) {
        ctx.patchState({
            contactFilter: {
                ...userContactFilter,
            },
        });
        return ctx.dispatch(new LoadContacts());
    }

    @Action(SwitchViewMode)
    switchViewMode(ctx: StateContext<UserContactStateModel>, { viewMode }: SwitchViewMode) {
        ctx.patchState({
            viewMode: viewMode,
        });
    }

    @Action(UpdateCurrentContact)
    updateCurrentContact(ctx: StateContext<UserContactStateModel>, { contact }: UpdateCurrentContact) {
        ctx.patchState({
            currentContact: contact,
        });
    }

    @Action(LoadContact)
    loadContact(ctx: StateContext<UserContactStateModel>, { contactId }: LoadContact) {
        return this._userContactService.get(contactId).pipe(
            map((contact) => {
                ctx.dispatch(new LoadContactSuccess(contact));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadContactsFailure(error.error.errorCode))),
        );
    }

    @Action(LoadContactSuccess)
    loadContactSuccess(ctx: StateContext<UserContactStateModel>, { contact }: LoadContactSuccess) {
        ctx.patchState({
            currentContact: contact,
        });
    }

    @Action(UpdateContact)
    updateContact(ctx: StateContext<UserContactStateModel>, { contactId, contact }: UpdateContact) {
        return this._userContactService.update(contactId, contact).pipe(
            map((contact) => {
                ctx.dispatch(new UpdateContactSuccess(contact));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new UpdateContactFailure(error.error.errorCode))),
        );
    }

    @Action(UpdateContactSuccess)
    updateContactSuccess(ctx: StateContext<UserContactStateModel>, { contact }: UpdateContactSuccess) {
        ctx.patchState({
            currentContact: contact,
        });
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.SUCCESS,
                    text: 'update_contact_success',
                },
                this.translationScope,
            ),
        );
    }

    @Action(UpdateContactFailure)
    updateContactFailure(ctx: StateContext<UserContactStateModel>, { error }: UpdateContactFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'update_contact_failure',
                },
                this.translationScope,
            ),
        );
    }

    @Action(CreateContact)
    createContact(ctx: StateContext<UserContactStateModel>, { contact }: CreateContact) {
        return this._userContactService.create(contact).pipe(
            map((contact) => {
                ctx.dispatch(new CreateContactSuccess(contact));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new CreateContactFailure(error.error.errorCode))),
        );
    }

    @Action(CreateContactSuccess)
    createContactSuccess(ctx: StateContext<UserContactStateModel>, { contact }: CreateContactSuccess) {
        ctx.patchState({
            currentContact: contact,
        });
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.SUCCESS,
                    text: 'create_contact_success',
                },
                this.translationScope,
            ),
        );
    }

    @Action(CreateContactFailure)
    createContactFailure(ctx: StateContext<UserContactStateModel>, { error }: CreateContactFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'create_contact_failure',
                },
                this.translationScope,
            ),
        );
    }

    @Action(DeleteContactConfirm)
    deleteContactConfirm(ctx: StateContext<UserContactStateModel>, { contact }: DeleteContactConfirm) {
        return this._modalService
            .confirm(ModalConfirmMessageEnum.DELETE_CONTACT, ModalConfirmMessageEnum.DELETE_CONTACT, {
                name: `${contact.firstname} ${contact.lastname}`,
            })
            .pipe(
                map((response) => {
                    if (response) {
                        return ctx.dispatch(new DeleteContact(contact.id));
                    }
                    return ctx.dispatch(new CancelAction());
                }),
                catchError(() => EMPTY),
            );
    }

    @Action(DeleteContact)
    deleteContact(ctx: StateContext<UserContactStateModel>, { contactId }: DeleteContact) {
        return this._userContactService.delete(contactId).pipe(
            map(() => {
                ctx.dispatch(new DeleteContactSuccess());
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new DeleteContactFailure(error.error.errorCode))),
        );
    }

    @Action(DeleteContactSuccess)
    deleteContactSuccess(ctx: StateContext<UserContactStateModel>) {
        return ctx.dispatch([
            new ShowMessage(
                {
                    level: MessageLevel.SUCCESS,
                    text: 'delete_contact_success',
                },
                this.translationScope,
            ),
            new LoadContacts(),
        ]);
    }

    @Action(DeleteContactFailure)
    deleteContactFailure(ctx: StateContext<UserContactStateModel>, { error }: DeleteContactFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'delete_contact_failure',
                },
                this.translationScope,
            ),
        );
    }

    @Action(OpenAssignGroupModal)
    openAssignGroupModal(ctx: StateContext<UserContactStateModel>) {
        this._modalService.addGroupToContact();
    }

    @Action(AssignGroupToContact)
    assignGroupToContact(ctx: StateContext<UserContactStateModel>, { groups }: AssignGroupToContact) {
        return ctx.dispatch(new AssignGroupToContactSuccess(groups));
    }
}
