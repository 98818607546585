import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from "@ngneat/transloco";
import { MatButtonModule } from "@angular/material/button";
import { SvgSelectorComponent } from '@shared/components/svg-selector/svg-selector.component';
import { ButtonCustomComponent } from '@shared/components/input/button/button-custom/button-custom.component';

@Component({
	selector: 'app-modal-confirm',
	templateUrl: './modal-confirm.component.html',
	styleUrls: ['./modal-confirm.component.scss'],
	imports: [
		MatDialogModule,
		TranslocoModule,
		MatButtonModule,
		SvgSelectorComponent,
		ButtonCustomComponent,
	],
	standalone: true
})
export class ModalConfirmComponent {

	constructor(public dialogRef: MatDialogRef<ModalConfirmComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	closeModal(result: boolean): void {
		this.dialogRef.close(result);
	}
}
