export interface UserPreferences {
	preferredLang: string;
	preferredTimezone: string;
	preferredHomePage: string;
	notificationsPreferences : NotificationPreference[];
}

export class NotificationPreference {
	category : NotificationCategoryEnum;
	media : NotificationMediaEnum;

	constructor(category: NotificationCategoryEnum, media: NotificationMediaEnum) {
		this.category = category;
		this.media = media;
	}
}

export class NotificationPreferenceRow {
	allMediasCheckedForCategory: boolean;
	category : NotificationCategoryEnum;
	mediasForCategory: boolean[];

	constructor(category: NotificationCategoryEnum, mediasForCategory: boolean[]) {
		this.category = category;
		this.mediasForCategory = mediasForCategory;
		this.allMediasCheckedForCategory = mediasForCategory.every(mediaChecked => mediaChecked);
	}
}

// values of enum must be same as backend and database NotificationMediaEnum
// enum's item order make order for notifcations preferences table
export enum NotificationMediaEnum {
	SMS = 'SMS',
	MAIL = 'MAIL',
	APP = 'APP'
}

// values of enum must be same as backend and database NotificationCategoryEnum
// enum's item order make order for notifcations preferences table
export enum NotificationCategoryEnum {
	SECURITY = 'SECURITY',
	SYSTEM = 'SYSTEM',
	ACCOUNT = 'ACCOUNT',
	ORGANIZATION = 'ORGANIZATION',
	COLLABORATORS = 'COLLABORATORS',
	QUOTE = 'QUOTE',
	FILE_FOLLOWUP = 'FILE_FOLLOWUP',
	DOCUMENT = 'DOCUMENT',
	INCIDENT = 'INCIDENT',
	DECISION_MAKING = 'DECISION_MAKING'
}