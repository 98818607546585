import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, delay, first, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { LocationsService } from '@services/locations/locations.service';
import {
	LoadCountries,
	LoadCountriesFailure,
	LoadCountriesSuccess,
	LoadPhoneTypes,
	LoadPhoneTypesFailure,
	LoadPhoneTypesSuccess,
	LoadPhoneVisibilities,
	LoadPhoneVisibilitiesFailure,
	LoadPhoneVisibilitiesSuccess,
	UserLoadHomePages,
	UserLoadHomePagesFailure,
	UserLoadHomePagesSuccess,
	UserLoadLanguages,
	UserLoadLanguagesFailure,
	UserLoadLanguagesSuccess,
	UserLoadTimezones,
	UserLoadTimezonesFailure,
	UserLoadTimezonesSuccess,
} from './referentials.actions';
import { Countries, Country } from '@domain/locations/country';
import { UserService } from '@services/users/user.service';
import { Timezone } from '@domain/user/timezone';
import { TranslocoService } from '@ngneat/transloco';

export interface ReferentialsStateModel {
	countries: Countries | null;
	languages: string[];
	timezones: Timezone[];
	phoneTypes: string[];
	phoneVisibilities: string[];
	homePages: string[];
}

@State<ReferentialsStateModel>({
	name: 'countries',
	defaults: {
		countries: null,
		languages: [],
		timezones: [],
		phoneTypes: [],
		phoneVisibilities: [],
		homePages: [],
	}
})

@Injectable()
export class ReferentialsState {
	constructor(
			private locationsService: LocationsService,
			private userService: UserService,
			private transloco: TranslocoService,
	) {
	}

	//#region countries

	@Action(LoadCountries)
	loadCountries(ctx: StateContext<ReferentialsStateModel>) {
		const countries = ctx.getState().countries;
		if (countries && countries.content && countries.content.length > 0) {
			return;
		}
		return this.locationsService.getAllCountries().pipe(
				map(countries => ctx.dispatch(new LoadCountriesSuccess(countries))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadCountriesFailure(error.error.errorCode)))
		)
	}

	@Action(LoadCountriesSuccess)
	loadCountriesSuccess(ctx: StateContext<ReferentialsStateModel>, { countries }: LoadCountriesSuccess) {
		countries = {
			...countries,
			content: countries.content.map(country => ({
				...country,
				name: this.transloco.translate(`countries.code.${country.code}`),
			})).sort((a: Country, b: Country) => a.name.localeCompare(b.name)),
		};
		ctx.patchState({
			countries
		});
	}

	@Action(LoadCountriesFailure)
	loadCountriesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: LoadCountriesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_countries_failure', level: MessageLevel.ERROR }, 'locations'))
	}

	//#endregion

	//#region languages

	@Action(UserLoadLanguages)
	userLoadLanguages(ctx: StateContext<ReferentialsStateModel>) {
		const languages = ctx.getState().languages;
		if (languages && languages.length > 0) {
			return;
		}
		return this.userService.getLanguages().pipe(
				map(langs => ctx.dispatch(new UserLoadLanguagesSuccess(langs))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UserLoadLanguagesFailure(error.error.errorCode)))
		)
	}

	@Action(UserLoadLanguagesSuccess)
	userLoadLanguagesSuccess(ctx: StateContext<ReferentialsStateModel>, { languages }: UserLoadLanguagesSuccess) {
		ctx.patchState({
			languages
		});
	}

	@Action(UserLoadLanguagesFailure)
	userLoadLanguagesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: UserLoadLanguagesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_languages_failure', level: MessageLevel.ERROR }, 'profile'))
	}

	//#endregion

	//#region timezones

	@Action(UserLoadTimezones)
	userLoadTimezones(ctx: StateContext<ReferentialsStateModel>) {
		const timezones = ctx.getState().timezones;
		if (timezones && timezones.length > 0) {
			return;
		}
		return this.userService.getTimezones().pipe(
				map(tz => ctx.dispatch(new UserLoadTimezonesSuccess(tz))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UserLoadTimezonesFailure(error.error.errorCode)))
		)
	}

	@Action(UserLoadTimezonesSuccess)
	userLoadTimezonesSuccess(ctx: StateContext<ReferentialsStateModel>, { timezones }: UserLoadTimezonesSuccess) {
		ctx.patchState({
			timezones
		});
	}

	@Action(UserLoadTimezonesFailure)
	userLoadTimezonesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: UserLoadTimezonesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_timezones_failure', level: MessageLevel.ERROR }, 'profile'))
	}

	//#endregion

	//#region phone types

	@Action(LoadPhoneTypes)
	loadPhoneTypes(ctx: StateContext<ReferentialsStateModel>) {
		const phoneTypes = ctx.getState().phoneTypes;
		if (phoneTypes && phoneTypes.length > 0) {
			return;
		}
		return this.userService.getPhoneTypes().pipe(
				map(types => ctx.dispatch(new LoadPhoneTypesSuccess(types))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadPhoneTypesFailure(error.error.errorCode)))
		)
	}

	@Action(LoadPhoneTypesSuccess)
	loadPhoneTypesSuccess(ctx: StateContext<ReferentialsStateModel>, { phoneTypes }: LoadPhoneTypesSuccess) {
		return ctx.patchState({
			phoneTypes
		});
	}

	@Action(LoadPhoneTypesFailure)
	loadPhoneTypesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: LoadPhoneTypesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_phone_types_failure', level: MessageLevel.ERROR }, 'phones'))
	}

	//#endregion

	//#region phone Visibilities

	@Action(LoadPhoneVisibilities)
	loadPhoneVisibilities(ctx: StateContext<ReferentialsStateModel>) {
		const phoneVisibilities = ctx.getState().phoneTypes;
		if (phoneVisibilities && phoneVisibilities.length > 0) {
			return;
		}
		return this.userService.getPhoneVisibilities().pipe(
				map(visibilities => ctx.dispatch(new LoadPhoneVisibilitiesSuccess(visibilities))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadPhoneVisibilitiesFailure(error.error.errorCode)))
		)
	}

	@Action(LoadPhoneVisibilitiesSuccess)
	loadPhoneVisibilitiesSuccess(ctx: StateContext<ReferentialsStateModel>, { phoneVisibilities }: LoadPhoneVisibilitiesSuccess) {
		ctx.patchState({
			phoneVisibilities
		});
	}

	@Action(LoadPhoneVisibilitiesFailure)
	loadPhoneVisibilitiesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: LoadPhoneVisibilitiesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_phone_visibilities_failure', level: MessageLevel.ERROR }, 'phones'))
	}

	//#endregion

	//#region home pages

	@Action(UserLoadHomePages)
	loadHomePages(ctx: StateContext<ReferentialsStateModel>) {
		const homePages = ctx.getState().homePages;
		if (homePages && homePages.length > 0) {
			return;
		}
		return this.userService.getHomePages().pipe(
				map(homePages => ctx.dispatch(new UserLoadHomePagesSuccess(homePages))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UserLoadHomePagesFailure(error.error.errorCode)))
		);
	}

	@Action(UserLoadHomePagesSuccess)
	loadHomePagesSuccess(ctx: StateContext<ReferentialsStateModel>, { homePages }: UserLoadHomePagesSuccess) {
		ctx.patchState({
			homePages
		});
	}

	@Action(UserLoadHomePagesFailure)
	loadHomePagesFailure(ctx: StateContext<ReferentialsStateModel>, { error }: UserLoadHomePagesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_home_pages_failure', level: MessageLevel.ERROR }, 'profile'))
	}
	//#endregion
}
