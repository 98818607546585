import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonCustomComponent } from '@shared/components/input/button/button-custom/button-custom.component';
import { AddressSelectorComponent } from '../address-selector.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Address } from '@domain/shared/address';
import { AddressItemComponent } from '../address-item/address-item.component';

@Component({
    selector: 'app-address-selection',
    standalone: true,
    templateUrl: './address-selection.component.html',
    styleUrl: './address-selection.component.scss',
    imports: [
        MatIconModule,
        ButtonCustomComponent,
        TranslocoModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        AsyncPipe,
        NgTemplateOutlet,
        AddressSelectionComponent,
        AddressItemComponent
    ]
})
export class AddressSelectionComponent {
    @Input() addresses: Address[] = [];
    @Input() searchInputText: string;

    @Output() public selectedAddress: EventEmitter<Address | undefined> = new EventEmitter();
    @Output() public newAddressClick: EventEmitter<void> = new EventEmitter();

    @ViewChild('matAddressList') matAddressList: MatSelectionList;

    constructor(public dialogRef: MatDialogRef<AddressSelectorComponent>, private store: Store) {
    }

    setSelectedAddress($event: MatSelectionListChange) {
        if ($event?.source?._value) {
            this.selectedAddress.emit($event.source._value[0] as Address);
        }
    }

	addAddress() {
        this.newAddressClick.emit();
	}
}
