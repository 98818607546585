<ng-container *transloco="let t">
	<div mat-dialog-title>
		<span class="title">{{ 'modal.confirm.title.' + data.title | transloco }}</span>
	</div>
	<div mat-dialog-content>
		<div class="body">
			<div>
				{{ 'modal.confirm.body.' + data.body | transloco:data.params }}

			</div>
			<app-shipment-container-chip [container]="container" [actions]="false"/>

		</div>
	</div>
	<div mat-dialog-actions class="actions">
		<app-button type="line" subtype="default" (clicked)="closeModal(false)">
			{{ 'app.cancel' | transloco }}
		</app-button>
		<app-button type="default" subtype="red" (clicked)="closeModal(true)">
			{{ 'app.delete' | transloco }}
		</app-button>
	</div>
</ng-container>
