import { DashboardActions } from './dashboard.actions.enum';

const PREFIX = '[dashboard] ';

export class CountUserBookings {
    static readonly type = PREFIX + DashboardActions.COUNT_USER_BOOKINGS;
    constructor() {}
}
export class CountUserBookingsSuccess {
    static readonly type = PREFIX + DashboardActions.COUNT_USER_BOOKINGS_SUCCESS;
    constructor(public nbBookings: number) {}
}
export class CountUserBookingsFailure {
    static readonly type = PREFIX + DashboardActions.COUNT_USER_BOOKINGS_FAILURE;
    constructor(public error: any) {}
}
export class AddOneToCountUserBookings {
    static readonly type = PREFIX + DashboardActions.ADD_ONE_TO_COUNT_USER_BOOKINGS;
    constructor() {}
}
export class MinusOneToCountUserBookings {
    static readonly type = PREFIX + DashboardActions.MINUS_ONE_TO_COUNT_USER_BOOKINGS;
    constructor() {}
}
