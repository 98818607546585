import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-search-input',
  standalone: true,
	imports: [
		TranslocoPipe,
		FormsModule,
		NgIf
	],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements OnInit, AfterViewInit {

	searchChanged: Subject<string> = new Subject<string>();

	@Input() placeholder: string;
	@Input() value: string | undefined = '';
	@Input() displayClear = false;
	@Input() debounceTime = 500;
	@Input() autoFocus = false;
	@Input() pressEnterTriggerValueChange = false;
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() clearEvent: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('search') searchInputElement: ElementRef;

	constructor(private destroyRef: DestroyRef) {}

	ngOnInit(): void {
		this.searchChanged.pipe(
			debounceTime(this.debounceTime),
			distinctUntilChanged(),
			takeUntilDestroyed(this.destroyRef)
		).subscribe(search => {
			this.value = search;
			this.valueChange.emit(search);
		});
	}
	
	keyPressed($event: KeyboardEvent) {
		if(this.pressEnterTriggerValueChange && $event.key == 'Enter') {
			this.value = this.searchInputElement.nativeElement.value;
			this.valueChange.emit(this.value);
		}
	}

	ngAfterViewInit(): void {
		if (this.autoFocus) {
			setTimeout(() => {
				this.searchInputElement.nativeElement.focus();
			}, 200); 
		}
	}
}
