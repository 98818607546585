<ng-container >
	<h1 mat-dialog-title> {{ 'customer.newContact' | transloco }} </h1>
	<div mat-dialog-content class="overflow-hidden">
		<div *ngIf="customerData.name && customerData.countryCode" class="owner d-flex align-items-center">
			<span> <span class="fw-bolder"> {{ customerData.name }} </span> {{ country | transloco }}</span>
		</div>

		<form [formGroup]="contactForm" class="row">

			<div class="d-lg-flex flex-lg-row flex-lg-wrap gap-lg-0 gap-2 align-items-lg-baseline d-flex flex-column justify-content-between align-items-center">
				<span class="icon-add-contact d-block text-black fs-5 fw-bold mt-lg-1 me-lg-2"></span>
				<mat-form-field appearance="outline">
					<input formControlName="firstname" [placeholder]="'customer.firstname' | transloco" matInput>
					<mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<input formControlName="lastname" [placeholder]="'customer.lastname' | transloco" matInput>
					<mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<input formControlName="mail" matInput [placeholder]="'customer.mail' | transloco">
					<mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
				</mat-form-field>

				<div class="phones">

					<app-add-phone-list [labels]="false" [appearance]="'outline'" [formArray]="contactPhones"
										(removePhoneEvent)="removePhoneNumber($event)"
										(addPhoneEvent)="addPhoneNumber()"></app-add-phone-list>
				</div>
			</div>
			<hr>

			<div class="row g-2">
				<div class="col-12 mb-2">
					<mat-label class="d-block mb-1"> {{ 'customer.gender' | transloco }}</mat-label>
					<mat-radio-group aria-label="Select an option" formControlName="gender">
						<mat-radio-button [value]="genderEnum.M">{{ 'customer.male' | transloco }}</mat-radio-button>
						<mat-radio-button [value]="genderEnum.F">{{ 'customer.female' | transloco }}</mat-radio-button>
						<mat-radio-button [value]="genderEnum.N">{{ 'customer.neutral' | transloco }}</mat-radio-button>
					</mat-radio-group>
				</div>

				<div class="col-12 row">
					<div class="col-3">
						<mat-label class="d-block">{{ 'customer.role' | transloco }}</mat-label>
						<mat-form-field appearance="outline">
							<input formControlName="role" matInput>
							<mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
						</mat-form-field>
					</div>
				</div>

				<mat-checkbox formControlName="notifyMe"> {{ 'customer.notifyMe' | transloco }} </mat-checkbox>
			</div>

		</form>

	</div>
	<div mat-dialog-actions>
		<app-button (clicked)="cancelCreate()">
			{{ 'app.cancel' | transloco }}
		</app-button>
		<app-button (clicked)="addContact()" subtype="gradient">
			{{ 'app.create' | transloco }}
		</app-button>
	</div>
</ng-container>
