import { Component, Inject } from '@angular/core';
import { ButtonCustomComponent } from "@shared/components/input/button/button-custom/button-custom.component";
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogContent,
	MatDialogRef,
	MatDialogTitle
} from "@angular/material/dialog";
import { TranslocoDirective, TranslocoPipe } from "@ngneat/transloco";
import { Store } from "@ngxs/store";
import { Equipment } from "@domain/shipment/equipment";
import {
	ShipmentContainerChipComponent
} from "@features/private/shipment/shipment-details/shipment-containers/shipment-container-chip/shipment-container-chip.component";

@Component({
  selector: 'app-modal-confirm-delete-container',
  standalone: true,
	imports: [
		ButtonCustomComponent,
		MatDialogActions,
		MatDialogContent,
		MatDialogTitle,
		TranslocoDirective,
		TranslocoPipe,
		ShipmentContainerChipComponent
	],
  templateUrl: './modal-confirm-delete-container.component.html',
  styleUrl: './modal-confirm-delete-container.component.scss'
})
export class ModalConfirmDeleteContainerComponent {

	container: Equipment;
	constructor(public dialogRef: MatDialogRef<ModalConfirmDeleteContainerComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.container = data.container || {};
	}

	closeModal(result: boolean): void {
		this.dialogRef.close(result);
	}
}
