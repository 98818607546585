import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';

import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TranslocoRootModule } from './transloco-root.module';

import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { ObjectAccessPipe } from '@shared/pipes/object-access.pipe';
import { GlobalState } from '@state/global/global.state';
import { ReferentialsState } from '@state/referentials/referentials.state';
import { merge } from 'lodash';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { NgxIntlTelInputModule } from '@justin-s/ngx-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { NotificationsState } from '@state/notifications/notifications.state';
import { KeyValuePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DashboardState } from '@state/dashboard/dashboard.state';

function initializeEnvAndKeycloak(keycloak: KeycloakService, httpClient: HttpClient, translocoService: TranslocoService) {
	return async () => {
		// Get environment configuration from server and override local properties.
		// Keep bootstraping the app if the configuration cannot be fetched from server and
		// use bundled environment file.
		try {
			const config = await lastValueFrom(httpClient.get('/config.json'));
			merge(environment, config);
		} catch (e) {
			console.error(e);
		}

		// refresh token before expiration (or call login method)
		keycloak.keycloakEvents$.subscribe({
			next: (e) => {
				if (e.type == KeycloakEventType.OnTokenExpired) {
					keycloak
							.updateToken()
							.then((res) => {
								if (!res) {
									keycloak.login();
								}
							})
							.catch(() => {
								keycloak.login();
							});
				}
			},
		});

		const keycloakPromise = keycloak.init({
			config: {
				url: environment.keycloak.url,
				realm: environment.keycloak.realm,
				clientId: environment.keycloak.client,
			},
			initOptions: {
				onLoad: 'check-sso',
				checkLoginIframe: false,
			},
		});
		const translocoPromise = firstValueFrom(translocoService.load(defaultLang));
		return Promise.all([keycloakPromise, translocoPromise]);
	};
}

registerLocaleData(localeFr, 'fr');
const defaultLang = getBrowserLang() || 'fr';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		NgxsModule.forRoot([DashboardState, GlobalState, NotificationsState, ReferentialsState], {
			developmentMode: !environment.production,
		}),
		NgxsReduxDevtoolsPluginModule.forRoot({
			maxAge: 25,
			disabled: environment.production,
		}),
		NgxsRouterPluginModule.forRoot(),
		KeycloakAngularModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		NgxIntlTelInputModule,
		MatSelectModule,
		MatListModule,
		MatTableModule,
		TranslocoRootModule,
		MatSnackBarModule,
		MatNativeDateModule,
		MatDialogModule,

		RecaptchaModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeEnvAndKeycloak,
			multi: true,
			deps: [KeycloakService, HttpClient, TranslocoService],
		},
		// {
		// 	provide: RECAPTCHA_SETTINGS,
		// 	useValue: { siteKey: environment.reCaptcha.siteKey } as RecaptchaSettings,
		// },
		{ provide: MAT_DATE_LOCALE, useValue: defaultLang },
		{ provide: LOCALE_ID, useValue: defaultLang },
		ObjectAccessPipe,
		KeyValuePipe,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
