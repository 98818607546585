import { Shipment, ShipmentFilterData, Shipments } from '@domain/shipment/shipment';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ShipmentService } from '@services/shipments/shipment.service';
import {
	ChangeKanbanSpinner,
	ChangeShipmentsSpinner,
	CloneEquipment,
	CloneEquipmentConfirm,
	CreateCargoItems,
	CreateCargoItemsFailure,
	CreateCargoItemsSuccess,
	CreateDoubleTrucking,
	CreateDoubleTruckingFailure,
	CreateDoubleTruckingSuccess,
	CreateEquipment,
	CreateEquipmentFailure,
	CreateEquipmentSuccess,
	CreateShipment,
	CreateShipmentFailure,
	CreateShipmentSuccess,
	DeleteCargoItems,
	DeleteCargoItemsFailure,
	DeleteCargoItemsForm,
	DeleteCargoItemsSuccess,
	DeleteDoubleTrucking,
	DeleteDoubleTruckingConfirm,
	DeleteDoubleTruckingFailure,
	DeleteDoubleTruckingSuccess,
	DeleteEquipment,
	DeleteEquipmentConfirm,
	DeleteEquipmentFailure,
	DeleteEquipmentSuccess,
	DeleteShipment,
	DeleteShipmentConfirm,
	DeleteShipmentFailure,
	DeleteShipmentSuccess,
	GroupEquipmentMerchandise,
	GroupEquipmentMerchandiseFailure,
	GroupEquipmentMerchandiseSuccess,
	LoadEquipmentRef,
	LoadEquipmentRefFailure,
	LoadEquipmentRefSuccess,
	LoadFilterCustomer,
	LoadFilterLocation,
	LoadLocations,
	LoadLocationsSuccess,
	LoadQuotes,
	LoadQuotesFailure,
	LoadQuotesSuccess,
	LoadShipment,
	LoadShipmentFailure,
	LoadShipments,
	LoadShipmentsFailure,
	LoadShipmentsKanban,
	LoadShipmentsKanbanFailure,
	LoadShipmentsKanbanMobile,
	LoadShipmentsKanbans,
	LoadShipmentsKanbanSuccess,
	LoadShipmentsSuccess,
	LoadShipmentSuccess,
	LoadUnNumbers,
	LoadUnNumbersFailure,
	LoadUnNumbersSuccess,
	OpenAddContactToShipmentCustomerDialog,
	PatchCargoItemLocal,
	PatchCargoItems,
	PatchCargoItemsFailure,
	PatchCargoItemsSuccess,
	PatchEquipment,
	PatchEquipmentDetail,
	PatchEquipmentDetailFailure,
	PatchEquipmentDetailSuccess,
	PatchEquipmentFailure,
	PatchEquipmentGrouped,
	PatchEquipmentGroupedSuccess,
	PatchEquipmentSuccess,
	PatchShipment,
	PatchShipmentFailure,
	PatchShipmentStatus,
	PatchShipmentStatusFailure,
	PatchShipmentStatusSuccess,
	PatchShipmentSuccess,
	PatchShipmentType,
	PatchShipmentTypeFailure,
	PatchShipmentTypeSuccess,
	ResetShipmentClient,
	SelectQuote,
	SelectQuoteFailure,
	SelectQuoteSuccess,
	UpdateDoubleTrucking,
	UpdateDoubleTruckingFailure,
	UpdateDoubleTruckingSuccess,
	UpdateLocationSearch,
	UpdateRefSearch,
	UpdateShipment,
	UpdateShipmentFailure,
	UpdateShipmentsFilter,
	UpdateShipmentsFilterData,
	UpdateShipmentsPage,
	UpdateShipmentsSort,
	UpdateShipmentSuccess,
	UpdateUnNumberFilter,
} from '@state/shipment/shipment.action';
import { catchError, EMPTY, finalize, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CancelAction, ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { UserStateSelector } from '@state/user/user.selectors';
import { Navigate } from '@ngxs/router-plugin';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Equipment, EquipmentPatch, UnNumber, UnNumberFilter } from '@domain/shipment/equipment';
import { EquipmentService } from '@services/shipments/equipment.service';
import { CargoItem } from '@domain/shipment/cargoItem';
import { CargoItemsService } from '@services/shipments/cargo-items.service';
import { ShipmentStatusEnum } from '@domain/shipment/shipment-status.enum';
import { PageOption } from '@shared/utils/query/page.options';
import { EquipmentRef } from '@domain/shipment/equipmentRef';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { LocationsService } from '@services/locations/locations.service';
import { CustomerReponse } from '@domain/client/customer';
import { CustomerService } from '@services/customers/customer.service';
import { ModalConfirmMessageEnum } from '@shared/components/modal/modal-confirm/modal-confirm-message-enum';
import { AddAddressToBookSuccess, UpdateCustomerFilter } from '@state/customer/customer.actions';
import { ModalService } from '@services/modal.service';
import { DoubleTruckingService } from '@services/shipments/double-trucking.service';
import { AdditionalServicesType, IAdditionalService } from '@domain/shipment/additionalservices/additional-services';
import { ShipmentFilter } from '@domain/shipment/shipment.filter';
import { QueryParamType } from '@shared/utils/query/query.builder';
import { AddOneToCountUserBookings, MinusOneToCountUserBookings } from '@state/dashboard/dashboard.actions';
import { MetricEnum, RoutingPlan } from "@domain/shipment/routing.plan";
import { QuoteType } from "@features/private/shipment/shipment-quotes/shipment-quotes.component";
import { Location, LocationFilter } from "@domain/locations/location";
import { LoadLocationsFailure } from "@state/locations/locations.actions";

export interface ShipmentStateModel {
	shipments?: Shipments;
	shipmentSpinner: boolean;
	shipment?: Shipment;
	loadingShipment?: boolean;
	shipmentFilter: ShipmentFilter;
	equipmentRefs?: PaginatedEntity<EquipmentRef>;
	refSearch?: string;
	shipmentFilterData?: ShipmentFilterData;
	equipments?: Equipment[];
	equipment?: Equipment;
	routingPlans?: RoutingPlan[];
	pageOption: PageOption;
	kanbans: Kanban[];
	unNumbers?: PaginatedEntity<UnNumber>;
	unNumberFilter?: UnNumberFilter;
	containersColors: string[];
	locationsSearch?: string;
	locations?: PaginatedEntity<Location>;
	locationPage: PageOption;
}

export interface Kanban {
	status: ShipmentStatusEnum;
	pageOption: PageOption;
	shipmentFilter?: ShipmentFilter;
	shipments?: Shipments;
	spinner: boolean;
}

const initialState: ShipmentStateModel = {
	shipmentSpinner: false,
	pageOption: {
		size: 10,
		page: 0,
		sort: '-createdOn',
		format: 'FULL',
	},
	shipmentFilter: {
		search: {
			key: 'search',
			value: undefined,
			type: QueryParamType.like,
		},
		customers: {
			key: 'clientId',
			value: [],
			type: QueryParamType.equal,
		},
		status: {
			key: 'status',
			value: [],
			type: QueryParamType.equal,
		},
		destination: {
			key: 'dest',
			value: [],
			type: QueryParamType.equal,
		},
		dateAfter: {
			key: 'createdOnAfter',
			value: undefined,
			type: QueryParamType.greater,
		},
		dateBefore: {
			key: 'createdOnBefore',
			value: undefined,
			type: QueryParamType.lower,
		},
	},
	shipmentFilterData: {
		customers: [],
		countries: [],
		customersSearch: '',
	},
	locationPage: {
		size: 10,
		sort: 'name',
		page: 0
	},
	kanbans: [
		{
			status: ShipmentStatusEnum.DRAFT,
			spinner: false,
			pageOption: {
				size: 5,
				page: 0,
				sort: 'createdOn',
			},
			shipmentFilter: {
				status: {
					key: 'status',
					value: [ShipmentStatusEnum.DRAFT],
					type: QueryParamType.equal,
				},
			},
		},
		{
			status: ShipmentStatusEnum.BOOKED,
			spinner: false,
			pageOption: {
				size: 5,
				page: 0,
				sort: 'createdOn',
			},
			shipmentFilter: {
				status: {
					key: 'status',
					value: [ShipmentStatusEnum.BOOKED],
					type: QueryParamType.equal,
				},
			},
		},
		{
			status: ShipmentStatusEnum.ON_THE_WAY,
			spinner: false,
			pageOption: {
				size: 5,
				page: 0,
				sort: 'createdOn',
			},
			shipmentFilter: {
				status: {
					key: 'status',
					value: [ShipmentStatusEnum.ON_THE_WAY],
					type: QueryParamType.equal,
				},
			},
		},
		{
			status: ShipmentStatusEnum.DELIVERED,
			spinner: false,
			pageOption: {
				size: 5,
				page: 0,
				sort: 'createdOn',
			},
			shipmentFilter: {
				status: {
					value: [ShipmentStatusEnum.DELIVERED],
					key: 'status',
					type: QueryParamType.equal,
				},
			},
		},
	],
	containersColors: [
		'#080F36',
		'#20A1B2',
		'#B3206B',
		'#5C72D7',
		'#046282',
		'#23B681',
		'#3453A9',
		'#E0454D',
		'#30585E',
		'#67748A',
	]
};

export const SHIPMENT_STATE_TOKEN: StateToken<ShipmentStateModel> = new StateToken<ShipmentStateModel>('shipments');

@State<ShipmentStateModel>({
	name: SHIPMENT_STATE_TOKEN,
	defaults: initialState,
})
@Injectable()
export class ShipmentState {
	translationScope: string = 'shipments';

	constructor(
			private _shipmentService: ShipmentService,
			private _equipmentService: EquipmentService,
			private _cargoItemService: CargoItemsService,
			private _doubleTruckingService: DoubleTruckingService,
			private _locationsService: LocationsService,
			private _customerService: CustomerService,
			private _modalService: ModalService,
			private _store: Store,
	) {
	}

	@Action(LoadShipments)
	loadShipments(ctx: StateContext<ShipmentStateModel>) {
		const pageOption = ctx.getState().pageOption;
		const filter = ctx.getState().shipmentFilter;
		ctx.dispatch(new ChangeShipmentsSpinner(true));
		return this._shipmentService.findAll(pageOption, filter).pipe(
				map((shipments) => ctx.dispatch(new LoadShipmentsSuccess(shipments))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadShipmentsFailure(error.error.errorCode))),
		);
	}

	@Action(LoadShipmentsKanbans)
	loadShipmentsKanbans(ctx: StateContext<ShipmentStateModel>) {
		return ctx.dispatch([
			new LoadShipmentsKanban(ShipmentStatusEnum.DRAFT),
			new LoadShipmentsKanban(ShipmentStatusEnum.BOOKED),
			new LoadShipmentsKanban(ShipmentStatusEnum.ON_THE_WAY),
			new LoadShipmentsKanban(ShipmentStatusEnum.DELIVERED),
		]);
	}

	@Action(LoadShipmentsKanban)
	loadShipmentsKanban(ctx: StateContext<ShipmentStateModel>, { status, clear }: LoadShipmentsKanban) {
		const kanbans = ctx.getState().kanbans;
		const currentKanban = ctx.getState().kanbans[kanbans.findIndex((k) => k.status === status)];
		const filter: ShipmentFilter = {
			...ctx.getState().shipmentFilter,
			status: {
				key: 'status',
				value: [currentKanban.status],
				type: QueryParamType.equal,
			},
		};
		const pageOption = {
			...currentKanban.pageOption,
		};
		if (clear) {
			pageOption.page = 0;
		} else {
			pageOption.page += 1;
		}
		ctx.dispatch(new ChangeKanbanSpinner(status, true));
		return this._shipmentService.findAll(pageOption, filter).pipe(
				map((shipments) => ctx.dispatch([new LoadShipmentsKanbanSuccess(shipments, status, clear)])),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(new LoadShipmentsKanbanFailure(error.error.errorCode, status)),
				),
		);
	}

	@Action(LoadShipmentsKanbanMobile)
	loadShipmentsKanbanMobile(ctx: StateContext<ShipmentStateModel>, { clear }: LoadShipmentsKanbanMobile) {
		const filter = ctx.getState().shipmentFilter;
		const pageOption = { ...ctx.getState().pageOption };
		if (clear) {
			pageOption.page = 0;
		} else {
			pageOption.page += 1;
		}
		ctx.dispatch(new ChangeShipmentsSpinner(true));
		return this._shipmentService.findAll(pageOption, filter).pipe(
				map((shipments) => ctx.dispatch([new LoadShipmentsSuccess(shipments, clear)])),
				catchError((error: HttpErrorResponse) => ctx.dispatch([new LoadShipmentsFailure(error.error.errorCode)])),
		);
	}

	@Action(LoadShipmentsKanbanSuccess)
	loadShipmentsKanbanSuccess(
			ctx: StateContext<ShipmentStateModel>,
			{ shipments, status, clear }: LoadShipmentsKanbanSuccess,
	) {
		const k = ctx.getState().kanbans;
		const index = k.findIndex((kanban) => kanban.status === status);
		if (!clear) {
			return ctx.setState(
					patch({
						kanbans: updateItem<Kanban>(
								index,
								patch({
									shipments: patch({
										content: shipments.content,
										numberOfElements: k[index].shipments!.numberOfElements + shipments.numberOfElements,
										size: k[index].shipments!.size + shipments.numberOfElements,
										first: shipments.first,
										last: shipments.last,
									}),
									pageOption: patch({
										page: shipments.number,
									}),
									spinner: false,
								}),
						),
					}),
			);
		} else {
			return ctx.setState(
					patch({
						kanbans: updateItem<Kanban>(
								index,
								patch({
									shipments: shipments,
									pageOption: patch({
										page: shipments.number,
									}),
									spinner: false,
								}),
						),
					}),
			);
		}
	}

	@Action(LoadShipmentsKanbanFailure)
	loadShipmentsKanbanFailure(ctx: StateContext<ShipmentStateModel>, { error, status }: LoadShipmentsKanbanFailure) {
		return ctx.dispatch([
			new ShowMessage(
					{
						text: 'load_shipments_kanban_failure.' + status,
						level: MessageLevel.ERROR,
					},
					'shipments',
			),
			new ChangeKanbanSpinner(status, false),
		]);
	}

	@Action(ChangeShipmentsSpinner)
	changeShipmentsSpinner(ctx: StateContext<ShipmentStateModel>, { active }: ChangeShipmentsSpinner) {
		ctx.setState(
				patch({
					shipmentSpinner: active,
				}),
		);
	}

	@Action(ChangeKanbanSpinner)
	changeKanbanSpinner(ctx: StateContext<ShipmentStateModel>, { status, active }: ChangeKanbanSpinner) {
		const k = ctx.getState().kanbans;
		const index = k.findIndex((kanban) => kanban.status === status);
		ctx.setState(
				patch({
					kanbans: updateItem<Kanban>(
							index,
							patch({
								spinner: active,
							}),
					),
				}),
		);
	}

	@Action(LoadShipmentsSuccess)
	loadShipmentsSuccess(ctx: StateContext<ShipmentStateModel>, { shipments, clear }: LoadShipmentsSuccess) {
		if (!clear) {
			return ctx.setState(
					patch({
						shipments: patch({
							content: append(shipments.content),
							numberOfElements: ctx.getState().shipments!.numberOfElements + shipments.numberOfElements,
							size: ctx.getState().shipments!.size + shipments.numberOfElements,
							first: shipments.first,
							last: shipments.last,
						}),
						pageOption: patch({
							page: shipments.number,
						}),
						shipmentSpinner: false,
					}),
			);
		} else {
			return ctx.patchState({
				shipments: shipments,
				shipmentSpinner: false,
			});
		}
	}

	@Action(LoadShipmentsFailure)
	loadShipmentsFailure(ctx: StateContext<ShipmentStateModel>, { error }: LoadShipmentsFailure) {
		return ctx.dispatch([
			new ChangeShipmentsSpinner(false),
			new ShowMessage(
					{
						text: 'load_shipments_failure',
						level: MessageLevel.ERROR,
					},
					'shipments',
			),
		]);
	}

	@Action(UpdateShipmentsPage)
	updateShipmentsPage(ctx: StateContext<ShipmentStateModel>, { page, size }: UpdateShipmentsPage) {
		const state = ctx.getState();
		ctx.patchState({
			pageOption: {
				...state.pageOption,
				page: page,
				size: size,
			},
		});
		return ctx.dispatch(new LoadShipments());
	}

	@Action(UpdateShipmentsFilter)
	updateShipmentsFilter(ctx: StateContext<ShipmentStateModel>, { filter }: UpdateShipmentsFilter) {
		for (const [key, value] of Object.entries(filter!)) {
			ctx.patchState({
				shipmentFilter: {
					...ctx.getState().shipmentFilter,
					[key]: {
						...ctx.getState().shipmentFilter[key]!,
						value,
					},
				},
			});
		}
		return ctx.dispatch(new LoadShipments());
	}

	@Action(UpdateRefSearch)
	updateRefSearch(ctx: StateContext<ShipmentStateModel>, { search }: UpdateRefSearch) {
		ctx.patchState({
			refSearch: search,
		});
	}

	@Action(UpdateShipmentsFilterData)
	updateShipmentsFilterData(ctx: StateContext<ShipmentStateModel>, { data }: UpdateShipmentsFilterData) {
		return ctx.patchState({
			shipmentFilterData: {
				...ctx.getState().shipmentFilterData,
				...data,
			},
		});
	}

	@Action(LoadFilterLocation)
	loadFilterLocation(ctx: StateContext<ShipmentStateModel>, {}: LoadFilterLocation) {
		return this._locationsService.getAllCountries().pipe(
				map((countriesPaginate) =>
						ctx.dispatch([
							new UpdateShipmentsFilterData({
								countries: countriesPaginate.content,
							}),
						]),
				),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(
								new ShowMessage(
										{
											level: MessageLevel.ERROR,
											text: 'load_failure',
										},
										'locations',
								),
						),
				),
		);
	}

	@Action(LoadFilterCustomer)
	loadFilterCustomer(ctx: StateContext<ShipmentStateModel>, { filter }: LoadFilterCustomer) {
		if (!filter?.searchName || filter?.searchName.length === 0) {
			return ctx.dispatch([
				new UpdateShipmentsFilterData({
					customers: [],
					customersSearch: '',
				}),
			]);
		}
		return this._customerService.findAll({ page: 0 }, filter).pipe(
				map((customers: PaginatedEntity<CustomerReponse>) =>
						ctx.dispatch([
							new UpdateShipmentsFilterData({
								customers: customers.content,
								customersSearch: filter?.searchName,
							}),
						]),
				),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(
								new ShowMessage(
										{
											level: MessageLevel.ERROR,
											text: 'load_failure',
										},
										'customer',
								),
						),
				),
		);
	}

	@Action(UpdateShipmentsSort)
	updateShipmentsSort(ctx: StateContext<ShipmentStateModel>, { sort, update }: UpdateShipmentsSort) {
		const state = ctx.getState();
		ctx.patchState({
			pageOption: {
				...state.pageOption,
				page: 0,
				sort: sort,
			},
		});
		if (update) {
			return ctx.dispatch(new LoadShipments());
		}
		return;
	}

	@Action(LoadShipment)
	loadShipment(ctx: StateContext<ShipmentStateModel>, { shipmentId }: LoadShipment) {
		ctx.patchState({
			loadingShipment: true,
		})
		return this._shipmentService.get(shipmentId).pipe(
				map((shipment) => ctx.dispatch(new LoadShipmentSuccess(shipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadShipmentFailure(error.error.errorCode))),
				finalize(() => ctx.patchState({
					loadingShipment: false,
				})),
		);
	}

	@Action(LoadShipmentSuccess)
	loadShipmentSuccess(ctx: StateContext<ShipmentStateModel>, { shipment }: LoadShipmentSuccess) {
		ctx.patchState({
			shipment: shipment,
		});
	}

	@Action(LoadShipmentFailure)
	loadShipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: LoadShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_shipment_failure',
							level: MessageLevel.ERROR,
						},
						'shipments',
				),
		);
	}

	@Action(CreateShipment)
	createShipment(ctx: StateContext<ShipmentStateModel>, { shipment }: CreateShipment) {
		const userId: string =
				this._store.selectSnapshot(UserStateSelector.userProfile).id! ||
				this._store.selectSnapshot(UserStateSelector.userSSOProfile)?.id!;
		return this._shipmentService.createShipment({ ...shipment, organizerId: userId }).pipe(
				map((shipment) => ctx.dispatch([new CreateShipmentSuccess(shipment), new UpdateCustomerFilter({
					search: undefined,
					personType: undefined,
					searchName: undefined,
					countryFilter: undefined,
				})])),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new CreateShipmentFailure(error.error.errorCode));
				}),
		);
	}

	@Action(CreateShipmentSuccess)
	createShipmentSuccess(ctx: StateContext<ShipmentStateModel>, { shipment }: CreateShipmentSuccess) {
		ctx.patchState({
			shipment: shipment,
		});
		return ctx.dispatch([
			new AddOneToCountUserBookings(),
			new Navigate(['/shipments', shipment.id]),
			new ShowMessage(
					{
						level: MessageLevel.SUCCESS,
						text: 'creation_success',
					},
					this.translationScope,
			),
		]);
	}

	@Action(CreateShipmentFailure)
	createShipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: CreateShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(UpdateShipment)
	updateShipment(ctx: StateContext<ShipmentStateModel>, updateShipment: UpdateShipment) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._shipmentService.update(shipmentId, updateShipment.shipment).pipe(
				map((shipment) => ctx.dispatch(new UpdateShipmentSuccess(shipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UpdateShipmentFailure(error.error.errorCode))),
		);
	}

	@Action(UpdateShipmentSuccess)
	updateShipmentSuccess(ctx: StateContext<ShipmentStateModel>, { shipment }: UpdateShipmentSuccess) {
		ctx.patchState({
			shipment: shipment,
		});
	}

	@Action(UpdateShipmentFailure)
	updateShipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: UpdateShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchShipment)
	patchShipment(ctx: StateContext<ShipmentStateModel>, { shipment }: PatchShipment) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._shipmentService.patchShipment(shipment, shipmentId).pipe(
				map((shipment) => ctx.dispatch(new PatchShipmentSuccess(shipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new PatchShipmentFailure(error.error.errorCode))),
		);
	}

	@Action(PatchShipmentSuccess)
	patchShipmentSuccess(ctx: StateContext<ShipmentStateModel>, { shipment }: PatchShipmentSuccess) {
		ctx.patchState({
			shipment: {
				...shipment,
				additionalServices: ctx.getState().shipment?.additionalServices,
			},
		});
		return ctx.dispatch([
			new ShowMessage(
					{
						level: MessageLevel.SUCCESS,
						text: 'patch_success',
					},
					this.translationScope,
			),
		]);
	}

	@Action(PatchShipmentFailure)
	patchShipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(DeleteShipmentConfirm)
	deleteShipmentConfirm(ctx: StateContext<ShipmentStateModel>) {
		return this._modalService
				.confirm(ModalConfirmMessageEnum.DELETE_SHIPMENT, ModalConfirmMessageEnum.DELETE_SHIPMENT, {
					name: ctx.getState().shipment?.name,
				})
				.pipe(
						map((response) => {
							if (response) {
								return ctx.dispatch(new DeleteShipment());
							}
							return ctx.dispatch(new CancelAction());
						}),
						catchError(() => EMPTY),
				);
	}

	@Action(DeleteShipment)
	deleteShipment(ctx: StateContext<ShipmentStateModel>) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._shipmentService.delete(shipmentId).pipe(
				map(() => ctx.dispatch(new DeleteShipmentSuccess())),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new DeleteShipmentFailure(error.error.errorCode));
				}),
		);
	}

	@Action(DeleteShipmentSuccess)
	deleteShipmentSuccess(ctx: StateContext<ShipmentStateModel>) {
		return ctx.dispatch([
			new MinusOneToCountUserBookings(),
			new ShowMessage(
					{
						text: 'delete_success',
						level: MessageLevel.SUCCESS,
					},
					'cargoItems',
			),
			new Navigate(['/shipments']),
		]);
	}

	@Action(DeleteShipmentFailure)
	deleteShipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: DeleteShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'delete_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchShipmentType)
	patchShipmentType(ctx: StateContext<ShipmentStateModel>, patchShipment: PatchShipmentType) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._shipmentService.patchShipment(patchShipment.shipment, shipmentId).pipe(
				map((shipment) => ctx.dispatch(new PatchShipmentTypeSuccess(shipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new PatchShipmentTypeFailure(error.error.errorCode))),
		);
	}

	@Action(PatchShipmentTypeSuccess)
	patchShipmentTypeSuccess(ctx: StateContext<ShipmentStateModel>, { shipment }: PatchShipmentTypeSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						isImport: shipment.isImport,
						reference: shipment.reference,
					}),
				}),
		);
		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchShipmentTypeFailure)
	patchShipmentTypeFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchShipmentTypeFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(LoadEquipmentRef)
	loadEquipmentRef(ctx: StateContext<ShipmentStateModel>) {
		const equipmentRefs: PaginatedEntity<EquipmentRef> | undefined = ctx.getState().equipmentRefs;
		if (equipmentRefs && equipmentRefs.content.length > 0) return;
		return this._shipmentService.getEquipmentRef().pipe(
				map((equipmentRef) => ctx.dispatch(new LoadEquipmentRefSuccess(equipmentRef))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadEquipmentRefFailure(error.error.errorCode))),
		);
	}

	@Action(LoadEquipmentRefSuccess)
	loadEquipmentRefSuccess(ctx: StateContext<ShipmentStateModel>, { equipmentRefs }: LoadEquipmentRefSuccess) {
		ctx.patchState({
			equipmentRefs,
		});
	}

	@Action(LoadEquipmentRefFailure)
	loadEquipmentRefFailure(ctx: StateContext<ShipmentStateModel>, { error }: LoadShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'get_equipmentref_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(LoadUnNumbers)
	loadUnNumbers(ctx: StateContext<ShipmentStateModel>) {
		return this._equipmentService.getUnNumbers(ctx.getState().unNumberFilter).pipe(
				map((unNumbers) => ctx.dispatch(new LoadUnNumbersSuccess(unNumbers))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadUnNumbersFailure(error.error.errorCode))),
		);
	}

	@Action(LoadUnNumbersSuccess)
	loadUnNumbersSuccess(ctx: StateContext<ShipmentStateModel>, { unNumbers }: LoadUnNumbersSuccess) {
		ctx.patchState({
			unNumbers,
		});
	}

	@Action(LoadUnNumbersFailure)
	loadUnNumbersFailure(ctx: StateContext<ShipmentStateModel>, { error }: LoadShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'get_un_numbers_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(UpdateUnNumberFilter)
	updateUnNumberFilter(ctx: StateContext<ShipmentStateModel>, { filter }: UpdateUnNumberFilter) {
		ctx.patchState({
			unNumberFilter: {
				...filter,
			},
		});
		return ctx.dispatch(new LoadUnNumbers());
	}

	@Action(PatchEquipment)
	patchEquipment(ctx: StateContext<ShipmentStateModel>, { equipment }: PatchEquipment) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._equipmentService.patchEquipment(equipment.id!, shipmentId, equipment).pipe(
				map((equipment) => ctx.dispatch(new PatchEquipmentSuccess(equipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new PatchEquipmentFailure(error.error.errorCode))),
		);
	}

	@Action(PatchEquipmentSuccess)
	patchEquipmentSuccess(ctx: StateContext<ShipmentStateModel>, { equipment }: PatchEquipmentSuccess) {
		const patchEq: EquipmentPatch = { ...equipment };
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipment.id,
								patch({
									...patchEq,
									cargoItems: equipment.cargoItems ? [...equipment.cargoItems].sort((a, b) => {
										return (a.displayIndex == undefined || b.displayIndex == undefined) ? 0 : (a.displayIndex - b.displayIndex);
									}) : equipment.cargoItems
								}),
						),
					}),
				}),
		);
		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchEquipmentFailure)
	patchEquipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchEquipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}


	@Action(PatchEquipmentGrouped)
	patchEquipmentGrouped(ctx: StateContext<ShipmentStateModel>, { equipment }: PatchEquipmentGrouped) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._equipmentService.patchEquipmentGrouped(equipment.id!, shipmentId, equipment).pipe(
				map((equipment) => ctx.dispatch(new PatchEquipmentGroupedSuccess(equipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new PatchEquipmentFailure(error.error.errorCode))),
		);
	}

	@Action(PatchEquipmentGroupedSuccess)
	patchEquipmentGroupedSuccess(ctx: StateContext<ShipmentStateModel>, { equipment }: PatchEquipmentGroupedSuccess) {
		const patchEq: EquipmentPatch = { ...equipment };
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipment.id,
								patch({
									...patchEq,
								}),
						),
					}),
				}),
		);

		const allEquipmentsOfContainerGroup = ctx.getState().shipment?.equipments?.filter(eq => eq.group === equipment?.group && eq.id !== equipment.id);
		if (equipment && allEquipmentsOfContainerGroup && allEquipmentsOfContainerGroup?.length > 0) {
			for (const eqGr of allEquipmentsOfContainerGroup) {
				ctx.setState(
					patch({
						shipment: patch({
							equipments: updateItem<Equipment>(
								(eq) => eq?.id === eqGr.id,
								patch({
									refrigerated: equipment.refrigerated,
									temperature: equipment.temperature,
									equipmentRef: equipment.equipmentRef,
									weight: equipment.weight,
									dangerous: equipment.dangerous,
									imoClass: equipment.imoClass,
									unNumber: equipment.unNumber
								}),
							),
						}),
					}),
				);
			}
		}

		const allEquipmentsOfMerchandiseGroup = ctx.getState().shipment?.equipments?.filter(eq => eq.merchandiseGroup === equipment?.merchandiseGroup && eq.id !== equipment.id);
		if (equipment && allEquipmentsOfMerchandiseGroup && allEquipmentsOfMerchandiseGroup?.length > 0) {
			for (const eqGr of allEquipmentsOfMerchandiseGroup) {
				ctx.setState(
					patch({
						shipment: patch({
							equipments: updateItem<Equipment>(
								(eq) => eq?.id === eqGr.id,
								patch({
									itemsDescription: equipment.itemsDescription,
									advancedOptions: equipment.advancedOptions,
									// cargoItems: equipment.cargoItems
								}),
							),
						}),
					}),
				);
			}
		}

		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						this.translationScope,
				),
		);
	}

	@Action(GroupEquipmentMerchandise)
	groupEquipmentMerchandise(ctx: StateContext<ShipmentStateModel>, {
		equipmentId,
		group
	}: GroupEquipmentMerchandise) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._equipmentService.groupEquipmentMerchandise(shipmentId, equipmentId, group).pipe(
				map((equipment) => ctx.dispatch(new GroupEquipmentMerchandiseSuccess(equipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new GroupEquipmentMerchandiseFailure(error.error.errorCode))),
		);
	}

	@Action(GroupEquipmentMerchandiseSuccess)
	groupEquipmentMerchandiseSuccess(ctx: StateContext<ShipmentStateModel>, { equipment }: GroupEquipmentMerchandiseSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipment.id,
								patch({
									...equipment,
								}),
						),
					}),
				}),
		);
		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						this.translationScope,
				),
		);
	}

	@Action(GroupEquipmentMerchandiseFailure)
	groupEquipmentMerchandiseFailure(ctx: StateContext<ShipmentStateModel>, { error }: GroupEquipmentMerchandiseFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchEquipmentDetail)
	patchEquipmentDetail(ctx: StateContext<ShipmentStateModel>, { equipment, preCarriage }: PatchEquipmentDetail) {
		const shipmentId: string = ctx.getState().shipment?.id!;

		return this._equipmentService.patchEquipmentDetail(equipment.id!, shipmentId, equipment, preCarriage).pipe(
				map((equipment) => ctx.dispatch(new PatchEquipmentDetailSuccess(equipment))),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(new PatchEquipmentDetailFailure(error.error.errorCode)),
				),
		);
	}

	@Action(PatchEquipmentDetailSuccess)
	patchEquipmentDetailSuccess(ctx: StateContext<ShipmentStateModel>, { equipment }: PatchEquipmentDetailSuccess) {
		const patchEq: EquipmentPatch = { ...equipment };
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipment.id,
								patch({
									...patchEq,
								}),
						),
					}),
				}),
		);
		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						this.translationScope,
				),
		);
	}

	@Action(PatchEquipmentDetailFailure)
	patchEquipmentDetailFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchEquipmentDetailFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_shipment_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(CreateEquipment)
	createEquipment(ctx: StateContext<ShipmentStateModel>, { equipment }: CreateEquipment) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._equipmentService
				.createEquipment(
						{
							...equipment,
							shipmentId,
							equipmentRefId: equipment.equipmentRef?.id,
							unNumberId: equipment.unNumber?.map(u => u.id),
						},
						shipmentId,
				)
				.pipe(
						map((equipment) => ctx.dispatch(new CreateEquipmentSuccess(equipment))),
						catchError((error: HttpErrorResponse) => {
							return ctx.dispatch(new CreateShipmentFailure(error.error.errorCode));
						}),
				);
	}

	@Action(CreateEquipmentSuccess)
	createEquipmentSuccess(ctx: StateContext<ShipmentStateModel>, { equipment }: CreateEquipmentSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: append<Equipment>([equipment]),
					}),
				}),
		);

		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'creation_success',
						},
						'equipments',
				),
		);
	}

	@Action(CreateEquipmentFailure)
	createEquipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: CreateShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_equipment_failure',
							level: MessageLevel.ERROR,
						},
						'equipment',
				),
		);
	}

	@Action(DeleteEquipmentConfirm)
	deleteEquipmentConfirm(ctx: StateContext<ShipmentStateModel>, { container }: DeleteEquipmentConfirm) {
		return this._modalService
				.confirmDeleteEquipment(ModalConfirmMessageEnum.DELETE_EQUIPMENT, ModalConfirmMessageEnum.DELETE_EQUIPMENT, container)
				.pipe(
						map((response) => {
							if (response) {
								return ctx.dispatch(new DeleteEquipment(container.displayIndex));
							}
							return ctx.dispatch(new CancelAction());
						}),
						catchError(() => EMPTY),
				);
	}

	@Action(DeleteEquipment)
	deleteEquipment(ctx: StateContext<ShipmentStateModel>, { index }: DeleteEquipment) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		const equipmentId: string = ctx.getState().shipment?.equipments?.find((e) => e.displayIndex === index)?.id!;
		return this._equipmentService.deleteEquipment(equipmentId, shipmentId).pipe(
				map((equipment) => ctx.dispatch(new DeleteEquipmentSuccess(index))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new DeleteEquipmentFailure(error.error.errorCode));
				}),
		);
	}

	@Action(DeleteEquipmentSuccess)
	deleteEquipmentSuccess(ctx: StateContext<ShipmentStateModel>, { index }: DeleteEquipmentSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: removeItem((eq) => eq?.displayIndex === index),
					}),
				}),
		);
		ctx.dispatch(new ShowMessage({ text: 'delete_success', level: MessageLevel.SUCCESS }, 'equipments'));
	}

	@Action(DeleteEquipmentFailure)
	deleteEquipmentFailure(ctx: StateContext<ShipmentStateModel>, { error }: DeleteEquipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_equipment_failure',
							level: MessageLevel.ERROR,
						},
						'equipment',
				),
		);
	}

	@Action(CloneEquipmentConfirm)
	cloneEquipmentConfirm(ctx: StateContext<ShipmentStateModel>, { index }: CloneEquipmentConfirm) {
		return this._modalService
				.confirm(ModalConfirmMessageEnum.CLONE_EQUIPMENT, ModalConfirmMessageEnum.CLONE_EQUIPMENT)
				.pipe(
						map((response) => {
							if (response) {
								return ctx.dispatch(new CloneEquipment(index));
							}
							return ctx.dispatch(new CancelAction());
						}),
						catchError(() => EMPTY),
				);
	}


	//#region CargoItems

	@Action(CreateCargoItems)
	createCargoItems(ctx: StateContext<ShipmentStateModel>, { cargoItem, equipmentId }: CreateCargoItems) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._cargoItemService.createCargoItems(cargoItem, shipmentId, equipmentId).pipe(
				map((ci) => ctx.dispatch(new CreateCargoItemsSuccess(ci, equipmentId))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new CreateCargoItemsFailure(error.error.errorCode));
				}),
		);
	}

	@Action(CreateCargoItemsSuccess)
	createCargoItemsSuccess(
			ctx: StateContext<ShipmentStateModel>,
			{ cargoItem, equipmentId }: CreateCargoItemsSuccess,
	) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipmentId,
								patch({
									cargoItems: append<CargoItem>([cargoItem]),
								}),
						),
					}),
				}),
		);

		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'creation_success',
						},
						'cargoItems',
				),
		);
	}

	@Action(CreateCargoItemsFailure)
	createCargoItemsFailure(ctx: StateContext<ShipmentStateModel>, { error }: CreateShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_cargo_item_failure',
							level: MessageLevel.ERROR,
						},
						'cargoItems',
				),
		);
	}

	@Action(DeleteCargoItemsForm)
	deleteCargoItemsForm(ctx: StateContext<ShipmentStateModel>, { parentIdx, idx }: DeleteCargoItemsForm) {
		const equipmentId: string = ctx.getState().shipment?.equipments?.[parentIdx].id!;
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipmentId,
								patch({
									cargoItems: removeItem(idx),
								}),
						),
					}),
				}),
		);
		ctx.dispatch(new ShowMessage({ text: 'delete_success', level: MessageLevel.SUCCESS }, 'cargoItems'));
	}

	@Action(DeleteCargoItems)
	deleteCargoItems(ctx: StateContext<ShipmentStateModel>, { equipmentId, item }: DeleteCargoItems) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._cargoItemService.deleteCargoItems(shipmentId, equipmentId, item.id!).pipe(
				map(() => ctx.dispatch(new DeleteCargoItemsSuccess(equipmentId, item))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new DeleteCargoItemsFailure(error.error.errorCode));
				}),
		);
	}

	@Action(DeleteCargoItemsSuccess)
	deleteCargoItemsSuccess(ctx: StateContext<ShipmentStateModel>, { equipmentId, item }: DeleteCargoItemsSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipmentId,
								patch({
									cargoItems: removeItem<CargoItem>((cg) => cg?.id == item.id),
								}),
						),
					}),
				}),
		);
		ctx.dispatch(new ShowMessage({ text: 'delete_success', level: MessageLevel.SUCCESS }, 'cargoItems'));
	}

	@Action(CreateCargoItemsFailure)
	deleteCargoItemsFailure(ctx: StateContext<ShipmentStateModel>, { error }: DeleteCargoItemsFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_cargo_item_failure',
							level: MessageLevel.ERROR,
						},
						'cargoItems',
				),
		);
	}

	@Action(PatchCargoItemLocal)
	patchCargoItemLocal(ctx: StateContext<ShipmentStateModel>, {
		cargoItemId,
		equipmentId,
		valid
	}: PatchCargoItemLocal) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipmentId,
								patch({
									cargoItems: updateItem<CargoItem>((cargo) => cargo?.id === cargoItemId, { valid }),
								}),
						),
					}),
				}),
		);
		const equipment = ctx.getState().shipment?.equipments?.find((eq) => eq.id === equipmentId);
		const grouped = ctx.getState().shipment?.equipments?.filter(eq => eq.merchandiseGroup === equipment?.merchandiseGroup && eq.id !== equipmentId);
		if (equipment && grouped && grouped.length > 0) {
			for (const eqGr of grouped) {
				ctx.setState(
						patch({
							shipment: patch({
								equipments: updateItem<Equipment>(
										(eq) => eq?.id === eqGr.id,
										patch({
											cargoItems: updateItem<CargoItem>((cargo) => cargo?.copyCargoItemId === cargoItemId, (cargo) => {
												return { ...cargo, valid }
											}),
										}),
								),
							}),
						}),
				);
			}

		}
	}

	@Action(PatchCargoItems)
	patchCargoItems(ctx: StateContext<ShipmentStateModel>, { equipmentId, cargoItem }: PatchCargoItems) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._cargoItemService
				.patchCargoItems(shipmentId, equipmentId, cargoItem.id!, cargoItem)
				.pipe(
						map((ci) => ctx.dispatch(new PatchCargoItemsSuccess({
							...ci,
							valid: cargoItem.valid
						}, equipmentId))),
						catchError((error: HttpErrorResponse) =>
								ctx.dispatch(new PatchCargoItemsFailure(error.error.errorCode)),
						),
				);
	}

	@Action(PatchCargoItemsSuccess)
	patchCargoItemsSuccess(ctx: StateContext<ShipmentStateModel>, { cargoItem, equipmentId }: PatchCargoItemsSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						equipments: updateItem<Equipment>(
								(eq) => eq?.id === equipmentId,
								patch({
									cargoItems: updateItem<CargoItem>((cargo) => cargo?.id === cargoItem.id, cargoItem),
								}),
						),
					}),
				}),
		);
		const equipment = ctx.getState().shipment?.equipments?.find((eq) => eq.id === equipmentId);
		const grouped = ctx.getState().shipment?.equipments?.filter(eq => eq.merchandiseGroup === equipment?.merchandiseGroup && eq.id !== equipmentId);
		if (equipment && grouped && grouped.length > 0) {
			for (const eqGr of grouped) {
				ctx.setState(
						patch({
							shipment: patch({
								equipments: updateItem<Equipment>(
										(eq) => eq?.id === eqGr.id,
										patch({
											cargoItems: updateItem<CargoItem>((cargo) => cargo?.copyCargoItemId === cargoItem.id, (cargo) => {
												return { ...cargo, valid: cargoItem.valid }
											}),
										}),
								),
							}),
						}),
				);
			}

		}
		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'patch_success',
						},
						'cargoItems',
				),
		);
	}

	@Action(PatchCargoItemsFailure)
	patchCargoItemsFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchCargoItemsFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_cargo_item_failure',
							level: MessageLevel.ERROR,
						},
						'cargoItems',
				),
		);
	}

	//#endregion

	@Action(AddAddressToBookSuccess)
	addAddressToBookSuccess(ctx: StateContext<ShipmentStateModel>, { addressBook }: AddAddressToBookSuccess) {
		return ctx.setState(
				patch({
					shipment: patch({
						client: patch({
							addressBook: addressBook,
						}),
					}),
				}),
		);
	}

	@Action(ResetShipmentClient)
	resetShipmentClient(ctx: StateContext<ShipmentStateModel>) {
		return ctx.patchState({
			shipment: {
				...ctx.getState().shipment,
				client: undefined,
			},
		});
	}

	@Action(OpenAddContactToShipmentCustomerDialog)
	openAddContactToShipmentCustomerDialog(ctx: StateContext<ShipmentStateModel>) {
		return this._modalService.addContactToShipmentCustomer().pipe(
				map((response) => {
					if (response) {
						return EMPTY;
					}
					return ctx.dispatch(new CancelAction());
				}),
				catchError(() => EMPTY),
		);
	}

	@Action(CreateDoubleTrucking)
	createDoubleTrucking(ctx: StateContext<ShipmentStateModel>, { trucking }: CreateDoubleTrucking) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._doubleTruckingService.createDoubleTrucking(shipmentId, trucking).pipe(
				map((tr) => ctx.dispatch(new CreateDoubleTruckingSuccess(tr))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new CreateDoubleTruckingFailure(error.error.errorCode));
				}),
		);
	}

	@Action(CreateDoubleTruckingSuccess)
	createDoubleTruckingSuccess(ctx: StateContext<ShipmentStateModel>, { trucking }: CreateDoubleTruckingSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						additionalServices: append<IAdditionalService>([
							{
								...trucking,
								type: AdditionalServicesType.DOUBLE_TRUCKING,
							},
						]),
					}),
				}),
		);

		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'creation_success',
						},
						'doubleTrucking',
				),
		);
	}

	@Action(CreateDoubleTruckingFailure)
	createDoubleTruckingFailure(ctx: StateContext<ShipmentStateModel>, { error }: CreateDoubleTruckingFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'create_double_trucking_failure',
							level: MessageLevel.ERROR,
						},
						'doubleTrucking',
				),
		);
	}

	@Action(UpdateDoubleTrucking)
	updateDoubleTrucking(ctx: StateContext<ShipmentStateModel>, { trucking }: UpdateDoubleTrucking) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._doubleTruckingService.updateDoubleTrucking(shipmentId, trucking).pipe(
				map((ci) => ctx.dispatch(new UpdateDoubleTruckingSuccess(trucking))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new UpdateDoubleTruckingFailure(error.error.errorCode));
				}),
		);
	}

	@Action(UpdateDoubleTruckingSuccess)
	updateDoubleTruckingSuccess(ctx: StateContext<ShipmentStateModel>, { trucking }: UpdateDoubleTruckingSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						additionalServices: updateItem<IAdditionalService>((s) => s?.id == trucking.id, patch(trucking)),
					}),
				}),
		);

		return ctx.dispatch(
				new ShowMessage(
						{
							level: MessageLevel.SUCCESS,
							text: 'update_success',
						},
						'doubleTrucking',
				),
		);
	}

	@Action(UpdateDoubleTruckingFailure)
	updateDoubleTruckingFailure(ctx: StateContext<ShipmentStateModel>, { error }: UpdateDoubleTruckingFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'update_double_trucking_failure',
							level: MessageLevel.ERROR,
						},
						'doubleTrucking',
				),
		);
	}

	@Action(DeleteDoubleTruckingConfirm)
	deleteDoubleTruckingConfirm(ctx: StateContext<ShipmentStateModel>, { id }: DeleteDoubleTruckingConfirm) {
		return this._modalService
				.confirm(ModalConfirmMessageEnum.DELETE_SERVICE, ModalConfirmMessageEnum.DELETE_SERVICE)
				.pipe(
						map((response) => {
							if (response) {
								return ctx.dispatch(new DeleteDoubleTrucking(id));
							}
							return ctx.dispatch(new CancelAction());
						}),
						catchError(() => EMPTY),
				);
	}

	@Action(DeleteDoubleTrucking)
	deleteDoubleTrucking(ctx: StateContext<ShipmentStateModel>, { id }: DeleteDoubleTrucking) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._doubleTruckingService.deleteDoubleTrucking(shipmentId, id).pipe(
				map(() => ctx.dispatch(new DeleteDoubleTruckingSuccess(id))),
				catchError((error: HttpErrorResponse) => {
					return ctx.dispatch(new DeleteDoubleTruckingFailure(error.error.errorCode));
				}),
		);
	}

	@Action(DeleteDoubleTruckingSuccess)
	deleteDoubleTruckingSuccess(ctx: StateContext<ShipmentStateModel>, { id }: DeleteDoubleTruckingSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						additionalServices: removeItem<IAdditionalService>((s) => s?.id === id),
					}),
				}),
		);
		ctx.dispatch(new ShowMessage({ text: 'delete_success', level: MessageLevel.SUCCESS }, 'double_trucking'));
	}

	@Action(DeleteDoubleTruckingFailure)
	deleteDoubleTruckingFailure(ctx: StateContext<ShipmentStateModel>, { error }: DeleteDoubleTruckingFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'delete_double_trucking_failure',
							level: MessageLevel.ERROR,
						},
						'doubleTrucking',
				),
		);
	}

	@Action(PatchShipmentStatus)
	patchShipmentStatus(ctx: StateContext<ShipmentStateModel>, { status, displayMessage }: PatchShipmentStatus) {
		// if (status === ShipmentStatusEnum.BOOKED) {
		// 	const errors: ShipmentErrorEnum[] = ShipmentValidator.validateShipment(ctx.getState().shipment!);
		// 	if (errors.length > 0) {
		// 		return this._modalService.showShipmentErrors(errors)
		// 	}
		// }
		return this._shipmentService.patchStatus(ctx.getState().shipment?.id!, status).pipe(
				map((shipment) => {
					if(ctx.getState().shipment?.status?.status === ShipmentStatusEnum.SELECTING_QUOTE && status === ShipmentStatusEnum.DRAFT) {
						ctx.patchState({
							routingPlans: undefined
						})
					}
					return ctx.dispatch(new PatchShipmentStatusSuccess(shipment.status!, displayMessage))
				}),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new PatchShipmentStatusFailure(error.error.errorCode))));
	}

	@Action(PatchShipmentStatusSuccess)
	patchShipmentStatusSuccess(ctx: StateContext<ShipmentStateModel>, { status, displayMessage }: PatchShipmentStatusSuccess) {
		ctx.setState(
				patch({
					shipment: patch({
						status: status,
					}),
				}),
		);
		if(displayMessage) {
			ctx.dispatch(new ShowMessage({ text: 'patch_status_success', level: MessageLevel.SUCCESS }, 'shipments'));
		}
	}

	@Action(PatchShipmentStatusFailure)
	patchShipmentStatusFailure(ctx: StateContext<ShipmentStateModel>, { error }: PatchShipmentStatusFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'patch_status_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(LoadQuotes)
	loadQuotes(ctx: StateContext<ShipmentStateModel>, { shipmentId }: LoadQuotes) {
		return this._shipmentService.getQuotes(shipmentId).pipe(
				map((quotes) => ctx.dispatch(new LoadQuotesSuccess([
					{
						...quotes.find(q => q.metric === MetricEnum.CO2),
						mark: 4.5,
						type: QuoteType.ECOLOGICAL,
					},
					{
						...quotes.find(q => q.metric === MetricEnum.price),
						mark: 4.0,
						type: QuoteType.ECONOMICAL,
					},
					{
						...quotes.find(q => q.metric === MetricEnum.price),
						mark: 2.90,
						type: QuoteType.PERSONALIZED,
						price: (quotes.find(q => q.metric === MetricEnum.price)?.price || 0) * 1.21
					}]))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadQuotesFailure(error.error.errorCode))),
		);
	}

	@Action(LoadQuotesSuccess)
	loadQuotesSuccess(ctx: StateContext<ShipmentStateModel>, { plans }: LoadQuotesSuccess) {
		ctx.patchState({
			routingPlans: plans,
		});
	}

	@Action(SelectQuote)
	selectQuote(ctx: StateContext<ShipmentStateModel>, { planId }: SelectQuote) {
		const shipmentId: string = ctx.getState().shipment?.id!;
		return this._shipmentService.selectQuote(shipmentId, planId).pipe(
				map(() => ctx.dispatch(new SelectQuoteSuccess())),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new SelectQuoteFailure(error.error.errorCode))),
		);
	}

	@Action(SelectQuoteSuccess)
	selectQuoteSuccess(ctx: StateContext<ShipmentStateModel>) {
		return ctx.dispatch(new ShowMessage({ text: 'select_quote_success', level: MessageLevel.SUCCESS }, 'shipments'));
	}

	@Action(SelectQuoteFailure)
	selectQuoteFailure(ctx: StateContext<ShipmentStateModel>, { error }: SelectQuoteFailure) {
		return ctx.dispatch(new ShowMessage({ text: 'select_quote_failure', level: MessageLevel.ERROR }, 'shipments'));
	}

	@Action(UpdateLocationSearch)
	updateLocationSearch(ctx: StateContext<ShipmentStateModel>, { search }: UpdateLocationSearch) {
		ctx.patchState({
			locationsSearch: search
		});
		return ctx.dispatch(new LoadLocations())
	}

	@Action(LoadLocations)
	loadPublicLocations(ctx: StateContext<ShipmentStateModel>) {
		const pageOptions: PageOption = ctx.getState().locationPage;
		const filter: LocationFilter | undefined = { search: ctx.getState().locationsSearch }
		return this._locationsService.searchLocations(pageOptions, filter).pipe(
				map((locations: PaginatedEntity<Location>) => ctx.dispatch(new LoadLocationsSuccess(locations))),
				catchError((error) => ctx.dispatch(new LoadLocationsFailure(error)))
		)
	}

	@Action(LoadLocationsSuccess)
	loadPublicLocationsSuccess(ctx: StateContext<ShipmentStateModel>, { locations }: LoadLocationsSuccess) {
		return ctx.patchState({
					locations: locations
				}
		)
	}

	@Action(LoadLocationsFailure)
	loadPublicLocationsFailure(ctx: StateContext<ShipmentStateModel>, { error }: LoadLocationsFailure) {
		return ctx.dispatch([
			new ChangeShipmentsSpinner(false),
			new ShowMessage({
				text: 'load_locations_failure',
				level: MessageLevel.ERROR,
			}, 'locations'),
		])
	}
}
