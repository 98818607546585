import { CustomerActionsEnum } from '@state/customer/customer.actions.enum';
import {
	AddressBook,
	Customer,
	CustomerFilter,
	CustomerReponse,
} from '@domain/client/customer';
import { Contact } from '@domain/contact/contact';
import { PageOption } from '@shared/utils/query/page.options';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { MoralPerson } from '@domain/client/moral.person';
import { CustomerInfo } from '@features/private/customer/create-contact/create-contact.component';
import { AddressDialogEnum } from '@state/customer/address.dialog.enum';
import { Address } from '@domain/shared/address';
import { AddAddressData } from '@shared/components/add-address/add-address.component';

const PREFIX = '[Customer] ';

//region Load Customers

export class LoadCustomers {
	static readonly type = PREFIX + CustomerActionsEnum.LOAD_CUSTOMERS;

	constructor() {}
}

export class LoadCustomersSuccess {
	static readonly type = PREFIX + CustomerActionsEnum.LOAD_CUSTOMERS_SUCCESS;

	constructor(public customers: PaginatedEntity<CustomerReponse>) {}
}

export class LoadCustomersFailure {
	static readonly type = PREFIX + CustomerActionsEnum.LOAD_CUSTOMERS_FAILURE;

	constructor(public error: any) {}
}

//endregion

export class LoadCustomerDetails {
	static readonly type = PREFIX + 'Load Customer Details';

	constructor(public customerId: string) {}
}

export class LoadCustomerDetailsSuccess {
	static readonly type = PREFIX + 'Load Customer Details Success';

	constructor(public customer: CustomerReponse) {}
}

export class LoadCustomerDetailsFailure {
	static readonly type = PREFIX + 'Load Customer Details Failure';

	constructor(public error: any) {}
}

export class UpdateCustomer {
	static readonly type = PREFIX + 'Update Customer';

	constructor(public customer: Partial<CustomerReponse>) {}
}

export class UpdateCustomerSuccess {
	static readonly type = PREFIX + 'Update Customer Success';

	constructor(public customer: CustomerReponse) {}
}

export class UpdateCustomerFailure {
	static readonly type = PREFIX + 'Update Customer Failure';

	constructor(public error: any) {}
}

//region Create Customer
export class CreateCustomer {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CUSTOMER;

	constructor(public toCreate: Customer, public sameBillingAddress: boolean) {}
}

export class CreateCustomerSuccess {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CUSTOMER_SUCCESS;
}

export class CreateCustomerFailure {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CUSTOMER_FAILURE;

	constructor(public error: any) {}
}

//endregion

//region Create Contact
export class CreateContact {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CONTACT;

	constructor(public clientId: string, public toCreate: Contact) {}
}

export class CreateContactSuccess {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CONTACT_SUCCESS;
}

export class CreateContactFailure {
	static readonly type = PREFIX + CustomerActionsEnum.CREATE_CONTACT_FAILURE;

	constructor(public error: any) {}
}

//endregion

export class UpdateCustomerPageOption {
	static readonly type = PREFIX + CustomerActionsEnum.UPDATE_CUSTOMER_SEARCH;

	constructor(public pageOption: PageOption) {}
}

export class UpdateCustomerFilter {
	static readonly type = PREFIX + CustomerActionsEnum.UPDATE_CUSTOMER_FILTER;

	constructor(public customerFilter?: CustomerFilter, public pageOption?: Partial<PageOption>) {}
}

export class OpenAddContactDialog {
	static readonly type = PREFIX + CustomerActionsEnum.OPEN_ADD_CONTACT_DIALOG;

	constructor(public addToCurent: boolean, public customer: CustomerInfo) {}
}

export class AddContactToCurrentCustomer {
	static readonly type =
			PREFIX + CustomerActionsEnum.ADD_CONTACT_TO_CURRENT_CUSTOMER;

	constructor(public contact: Contact) {}
}

export class RemoveContactFromCurrentCustomer {
	static readonly type =
			PREFIX + CustomerActionsEnum.REMOVE_CONTACT_FROM_CURRENT_CUSTOMER;

	constructor(public index: number) {}
}

export class OpenAddressDialog {
	static readonly type = PREFIX + CustomerActionsEnum.OPEN_ADDRESS_DIALOG;

	constructor(public data: AddAddressData) {}
}

export class AddAddressToBook {
	static readonly type = PREFIX + CustomerActionsEnum.ADD_ADDRESS_TO_BOOK;

	constructor(public address: Address, public customerId: string) {}
}

export class AddAddressToBookSuccess {
	static readonly type =
			PREFIX + CustomerActionsEnum.ADD_ADDRESS_TO_BOOK_SUCCESS;

	constructor(public addressBook: AddressBook, public customerId: string) {}
}

export class AddAddressToBookFailure {
	static readonly type =
			PREFIX + CustomerActionsEnum.ADD_ADDRESS_TO_BOOK_FAILURE;

	constructor(public error: any) {}
}

export class SetCurrentContact {
	static readonly type = PREFIX + 'Set Current Contact';

	constructor(public contact?: Contact) {}
}

export class SetContactOpen {
	static readonly type = PREFIX + 'Set Open Contact';

	constructor(public contactOpen?: boolean) {}
}

export class DeleteCurrentCustomer {
	static readonly type = PREFIX + 'Delete Customer';
}

export class DeleteCurrentCustomerSuccess {
	static readonly type = PREFIX + 'Delete Customer Success';
}

export class DeleteCurrentCustomerFailure {
	static readonly type = PREFIX + 'Delete Customer Failure';

	constructor(public error: any) {}
}
