import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgOptimizedImage } from "@angular/common";
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';

type SvgCategory = 'icons' | 'logos' | 'illustrations' | 'events' | 'notifications' | 'specific' | 'others';
type SvgType = 'colored' | 'monochrome' | 'black' | 'black-colored' | 'standalone' | 'no-fill' | 'fill' | 'flat' | 'icons' | 'shipper' | 'flags' | 'player';

@Component({
	selector: 'app-svg-selector',
	templateUrl: './svg-selector.component.html',
	styleUrls: ['./svg-selector.component.scss'],
	imports: [
		NgOptimizedImage
	],
	standalone: true

})
export class SvgSelectorComponent {
	basePath: string = "assets/images/"
	@Input() svg: string = '';
	@Input() category: SvgCategory = 'illustrations';
	@Input() type: SvgType = 'colored';
	@Input() color?: 'white' | 'grey';

	/**
	 * On veut une taille de 38 px, donc en rem, 2.375 car 2.375 * 16 = 38
	 */
	@Input() set size(value: NumberInput) {
		this._size = coerceNumberProperty(value, 2.375);
	}
	_size: number;
	get size(): number {
		return this._size;
	}

	@HostBinding('style.width.rem')
	get width() {
		return this.size;
	}

	@HostBinding('style.height.rem')
	get height() {
		return this.size;
	}
}
