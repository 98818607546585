import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatString',
  standalone: true
})
export class ConcatStringPipe implements PipeTransform {

  transform(values: (string | undefined)[], delimitor = ', '): string {
    return values.map(value => value?.trim()).filter(Boolean).join(delimitor);
  }

}
