import { EquipmentRef } from '@domain/shipment/equipmentRef';
import { AdvancedCargoItemPart, CargoItem } from '@domain/shipment/cargoItem';
import { Address } from '@domain/shared/address';
import { DateTime } from "luxon";
import { EquipmentTransportationModeEnum } from "@domain/shipment/equipment.transportation.mode.enum";
import { Location } from "@domain/locations/location";

export interface Equipment {
	id?: string;
	displayIndex: number;
	group: number;
	merchandiseGroup: number;
	preCarriageGroup: number;
	postCarriageGroup: number;
	equipmentRef?: EquipmentRef;
	imoClass?: EquipmentImoClassEnum[];
	unNumber?: UnNumber[];
	containerNumber?: string;
	refrigerated: boolean;
	temperature?: number;
	refrigeratedZone?: RefrigeratedZoneEnum;
	advancedOptions: boolean;
	itemsDescription?: string;
	weight?: number;
	shipmentId?: string;
	cargoItems?: CargoItem[];
	equipmentMaritimRoute?: EquipmentMaritimRoute;
	equipmentDelivery?: EquipmentDetailWithTime;
	equipmentEmptyPickup?: EquipmentDetail;
	equipmentPickup?: EquipmentDetailWithTime;
	equipmentEmptyReturn?: EquipmentDetail;
	dangerous?: boolean;

}

export enum RefrigeratedZoneEnum {
	ONLY_SEA = 'ONLY_SEA',
	ALL_TIME = 'ALL_TIME'

}

export interface EquipmentCreate extends Equipment {
	equipmentRefId?: string
	unNumberId?: string[]
}

export interface EquipmentDetail {
	id?: string;
	equipmentId?: string,
	address?: Address;
	date?: DateTime | string | Date | null;
	hour?: string | null;
	reference?: string;
	mustBePlugged?: boolean;
	temperature?: number;
	transportationChoice?: boolean;
	transportationMode?: EquipmentTransportationModeEnum;
	comments?: string
}

export interface EquipmentMaritimRoute {
	id?: string;
	equipmentId?: string,
	mustBePlugged?: boolean;
	temperature?: number;
}

export interface EquipmentDetailWithTime extends EquipmentDetail {
	estimatedTime?: string;
}


export interface EquipmentPatch {
	id?: string;
	loadingType?: LoadingTypeEnum;
	volume?: number;
	advancedOptions?: boolean;
	itemsDescription?: string;
	equipmentRef?: EquipmentRef;
	imoClass?: EquipmentImoClassEnum[];
	unNumber?: UnNumber[];
	containerNumber?: string;
	weight?: number;
	group?: number;
	merchandiseGroup?: number;
	preCarriageGroup?: number;
	postCarriageGroup?: number;
	refrigerated?: boolean;
	dangerous?: boolean;
	temperature?: number;
	refrigeratedZone?: RefrigeratedZoneEnum;
	shipmentId?: string;
	groupBy?: Group;
}

export interface EquipmentDetailPatch {
	id?: string;
	preCarriageGroup?: number;
	postCarriageGroup?: number;
	equipmentMaritimRoute?: EquipmentMaritimRoute;
	equipmentDelivery?: EquipmentDetailWithTime;
	equipmentEmptyPickup?: EquipmentDetail;
	equipmentPickup?: EquipmentDetailWithTime;
	equipmentEmptyReturn?: EquipmentDetail;
}

export interface UnNumber {
	id: string,
	unNumber: string,
	imoClass: EquipmentImoClassEnum,
	description: string
}

export interface UnNumberFilter {
	imoClass: EquipmentImoClassEnum[],
	unNumber?: string[]
}

export enum Group {
    Equipment,
    Merchandise,
    PreCarriage,
    PostCarriage
}

export enum EquipmentImoClassEnum {
	IMO_1 = '1',
	IMO_1_1 = '1.1',
	IMO_1_2 = '1.2',
	IMO_1_3 = '1.3',
	IMO_1_4 = '1.4',
	IMO_1_5 = '1.5',
	IMO_1_6 = '1.6',
	IMO_2 = '2',
	IMO_2_1 = '2.1',
	IMO_2_2 = '2.2',
	IMO_2_3 = '2.3',
	IMO_3 = '3',
	IMO_4 = '4',
	IMO_4_1 = '4.1',
	IMO_4_2 = '4.2',
	IMO_4_3 = '4.3',
	IMO_5 = '5',
	IMO_5_1 = '5.1',
	IMO_5_2 = '5.2',
	IMO_6 = '6',
	IMO_6_1 = '6.1',
	IMO_6_2 = '6.2',
	IMO_7 = '7',
	IMO_8 = '8',
	IMO_9 = '9'
}

export enum LoadingTypeEnum {
	FCL = 'FCL', // Full container load
	LCL = 'LCL'  // Less than container load 
}
