<div class="container">
    <div class="close-group">
        <div class="subtitle">
            {{ 'address.createNewAddressRecap' | transloco }}
        </div>    
        <div class="address">
            {{ addressString }}
        </div>   
    </div> 
    <div class="body1">
        {{ 'address.nameYourAddress' | transloco }}
    </div>
    <div class="close-group">
        <mat-label class="d-block required">{{ 'address.nameOfAddress' | transloco }}</mat-label>
        <input [placeholder]="'address.addressNamePlaceholder' | transloco" matInput (input)="onNewAddressNameChange($event)" [(ngModel)]="newAddressName">
        @if (error) {
            <mat-error>{{ 'messages.error.address.error_name' | transloco }}</mat-error>
        }
    </div>
</div>
