<form [formGroup]="form" class="col-12">

    <mat-label class="d-block required">{{ 'address.addressName' | transloco }}</mat-label>
    <mat-form-field appearance="outline" >
      <input formControlName="name" matInput>
		<mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
    </mat-form-field>

  <ng-container *ngIf="displayCompany">
    <mat-label class="d-block">{{ 'address.company' | transloco }}</mat-label>
    <mat-form-field appearance="outline" >
      <input formControlName="company" matInput>
    </mat-form-field>
  </ng-container>

  <mat-label class="d-block required">{{ 'address.address1' | transloco }}</mat-label>
  <mat-form-field appearance="outline" >
    <input formControlName="address1" matInput>
	  <mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
  </mat-form-field>

  <mat-label class="d-block">{{ 'address.address2' | transloco }}</mat-label>
  <mat-form-field appearance="outline" >
    <input formControlName="address2" matInput>
  </mat-form-field>

  <mat-label class="d-block required">{{ 'address.city' | transloco }}</mat-label>
  <mat-form-field appearance="outline" >
    <input formControlName="city" matInput>
	  <mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
  </mat-form-field>

  <mat-label class="d-block required">{{ 'address.state' | transloco }}</mat-label>
  <mat-form-field appearance="outline" >
    <input formControlName="state" matInput>
	  <mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
  </mat-form-field>

  <mat-label class="d-block required">{{ 'address.postalCode' | transloco }}</mat-label>
  <mat-form-field appearance="outline" >
    <input formControlName="postalCode" matInput>
	  <mat-error *ngIf="form.get('postalCode')?.hasError('maxlength')"> {{ 'forms.invalid' | transloco }}</mat-error>
	  <mat-error id="required">{{ "app.requiredField" | transloco }}</mat-error>
  </mat-form-field>

  <app-country-selector [required]="true" [control]="countryCodeControl"></app-country-selector>

  <ng-container *ngIf="displayComment">
    <mat-label class="d-block">{{ 'address.comment' | transloco }}</mat-label>
    <mat-form-field appearance="outline" >
      <input formControlName="comments" matInput>
    </mat-form-field>
  </ng-container>

</form>
