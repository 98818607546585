<div class="container">
    <div class="address-list">
        <div class="subtitle">
            {{ 'address.searchResult' | transloco }}
        </div>   
        <mat-selection-list [multiple]="false" (selectionChange)="onSelectedAddressChange($event)" #matAddressList>
            @for(address of addresses; track address) {
                <mat-list-option [selected]="address == addresses[0]" [value]="address" class="multi-line">
                    {{ address.displayName }}
                </mat-list-option>
            }
        </mat-selection-list>
    </div>
    <div id="map">
        
    </div>
</div>
