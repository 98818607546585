export class UserContact {
    id: string;
    firstname: string;
    lastname: string;
    email: string;

    title: string;
    comment: string;
    organization: string;

    address: string;
    city: string;
    postalCode: string;
    countryCode: string;
    state: string;

    homePhone: string;
    mobilePhone: string;

    deleted: boolean;

    userId: string;
    organizationId: string;
	avatar: string;

	contactCarriagesTasks: { [key: string]: string[] };

    role: UserContactRoleEnum;
    groups: UserContactGroup[];
}

export enum UserContactRoleEnum {
    EXTERNAL = 'EXTERNAL',
}

export interface UserContactFilter {
    search?: string;
    deleted?: string;
    group?: string;
}

export interface UserContactGroupFilter {
    name?: string;
}

export class UserContactGroup {
    id: string;
    name: string;
    userId: string;
    organizationId: string;
    contacts: UserContact[];
}
