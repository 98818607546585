export enum ReferentialsActions {
  LOAD_COUNTRIES = 'Load Countries',
  LOAD_COUNTRIES_SUCCESS = 'Load Countries Success',
  LOAD_COUNTRIES_FAILURE = 'Load Countries Failure',

  LOAD_LANGUAGES = 'Load Languages',
  LOAD_LANGUAGES_SUCCESS = 'Load Languages Success',
  LOAD_LANGUAGES_FAILURE = 'Load Languages Failure',

  LOAD_TIMEZONES = 'Load Timezones',
  LOAD_TIMEZONES_SUCCESS = 'Load Timezones Success',
  LOAD_TIMEZONES_FAILURE = 'Load Timezones Failure',

  LOAD_PHONE_TYPES = 'Load Phone Types',
  LOAD_PHONE_TYPES_SUCCESS = 'Load Phone Types Success',
  LOAD_PHONE_TYPES_FAILURE = 'Load Phone Types Failure',

  LOAD_PHONE_VISIBILITIES = 'Load Phone Visibilities',
  LOAD_PHONE_VISIBILITIES_SUCCESS = 'Load Phone Visibilities Success',
  LOAD_PHONE_VISIBILITIES_FAILURE = 'Load Phone Visibilities Failure',
}

