import { HashMap } from "@ngneat/transloco";
import { GlobalActions } from "@state/global/global.actions.enum";
import {Device, Message} from '@state/global/global.state'

const PREFIX = '[Global] '

export class ShowMessage {
  static readonly type = PREFIX + GlobalActions.SHOW_MESSAGE;
  constructor(public message: Message, public scope: string, public data?:HashMap<any>) {}
}

export class ChangeNavbar {
  static readonly type = PREFIX + GlobalActions.CHANGE_NAVBAR;
}

export class CancelAction {
  static readonly type = PREFIX + GlobalActions.CANCEL_ACTIONS;
}

export class LoadAvailablesLanguages {
  static readonly type = PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES;
}

export class LoadAvailablesLanguagesSuccess {
  static readonly type = PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES_SUCCESS;
  constructor(public languages: string[]) {}
}

export class LoadAvailablesLanguagesFailure {
  static readonly type = PREFIX + GlobalActions.LOAD_AVAILABLE_LANGUAGES_FAILURE;
  constructor(public error: any) {}
}

export class UpdateCurrentLang {
  static readonly type = PREFIX + GlobalActions.UPDATE_CURRENT_LANG;
  constructor(public currentLang: string) {}
}

export class UpdateCurrentLangSuccess {
  static readonly type = PREFIX + GlobalActions.UPDATE_CURRENT_LANG_SUCCESS;
}

export class UpdateCurrentLangFailure {
  static readonly type = PREFIX + GlobalActions.UPDATE_CURRENT_LANG_FAILURE;
  constructor(public error: any) {}
}
export class SetDeviceType {
  static readonly type = PREFIX + GlobalActions.SET_DEVICE_TYPE;
  constructor(public device: Device) {}
}

export class ConfirmPageLeave {
  static readonly type = PREFIX + 'Confirm Page Leave';
}

export class OpenAvatarDialog {
    static readonly type = PREFIX + GlobalActions.OPEN_AVATAR_DIALOG;
    constructor(public imageForm: 'rect' | 'circle') {}
}

export class CloseAvatarDialog {
    static readonly type = PREFIX + GlobalActions.CLOSE_AVATAR_DIALOG;
}

export class SetAvatar {
    static readonly type = PREFIX + GlobalActions.UPLOAD_AVATAR;
    constructor(public base64Image: string) {}
}

export class SetHeaderVisible {
	static readonly type = PREFIX + 'Set Header Visible';
	constructor(public headerVisible: boolean) {}
}

export class SetMyAccountMenuOpen {
	static readonly type = PREFIX + 'Set My Account Menu Open';
	constructor(public open: boolean) {}
}

export class InitShipmentTabs {
	static readonly type = PREFIX + 'Init Shipment Tabs';
}

export class OpenShipmentTab {
	static readonly type = PREFIX + 'Open Shipment Tab';

	constructor(public shipmentId: string) {}
}

export class CloseShipmentTab {
	static readonly type = PREFIX + 'Close Shipment Tab';

	constructor(public shipmentId: string) {}
}

export class CopyToClipboard {
	static readonly type = PREFIX + 'Copy to Clipboard';

	constructor(public text: string) {}
}

export class UpdateLateralShipmentName {
	static readonly type = PREFIX + 'Update Lateral Shipment Name';

	constructor(public shipmentId: string, public name: string) {}
}
