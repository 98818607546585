<ng-container class="select-address-modal">
    <div class="select-address-modal__header">
        <h2 mat-dialog-title>{{ ('address.' + (step == Step.SELECTION ? 'selectAddress' : 'createNewAddress')) | transloco }}</h2>
        <button aria-label="close modal" (click)="closeModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="select-address-modal__body">
        @if (step != Step.CONFIRMATION) {
            <div class="select-address-modal__body__search">
                @if (step == Step.CREATION) {
                    <button (click)="moveToStep(Step.SELECTION, true)" class="back-arrow-btn">
                        <span class="icon-arrow-left"></span>
                    </button>
                }
                <app-search-input [autoFocus]="true"
                        [pressEnterTriggerValueChange]="true"
                        (valueChange)="performSearch($event)"
                        [placeholder]="'address.searchInAddressBook' | transloco" class="search-input"/>
                <!-- Address type filter is disabled for the moment -->
                @if (false && step == Step.SELECTION) {
                    <mat-form-field appearance="outline" class="col-3">
                        <mat-select [(value)]="addressType" multiple="true" matInput placeholder="Type d'adresse">
                            <mat-option value="Facturation">
                                Facturation
                            </mat-option>
                            <mat-option value="Livraison">
                                Livraison
                            </mat-option>
                            <mat-option value="Siège social">
                                Siège social
                            </mat-option>
                            <mat-option value="Lieu dit">
                                Lieu dit
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                }
                <app-button (clicked)="performSearch(searchInputText)" class="col-2">{{ 'address.search' | transloco }}</app-button>
            </div>
        }        
        
        @switch (step) {
            @case (Step.SELECTION) {
                <app-address-selection class="w-100 h-100" [addresses]="addressesResult"
                        [searchInputText]="searchInputText"
                        (selectedAddress)="setSelectedAddress($event)"
                        (newAddressClick)="moveToStep(Step.CREATION, true)">
                </app-address-selection>
            }
            @case (Step.CREATION) {
                <app-address-creation class="w-100 h-100" [addresses]="newAddressesResult"
                        (selectedAddress)="setSelectedAddress($event)">
                </app-address-creation>
            }
            @case (Step.CONFIRMATION) {
                <app-address-confirmation class="w-100 h-100" [addressString]="selectedAddress?.displayName"
                        (newAddressNameChange)="onNewAddressNameChange($event)">
                </app-address-confirmation>
            }
        }

        <hr class="divider">
        
        <div class="select-address-modal__body__buttons">
            <app-button type="line" (clicked)="closeModal()">{{ 'app.cancel' | transloco }}</app-button>
            <app-button (clicked)="submit()" [disabled]="((step == Step.SELECTION || step == Step.CREATION) && !selectedAddress) || (step == Step.CONFIRMATION && !newAddressName)" >
                {{ ('address.' + (step == Step.CREATION ? 'addThisAddress' : 'validate')) | transloco }}
            </app-button>
        </div>
    </div>
</ng-container>