import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserContactGroup } from '@domain/address-book/address-book';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { UnsubscribeOnDestroyComponent } from '@shared/components/abstract/unsubscribe-on-destroy-component.service';
import { UpdateGroupFilter } from '@state/address-book/contact-group/contact-group.actions';
import { GroupContactSelector } from '@state/address-book/contact-group/contact-group.selector';
import { AssignGroupToContact } from '@state/address-book/user-contact/user-contact.actions';
import { UserContactSelector } from '@state/address-book/user-contact/user-contact.selector';
import { Subject, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'app-group-modal',
    templateUrl: './group-modal.component.html',
    styleUrls: ['./group-modal.component.scss'],
})
export class GroupModalComponent extends UnsubscribeOnDestroyComponent implements OnInit {
    groups$ = this.store.select(GroupContactSelector.groups);
    currentContact$ = this.store.select(UserContactSelector.currentContact);

    groupFilter$ = this.store.select(GroupContactSelector.groupFilter);
    searchChanged: Subject<string> = new Subject<string>();

    groups: UserContactGroup[] = [];
    contactGroups: UserContactGroup[] = [];

    isAllGroupsChecked = false;

    constructor(public dialogRef: MatDialogRef<GroupModalComponent>, private store: Store, private actions$: Actions) {
        super();
    }

    ngOnInit(): void {
        this.subscribe(this.actions$.pipe(ofActionSuccessful(AssignGroupToContact)), () => {
            this.dialogRef.close();
        });

        this.subscribe(combineLatest([this.groups$, this.currentContact$]), ([groups, contact]) => {
            this.groups = groups?.content ?? [];
            this.contactGroups = contact?.groups ?? [];
            this.isAllGroupsChecked = this.groups.length === this.contactGroups.length;
        });

        this.searchChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((search) =>
            this.store.dispatch(
                new UpdateGroupFilter({
                    name: search != '' ? search : undefined,
                }),
            ),
        );
    }

    onChange(event: any) {
        this.searchChanged.next(event);
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    isGroupInContact(group: UserContactGroup): boolean {
        return this.contactGroups.some((g: UserContactGroup) => g.id === group.id);
    }

    assignGroup(group: UserContactGroup): void {
        if (this.isGroupInContact(group)) {
            this.contactGroups = this.contactGroups.filter((g: UserContactGroup) => g.id !== group.id);
        } else {
            this.contactGroups = [...this.contactGroups, group];
        }
    }

    assignAllGroups(): void {
        this.contactGroups = this.isAllGroupsChecked ? [] : this.groups;
        this.isAllGroupsChecked = !this.isAllGroupsChecked;
    }

    validateGroups(): void {
        this.store.dispatch(new AssignGroupToContact(this.contactGroups));
    }
}
