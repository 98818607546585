import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({
	name: 'nationalPhone',
	pure: false,
	standalone: true
})
export class NationalPhonePipe implements PipeTransform {

	constructor() {
	}

	transform(value: any): any {
		if (value && value.number) {
			const ayt = new AsYouType(value.countryCode || 'FR')
			ayt.input(value.number);
			if (ayt.getNumber() && ayt.isValid()) {
				return ayt.getNumber()?.formatNational();
			} else {
				return value.number;
			}
		} else {
			return "";
		}
	}
}
