import { createSelector, Selector } from '@ngxs/store';
import { TRACKING_STATE_TOKEN, TrackingStateModel } from './tracking.state';
import { PrestationTask } from '@domain/shipment/route/prestation-task';
import { ShipmentFilter } from '@domain/shipment/shipment.filter';
import { ShipmentTrackingStatusEnum } from '@domain/shipment/ShipmentTrackingStatusEnum';
import { TaskDocument, TaskDocumentCategory } from '@domain/document/document';
import {
	PrePostCarriage,
	PrePostCarriageType,
	PrePostCarriageWithType,
} from '@domain/shipment/route/pre-post-carriage';
import { Shipment } from '@domain/shipment/shipment';
import { MarkersTaskUtils } from '@shared/utils/leaflet/markers.utils';
import { Contact } from "@domain/contact/contact";
import { Equipment } from "@domain/shipment/equipment";
import { Incident } from '@domain/shipment/incident';
import { UserContact } from "@domain/address-book/address-book";

export interface FilteredDocuments {
	documents: (TaskDocument & { task: PrestationTask})[];
	carriage: PrePostCarriageWithType;
}

export class TrackingSelectors {
	@Selector([TRACKING_STATE_TOKEN])
	static shipments(state: TrackingStateModel) {
		return state[state.currentDisplayedShipments]!.shipments;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static categories(state: TrackingStateModel) {
		return state.incidentCategories;
	}


	static incidentType(id: string) {
		return createSelector([TrackingSelectors.categories], (categories) => {
			return categories?.flatMap(c => c.types)?.find(t => t?.id === id);
		})
	}

	@Selector([TRACKING_STATE_TOKEN])
	static pageOption(state: TrackingStateModel) {
		return state[state.currentDisplayedShipments]!.pageOption;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static prePostCarriage(state: TrackingStateModel) {
		return state.prePostCarriage;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentExpandedCarriage(state: TrackingStateModel): string | undefined {
		return state.expandedCarriage;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static currentDisplayedShipments(state: TrackingStateModel): ShipmentTrackingStatusEnum {
		return state.currentDisplayedShipments;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipment(state: TrackingStateModel) {
		return state.shipment;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static loadingShipment(state: TrackingStateModel) {
		return state.loadingShipment;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentTracking(state: TrackingStateModel) {
		return state.shipmentTracking;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentTrackingEquipment(state: TrackingStateModel): Equipment[] | undefined {
		return state.shipment?.equipments;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentTrackingCarriages(state: TrackingStateModel): PrePostCarriageWithType[] {
		const preCarriagesFiltered = state.shipment?.route?.preCarriages
				?.filter((c) => state.shipmentEquipmentFilter
						?.some(e => c.equipmentIds?.includes(e)))
				.sort((a, b) =>
						(Math.min(...a.prestations!.map(p => Math.min(...p!.tasks!.map(t => t!.ordering!))))) - (Math.min(...b.prestations!.map(p => Math.min(...p!.tasks!.map(t => t!.ordering!)))))) || [];
		const postCarriageFiltered = state.shipment?.route?.postCarriages?.filter((c) => state.shipmentEquipmentFilter?.some(e => c.equipmentIds?.includes(e))) || [];
		return [...preCarriagesFiltered.map(e => ({ ...e, type: PrePostCarriageType.PRE_CARRIAGE })),
				...postCarriageFiltered.map(e => ({ ...e, type: PrePostCarriageType.POST_CARRIAGE }))];
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentEquipmentFilter(state: TrackingStateModel) {
		return state.shipmentEquipmentFilter;
	}

	@Selector([TrackingSelectors.shipmentTrackingEquipment, TrackingSelectors.shipmentEquipmentFilter])
	static selectedEquipment(equipments: Equipment[] | undefined, filter: string[]) {
		if(!filter.length || filter.length === equipments?.length) {
			return undefined;
		}
		return equipments?.find(e => e.id && filter.includes(e.id))
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentTrackingLoading(state: TrackingStateModel) {
		return state.shipmentTrackingLoading;
	}



	@Selector([TRACKING_STATE_TOKEN])
	static shipmentFilter(state: TrackingStateModel): ShipmentFilter {
		return state.shipmentFilter;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentsCount(state: TrackingStateModel): Map<ShipmentTrackingStatusEnum, number> {
		const map = new Map<ShipmentTrackingStatusEnum, number>();
		map.set(ShipmentTrackingStatusEnum.ONGOING, state[ShipmentTrackingStatusEnum.ONGOING].shipments?.totalElements || 0);
		map.set(ShipmentTrackingStatusEnum.PLANNED, state[ShipmentTrackingStatusEnum.PLANNED].shipments?.totalElements || 0);
		map.set(ShipmentTrackingStatusEnum.CLOSED, state[ShipmentTrackingStatusEnum.CLOSED].shipments?.totalElements || 0);
		return map;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentsRelatedClients(state: TrackingStateModel) {
		return state.trackingRelatedInfos?.clientInfos;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static menuOpen(state: TrackingStateModel) {
		return state.trackingMenuOpen;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static activeMenuKey(state: TrackingStateModel) {
		return state.activeMenuKey;
	}

	//region Map
	@Selector([TrackingSelectors.shipment, TrackingSelectors.shipmentExpandedCarriage])
	static incidentMarkers(shipment?: Shipment, expandedCarriage?: string) {
		if (!shipment || !expandedCarriage) {
			return [];
		}
		const carriage: PrePostCarriage | undefined = [...shipment.route?.preCarriages || [], ...shipment.route?.postCarriages || []].find(c => c.id === expandedCarriage);
		if (!carriage) {
			return [];
		}
		const tasks = carriage.prestations?.flatMap(p => p.tasks!).filter(Boolean).sort((a, b) => (a?.ordering || 0) - (b?.ordering || 0))
		const incidents: Incident[] = tasks?.flatMap(t => t?.incidents).filter(Boolean) as Incident[] || [];
		return incidents ? MarkersTaskUtils.generateMarkersIncident(incidents) : [];
	}

	@Selector([TRACKING_STATE_TOKEN])
	static shipmentTasks(state: TrackingStateModel) {
		return [
			...state.shipment?.route?.preCarriages?.flatMap((c) => c.prestations)?.flatMap((p) => p?.tasks).sort((a, b) => (a?.ordering || 0) - (b?.ordering || 0)) || [],
			...state.shipment?.route?.postCarriages?.flatMap((c) => c.prestations)?.flatMap((p) => p?.tasks).sort((a, b) => (a?.ordering || 0) - (b?.ordering || 0)) || [],
		] as PrestationTask[];
	}


	@Selector([TRACKING_STATE_TOKEN])
	static activeTask(state: TrackingStateModel) {
		return state.activeTask;
	}
	//endregion


	@Selector([TrackingSelectors.shipmentTrackingCarriages, TrackingSelectors.shipmentExpandedCarriage])
	static expandedCarriage(carriages: PrePostCarriageWithType[], expandedCarriage?: string) : PrePostCarriageWithType | undefined {
		return carriages.find(c => c.id === expandedCarriage);
	}

	@Selector([TrackingSelectors.shipmentTrackingEquipment, TrackingSelectors.shipmentEquipmentFilter, TrackingSelectors.expandedCarriage])
	static filteredEquipments(equipments: Equipment[] | undefined, filter: string[], expandedCarriage?: PrePostCarriageWithType) {
		if(!expandedCarriage) {
			return equipments?.filter(e => e.id && filter.includes(e.id));
		}
		return equipments?.filter(e => e.id && filter.includes(e.id) && expandedCarriage.equipmentIds?.includes(e.id));
	}

	//region Contacts
	@Selector([TRACKING_STATE_TOKEN])
	static expandedContact(state: TrackingStateModel) {
		return state.expandedContact;
	}

	@Selector([TrackingSelectors.filteredContacts])
	static filteredContactsCount(contacts: UserContact[]) {
		return contacts.length || 0;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static contactSearch(state: TrackingStateModel) {
		return state.contactSearch;
	}

	@Selector([TrackingSelectors.shipment, TrackingSelectors.expandedCarriage, TrackingSelectors.shipmentTrackingCarriages, TrackingSelectors.contactSearch])
	static filteredContacts(shipment: Shipment | undefined, carriage: PrePostCarriageWithType | undefined, carriagesFiltered: PrePostCarriageWithType[], filters: string) {
		const contacts = Object.values(shipment?.allContacts || {});
		return contacts.filter((c) => (!filters
						|| c.firstname.toLowerCase().includes(filters.toLowerCase())
						|| c.lastname.toLowerCase().includes(filters.toLowerCase())
						|| c.organization?.toLowerCase().includes(filters.toLowerCase())
						|| c.mobilePhone?.includes(filters)
						|| c.homePhone?.includes(filters))
				&& (carriage ? Object.keys(c.contactCarriagesTasks).includes(carriage.id!) : true)
				&& (Object.keys(c.contactCarriagesTasks).some(carriage => carriagesFiltered.map(ca => ca.id).includes(carriage)))
		);
	}
	//endregion

	@Selector([TRACKING_STATE_TOKEN])
	static taskDocumentToUpload(state: TrackingStateModel) {
		return state.taskDocumentToUpload;
	}

	@Selector([TRACKING_STATE_TOKEN])
	static documentFilters(state: TrackingStateModel) {
		return state.documentFilters;
	}

	@Selector([TrackingSelectors.documentFilters])
	static anyDocumentFilterActive(filters: ReturnType<typeof TrackingSelectors.documentFilters>) {
		return filters.prePostCarriageType.length > 0 || filters.documentType.length > 0;

	}

	@Selector([TRACKING_STATE_TOKEN, TrackingSelectors.shipmentTrackingCarriages, TrackingSelectors.expandedCarriage])
	static filteredDocuments(state: TrackingStateModel, carriages: PrePostCarriageWithType[], expandedCarriage?: PrePostCarriageWithType) {
		const filters = state.documentFilters;
		const toProcessCarriages = expandedCarriage ? [expandedCarriage] : carriages;
		return toProcessCarriages.filter((c) => !filters.prePostCarriageType.length || filters.prePostCarriageType.includes(c.type))
				.map((c) => {
					const carriageDocuments = TrackingSelectors.filterCarriageDocuments(c, filters);
					if (!carriageDocuments?.length) {
						return undefined;
					}
					return {
						documents: carriageDocuments,
						carriage: c,
					} as FilteredDocuments;
				}).filter(Boolean) as FilteredDocuments[];
	}

	@Selector([TrackingSelectors.filteredDocuments])
	static filteredDocumentsCount(documents: FilteredDocuments[]) {
		return documents.flatMap((d) => d.documents).length;
	}

	@Selector([TrackingSelectors.shipmentTrackingCarriages, TrackingSelectors.expandedCarriage])
	static filteredIncidents(carriages: PrePostCarriageWithType[], expandedCarriage?: PrePostCarriageWithType) {
		const toProcessCarriages = expandedCarriage ? [expandedCarriage] : carriages;
		return toProcessCarriages.flatMap((c) => c?.prestations)
				?.flatMap((p) => p?.tasks)
				?.flatMap((t) => t?.incidents)
				?.filter(Boolean) as Incident[];
	}

	@Selector([TrackingSelectors.filteredIncidents])
	static filteredIncidentsCount(incidents: Incident[]) {
		return incidents.length;
	}

	private static filterCarriageDocuments(carriage: PrePostCarriage, filters: {
		search: string,
		prePostCarriageType: PrePostCarriageType[],
		documentType: TaskDocumentCategory[],
	}) {
		const { search } = filters;
		return carriage.prestations
				?.flatMap((p) => p?.tasks)
				?.flatMap((t) => t?.documents?.map((d) => ({
					...d,
					task: t,
				})))
				?.filter(Boolean)
				?.filter((d) => search ?
						(d?.name?.toLowerCase()?.includes(search.toLowerCase()) || d?.filename?.toLowerCase()?.includes(search)) :
						true)
				?.filter((d) => !filters.documentType.length
						|| !d?.taskDocumentCategory
						|| filters.documentType.includes(d?.taskDocumentCategory)) as (TaskDocument & { task: PrestationTask})[];
	}
	//endregion
}
