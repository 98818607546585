import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'objectAccess',
	standalone: true
})
export class ObjectAccessPipe implements PipeTransform {
	// keys : 1 index 1 path ex: client.name
	// c'est un tableau pour du multi type d'objets
	transform(obj: any, keys: string[] = []): any {
		keys = keys.filter(k => k !== '');
		if (!keys.length) {
			return obj;
		}
		for (let j = 0; j < keys.length; j++) {
			const keysList: string[] = keys[j].split('.');
			let tmp: any = obj;
			for (let i = 0; i < keysList.length; i++) {
				tmp = tmp[keysList[i]];
			}
			if (tmp) {
				return tmp;
			}
		}
		return obj;
	}
}
