import { PrestationTask } from '@domain/shipment/route/prestation-task';
import { Shipment } from '@domain/shipment/shipment';
import { ShipmentTracking } from '@domain/shipment/shipment-tracking';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { ShipmentFilterUpdate } from '@domain/shipment/shipment.filter';
import { ShipmentTrackingStatusEnum } from '@domain/shipment/ShipmentTrackingStatusEnum';
import { ShipmentTrackingRelatedInfos } from '@domain/shipment/shipment.tracking.related.infos';
import {
	TrackingMenuKey,
} from '@features/private/tracking/shipment-tracking/tracking-menu/shipment-tracking-menu.component';
import { TaskDocument, TaskDocumentCategory, UploadDocument } from '@domain/document/document';
import {
	PrePostCarriage,
	PrePostCarriageType,
	PrePostCarriageWithType
} from '@domain/shipment/route/pre-post-carriage';
import { IncidentCategory } from "@domain/shipment/incident";

const PREFIX = '[Tracking] ';


export class LoadAllTrackingShipments {
	static readonly type = PREFIX + 'Load All Tracking Shipments';
}

export class LoadTrackingShipments {
	static readonly type = PREFIX + 'Load Tracking Shipments';
}

export class LoadTrackingShipmentsSuccess {
	static readonly type = PREFIX + 'Load Tracking Shipments Success';

	constructor(public shipments: PaginatedEntity<Shipment>, public type: ShipmentTrackingStatusEnum, public clear: boolean = true) {}
}

export class LoadTrackingShipmentsFailure {
	static readonly type = PREFIX + 'Load Tracking Shipments Failure';

	constructor(public error: any) {}
}

export class LoadShipmentTrackingRelatedInfos {
	static readonly type = PREFIX + 'Load Shipment Tracking Related Infos';

	constructor() {}
}

export class SetExpandedCarriage {
	static readonly type = PREFIX + 'Set Expanded Carriage';

	constructor(public id: string) {}
}

export class ToggleCarriage {
	static readonly type = PREFIX + 'Toggle Carriage';

	constructor(public id: string) {}
}

export class LoadShipmentTrackingRelatedInfosSuccess {
	static readonly type = PREFIX + 'Load Shipment Tracking Related Infos Success';

	constructor(public infos: ShipmentTrackingRelatedInfos) {}
}

export class LoadShipmentTrackingRelatedInfosFailure {
	static readonly type = PREFIX + 'Load Shipment Tracking Related Infos Failure';

	constructor(public error: any) {}
}

export class ChangeDisplayedShipments {
	static readonly type = PREFIX + 'Change Displayed Shipments';

	constructor(public type: ShipmentTrackingStatusEnum) {}
}

export class LoadTrackingShipment {
	static readonly type = PREFIX + 'Load Tracking Shipment';

	constructor(public shipmentId: string) {}
}

export class LoadTrackingShipmentSuccess {
	static readonly type = PREFIX + 'Load Tracking Shipment Success';

	constructor(public shipment: Shipment) {}
}

export class LoadTrackingShipmentFailure {
	static readonly type = PREFIX + 'Load Tracking Shipment Failure';

	constructor(public error: any) {}
}

export class UpdateShipmentEquipmentFilter {
	static readonly type = PREFIX + "Update Shipment Equipment Filter";
	constructor(public filter: string[]) {}
}

export class UpdateShipmentsTrackingPage {
	static readonly type = PREFIX + 'Update Tracking Shipment Page';

	constructor(public page: number, public size: number) {}
}

export class GetShipmentTracking {
	static readonly type = PREFIX + 'Get Shipment Tracking';

	constructor(public shipmentId: string) {}
}

export class GetShipmentTrackingSuccess {
	static readonly type = PREFIX + 'Get Shipment Tracking Success';

	constructor(public tracking: ShipmentTracking[]) {}
}

export class GetShipmentTrackingFailure {
	static readonly type = PREFIX + 'Get Shipment Tracking Failure';

	constructor(public error: any) {}
}

export class SetActiveTask {
	static readonly type = PREFIX + 'Set Active Task';

	constructor(public task?: PrestationTask) {}
}

export class UpdateTrackingShipmentFilter {
	static readonly type = PREFIX + 'Update Tracking Shipment Filter';

	constructor(public filter: ShipmentFilterUpdate) {}
}

export class UpdateTrackingShipmentsSort {
	static readonly type = PREFIX + 'Update Tracking Shipments Sort';

	constructor(public sort: string, public update: boolean = true) {}
}

export class SetActiveTrackingMenu {
	static readonly type = PREFIX + 'Set Active Tracking Menu';

	constructor(public menu: TrackingMenuKey) {}
}

export class ExpandContact {
	static readonly type = PREFIX + 'Expand Contact';

	constructor(public contactId: string) {}
}

export class CollapseContact {
	static readonly type = PREFIX + 'Collapse Contact';

	constructor(public contactId: string) {}
}

export class SearchContact {
	static readonly type = PREFIX + 'Search Contact';

	constructor(public search: string) {}
}

export class CloseTrackingMenu {
	static readonly type = PREFIX + 'Close Tracking Menu';
}

export class ResetTrackingMenu {
	static readonly type = PREFIX + 'Reset Tracking Menu';
}

export class ResetDocumentFilters {
	static readonly type = PREFIX + 'Reset Document Filters';
}

export class SetDocumentSearch {
	static readonly type = PREFIX + 'Set Document Search';

	constructor(public search: string) {}
}

export class ToggleDocumentSearchType {
	static readonly type = PREFIX + 'Toggle Document Search Type';

	constructor(public type: TaskDocumentCategory) {}
}

export class ToggleDocumentSearchCarriageType {
	static readonly type = PREFIX + 'Toggle Document Search Carriage Type';

	constructor(public type: PrePostCarriageType) {}
}

export class OpenAddDocumentModal {
	static readonly type = PREFIX + 'Open Add Document Modal';
}

export class UploadTaskDocument {
	static readonly type = PREFIX + 'Upload Task Document';

	constructor(public document: UploadDocument) {}
}

export class UploadTaskDocumentSuccess {
	static readonly type = PREFIX + 'Upload Task Document Success';

	constructor(public document: TaskDocument) {}
}

export class UploadTaskDocumentFailure {
	static readonly type = PREFIX + 'Upload Task Document Failure';

	constructor(public error: any) {}
}

export class RetryUploadTaskDocument {
	static readonly type = PREFIX + 'Retry Upload Task Document';

	constructor() {}
}

export class OpenDocumentModal {
	static readonly type = PREFIX + 'Open Document Modal';

	constructor(public document: TaskDocument, public carriage: PrePostCarriageWithType) {}
}

export class DownloadDocument {
	static readonly type = PREFIX + 'Download Document';

	constructor(public document: { filename: string, fileId: string }) {}
}

export class LoadCarriage {
	static readonly type = PREFIX + 'Load Carriage';

	constructor(public carriageId: string, public shipmentId?: string) {}
}

export class LoadCarriageSuccess {
	static readonly type = PREFIX + 'Load Carriage Success';

	constructor(public carriage: PrePostCarriage) {}
}

export class LoadCarriageFailure {
	static readonly type = PREFIX + 'Load Carriage Failure';

	constructor(public error: any) {}
}

export class LoadIncidentCategories {
	static readonly type = PREFIX + 'Load Incident Categories';
}

export class LoadIncidentCategoriesSuccess {
	static readonly type = PREFIX + 'Load Incident Categories Success';

	constructor(public categories: IncidentCategory[]) {}
}

export class LoadIncidentCategoriesFailure {
	static readonly type = PREFIX + 'Load Incident Categories Failure';

	constructor(public error: any) {}
}
