import { User } from '@domain/user/user';
import { Address } from '@domain/shared/address';
import { TaskDocument } from '@domain/document/document';
import { Marker } from 'leaflet';
import { TaskLog } from "@domain/shipment/route/task.log";
import { Incident } from '@domain/shipment/incident';

export interface PrestationTask {
	id: string;
	ordering?: number;
	name?: string;
	at?: string;
	actorId?: string;
	actor?: User;
	address?: Address;
	type?: TaskTypeEnum;
	clientComment?: string;
	chartererComment?: string;
	actorComment?: string;
	state?: PrestationTaskState;
	reference?: string;
	documents?: TaskDocument[];
	logs: TaskLog[]
	incidents?: Incident[];
}

export interface PrestationTaskState {
	state?: TaskStateEnum;
	by?: string;
	at?: string;
}

export enum TaskTypeEnum {
	PNE_SEAPORT_CONTAINER_DEPOSIT = 'PNE_SEAPORT_CONTAINER_DEPOSIT',
	PNE_SEAPORT_CONTAINER_RETRIEVE = 'PNE_SEAPORT_CONTAINER_RETRIEVE',
	PNE_RIVERPORT_CONTAINER_DEPOSIT = 'PNE_RIVERPORT_CONTAINER_DEPOSIT',
	PNE_RIVERPORT_CONTAINER_RETRIEVE = 'PNE_RIVERPORT_CONTAINER_RETRIEVE',
	PNE_STATION_CONTAINER_DEPOSIT = 'PNE_STATION_CONTAINER_DEPOSIT',
	PNE_STATION_CONTAINER_RETRIEVE = 'PNE_STATION_CONTAINER_RETRIEVE',
	PNE_STOCK_CONTAINER_DEPOSIT = 'PNE_STOCK_CONTAINER_DEPOSIT',
	PNE_STOCK_CONTAINER_RETRIEVE = 'PNE_STOCK_CONTAINER_RETRIEVE',
	PNE_SEAPORT_CONTAINER_EMPTY_DEPOSIT = 'PNE_SEAPORT_CONTAINER_EMPTY_DEPOSIT',
	PNE_SEAPORT_CONTAINER_EMPTY_RETRIEVE = 'PNE_SEAPORT_CONTAINER_EMPTY_RETRIEVE',
	PNE_CLIENT_CONTAINER_LOAD = 'PNE_CLIENT_CONTAINER_LOAD',
	PNE_CLIENT_CONTAINER_UNLOAD = 'PNE_CLIENT_CONTAINER_UNLOAD',
	PNE_CLIENT_CONTAINER_DC_DEPOSIT = 'PNE_CLIENT_CONTAINER_DC_DEPOSIT',
	PNE_CLIENT_CONTAINER_DC_RETRIEVE = 'PNE_CLIENT_CONTAINER_DC_RETRIEVE',
	PNE_CLIENT_CHASSIS_DC_DEPOSIT = 'PNE_CLIENT_CHASSIS_DC_DEPOSIT',
	PNE_CLIENT_CHASSIS_DC_RETRIEVE = 'PNE_CLIENT_CHASSIS_DC_RETRIEVE',
	PNE_SEAPORT_PRECARIAGE_CONTAINER_ARRIVAL = 'PNE_SEAPORT_PRECARIAGE_CONTAINER_ARRIVAL',
	PNE_SEAPORT_POSTCARIAGE_CONTAINER_RETRIEVING = 'PNE_SEAPORT_POSTCARIAGE_CONTAINER_RETRIEVING',
	PTL_ROAD_TRANSPORT = 'PTL_ROAD_TRANSPORT',
	PTL_LOCAL_TRANSPORT = 'PTL_LOCAL_TRANSPORT',
	PTF_CONTAINERSHIP_TRANSPORT = 'PTF_CONTAINERSHIP_TRANSPORT',
	PTF_BARGE_TRANSPORT = 'PTF_BARGE_TRANSPORT',
	PTF_TRAIN_TRANSPORT = 'PTF_TRAIN_TRANSPORT',
}

export enum TaskStateEnum {
	PLANNED = 'PLANNED',
	STARTED = 'STARTED',
	PAUSED = 'PAUSED',
	DONE = 'DONE',
	CANCELED = 'CANCELED',
}

