import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { CargoItem } from "@domain/shipment/cargoItem";
import { DoubleTrucking } from "@domain/shipment/additionalservices/double-trucking";

@Injectable({
  providedIn: 'root',
})
export class DoubleTruckingService {
  constructor(private http: HttpClient) {
  }

  createDoubleTrucking(shipmentId: string, trucking: DoubleTrucking): Observable<DoubleTrucking> {
    return this.http.post<DoubleTrucking>(environment.api.bff + "/shipments/" + shipmentId + "/additional-services/double-trucking", trucking)
  }

  updateDoubleTrucking(shipmentId: string, trucking: DoubleTrucking): Observable<DoubleTrucking> {
    return this.http.put<DoubleTrucking>(environment.api.bff + "/shipments/" + shipmentId + "/additional-services/double-trucking/" + trucking.id, trucking)
  }

  deleteDoubleTrucking(shipmentId: string, id: string): Observable<void> {
    return this.http.delete<void>(environment.api.bff + "/shipments/" + shipmentId + "/additional-services/double-trucking/" + id)
  }

}
