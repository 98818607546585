<div mat-dialog-title>
	<div class="header">
		<h1>{{ 'tracking.shipments.menu.documents.details.title' | transloco : { name: document.name } }}</h1>
		<button (click)="close()" class="header__close">
			<span class="icon-close"></span>
		</button>
	</div>
</div>
<mat-dialog-content class="content">
	<div class="content__left">
		@if (document.createdOn | localizedDate : 'fff'; as date) {
			<span class="content__left__uploaded"
				  [innerHTML]="'tracking.shipments.menu.documents.details.uploaded' | transloco : { date, name: document.createdByName }">
			</span>
		}
		<div class="content__left__field">
			<mat-form-field appearance="outline" class="no-space-under">
				<input disabled matInput [value]="document.name" />
			</mat-form-field>
		</div>
		<div class="content__left__field">
			<span>{{ 'tracking.shipments.menu.documents.documentType' | transloco }}</span>
			<mat-form-field appearance="outline" class="no-space-under">
				<mat-select *transloco="let t; read 'tracking.shipments.menu.documents.categories'"
							[value]="document.taskDocumentCategory"
							[disabled]="true">
					@for (category of TaskDocumentCategory | keyValuePreserveOrder; track category) {
						<mat-option value="{{ category.key }}">{{ t(category.value) }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
		</div>
		<div class="content__left__field">
			<span>{{ 'tracking.shipments.menu.documents.carriage' | transloco }}</span>
			<mat-form-field appearance="outline" class="no-space-under">
				<mat-select #carriageSelect [value]="carriage" [disabled]="true">
					@for (carriage of carriages$ | async; track carriage.id) {
						<mat-option [value]="carriage">
							<div class="content__left__field__carriage">
								<app-svg-selector svg="truck" [size]="1.5" type="black" />
								<div class="content__left__field__carriage__label">
									<span *transloco="let t; read 'tracking.shipments.prePostCarriage.type'"
										  class="content__left__field__carriage__label__type">
										{{ t(carriage.type) }}
									</span>
									<span class="content__left__field__carriage__label__id" [title]="carriage.id">
										@if (carriage.equipmentNumbers) {
											{{ carriage.equipmentNumbers | joinArray }}
										}
									</span>
								</div>
							</div>
						</mat-option>
					}
					@if (carriageSelect.value; as carriage) {
						<mat-select-trigger>
							<div class="content__left__field__carriage">
								<app-svg-selector svg="truck" [size]="1.5" type="black" />
								<div class="content__left__field__carriage__label">
									<span *transloco="let t; read 'tracking.shipments.prePostCarriage.type'"
										  class="content__left__field__carriage__label__type">
										{{ t(carriage.type) }}
									</span>
									<span class="content__left__field__carriage__label__id">
										@if (carriage.equipmentNumbers) {
											{{ carriage.equipmentNumbers | joinArray }}
										}
									</span>
								</div>
							</div>
						</mat-select-trigger>
					}
				</mat-select>
			</mat-form-field>
		</div>
		@if (carriageSelect.value; as carriage) {
			<div class="content__left__field">
				<span>{{ 'tracking.shipments.menu.documents.task' | transloco }}</span>
				<mat-form-field appearance="outline" class="no-space-under">
					<mat-select #taskSelect [disabled]="true" [value]="document.task">
						@for (task of carriage | prePostCarriageTasks; track task.id) {
							<mat-option [value]="task">
								<div class="content__left__field__task">
									<app-svg-selector svg="truck" size="1.5" type="black" />
									<div class="content__left__field__task__label">
										@if (task.type) {
											<span *transloco="let t; read 'shipment.steps.types'"
												  class="content__left__field__task__label__type">
												{{ t(task.type) }}
											</span>
										}
										<span class="content__left__field__task__label__id">
											{{ task.name }}
										</span>
									</div>
								</div>
							</mat-option>
						}
						@if (taskSelect.value) {
							<mat-select-trigger>
								<div class="content__left__field__task">
									<app-svg-selector svg="truck" size="1.5" type="black" />
									<div class="content__left__field__task__label">
										@if (taskSelect.value.type) {
											<span *transloco="let t; read 'shipment.steps.types'"
												  class="content__left__field__task__label__type">
											{{ t(taskSelect.value.type) }}
										</span>
										}
										<span class="content__left__field__task__label__id">
											{{ taskSelect.value.name }}
										</span>
									</div>
								</div>
							</mat-select-trigger>
						}
					</mat-select>
				</mat-form-field>
			</div>
		}
	</div>
	<div class="content__right">
		<app-button icon="download" (clicked)="downloadFile()">{{ 'app.download' | transloco }}</app-button>
	</div>
</mat-dialog-content>
