export interface RoutingStep {
	id?: string;
	price?: number;
	co2?: number;
	company?: string;
	lineName?: string;
	type?: RoutingStepType;
	from: RoutingPoint;
	to: RoutingPoint;
}

export interface RoutingPoint {
	name?: string;
	latitude: number;
	longitude: number;
	date: Date;
	locode?: string;
}

export enum RoutingStepType {
	TRUCK = 'Truck',
	BOAT = 'Boat',
}
