export enum GlobalActions {
    SHOW_MESSAGE = 'Show message',
    CHANGE_NAVBAR = 'Change Navbar visibility',
    CANCEL_ACTIONS = 'Cancel Action',
    LOAD_AVAILABLE_LANGUAGES = 'Load Availables Languages',
    LOAD_AVAILABLE_LANGUAGES_SUCCESS = 'Load Availables Languages Success',
    LOAD_AVAILABLE_LANGUAGES_FAILURE = 'Load Availables Languages Failure',
    UPDATE_CURRENT_LANG = 'Update Current Lang',
    UPDATE_CURRENT_LANG_SUCCESS = 'Update Current Lang Success',
    UPDATE_CURRENT_LANG_FAILURE = 'Update Current Lang Failure',

    SET_DEVICE_TYPE = 'Set Device Type',

    OPEN_AVATAR_DIALOG = 'Open Avatar Dialog',
    CLOSE_AVATAR_DIALOG = 'Close Avatar Dialog',

    UPLOAD_AVATAR = 'Upload Avatar',
    UPLOAD_AVATAR_SUCCESS = 'Upload Avatar Success',

}
