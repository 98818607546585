import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-customer-delete',
  templateUrl: './confirm-customer-delete.component.html',
  styleUrls: ['./confirm-customer-delete.component.scss']
})
export class ConfirmCustomerDeleteComponent {
	constructor(private dialogRef: MatDialogRef<ConfirmCustomerDeleteComponent>) {}

	closeModal(confirm: boolean): void {
		this.dialogRef.close(confirm);
	}
}
