import { Selector } from '@ngxs/store';
import { ReferentialsState, ReferentialsStateModel } from './referentials.state';

export class ReferentialsStateSelector {

    @Selector([ReferentialsState])
    static countries(state: ReferentialsStateModel) {
        return state.countries;
    }

    @Selector([ReferentialsState])
    static languages(state: ReferentialsStateModel) {
        return state.languages;
    }

    @Selector([ReferentialsState])
    static timezones(state: ReferentialsStateModel) {
        return state.timezones;
    }

    @Selector([ReferentialsState])
    static phoneTypes(state: ReferentialsStateModel) {
        return state.phoneTypes;
    }

    @Selector([ReferentialsState])
    static phoneVisibilities(state: ReferentialsStateModel) {
        return state.phoneVisibilities;
    }

	@Selector([ReferentialsState])
	static homePages(state: ReferentialsStateModel) {
		return state.homePages;
	}
}
