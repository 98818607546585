import { Selector } from '@ngxs/store';
import { UserContactState, UserContactStateModel } from './user-contact.state';

export class UserContactSelector {
    @Selector([UserContactState])
    static contacts(state: UserContactStateModel) {
        return state.contacts;
    }

    @Selector([UserContactState])
    static contactFilter(state: UserContactStateModel) {
        return state.contactFilter;
    }

    @Selector([UserContactState])
    static viewMode(state: UserContactStateModel) {
        return state.viewMode;
    }

    @Selector([UserContactState])
    static currentContact(state: UserContactStateModel) {
        return state.currentContact;
    }

    @Selector([UserContactState])
    static groups(state: UserContactStateModel) {
        return state.groups;
    }
}
