import { Injectable } from '@angular/core';
import { ModalConfirmMessageEnum } from '@shared/components/modal/modal-confirm/modal-confirm-message-enum';
import { Observable } from 'rxjs';
import { ModalConfirmComponent } from '@shared/components/modal/modal-confirm/modal-confirm.component';
import { ModalErrorComponent } from '@shared/components/modal/modal-error/modal-error.component';
import { ModalErrorMessageEnum } from '@shared/components/modal/modal-error/modal-error-message-enum';
import { ModalInfoMessageEnum } from '@shared/components/modal/modal-info/modal-info-message-enum';
import { ModalInfoComponent } from '@shared/components/modal/modal-info/modal-info.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
	CreateContactComponent,
	CustomerInfo,
} from '@features/private/customer/create-contact/create-contact.component';
import { AddAddressComponent, AddAddressData } from '@shared/components/add-address/add-address.component';
import {
	EditShipmentContactComponent,
} from '@features/private/shipment/shipment-details/edit-shipment-contact/edit-shipment-contact.component';
import { ShipmentErrorEnum } from '@domain/shipment/shipment.error.enum';
import { ShipmentErrorsComponent } from '@features/private/shipment/shipment-errors/shipment-errors.component';
import { UploadAvatarComponent } from '@features/private/account/upload-avatar/upload-avatar.component';
import { GroupModalComponent } from '@features/private/account/address-book/group/group-modal/group-modal.component';
import {
	ConfirmCustomerDeleteComponent,
} from '@features/private/customer/customer-details/confirm-delete/confirm-customer-delete.component';
import {
	ShipmentTrackingAddDocumentModalComponent,
} from '@features/private/tracking/shipment-tracking/tracking-menu/tracking-menu-documents/tracking-menu-add-document-modal/shipment-tracking-add-document-modal.component';
import {
	ShipmentTrackingViewDocumentModalComponent
} from '@features/private/tracking/shipment-tracking/tracking-menu/tracking-menu-documents/tracking-view-document-modal/shipment-tracking-view-document-modal.component';
import { TaskDocument } from '@domain/document/document';
import { PrePostCarriageWithType } from '@domain/shipment/route/pre-post-carriage';
import { Equipment } from "@domain/shipment/equipment";
import {
	ModalConfirmDeleteContainerComponent
} from "@shared/components/modal/modal-confirm-delete-container/modal-confirm-delete-container.component";
import { AddressSelectorComponent } from '@shared/components/address-selector/address-selector.component';
import { Address } from '@domain/shared/address';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private _dialog: MatDialog) {}

    confirm(title: ModalConfirmMessageEnum, body: ModalConfirmMessageEnum, params?: any): Observable<boolean> {
        return this._dialog
            .open(ModalConfirmComponent, <MatDialogConfig>{
                data: {
                    title: ModalConfirmMessageEnum[title],
                    body: ModalConfirmMessageEnum[body],
                    params: params,
                },
            })
            .afterClosed();
    }
	confirmDeleteEquipment(title: ModalConfirmMessageEnum, body: ModalConfirmMessageEnum, container: Equipment, params?: any): Observable<boolean> {
		return this._dialog
				.open(ModalConfirmDeleteContainerComponent, <MatDialogConfig>{
					data: {
						title: ModalConfirmMessageEnum[title],
						body: ModalConfirmMessageEnum[body],
						container: container,
						params: params,
					},
				})
				.afterClosed();
	}


    error(title: ModalErrorMessageEnum, body: ModalErrorMessageEnum): Observable<void> {
        return this._dialog
            .open(ModalErrorComponent, <MatDialogConfig>{
                data: {
                    title: ModalErrorMessageEnum[title],
                    body: ModalErrorMessageEnum[body],
                },
            })
            .afterClosed();
    }

    info(title: ModalInfoMessageEnum, body: ModalInfoMessageEnum): Observable<void> {
        return this._dialog
            .open(ModalInfoComponent, <MatDialogConfig>{
                data: {
                    title: ModalInfoMessageEnum[title],
                    body: ModalInfoMessageEnum[body],
                },
            })
            .afterClosed();
    }

    createContact(customer: CustomerInfo): Observable<boolean> {
        return this._dialog
            .open(CreateContactComponent, {
                disableClose: true,
                data: customer,
                width: '1335px',
            })
            .afterClosed();
    }

    addAddress(data: AddAddressData): Observable<boolean> {
        return this._dialog
            .open(AddAddressComponent, {
                disableClose: true,
                data: data,
                width: '400px',
                height: '700px',
            })
            .afterClosed();
    }

    addContactToShipmentCustomer(): Observable<boolean> {
        return this._dialog
            .open(EditShipmentContactComponent, {
                disableClose: true,
                width: '1335px',
            })
            .afterClosed();
    }

    showShipmentErrors(errors: ShipmentErrorEnum[]): Observable<void> {
        return this._dialog
            .open(ShipmentErrorsComponent, <MatDialogConfig>{
                data: {
                    errors: errors,
                },
            })
            .afterClosed();
    }

    uploadAvatar(imageForm: 'rect' | 'circle'): Observable<boolean> {
        return this._dialog
            .open(UploadAvatarComponent, {
                disableClose: true,
                width: '410px',
                height: '450px',
                data: {
                    imageForm: imageForm,
                },
            })
            .afterClosed();
    }

    addGroupToContact(): Observable<boolean> {
        return this._dialog
            .open(GroupModalComponent, {
                disableClose: true,
                width: '850px',
                height: '500px',
            })
            .afterClosed();
    }

	confirmCustomerDelete(): Observable<boolean> {
		return this._dialog
			.open(ConfirmCustomerDeleteComponent)
			.afterClosed();
	}

	addDocument(): Observable<boolean> {
		return this._dialog
			.open(ShipmentTrackingAddDocumentModalComponent)
			.afterClosed();
	}

	openDocument(document: TaskDocument, carriage: PrePostCarriageWithType): Observable<boolean> {
		return this._dialog
			.open(ShipmentTrackingViewDocumentModalComponent, {
				data: { document, carriage },
			})
			.afterClosed();
	}

    selectAddress(): Observable<Address> {
        return this._dialog
			.open(AddressSelectorComponent, {})
            .afterClosed();
    }
}
