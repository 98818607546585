import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { GenericForm } from '@shared/types/generic.form';
import { Address } from '@domain/shared/address';
import { Subject, takeUntil } from 'rxjs';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { CustomerUtils } from '@features/private/customer/customer.utils';
import { AddressDialogEnum } from '@state/customer/address.dialog.enum';
import { AddAddressToBook, AddAddressToBookSuccess, } from '@state/customer/customer.actions';
import { LoadCountries } from '@state/referentials/referentials.actions';
import { AddressFormComponent } from "@shared/components/address-form/address-form.component";
import { ButtonCustomComponent } from "@shared/components/input/button/button-custom/button-custom.component";
import { TranslocoModule } from "@ngneat/transloco";
import { MatButtonModule } from "@angular/material/button";

export interface AddAddressData {
	action: AddressDialogEnum;
	customerId: string;
}

@Component({
	selector: 'app-add-address',
	templateUrl: './add-address.component.html',
	styleUrls: ['./add-address.component.scss'],
	imports: [
		AddressFormComponent,
		ButtonCustomComponent,
		TranslocoModule,
		MatDialogModule,
		MatButtonModule
	],
	standalone: true
})
export class AddAddressComponent implements OnInit, OnDestroy {
	addressForm: FormGroup<GenericForm<Address | undefined>> =
			CustomerUtils.createAddressFormGroup();

	private ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
			public dialogRef: MatDialogRef<AddAddressComponent>,
			@Inject(MAT_DIALOG_DATA) public data: AddAddressData,
			private store: Store,
			private actions$: Actions
	) {
	}

	ngOnInit(): void {
		this.actions$
				.pipe(
						ofActionSuccessful(AddAddressToBookSuccess),
						takeUntil(this.ngUnsubscribe)
				)
				.subscribe(() => this.dialogRef.close(true));
		this.store.dispatch(new LoadCountries());
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	cancel() {
		this.dialogRef.close(false);
	}

	addAddress() {
		if (this.data.action === AddressDialogEnum.ADD_TO_BOOK) {
			this.store.dispatch(
					new AddAddressToBook(
							this.addressForm.value as Address,
							this.data.customerId
					)
			);
		}
	}
}
