import { ShipmentAction } from '@state/shipment/shipment.action.enum';
import {
	Shipment,
	ShipmentCreate,
	ShipmentFilterData,
	ShipmentPatch,
	Shipments,
	ShipmentStatus
} from '@domain/shipment/shipment';
import { Equipment, EquipmentDetailPatch, EquipmentPatch, UnNumber, UnNumberFilter } from '@domain/shipment/equipment';
import { CargoItem } from '@domain/shipment/cargoItem';
import { ShipmentStatusEnum } from '@domain/shipment/shipment-status.enum';
import { CustomerFilter } from '@domain/client/customer';
import { EquipmentRef } from '@domain/shipment/equipmentRef';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { DoubleTrucking } from '@domain/shipment/additionalservices/double-trucking';
import { ShipmentFilterUpdate } from '@domain/shipment/shipment.filter';
import { RoutingPlan } from "@domain/shipment/routing.plan";
import { Location } from "@domain/locations/location";
import { Address } from '@domain/shared/address';

const PREFIX = '[Shipment] ';

export class LoadShipments {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS;
}

export class LoadShipmentsKanbans {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_KANBANS;
}

export class LoadShipmentsKanban {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_KANBAN;

	constructor(public status: ShipmentStatusEnum, public clear: boolean = true) {
	}
}

export class LoadShipmentsKanbanMobile {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_KANBAN_MOBILE;

	constructor(public clear: boolean = true) {
	}
}

export class LoadShipmentsKanbanSuccess {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_KANBAN_SUCCESS;

	constructor(public shipments: Shipments, public status: ShipmentStatusEnum, public clear: boolean) {
	}
}

export class LoadShipmentsKanbanFailure {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_KANBAN_FAILURE;

	constructor(public error: any, public status: ShipmentStatusEnum) {
	}
}

export class ChangeShipmentsSpinner {
	static readonly type = PREFIX + ShipmentAction.CHANGE_SHIPMENTS_SPINNER;

	constructor(public active: boolean) {
	}
}

export class ChangeKanbanSpinner {
	static readonly type = PREFIX + ShipmentAction.CHANGE_KANBAN_SPINNER;

	constructor(public status: ShipmentStatusEnum, public active: boolean) {
	}
}

export class LoadShipmentsSuccess {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_SUCCESS;

	constructor(public shipments: PaginatedEntity<Shipment>, public clear: boolean = true) {
	}
}

export class LoadShipmentsFailure {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENTS_FAILURE;

	constructor(public error: any) {
	}
}

export class UpdateShipmentsPage {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_PAGE;

	constructor(public page: number, public size: number) {
	}
}

export class UpdateShipmentsSort {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_SORT;

	constructor(public sort: string, public update: boolean = true) {
	}
}

export class LoadFilterLocation {
	static readonly type = PREFIX + ShipmentAction.LOAD_FILTER_LOCATION;

	constructor() {
	}
}

export class LoadFilterCustomer {
	static readonly type = PREFIX + ShipmentAction.LOAD_FILTER_CUSTOMER;

	constructor(public filter: CustomerFilter = {}) {
	}
}

export class UpdateShipmentsFilterData {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_FILTER_DATA;

	constructor(public data: ShipmentFilterData) {
	}
}

export class UpdateShipmentsFilter {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_FILTER;

	constructor(public filter: ShipmentFilterUpdate) {
	}
}

export class LoadShipment {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENT;

	constructor(public shipmentId: string) {
	}
}

export class LoadShipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENT_SUCCESS;

	constructor(public shipment: Shipment) {
	}
}

export class LoadShipmentFailure {
	static readonly type = PREFIX + ShipmentAction.LOAD_SHIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class CreateShipment {
	static readonly type = PREFIX + ShipmentAction.CREATE_SHIPMENT;

	constructor(public shipment: ShipmentCreate) {
	}
}

export class CreateShipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.CREATE_SHIPMENT_SUCCESS;

	constructor(public shipment: Shipment) {
	}
}

export class CreateShipmentFailure {
	static readonly type = PREFIX + ShipmentAction.CREATE_SHIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class UpdateRefSearch {
	static readonly type = PREFIX + 'Update Ref Search';

	constructor(public search: string) {
	}

}

export class DeleteShipmentConfirm {
	static readonly type = PREFIX + ShipmentAction.DELETE_SHIPMENT_CONFIRM;
}

export class DeleteShipment {
	static readonly type = PREFIX + ShipmentAction.DELETE_SHIPMENT;
}

export class DeleteShipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.DELETE_SHIPMENT_SUCCESS;
}

export class DeleteShipmentFailure {
	static readonly type = PREFIX + ShipmentAction.DELETE_SHIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class UpdateShipment {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT;

	constructor(public shipment: Shipment) {
	}
}

export class UpdateShipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_SUCCESS;

	constructor(public shipment: Shipment) {
	}
}

export class UpdateShipmentFailure {
	static readonly type = PREFIX + ShipmentAction.UPDATE_SHIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class PatchShipment {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT;

	constructor(public shipment: ShipmentPatch) {
	}
}

export class PatchShipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT_SUCCESS;

	constructor(public shipment: Shipment) {
	}
}

export class PatchShipmentFailure {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class PatchShipmentType {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT_TYPE;

	constructor(public shipment: ShipmentPatch) {
	}
}

export class PatchShipmentTypeSuccess {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT_TYPE_SUCCESS;

	constructor(public shipment: Shipment) {
	}
}

export class PatchShipmentTypeFailure {
	static readonly type = PREFIX + ShipmentAction.PATCH_SHIPMENT_TYPE_FAILURE;

	constructor(public error: any) {
	}
}

export class LoadEquipmentRef {
	static readonly type = PREFIX + ShipmentAction.LOAD_EQUIPMENT_REF;
}

export class LoadEquipmentRefSuccess {
	static readonly type = PREFIX + ShipmentAction.LOAD_EQUIPMENT_REF_SUCCESS;

	constructor(public equipmentRefs: PaginatedEntity<EquipmentRef>) {
	}
}

export class LoadEquipmentRefFailure {
	static readonly type = PREFIX + ShipmentAction.LOAD_EQUIPMENT_REF_FAILURE;

	constructor(public error: any) {
	}
}

export class LoadUnNumbers {
	static readonly type = PREFIX + ShipmentAction.LOAD_UN_NUMBERS;
}

export class LoadUnNumbersSuccess {
	static readonly type = PREFIX + ShipmentAction.LOAD_UN_NUMBERS_SUCCESS;

	constructor(public unNumbers: PaginatedEntity<UnNumber>) {
	}
}

export class LoadUnNumbersFailure {
	static readonly type = PREFIX + ShipmentAction.LOAD_UN_NUMBERS_FAILURE;

	constructor(public error: any) {
	}
}

export class UpdateUnNumberFilter {
	static readonly type = PREFIX + ShipmentAction.UPDATE_UN_NUMBER_FILTER;

	constructor(public filter: UnNumberFilter) {
	}
}

export class PatchEquipment {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT;

	constructor(public equipment: EquipmentPatch) {
	}
}

export class PatchEquipmentGrouped {
	static readonly type = PREFIX + 'Patch Equipment Grouped';

	constructor(public equipment: EquipmentPatch) {
	}
}

export class PatchEquipmentGroupedSuccess {
	static readonly type = PREFIX + 'Patch Equipment Grouped Success';

	constructor(public equipment: EquipmentPatch) {
	}
}

export class GroupEquipmentMerchandise {
	static readonly type = PREFIX + 'Group Equipment Merchandise';

	constructor(public equipmentId: string, public group: number) {
	}
}

export class GroupEquipmentMerchandiseSuccess {
	static readonly type = PREFIX + 'Group Equipment Merchandise Success';

	constructor(public equipment: Equipment) {
	}
}

export class GroupEquipmentMerchandiseFailure {
	static readonly type = PREFIX + 'Group Equipment Merchandise Failure';

	constructor(public error: any) {
	}
}

export class PatchEquipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT_SUCCESS;

	constructor(public equipment: Equipment) {
	}
}

export class PatchEquipmentFailure {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class PatchEquipmentDetail {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT_DETAIL;

	constructor(public equipment: EquipmentDetailPatch, public preCarriage: boolean) {
	}
}

export class PatchEquipmentDetailSuccess {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT_DETAIL_SUCCESS;

	constructor(public equipment: Equipment) {
	}
}

export class PatchEquipmentDetailFailure {
	static readonly type = PREFIX + ShipmentAction.PATCH_EQUIPMENT_DETAIL_FAILURE;

	constructor(public error: any) {
	}
}

export class CreateEquipment {
	static readonly type = PREFIX + ShipmentAction.CREATE_EQUIPMENT;

	constructor(public equipment: Equipment) {
	}
}

export class CreateEquipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.CREATE_EQUIPMENT_SUCCESS;

	constructor(public equipment: Equipment) {
	}
}

export class CreateEquipmentFailure {
	static readonly type = PREFIX + ShipmentAction.CREATE_EQUIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class DeleteEquipmentConfirm {
	static readonly type = PREFIX + ShipmentAction.DELETE_EQUIPMENT_CONFIRM;

	constructor(public container: Equipment) {
	}
}

export class DeleteEquipment {
	static readonly type = PREFIX + ShipmentAction.DELETE_EQUIPMENT;

	constructor(public index: number) {
	}
}

export class DeleteEquipmentSuccess {
	static readonly type = PREFIX + ShipmentAction.DELETE_EQUIPMENT_SUCCESS;

	constructor(public index: number) {
	}
}

export class DeleteEquipmentFailure {
	static readonly type = PREFIX + ShipmentAction.DELETE_EQUIPMENT_FAILURE;

	constructor(public error: any) {
	}
}

export class CloneEquipmentConfirm {
	static readonly type = PREFIX + ShipmentAction.CLONE_EQUIPMENT_CONFIRM;

	constructor(public index: number) {
	}
}

export class CloneEquipment {
	static readonly type = PREFIX + ShipmentAction.CLONE_EQUIPMENT;

	constructor(public index: number) {
	}
}

export class CreateCargoItems {
	static readonly type = PREFIX + ShipmentAction.CREATE_CARGO_ITEMS;

	constructor(public cargoItem: CargoItem, public equipmentId: string) {
	}
}

export class CreateCargoItemsSuccess {
	static readonly type = PREFIX + ShipmentAction.CREATE_CARGO_ITEMS_SUCCESS;

	constructor(public cargoItem: CargoItem, public equipmentId: string) {
	}
}

export class CreateCargoItemsFailure {
	static readonly type = PREFIX + ShipmentAction.CREATE_CARGO_ITEMS_FAILURE;

	constructor(public error: any) {
	}
}

export class DeleteCargoItemsForm {
	static readonly type = PREFIX + ShipmentAction.DELETE_CARGO_ITEMS_FORM;

	constructor(public parentIdx: number, public idx: number) {
	}
}

export class DeleteCargoItems {
	static readonly type = PREFIX + ShipmentAction.DELETE_CARGO_ITEMS;

	constructor(public equipmentId: string, public item: CargoItem) {
	}
}

export class DeleteCargoItemsSuccess {
	static readonly type = PREFIX + ShipmentAction.DELETE_CARGO_ITEMS_SUCCESS;

	constructor(public equipmentId: string, public item: CargoItem) {
	}
}

export class DeleteCargoItemsFailure {
	static readonly type = PREFIX + ShipmentAction.DELETE_CARGO_ITEMS_FAILURE;

	constructor(public error: any) {
	}
}

export class PatchCargoItemLocal {
	static readonly type = PREFIX + 'Patch Cargo Item Local';

	constructor(public cargoItemId: string, public equipmentId: string, public valid: boolean) {
	}

}

export class PatchCargoItems {
	static readonly type = PREFIX + ShipmentAction.PATCH_CARGO_ITEMS;

	constructor(public equipmentId: string, public cargoItem: CargoItem) {
	}
}

export class PatchCargoItemsSuccess {
	static readonly type = PREFIX + ShipmentAction.PATCH_CARGO_ITEMS_SUCCESS;

	constructor(public cargoItem: CargoItem, public equipmentId: string) {
	}
}

export class PatchCargoItemsFailure {
	static readonly type = PREFIX + ShipmentAction.PATCH_CARGO_ITEMS_FAILURE;

	constructor(public error: any) {
	}
}

export class ResetShipmentClient {
	static readonly type = PREFIX + ShipmentAction.RESET_SHIPMENT_CLIENT;

	constructor() {
	}
}

export class OpenAddContactToShipmentCustomerDialog {
	static readonly type = PREFIX + ShipmentAction.OPEN_ADD_CONTACT_TO_SHIPMENT_CUSTOMER;
}

export class CreateDoubleTrucking {
	static readonly type = PREFIX + ShipmentAction.CREATE_DOUBLE_TRUCKING;

	constructor(public trucking: DoubleTrucking) {
	}
}

export class CreateDoubleTruckingSuccess {
	static readonly type = PREFIX + ShipmentAction.CREATE_DOUBLE_TRUCKING_SUCCESS;

	constructor(public trucking: DoubleTrucking) {
	}
}

export class CreateDoubleTruckingFailure {
	static readonly type = PREFIX + ShipmentAction.CREATE_DOUBLE_TRUCKING_FAILURE;

	constructor(public error: any) {
	}
}

export class UpdateDoubleTrucking {
	static readonly type = PREFIX + ShipmentAction.UPDATE_DOUBLE_TRUCKING;

	constructor(public trucking: DoubleTrucking) {
	}
}

export class UpdateDoubleTruckingSuccess {
	static readonly type = PREFIX + ShipmentAction.UPDATE_DOUBLE_TRUCKING_SUCCESS;

	constructor(public trucking: DoubleTrucking) {
	}
}

export class UpdateDoubleTruckingFailure {
	static readonly type = PREFIX + ShipmentAction.UPDATE_DOUBLE_TRUCKING_FAILURE;

	constructor(public error: any) {
	}
}

export class DeleteDoubleTruckingConfirm {
	static readonly type = PREFIX + ShipmentAction.DELETE_DOUBLE_TRUCKING_CONFIRM;

	constructor(public id: string) {
	}
}

export class DeleteDoubleTrucking {
	static readonly type = PREFIX + ShipmentAction.DELETE_DOUBLE_TRUCKING;

	constructor(public id: string) {
	}
}

export class DeleteDoubleTruckingSuccess {
	static readonly type = PREFIX + ShipmentAction.DELETE_DOUBLE_TRUCKING_SUCCESS;

	constructor(public id: string) {
	}
}

export class DeleteDoubleTruckingFailure {
	static readonly type = PREFIX + ShipmentAction.DELETE_DOUBLE_TRUCKING_FAILURE;

	constructor(public error: any) {
	}
}

export class PatchShipmentStatus {
	static readonly type = PREFIX + 'Patch Shipment Status';

	constructor(public status: ShipmentStatusEnum, public displayMessage = true) {
	}
}

export class PatchShipmentStatusSuccess {
	static readonly type = PREFIX + 'Patch Shipment Status Success';

	constructor(public status: ShipmentStatus, public displayMessage = true) {
	}
}

export class PatchShipmentStatusFailure {
	static readonly type = PREFIX + 'Patch Shipment Status Failure';

	constructor(public error: any) {
	}
}

export class LoadQuotes {
	static readonly type = PREFIX + 'Load Quotes';

	constructor(public shipmentId: string) {
	}
}

export class LoadQuotesSuccess {
	static readonly type = PREFIX + 'Load Quotes Success';

	constructor(public plans: RoutingPlan[]) {
	}
}

export class LoadQuotesFailure {
	static readonly type = PREFIX + 'Load Quotes Failure';

	constructor(public error: any) {
	}
}

export class UpdateLocationSearch {
	static readonly type = PREFIX + 'Update Location Search';

	constructor(public search: string) {
	}
}

export class LoadLocations {
	static readonly type = PREFIX + 'Load Locations';

	constructor() {
	}
}

export class LoadLocationsSuccess {
	static readonly type = PREFIX + 'Load Locations Success';

	constructor(public locations: PaginatedEntity<Location>) {
	}
}

export class SelectQuote {
	static readonly type = PREFIX + 'Select Quote';

	constructor(public planId: string) {
	}
}

export class SelectQuoteSuccess {
	static readonly type = PREFIX + 'Select Quote Success';
}

export class SelectQuoteFailure {
	static readonly type = PREFIX + 'Select Quote Failure';

	constructor(public error: any) {
	}
}
