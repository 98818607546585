import { ActivityTypeEnum } from "@domain/user/activity-type.enum";

export class User {
    id?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    gender?: string;
    avatar?: string;
    localisation?: string;
    preferredLang?: string;
    preferredTimezone?: string;
    organization?: Organization;
    phones?: Phone[];
	roles?: RoleEnum[];
}

export interface UserCreate {
	email: string;
	phones: Phone[]
	firstname: string;
	lastname: string;
	reference?: string;
	organizationId: string;
	localization: string;
	roles: RoleEnum[];

}

export interface Application {
	id: string;
	name: string;
}

export enum ConnectionState {
	PENDING = 'PENDING',
	CONNECTED = 'CONNECTED',
	LOCKED = 'LOCKED',
	DISCONNECTED = 'DISCONNECTED',
}

export interface UserPatch {
	id?: string;
	firstname?: string;
	lastname?: string;
	disabled?: boolean;
}

export enum RoleEnum {
    ADMIN = 'ADMIN',
    COLLABORATOR = 'COLLABORATOR',
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    TRUCK_DRIVER = 'TRUCK_DRIVER',
    CHARTERER = 'CHARTERER',
    ORGANIZATOR = 'ORGANIZATOR',
	PORT_OPERATOR = 'PORT_OPERATOR',
}

export interface Organization {
    id?: string;
    name?: string;
    type?: ActivityTypeEnum[];
    address?: string;
    postalCode?: string;
    state?: string;
    duns?: string;
    city?: string;
    countryCode?: string;
    logo?: string;
    findme?: boolean;
    requestme?: boolean;
	portId?: string;
	portName?: string;
}


export interface Phone {
    id?: string;
    countryCode?: string;
    number?: string;
    type?: string;
    privileged?: boolean;
    visibility?: string;
}

export interface LibPhoneNumber {
    countryCode: string; //"FR"
    dialCode: string; //"+33"
    e164Number: string; //"+33458798654"
    internationalNumber: string; //"+33 4 58 79 86 54"
    nationalNumber: string; //"04 58 79 86 54"
    number: string; //"0458798654"
}


