export enum AdditionalServicesType {
  MULTI_P_D = "MULTI_P_D",
  DOUBLE_TRUCKING = 'DOUBLE_TRUCKING',

}

export type Page = 'home' | AdditionalServicesType;

export enum TransportType {
  ALL = "ALL",
  ROAD = "ROAD",
  BARGE = "BARGE"
}

export interface DialogOpenData {
  page?: Page,
  serviceId?: string,
}

export interface AdditionalServicesData {
  title: string;
  description: string;
  serviceType: AdditionalServicesType;
  transportTypes: TransportType[];
}

export interface AdditionalService {
  serviceType: AdditionalServicesType,
  transportTypes: TransportType[]

}
export const additionalServicesList: AdditionalService[] = [
  {serviceType: AdditionalServicesType.MULTI_P_D, transportTypes: [TransportType.ROAD]},
  {serviceType: AdditionalServicesType.DOUBLE_TRUCKING, transportTypes: [TransportType.ROAD]},
];

export interface IAdditionalService {
  type: AdditionalServicesType,
  shipmentId: string,
  id: string
}
