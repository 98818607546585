import { Equipment, LoadingTypeEnum } from '@domain/shipment/equipment';
import { ShipmentStatusEnum } from '@domain/shipment/shipment-status.enum';
import { ShipmentVisibilityEnum } from '@domain/shipment/shipment-visibility.enum';
import { ShipmentPortDischarge, ShipmentPortLoading } from '@domain/shipment/shipment-port';
import { CustomerReponse } from '@domain/client/customer';
import { Contact } from '@domain/contact/contact';
import { Country } from '@domain/locations/country';
import { IAdditionalService } from '@domain/shipment/additionalservices/additional-services';
import { ShipmentRoute } from '@domain/shipment/route/shipment-route';
import { UserContact } from '@domain/address-book/address-book';
import { User } from '@domain/user/user';

export class ShipmentBase {
	name?: string;
	organizerId?: string;
	status?: ShipmentStatus;
	contacts?: Contact[];
	allContacts?: { [key: string]: UserContact };
	visibility?: ShipmentVisibilityEnum;
	isImport?: boolean;
	preCarriage?: boolean;
	maritimRoute?: boolean;
	postCarriage?: boolean;
	groupId?: string;
	equipments?: Equipment[];
	shipmentPortOfDischarge?: ShipmentPortDischarge;
	shipmentPortOfLoading?: ShipmentPortLoading;
	comments?: string;
	// TODO: A faire dans le back
	bookingNumber?: string;
	additionalServices?: IAdditionalService[];
	loadingType?: LoadingTypeEnum;
}

export interface ShipmentStatus {
	status: ShipmentStatusEnum;
	on: string;
}

export class Shipment extends ShipmentBase {
	id?: string;
	reference?: string;
	client?: CustomerReponse;
	createdOn?: string;
	createdBy?: User;
	route?: ShipmentRoute;
}

export interface ShipmentCreate extends ShipmentBase {
	clientId?: string,
}


export interface ShipmentFilterData {
	countries?: Country[],
	customers?: CustomerReponse[]
	customersSearch?: string;
}

export interface ShipmentPatch {
	id?: string;
	reference?: string;
	name?: string;
	organizerId?: string;
	status?: ShipmentStatus;
	visibility?: ShipmentVisibilityEnum;
	preCarriage?: boolean;
	maritimRoute?: boolean;
	postCarriage?: boolean;
	clientId?: string | null;
	contactIds?: string[];
	isImport?: boolean;
	groupId?: string;
	shipmentPortOfDischarge?: ShipmentPortDischarge;
	shipmentPortOfLoading?: ShipmentPortLoading;
	loadingType?: LoadingTypeEnum;
}

export interface Shipments {
	content: Shipment[];
	size: number;
	numberOfElements: number;
	totalElements: number;
	totalPages: number;
	number: number;
	first: boolean;
	last: boolean;
}

export function equal(s1: ShipmentPatch, s2: ShipmentPatch, comparePatch = true): boolean {
	if (!s1 || !s2) {
		return false;
	}
	let equal = true;
	const keys: string[] = Object.keys(s1);
	keys.forEach((key) => {
		// @ts-ignore
		if (comparePatch && s2[key] || !comparePatch) {
			// @ts-ignore
			equal = equal && s1[key] === s2[key];
		}
	});
	return equal;
}
