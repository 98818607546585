<ng-container *transloco="let t; read: 'account.address-book.contacts.group-modal'">
    <div mat-dialog-title>
        <h1>{{ t('title') }}</h1>
        <button aria-label="close modal" (click)="closeModal()">
            <mat-icon class="float-end">close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="d-flex flex-column gap-3 h-100">
        <mat-form-field class="search" appearance="outline" subscriptSizing="dynamic" class="w-100">
            <span matTextPrefix class="icon-research"></span>
            <input
                matInput
                placeholder="{{ t('search') }}"
                class="ps-2"
                [ngModel]="(groupFilter$ | async)?.name"
                (ngModelChange)="onChange($event)"
            />
        </mat-form-field>

        <div class="d-flex align-items-center group-header">
            <mat-checkbox (click)="assignAllGroups()" [checked]="isAllGroupsChecked"></mat-checkbox>
            <span colspan="2" class="body-1-accent group-header-title flex-grow-1"
                >{{ (groups$ | async)?.totalElements }} {{ t('group') }}</span
            >
            <span class="body-1">{{ contactGroups.length }} {{ t('select') }}</span>
        </div>
        <div class="group-body h-100">
            <div *ngFor="let group of (groups$ | async)?.content" class="contact-row">
                <mat-checkbox [checked]="isGroupInContact(group)" (click)="assignGroup(group)"></mat-checkbox>
                <div class="contacts-avatar position-relative">
                    <ng-container *ngFor="let contact of group.contacts.slice(0, 5); let i = index; let last = last">
                        <app-profile-picture
                            class="position-absolute"
                            [style.left]="i * 12 + 'px'"
                        ></app-profile-picture>
                        <span
                            *ngIf="group.contacts.length > 5 && last"
                            class="position-absolute contact-count web-label"
                            [style.left]="(i + 1) * 12 + 'px'"
                            >+{{ group.contacts.length - 5 }}</span
                        >
                    </ng-container>
                </div>
                <span class="body-1-accent group-name">{{ group.name }}</span>
                <span class="body-2">{{ group.contacts.length }} {{ t('contact') }}</span>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex align-items-center justify-content-end">
        <!-- <app-button icon="add" [type]="'line'" >{{ t('btn') }}</app-button> -->
        <div class="d-flex gap-2">
            <app-button (clicked)="validateGroups()">{{ 'app.save' | transloco }}</app-button>
            <app-button [type]="'line'" (click)="closeModal()">{{ 'app.cancel' | transloco }}</app-button>
        </div>
    </div>
</ng-container>
