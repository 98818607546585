import { RoutingStep } from "@domain/shipment/routing.step";
import { QuoteType } from "@features/private/shipment/shipment-quotes/shipment-quotes.component";

export interface RoutingPlan {
	id?: string;
	price?: number;
	metric?: MetricEnum;
	co2?: number;
	content?: RoutingStep[];
	mark?: number;
	type?: QuoteType;
}

export enum MetricEnum {
	price = 'price',
	CO2 = 'CO2',
}
