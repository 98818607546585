import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationFilter, NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';
import { PageOption } from "@shared/utils/query/page.options";
import { RequestParams } from "@shared/utils/query/request.params";
import { QueryBuilder, QueryParamType } from "@shared/utils/query/query.builder";

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private http: HttpClient) {}

	getUnreadNotifications() {
		return this.http.get<UnreadNotifications>(environment.api.bff + '/notifications/unread')
	}

	getLatestNotifications() {
		return this.findAll({ page: 0, size: 15 });
	}

	findAll(pageOptions: PageOption, filter?: NotificationFilter) {

		const parametres: RequestParams = {
			page: pageOptions.page || 0,
			size: pageOptions.size || 10,
		}

		if (filter) {
			parametres.q = new QueryBuilder()
					.addParam({ key: 'search', type: QueryParamType.like, value: filter.search })
					.get()
		}
		return this.http.get<PaginatedEntity<NotificationResponse>>(environment.api.bff + '/notifications', {
			params: {
				...parametres
			},
		});
	}

	markAsRead(ids: string[]) {
		return this.http.patch<void>(environment.api.bff + '/notifications/read', {}, {
			params: {
				ids,
			}
		});
	}

	markAllAsRead() {
		return this.http.patch<void>(environment.api.bff + '/notifications/read-all', {});

	}

	markAsUnread(ids: string[]) {
		return this.http.patch<void>(environment.api.bff + '/notifications/unread', {}, {
			params: {
				ids,
			}
		});
	}
}
