import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

export const routingConfiguration: ExtraOptions = {
	paramsInheritanceStrategy: 'always',
	anchorScrolling: 'enabled',
};

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		loadChildren: () => import('@features/private/private.module').then((m) => m.PrivateModule),
	},
	{
		path: 'public',
		loadChildren: () => import('@features/public/public.module').then((m) => m.PublicModule),
	},
	{ path: '**', redirectTo: '' },
];
@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes, routingConfiguration)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
