import { UserContactGroup, UserContactGroupFilter } from '@domain/address-book/address-book';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { GroupContactActions } from './contact-group.actions.enum';

const PREFIX = '[group-contact] ';

export class LoadGroupContacts {
    static readonly type = PREFIX + GroupContactActions.LOAD_GROUP_CONTACTS;
    constructor() {}
}

export class LoadGroupContactsSuccess {
    static readonly type = PREFIX + GroupContactActions.LOAD_GROUP_CONTACTS_SUCCESS;
    constructor(public groups: PaginatedEntity<UserContactGroup>) {}
}

export class UpdateGroupFilter {
    static readonly type = PREFIX + GroupContactActions.UPDATE_GROUP_FILTER;
    constructor(public userContactGroupFilter: UserContactGroupFilter) {}
}
