<!-- Mandatory to have multiple ng-content - keep it in case we need it -->
<ng-template #content><ng-content></ng-content></ng-template>


<button *ngIf="type !== 'icon'"
		mat-flat-button
		[disableRipple]="type === 'line'"
		class="custom-button"
		ngClass="custom-button_{{type}} custom-button_{{type}}_{{subtype}} custom-button_{{type}}_{{size}} {{buttonClass}}"
		[ngStyle]="buttonStyle"
		[disabled]="disabled"
		(click)="clicked.emit()">
	<mat-icon *ngIf="icon">{{icon}}</mat-icon>
	<ng-container *ngTemplateOutlet="content"></ng-container>
</button>


<button *ngIf="type === 'icon'"
		mat-flat-button
		class="custom-button"
		ngClass="custom-button_{{type}} custom-button_{{type}}_{{subtype}} custom-button_{{type}}_{{size}} {{buttonClass}}"
		[ngStyle]="buttonStyle"
		[disabled]="disabled"
		(click)="clicked.emit()">
	<mat-icon *ngIf="icon">{{icon}}</mat-icon>
</button>
