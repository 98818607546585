import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Phone, User } from '@domain/user/user';
import { environment } from '@environment/environment';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { UserService } from '@services/users/user.service';
import { ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import {
	AddOneEmptyUserPhone,
	AddOneUserPhone,
	AddOneUserPhoneFailure,
	AddOneUserPhoneSuccess,
	ConfirmNewUserEmail,
	ConfirmNewUserEmailFailure,
	ConfirmNewUserEmailSuccess,
	DeleteOneUserPhone,
	LoadPreferences,
	LoadPreferencesFailure,
	LoadPreferencesSuccess,
	LoadUserProfileCompletion,
	LoadUserProfileCompletionFailure,
	LoadUserProfileCompletionSuccess,
	UpdateOneUserPhone,
	UpdateOneUserPhoneFailure,
	UpdateOneUserPhoneSuccess,
	UpdateOrganization,
	UpdateOrganizationFailure,
	UpdateOrganizationSuccess,
	UpdatePreferences,
	AddNotificationsPreferences,
	DeleteNotificationsPreferences,
	UpdatePreferencesFailure,
	UpdatePreferencesSuccess,
	UpdateUserEmail,
	UpdateUserEmailFailure,
	UpdateUserEmailSuccess,
	UpdateUserProfile,
	UpdateUserProfileFailure,
	UpdateUserProfileSuccess,
	UploadAvatar,
	UploadAvatarFailure,
	UploadAvatarSuccess,
	UserLoadProfile,
	UserLoadProfileFailure,
	UserLogin,
	UserLoginFailure,
	UserLoginSuccess,
	UserLogout,
	UserLogoutSuccess,
	UserProfileLoadSuccess,
	UserSSOProfileLoadSuccess,
	ValidateUpdateUserEmail,
	ValidateUpdateUserEmailFailure,
	ValidateUpdateUserEmailSuccess,
	AddNotificationsPreferencesSuccess,
	AddNotificationsPreferencesFailure,
	DeleteNotificationsPreferencesSuccess,
	DeleteNotificationsPreferencesFailure,
} from '@state/user/user.actions';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { catchError, from, map, of } from 'rxjs';
import { UserStateSelector } from './user.selectors';
import { ModalService } from '@services/modal.service';
import { Timezone } from '@domain/user/timezone';
import { UserPreferences } from '@domain/user/user-preferences';
import { TranslocoService } from '@ngneat/transloco';

export interface UserStateModel {
	isLoggedIn: boolean;
	userSSOProfile: KeycloakProfile | null;
	userProfile: User;
	emailValidationStatus: Status | null;
	confirmNewEmailStatus: Status | null;
	profileCompletion: number;
	nbBookings: number;
	preferences?: UserPreferences;
	timezones: Timezone[];
}

export interface Status {
	isOk: boolean;
	error: string | null;
}

export const USER_STATE_TOKEN: StateToken<UserStateModel> = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
	name: USER_STATE_TOKEN,
	defaults: {
		isLoggedIn: false,
		userSSOProfile: null,
		userProfile: {},
		emailValidationStatus: null,
		confirmNewEmailStatus: null,
		profileCompletion: 25,
		nbBookings: 0,
		timezones: [],
	},
})
@Injectable()
export class UserState {
	constructor(
			private readonly keycloak: KeycloakService,
			private userService: UserService,
			private store: Store,
			private _modalService: ModalService,
			private translocoService: TranslocoService,
	) {
	}

	//#region login

	/**
	 * Action dispatched at startup, after user authentication.
	 * Trigger a call to the backend to sync user with token information (in case they have changed externaly)
	 */
	@Action(UserLogin)
	userLogin(ctx: StateContext<UserStateModel>) {

		if (this.keycloak.isLoggedIn()) {
			return this.userService
					.syncUser()
					.pipe(map((user) => ctx.dispatch(new UserLoginSuccess(this.keycloak.isLoggedIn(), user))));
		}
		return ctx.dispatch(new UserLoginFailure());

	}

	@Action(UserSSOProfileLoadSuccess)
	userSSOProfileLoadSuccess(ctx: StateContext<UserStateModel>, { user }: UserSSOProfileLoadSuccess) {
		return ctx.patchState({
			userSSOProfile: user,
		});
	}

	@Action(UserLoginSuccess)
	userLoginSuccess(ctx: StateContext<UserStateModel>, { connected, userDB }: UserLoginSuccess) {
		return from(this.keycloak.loadUserProfile()).pipe(
				map((user) => {
					ctx.patchState({
						isLoggedIn: connected,
						userProfile: userDB,
					});
					ctx.dispatch(new UserSSOProfileLoadSuccess(user));
				}),
		);
	}

	//#endregion

	//#region logout

    @Action(UserLogout)
    userLogout(ctx: StateContext<UserStateModel>) {
		localStorage.clear();
        this.keycloak.logout(environment.app.logoutUrl);
        return ctx.dispatch(new UserLogoutSuccess());
    }

	@Action(UserLogoutSuccess)
	userLogoutSuccess(ctx: StateContext<UserStateModel>, {}: UserLogoutSuccess) {
		ctx.patchState({
			isLoggedIn: false,
			userSSOProfile: null,
			userProfile: {},
		});
	}

	//#endregion

	//#region load active user profile from backend

	@Action(UserLoadProfile)
	userLoadProfile(ctx: StateContext<UserStateModel>) {
		if (ctx.getState().userProfile.id) return;
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.getUserProfile(userSSO.id).pipe(
					map((user) => {
						return ctx.dispatch(new UserProfileLoadSuccess(user));
					}),
					catchError((error: HttpErrorResponse) =>
							ctx.dispatch(new UserLoadProfileFailure(error.error.errorCode)),
					),
			);
		} else {
			return of();
		}
	}

	@Action(UserProfileLoadSuccess)
	userProfileLoadSuccess(ctx: StateContext<UserStateModel>, { user }: UserProfileLoadSuccess) {
		ctx.patchState({
			userProfile: user,
		});
	}

	@Action(UserLoadProfileFailure)
	userLoadProfileFailure(ctx: StateContext<UserStateModel>, { error }: UserLoadProfileFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_profile_failure', level: MessageLevel.ERROR }, 'profile'));
	}

	//#endregion

	//#region update active user profile

	@Action(UpdateUserProfile)
	updateUserProfile(ctx: StateContext<UserStateModel>, { user }: UpdateUserProfile) {
		const userProfile = this.store.selectSnapshot(UserStateSelector.userProfile);
		return this.userService.updateUserProfile({ ...userProfile, ...user }).pipe(
				map((user) => ctx.dispatch(new UpdateUserProfileSuccess(user))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UpdateUserProfileFailure(error.error.errorCode))),
		);
	}

	@Action(UpdateUserProfileSuccess)
	updateUserProfileSuccess(ctx: StateContext<UserStateModel>, { user }: UpdateUserProfileSuccess) {
		ctx.patchState({
			userProfile: user,
		});
		ctx.dispatch(new ShowMessage({ text: 'user_update_success', level: MessageLevel.SUCCESS }, 'profile'));
	}

	@Action(UpdateUserProfileFailure)
	updateUserProfileFailure(ctx: StateContext<UserStateModel>, { error }: UpdateUserProfileFailure) {
		ctx.dispatch(new ShowMessage({ text: 'user_update_failure', level: MessageLevel.ERROR }, 'profile'));
	}

	//#endregion

	// region update organization
	@Action(UpdateOrganization)
	updateOrganization(ctx: StateContext<UserStateModel>, { organization }: UpdateOrganization) {
		const userOrganization = this.store.selectSnapshot(UserStateSelector.organization);
		return this.userService.updateOrganization({ ...userOrganization, ...organization }).pipe(
				map((organization) => ctx.dispatch(new UpdateOrganizationSuccess(organization))),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(new UpdateOrganizationFailure(error.error.errorCode)),
				),
		);
	}

	@Action(UpdateOrganizationSuccess)
	updateOrganizationSuccess(ctx: StateContext<UserStateModel>, { organization }: UpdateOrganizationSuccess) {
		ctx.patchState({
			userProfile: {
				...ctx.getState().userProfile,
				organization: organization,
			},
		});
		ctx.dispatch(new ShowMessage({ text: 'organization_update_success', level: MessageLevel.SUCCESS }, 'profile'));
	}

	@Action(UpdateOrganizationFailure)
	updateOrganizationFailure(ctx: StateContext<UserStateModel>, { error }: UpdateOrganizationFailure) {
		ctx.dispatch(new ShowMessage({ text: 'user_update_failure', level: MessageLevel.ERROR }, 'profile'));
	}

	//#region add a new phone to the active user profile

	/** add a new and empty phone to the state to display a "form" to allow the user to enter a new phone */
	@Action(AddOneEmptyUserPhone)
	addOneEmptyUserPhone(ctx: StateContext<UserStateModel>) {
		ctx.setState(
				patch({
					userProfile: patch({
						phones: append<Phone>([{}]),
					}),
				}),
		);
	}

	/** save a new phone */
	@Action(AddOneUserPhone)
	addOneUserPhone(ctx: StateContext<UserStateModel>, { phone }: AddOneUserPhone) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.addOneUserPhone(userSSO.id, phone).pipe(
					map((createdPhone: Phone) => ctx.dispatch(new AddOneUserPhoneSuccess(createdPhone))),
					catchError((error: HttpErrorResponse) =>
							ctx.dispatch(new AddOneUserPhoneFailure(error.error.errorCode)),
					),
			);
		} else {
			return of();
		}
	}

	@Action(AddOneUserPhoneSuccess)
	addOneUserPhoneSuccess(ctx: StateContext<UserStateModel>, { phone }: AddOneUserPhoneSuccess) {
		ctx.dispatch(new ShowMessage({ text: 'add_one_user_phone_success', level: MessageLevel.SUCCESS }, 'phones'));
	}

	@Action(AddOneUserPhoneFailure)
	addOneUserPhoneFailure(ctx: StateContext<UserStateModel>, { error }: AddOneUserPhoneFailure) {
		ctx.dispatch(new ShowMessage({ text: 'add_one_user_phone_failure', level: MessageLevel.ERROR }, 'phones'));
	}

	//#endregion

	//#region update one of the existing phones of the active user

	@Action(UpdateOneUserPhone)
	updateOneUserPhone(ctx: StateContext<UserStateModel>, { phone }: UpdateOneUserPhone) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.updateOneUserPhone(userSSO.id, phone).pipe(
					map(() => ctx.dispatch(new UpdateOneUserPhoneSuccess())),
					catchError((error: HttpErrorResponse) =>
							ctx.dispatch(new UpdateOneUserPhoneFailure(error.error.errorCode)),
					),
			);
		} else {
			return of();
		}
	}

	@Action(UpdateOneUserPhoneSuccess)
	updateOneUserPhoneSuccess(ctx: StateContext<UserStateModel>) {
		ctx.dispatch(new ShowMessage({ text: 'update_one_user_phone_success', level: MessageLevel.SUCCESS }, 'phones'));
	}

	@Action(UpdateOneUserPhoneFailure)
	updateOneUserPhoneFailure(ctx: StateContext<UserStateModel>, { error }: UpdateOneUserPhoneFailure) {
		ctx.dispatch(new ShowMessage({ text: 'update_one_user_phone_failure', level: MessageLevel.ERROR }, 'phones'));
	}

	//#endregion

	//#region delete one of the phones of the active user
	@Action(DeleteOneUserPhone)
	deleteOneUserPhone(ctx: StateContext<UserStateModel>, { phoneIdx }: DeleteOneUserPhone) {
		ctx.setState(
				patch({
					userProfile: patch({
						phones: removeItem(phoneIdx),
					}),
				}),
		);
		ctx.dispatch(new ShowMessage({ text: 'delete_one_user_phone_success', level: MessageLevel.SUCCESS }, 'phones'));
	}

	//#endregion

	//#region email update process

	@Action(UpdateUserEmail)
	updateUserEmail(ctx: StateContext<UserStateModel>, { newEmail }: UpdateUserEmail) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.updateUserEmail(userSSO.id, newEmail).pipe(
					map(() => ctx.dispatch(new UpdateUserEmailSuccess())),
					catchError((error: HttpErrorResponse) =>
							ctx.dispatch(new UpdateUserEmailFailure(error.error.errorCode)),
					),
			);
		} else {
			return of();
		}
	}

	@Action(UpdateUserEmailSuccess)
	updateUserEmailSuccess(ctx: StateContext<UserStateModel>) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.username) {
			ctx.dispatch(
					new ShowMessage(
							{
								text: 'mail_update_mail_sent',
								level: MessageLevel.SUCCESS,
							},
							'profile',
							{ email: userSSO.username },
					),
			);
		}
	}

	@Action(UpdateUserEmailFailure)
	updateUserEmailFail(ctx: StateContext<UserStateModel>, { error }: UpdateUserEmailFailure) {
		ctx.dispatch(new ShowMessage({ text: 'mail_update_failure', level: MessageLevel.ERROR }, 'profile'));
	}

	@Action(ValidateUpdateUserEmail)
	validateUpdateUserEmail(ctx: StateContext<UserStateModel>, { code }: ValidateUpdateUserEmail) {
		return this.userService.validateUserEmail(code).pipe(
				map(() => ctx.dispatch(new ValidateUpdateUserEmailSuccess())),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(new ValidateUpdateUserEmailFailure(error.error.errorCode)),
				),
		);
	}

	@Action(ValidateUpdateUserEmailSuccess)
	validateUpdateUserEmailSuccess(ctx: StateContext<UserStateModel>) {
		ctx.patchState({
			emailValidationStatus: {
				isOk: true,
				error: null,
			},
		});
	}

	@Action(ValidateUpdateUserEmailFailure)
	validateUpdateUserEmailFailure(ctx: StateContext<UserStateModel>, { error }: ValidateUpdateUserEmailFailure) {
		ctx.patchState({
			emailValidationStatus: {
				isOk: false,
				error: error,
			},
		});
	}

	@Action(ConfirmNewUserEmail)
	confirmNewUserEmail(ctx: StateContext<UserStateModel>, { code }: ConfirmNewUserEmail) {
		return this.userService.confirmNewUserEmail(code).pipe(
				map(() => ctx.dispatch(new ConfirmNewUserEmailSuccess())),
				catchError((error: HttpErrorResponse) =>
						ctx.dispatch(new ConfirmNewUserEmailFailure(error.error.errorCode)),
				),
		);
	}

	@Action(ConfirmNewUserEmailSuccess)
	confirmNewUserEmailSuccess(ctx: StateContext<UserStateModel>) {
		ctx.patchState({
			confirmNewEmailStatus: {
				isOk: true,
				error: null,
			},
		});
	}

	@Action(ConfirmNewUserEmailFailure)
	confirmNewUserEmailFailure(ctx: StateContext<UserStateModel>, { error }: ConfirmNewUserEmailFailure) {
		ctx.patchState({
			confirmNewEmailStatus: {
				isOk: false,
				error: error,
			},
		});
	}

	//#endregion

	//#region profile completion
	@Action(LoadUserProfileCompletion)
	loadUserProfileCompletion(ctx: StateContext<UserStateModel>) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.getUserProfileCompletion().pipe(
					map((profileCompletion) => ctx.dispatch(new LoadUserProfileCompletionSuccess(profileCompletion))),
					catchError((error: HttpErrorResponse) =>
							ctx.dispatch(new LoadUserProfileCompletionFailure(error.error.errorCode)),
					),
			);
		} else {
			return of();
		}
	}

	@Action(LoadUserProfileCompletionSuccess)
	loadUserProfileCompletionSuccess(
			ctx: StateContext<UserStateModel>,
			{ profileCompletion }: LoadUserProfileCompletionSuccess,
	) {
		ctx.patchState({
			profileCompletion: profileCompletion,
		});
	}

	@Action(LoadUserProfileCompletionFailure)
	loadUserProfileCompletionFailure(ctx: StateContext<UserStateModel>, { error }: LoadUserProfileCompletionFailure) {
		ctx.dispatch(
				new ShowMessage({ text: 'load_user_profile_completion_failure', level: MessageLevel.ERROR }, 'profile'),
		);
	}

	//#endregion

	//region upload user avatar
	@Action(UploadAvatar)
	UploadAvatar(ctx: StateContext<UserStateModel>, { avatar }: UploadAvatar) {
		const userSSO = this.store.selectSnapshot(UserStateSelector.userSSOProfile);
		if (userSSO && userSSO.id) {
			return this.userService.uploadAvatar(userSSO.id, avatar).pipe(
					map((user: User) => ctx.dispatch(new UploadAvatarSuccess(user))),
					catchError((error: HttpErrorResponse) => ctx.dispatch(new UploadAvatarFailure(error.error.errorCode))),
			);
		} else {
			return of();
		}
	}

	@Action(UploadAvatarSuccess)
	UploadAvatarSuccess(ctx: StateContext<UserStateModel>, { user }: UploadAvatarSuccess) {
		ctx.patchState({
			userProfile: user,
		});
		ctx.dispatch(new ShowMessage({ text: 'upload_user_avatar_success', level: MessageLevel.SUCCESS }, 'profile'));
	}

	@Action(UploadAvatarFailure)
	UploadAvatarFailure(ctx: StateContext<UserStateModel>, { error }: UploadAvatarFailure) {
		ctx.dispatch(new ShowMessage({ text: 'upload_user_avatar_failure', level: MessageLevel.ERROR }, 'profile'));
	}

	//endregion

	//#region preferences
	@Action(LoadPreferences)
	loadPreferences(ctx: StateContext<UserStateModel>) {
		return this.userService.getPreferences().pipe(
				map((preferences) => ctx.dispatch(new LoadPreferencesSuccess(preferences))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadPreferencesFailure(error.error.errorCode))),
		);
	}

	@Action(LoadPreferencesSuccess)
	loadPreferencesSuccess(ctx: StateContext<UserStateModel>, { preferences }: LoadPreferencesSuccess) {
		ctx.patchState({
			preferences: preferences,
		});
	}

	@Action(LoadPreferencesFailure)
	loadPreferencesFailure(ctx: StateContext<UserStateModel>, { error }: LoadPreferencesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'load_preferences_failure', level: MessageLevel.ERROR }, 'preferences'));
	}

	@Action(UpdatePreferences)
	updatePreferences(ctx: StateContext<UserStateModel>, { preferences }: UpdatePreferences) {
		return this.userService.updatePreferences(preferences).pipe(
				map((pref) => ctx.dispatch(new UpdatePreferencesSuccess(pref, !!preferences.preferredLang))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UpdatePreferencesFailure(error.error.errorCode))),
		);
	}

	@Action(UpdatePreferencesSuccess)
	updatePreferencesSuccess(
			ctx: StateContext<UserStateModel>,
			{ preferences, languageUpdated }: UpdatePreferencesSuccess,
	) {
		if (languageUpdated) {
			const lang = new Intl.Locale(preferences.preferredLang).region?.toLowerCase() || '';
			lang && this.translocoService.setActiveLang(lang);
		}
		ctx.patchState({
			preferences: preferences,
		});
		ctx.dispatch(
				new ShowMessage({ text: 'update_preferences_success', level: MessageLevel.SUCCESS }, 'preferences'),
		);
	}

	@Action(UpdatePreferencesFailure)
	updatePreferencesFailure(ctx: StateContext<UserStateModel>, { error }: UpdatePreferencesFailure) {
		ctx.dispatch(new ShowMessage({ text: 'update_preferences_failure', level: MessageLevel.ERROR }, 'preferences'));
	}

	@Action(AddNotificationsPreferences)
	addNotificationsPreferences(ctx: StateContext<UserStateModel>, { notificationsPreferences }: AddNotificationsPreferences) {
		return this.userService.addNotificationsPreferences(notificationsPreferences).pipe(
			map(pref => ctx.dispatch(new AddNotificationsPreferencesSuccess(pref))),
			catchError((error: HttpErrorResponse) => ctx.dispatch(new AddNotificationsPreferencesFailure(error.error.errorCode))),
		);
	}

	@Action(AddNotificationsPreferencesSuccess)
	addNotificationsPreferencesSuccess(
			ctx: StateContext<UserStateModel>,
			{ preferences }: AddNotificationsPreferencesSuccess,
	) {
		ctx.patchState({
			preferences: preferences,
		});
		ctx.dispatch(new ShowMessage({ text: 'update_notifications_preferences_success', level: MessageLevel.SUCCESS }, 'preferences'));
	}

	@Action(AddNotificationsPreferencesFailure)
	addNotificationsPreferencesFailure(ctx: StateContext<UserStateModel>, { error }: UpdatePreferencesFailure) {
		// this patchState is usefull to update view after check in error
		ctx.patchState({
			preferences: ctx.getState().preferences,
		});
		ctx.dispatch(new ShowMessage({ text: 'update_notifications_preferences_failure', level: MessageLevel.ERROR }, 'preferences'));
	}

	@Action(DeleteNotificationsPreferences)
	deleteNotificationsPreferences(ctx: StateContext<UserStateModel>, { notificationsPreferences }: DeleteNotificationsPreferences) {
		return this.userService.deleteNotificationsPreferences(notificationsPreferences).pipe(
			map((pref) => ctx.dispatch(new DeleteNotificationsPreferencesSuccess(pref))),
			catchError((error: HttpErrorResponse) => ctx.dispatch(new DeleteNotificationsPreferencesFailure(error.error.errorCode))),
		);
	}

	@Action(DeleteNotificationsPreferencesSuccess)
	deleteNotificationsPreferencesSuccess(
			ctx: StateContext<UserStateModel>,
			{ preferences }: DeleteNotificationsPreferencesSuccess,
	) {
		ctx.patchState({
			preferences: preferences,
		});
		ctx.dispatch(new ShowMessage({ text: 'update_notifications_preferences_success', level: MessageLevel.SUCCESS }, 'preferences'));
	}

	@Action(DeleteNotificationsPreferencesFailure)
	deleteNotificationsPreferencesFailure(ctx: StateContext<UserStateModel>, { error }: UpdatePreferencesFailure) {
		// this patchState is usefull to update view after uncheck in error
		ctx.patchState({
			preferences: ctx.getState().preferences,
		});
		ctx.dispatch(new ShowMessage({ text: 'update_notifications_preferences_failure', level: MessageLevel.ERROR }, 'preferences'));
	}

	//#endregion
}
