import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { AddressService } from '@services/address-book/address.service';
import { ClearSearchAddressResults, OpenSelectAddressDialog, SearchAddressBook, SearchAddressBookFailure, SearchAddressBookSuccess, SearchNewAddress, SearchNewAddressFailure, SearchNewAddressSuccess, UpdateAddressLastUse } from './address.actions';
import { EMPTY, catchError, map } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CancelAction, ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { Address, OSMAddressResponse } from '@domain/shared/address';
import { ModalService } from '@services/modal.service';
import { DateTime } from 'luxon';

export class AddressStateModel {
    searchNewAddressesResult: OSMAddressResponse[];
    searchAddressBookResult: Address[];
}

export const ADDRESS_STATE_TOKEN: StateToken<AddressStateModel> = new StateToken<AddressStateModel>(
    'addressBook',
);

@State<AddressStateModel>({
    name: ADDRESS_STATE_TOKEN,
    defaults: {
        searchNewAddressesResult: [],
        searchAddressBookResult: []
    }
})
@Injectable()
export class AddressState {
    translationScope = 'address';

    constructor(private addressService: AddressService, private _modalService: ModalService) {

    }

	@Action(OpenSelectAddressDialog)
	openSelectAddressDialog(ctx: StateContext<AddressStateModel>, { callback, parentComponent }: OpenSelectAddressDialog) {
		return this._modalService.selectAddress().pipe(
			map((address) => {
                ctx.dispatch(new ClearSearchAddressResults());
				if (!address) {
					return ctx.dispatch(new CancelAction());
				} else {
                    ctx.dispatch(new UpdateAddressLastUse(address));
                }
				return callback(address, parentComponent);
			}),
			catchError(() => EMPTY),
		);
	}

    @Action(SearchNewAddress)
    searchNewAddress(ctx: StateContext<AddressStateModel>, { searchText }: SearchNewAddress) {
        return this.addressService.searchNewAddress(searchText).pipe(
            map((addresses) => {
                ctx.dispatch(new SearchNewAddressSuccess(addresses));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new SearchNewAddressFailure(error))),
        );
    }

    @Action(SearchNewAddressSuccess)
    searchNewAddressSuccess(ctx: StateContext<AddressStateModel>, { addresses }: SearchNewAddressSuccess) {
        return ctx.patchState({
            searchNewAddressesResult: addresses,
        });
    }

    @Action(SearchNewAddressFailure)
    searchNewAddressFailure(ctx: StateContext<AddressStateModel>, { error }: SearchNewAddressFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'search_new_addresses_failure',
                },
                this.translationScope,
            ),
        );
    }

    @Action(SearchAddressBook)
    searchInAddressBook(ctx: StateContext<AddressStateModel>, { clientId, searchText }: SearchAddressBook) {
        return this.addressService.searchInAddressBook(clientId, searchText).pipe(
            map((addresses) => {
                ctx.dispatch(new SearchAddressBookSuccess(addresses));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new SearchAddressBookFailure(error))),
        );
    }

    @Action(SearchAddressBookSuccess)
    searchInAddressBookSuccess(ctx: StateContext<AddressStateModel>, { addresses }: SearchAddressBookSuccess) {
        return ctx.patchState({
            searchAddressBookResult: addresses.map(address => {
                if (address.lastUse) {
                    address.lastUse = DateTime.fromISO(address.lastUse as unknown as string);
                }
                return address;
            }),
        });
    }

    @Action(SearchAddressBookFailure)
    searchInAddressBookFailure(ctx: StateContext<AddressStateModel>, { error }: SearchAddressBookFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    level: MessageLevel.ERROR,
                    text: 'search_in_client_address_book',
                },
                this.translationScope,
            ),
        );
    }

    @Action(UpdateAddressLastUse)
    updateAddressLastUse(ctx: StateContext<AddressStateModel>, { address }: UpdateAddressLastUse) {
        return this.addressService.updateAddressLastUse(address);
    }

    @Action(ClearSearchAddressResults)
    clearSearchAddressResults(ctx: StateContext<AddressStateModel>) {
        return ctx.patchState({
            searchAddressBookResult: [],
            searchNewAddressesResult: []
        });
    }
}