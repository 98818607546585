import { PrestationTask, TaskStateEnum, TaskTypeEnum } from '@domain/shipment/route/prestation-task';
import Leaflet, { LayerGroup, Marker } from 'leaflet';
import { PrePostCarriageTransportType } from '@features/private/tracking/directives/pre-post-carriage-icon.directive';
import { Incident } from '@domain/shipment/incident';
import { DateTime } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';

export type SiriusMarker = {
	markerType: 'CARRIAGE' | 'TASK' | 'CURRENT_POSITION' | 'INCIDENT'
} & Marker

export interface IncidentMarker extends SiriusMarker {
	markerType: 'INCIDENT'
	incident: Incident;
}

export interface TaskMarker extends SiriusMarker {
	markerType: 'TASK' | 'CARRIAGE';
	number?: number;
	task?: PrestationTask;
	tasks?: PrestationTask[];
	current?: boolean;
}

export interface CurrentPositionMarker extends SiriusMarker {
	markerType: 'CURRENT_POSITION'
}

export class MarkersTaskUtils {

	static readonly TRANSPORT_TASKS = [
		TaskTypeEnum.PTL_LOCAL_TRANSPORT,
		TaskTypeEnum.PTL_ROAD_TRANSPORT,
		TaskTypeEnum.PTF_BARGE_TRANSPORT,
		TaskTypeEnum.PTF_CONTAINERSHIP_TRANSPORT,
		TaskTypeEnum.PTF_TRAIN_TRANSPORT
	];

	static readonly MAP_TYPES: Map<PrePostCarriageTransportType, TaskTypeEnum[]> = new Map([
		[PrePostCarriageTransportType.TRAIN,
			[
				TaskTypeEnum.PTF_TRAIN_TRANSPORT
			]],
		[PrePostCarriageTransportType.ROAD,
			[
				TaskTypeEnum.PTL_ROAD_TRANSPORT,
				TaskTypeEnum.PTL_LOCAL_TRANSPORT,
				TaskTypeEnum.PNE_STOCK_CONTAINER_DEPOSIT,
				TaskTypeEnum.PNE_STOCK_CONTAINER_RETRIEVE,
				TaskTypeEnum.PNE_CLIENT_CONTAINER_LOAD,
				TaskTypeEnum.PNE_CLIENT_CONTAINER_UNLOAD,
				TaskTypeEnum.PNE_CLIENT_CONTAINER_DC_DEPOSIT,
				TaskTypeEnum.PNE_CLIENT_CONTAINER_DC_RETRIEVE,
				TaskTypeEnum.PNE_CLIENT_CHASSIS_DC_DEPOSIT,
				TaskTypeEnum.PNE_CLIENT_CHASSIS_DC_RETRIEVE,
				TaskTypeEnum.PNE_STATION_CONTAINER_DEPOSIT,
				TaskTypeEnum.PNE_STATION_CONTAINER_RETRIEVE,
				TaskTypeEnum.PNE_SEAPORT_CONTAINER_DEPOSIT,
				TaskTypeEnum.PNE_SEAPORT_CONTAINER_RETRIEVE,
				TaskTypeEnum.PNE_SEAPORT_CONTAINER_EMPTY_DEPOSIT,
				TaskTypeEnum.PNE_SEAPORT_CONTAINER_EMPTY_RETRIEVE,
				TaskTypeEnum.PNE_RIVERPORT_CONTAINER_DEPOSIT,
				TaskTypeEnum.PNE_RIVERPORT_CONTAINER_RETRIEVE,
			]],
		[PrePostCarriageTransportType.SEA,
			[
				TaskTypeEnum.PTF_CONTAINERSHIP_TRANSPORT
			]],
		[PrePostCarriageTransportType.BARGE,
			[
				TaskTypeEnum.PTF_BARGE_TRANSPORT
			]],
	])

	static generateMarkersIncident(incidents: Incident[]) {
		return incidents.filter(incident => {
			if (!incident?.location?.coordinates) return false;
			const [lng, lat] = incident.location.coordinates;
			return !(!lat || !lng);
		}).map((incident: Incident) => {
			const [lng, lat] = incident.location.coordinates;
			return this.incidentMarker([lat, lng], incident)
		}) || [];
	}

	static incidentMarker([lat, lng]: [number, number], incident: Incident): IncidentMarker {
		let icon = incident.incidentType?.category?.icon || 'other';
		if (icon === 'settings.svg') icon = 'more.svg';
		const marker: IncidentMarker = Leaflet.marker([lat, lng], {
			icon: Leaflet.divIcon({
				className: `sirius-marker-icon sirius-incident-marker incident`,
				html: `<img src="/assets/images/illustrations/standalone/${icon}">`,
				iconSize: [30, 30],
				iconAnchor: [15, 15],
				tooltipAnchor: [2, 15]
			}),
		}) as IncidentMarker;
		marker.incident = incident;
		marker.markerType = 'INCIDENT';
		return marker;
	}

	static currentPositionMarker([lat, lng]: [number, number], incident?: boolean): CurrentPositionMarker {
		const classes = ['sirius-position-marker sirius-current-position-marker'];
		if (incident) classes.push('incident');
		const marker: CurrentPositionMarker = Leaflet.marker([lat, lng], {
			icon: Leaflet.divIcon({
				html: '<div class="content"><img src="/assets/images/illustrations/standalone/container.svg"/></div>',
				className: classes.join(' '),
				iconSize: [35, 35],
				iconAnchor: [15, 15],
				tooltipAnchor: [2, 15]
			}),
		}) as CurrentPositionMarker;
		marker.markerType = 'CURRENT_POSITION';
		return marker;
	}

	static stepMarker([lat, lng]: [number, number], stepType: TaskStateEnum, content: string, type: 'TASK' | 'CARRIAGE', number?: number, task?: PrestationTask): TaskMarker {
		const classes = ['sirius-marker-icon'];
		if (stepType === TaskStateEnum.STARTED) classes.push('in-progress');
		if (stepType === TaskStateEnum.PLANNED) classes.push('planned');
		if (stepType === TaskStateEnum.DONE) classes.push('done');

		const icon = Leaflet.divIcon({
			className: classes.join(' '),
			html: content,
			iconSize: [30, 30],
			iconAnchor: [15, 15],
		});
		const marker: TaskMarker = Leaflet.marker([lat, lng], {icon: icon}) as TaskMarker;
		marker.markerType = type;
		marker.task = task;
		marker.number = number;
		return marker;
	}

	static iconMarker(task: PrestationTask) {
		return `/assets/images/illustrations/standalone/${this.getTransportMode(task.type!)}.svg`;
	}

	static drawRouteBetweenTasks(tasks: PrestationTask[], layer: LayerGroup) {
		let prevLong: number| undefined, prevLat: number| undefined = undefined;
		tasks.forEach((task) => {
			let color;
			switch (task.state?.state) {
				case TaskStateEnum.PLANNED:
					color = '#A2EE8B';
					break;
				case TaskStateEnum.DONE:
					color = '#67748A';
					break;
				case TaskStateEnum.STARTED:
				case TaskStateEnum.PAUSED:
					color = '#00BBD4';
					break;
				default:
					return;
			}
			if(!task.address?.position || !task.address.position.coordinates ) return;
			const [ lng , lat] = task.address?.position?.coordinates || {};
			if (!lng || !lat) return;
			if (!prevLat || !prevLong) {
				[prevLat, prevLong] = [lat, lng];
				return;
			}
			const line = MarkersTaskUtils.line([prevLat!, prevLong!], [lat, lng], color);
			line.addTo(layer);
			[prevLat, prevLong] = [lat, lng];
		});
	}

	static line(start: [number, number], end: [number, number], color?: string) {
		return Leaflet.polyline([start, end], {
			className: 'sirius-line',
			color: color || '#00bbd4',
			weight: 4,
		});
	}

	static taskTooltip(marker: TaskMarker, transloco: TranslocoService) {
		let stateDateStr: string | undefined;
		if (marker.task?.state?.state !== TaskStateEnum.PLANNED) {
			stateDateStr = marker.task?.state?.at!;
		} else {
			stateDateStr = marker?.task.at
		}
		if (stateDateStr) {
			const date = DateTime.fromISO(stateDateStr);
			const time = `${date.hour}h${(date.minute + '').padStart(2, '0')}`;
			const number = marker.number;
		
			const tooltipHtml = `<div class='task'>
			${transloco.translate('tracking.shipments.map.taskTooltip', {
				time,
				stepNumber: number
			})}
			</div>`
			return tooltipHtml;
		}
		return null;
	}

	public static addIncidentsTooltip(tooltipHtml: string, incidentsMarkers: IncidentMarker[], transloco: TranslocoService) {
		if (incidentsMarkers.length > 0) {
			incidentsMarkers.forEach(incidentMarker => {
				const incident = incidentMarker.incident;
				const time = DateTime.fromISO(incident.at).toLocaleString(DateTime.TIME_SIMPLE);
				const category = transloco.translate(`incident.categories.${incident.incidentType?.category?.name}`);
				const type = transloco.translate(`incident.types.${incident.incidentType?.category?.name}.${incident.incidentType?.name}`);
				tooltipHtml += `<div class='incident'>${transloco.translate('tracking.shipments.map.incidentTooltip', {
					time,
					category,
					type,
				})}</div>`;
			})
		}
		return tooltipHtml;
	}

	static getTransportMode(type: TaskTypeEnum) {
		const fallbackImage = 'container';
		for (let [key, value] of this.MAP_TYPES.entries()) {
			if (value.includes(type)) return key;
		}
		return fallbackImage;
	}

	static getTransportTask(tasks: PrestationTask[]): PrestationTask | undefined {
		if (tasks.length == 0) {
			return;
		}
		return tasks.find(task => MarkersTaskUtils.TRANSPORT_TASKS.some(type => type == task.type));
	}
}
