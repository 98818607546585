import { LocationActions } from "@state/locations/locations.actions.enum";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { Location, LocationFilter, LocationShort } from "@domain/locations/location";

const PREFIX = '[Location] '

//region Load Locations
export class LoadLocations {
  static readonly type = PREFIX + LocationActions.LOAD_LOCATIONS;
}

export class LoadLocationsSuccess {
  static readonly type = PREFIX + LocationActions.LOAD_LOCATIONS_SUCCESS;

  constructor(public locations: PaginatedEntity<Location>) {
  }
}

export class LoadLocationsFailure {
  static readonly type = PREFIX + LocationActions.LOAD_LOCATIONS_FAILURE;

  constructor(public error: any) {
  }
}


//endregion

//region Search Location
export class UpdateLocationFilter {
  static readonly type = PREFIX + LocationActions.UPDATE_LOCATION_FILTER

  constructor(public filter: LocationFilter) {
  }
}

//endregion

