import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationFilter, NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NotificationsService } from '@services/notifications/notifications.service';
import {
	GetUnreadNotitications,
	GetUnreadNotiticationsFailure,
	GetUnreadNotiticationsSuccess,
	LoadLatestNotifications,
	LoadLatestNotificationsFailure,
	LoadLatestNotificationsSuccess,
	LoadNotifications,
	LoadNotificationsSuccess,
	MarkAllNotificationsAsRead,
	MarkAllNotificationsAsReadFailure,
	MarkAllNotificationsAsReadSuccess,
	MarkNotificationsAsRead,
	MarkNotificationsAsReadFailure,
	MarkNotificationsAsReadSuccess,
	MarkNotificationsAsUnread,
	MarkNotificationsAsUnreadFailure,
	MarkNotificationsAsUnreadSuccess,
	UpateNotificationsPageOption,
	UpdateNotificationFilter,
} from '@state/notifications/notifications.actions';
import { catchError, map } from 'rxjs';
import { Message, MessageLevel } from '@state/global/global.state';
import { ShowMessage } from '@state/global/global.actions';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { PageOption } from "@shared/utils/query/page.options";

export interface NotificationsStateModel {
	latestNotifications?: NotificationResponse[];
	unreadNotifications?: UnreadNotifications;
	notifications?: PaginatedEntity<NotificationResponse>;
	pageOptions: PageOption,
	notificationsFilter?: NotificationFilter;
}

@State<NotificationsStateModel>({
	name: 'notifications',
	defaults: {
		pageOptions: {
			page: 0,
			size: 30
		}
	}
})
@Injectable()
export class NotificationsState {
	private translationScope = 'notifications';

	constructor(private notificationsService: NotificationsService) {
	}

	@Action(LoadNotifications)
	loadNotifications(ctx: StateContext<NotificationsStateModel>, { replace }: LoadNotifications) {
		const state = ctx.getState();
		return this.notificationsService.findAll(state.pageOptions, state.notificationsFilter).pipe(
				map((notifications) => ctx.dispatch(new LoadNotificationsSuccess(notifications, replace))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(LoadNotificationsSuccess)
	loadNotificationsSuccess(ctx: StateContext<NotificationsStateModel>, {
		notifications,
		replace
	}: LoadNotificationsSuccess) {
		ctx.setState(patch({
			notifications: patch({
				...notifications,
				content: replace ? notifications.content : append(notifications.content),
			})
		}));
	}

	@Action(LoadLatestNotificationsFailure)
	loadNotificationsFailure(ctx: StateContext<NotificationsStateModel>) {
		const message: Message = {
			text: 'load_notifications_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(UpateNotificationsPageOption)
	updateNotificationsPageOption(ctx: StateContext<NotificationsStateModel>, { pageOption }: UpateNotificationsPageOption) {
		ctx.patchState({ pageOptions: { ...ctx.getState().pageOptions, ...pageOption } });
		return ctx.dispatch(new LoadNotifications());
	}

	@Action(LoadLatestNotifications)
	loadLatestNotifications(ctx: StateContext<NotificationsStateModel>) {
		return this.notificationsService.getLatestNotifications().pipe(
				map((notifications) => ctx.dispatch(new LoadLatestNotificationsSuccess(notifications))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(LoadLatestNotificationsSuccess)
	loadLatestNotificationsSuccess(ctx: StateContext<NotificationsStateModel>, { notifications }: LoadLatestNotificationsSuccess) {
		ctx.patchState({ latestNotifications: notifications.content });
	}

	@Action(LoadLatestNotificationsFailure)
	loadLatestNotificationsFailure(ctx: StateContext<NotificationsStateModel>) {
		const message: Message = {
			text: 'load_latest_notifications_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(GetUnreadNotitications)
	getUnreadNotitications(ctx: StateContext<NotificationsStateModel>) {
		return this.notificationsService.getUnreadNotifications().pipe(
				map((unreadNotifications) => ctx.dispatch(new GetUnreadNotiticationsSuccess(unreadNotifications))),
				catchError((error) => ctx.dispatch(new GetUnreadNotiticationsFailure(error))),
		)
	}

	@Action(GetUnreadNotiticationsSuccess)
	getUnreadNotiticationsSuccess(ctx: StateContext<NotificationsStateModel>, { unreadNotifications }: GetUnreadNotiticationsSuccess) {
		ctx.patchState({ unreadNotifications })
	}

	@Action(GetUnreadNotiticationsFailure)
	getUnreadNotiticationsFailure(ctx: StateContext<NotificationsStateModel>, { error }: GetUnreadNotiticationsFailure) {
		const message: Message = {
			text: 'get_unread_notifications_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(MarkNotificationsAsRead)
	markNotificationsAsRead(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsRead) {
		return this.notificationsService.markAsRead(ids).pipe(
				map(() => ctx.dispatch(new MarkNotificationsAsReadSuccess(ids))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(MarkNotificationsAsReadSuccess)
	markNotificationsAsReadSuccess(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsReadSuccess) {
		ctx.setState(patch({
			latestNotifications: updateItem((n) => ids.includes(n.id), patch({
				read: true,
			})),
			notifications: patch({
				content: updateItem((n) => ids.includes(n.id), patch({
					read: true,
				}))
			})
		}));
	}

	@Action(MarkNotificationsAsReadFailure)
	markNotificationsAsReadFailure(ctx: StateContext<NotificationsStateModel>) {
		const message: Message = {
			text: 'mark_notifications_as_read_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(MarkAllNotificationsAsRead)
	markAllNotificationsAsRead(ctx: StateContext<NotificationsStateModel>) {
		return this.notificationsService.markAllAsRead().pipe(
				map(() => ctx.dispatch(new MarkAllNotificationsAsReadSuccess())),
				catchError((error) => ctx.dispatch(new MarkAllNotificationsAsReadFailure(error))),
		);
	}

	@Action(MarkAllNotificationsAsReadSuccess)
	markAllNotificationsAsReadSuccess(ctx: StateContext<NotificationsStateModel>) {
		ctx.setState(patch({
			latestNotifications: ctx.getState().latestNotifications?.map((n) => ({ ...n, read: true })),
			notifications: patch({
				content: ctx.getState().notifications?.content?.map((n) => ({ ...n, read: true }))
			})
		}));
	}

	@Action(MarkAllNotificationsAsReadFailure)
	markAllNotificationsAsReadFailure(ctx: StateContext<NotificationsStateModel>) {
		const message: Message = {
			text: 'mark_all_notifications_as_read_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(MarkNotificationsAsUnread)
	markNotificationsAsUnread(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsRead) {
		return this.notificationsService.markAsUnread(ids).pipe(
				map(() => ctx.dispatch(new MarkNotificationsAsUnreadSuccess(ids))),
				catchError((error) => ctx.dispatch(new LoadLatestNotificationsFailure(error))),
		);
	}

	@Action(MarkNotificationsAsUnreadSuccess)
	markNotificationsAsUnreadSuccess(ctx: StateContext<NotificationsStateModel>, { ids }: MarkNotificationsAsUnreadSuccess) {
		ctx.setState(patch({
			latestNotifications: updateItem((n) => ids.includes(n.id), patch({
				read: false,
			})),
			notifications: patch({
				content: updateItem((n) => ids.includes(n.id), patch({
					read: false,
				}))
			})
		}));
	}

	@Action(MarkNotificationsAsUnreadFailure)
	markNotificationsAsUnreadFailure(ctx: StateContext<NotificationsStateModel>) {
		const message: Message = {
			text: 'mark_notifications_as_unread_failure',
			level: MessageLevel.ERROR,
		};
		return ctx.dispatch(new ShowMessage(message, this.translationScope));
	}

	@Action(UpdateNotificationFilter)
	updateCustomerFiler(ctx: StateContext<NotificationsStateModel>, { filter }: UpdateNotificationFilter) {
		ctx.setState(
				patch({
					notificationsFilter: patch({
						...filter,
					}),
				}),
		);
		return ctx.dispatch(new LoadNotifications(true));
	}
}
