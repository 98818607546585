import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-profile-picture',
    templateUrl: './profile-picture.component.html',
    styleUrls: ['./profile-picture.component.scss'],
    imports: [NgIf],
    standalone: true,
})
export class ProfilePictureComponent {
    @Input() src: string | undefined;
    @Input() type: 'small' | 'medium' | 'big' | 'large' = 'small';
    @Input() icon: 'edit' | 'contact' = 'contact';
}
