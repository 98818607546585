/**
 * Confirm message enum
 */
export enum ModalConfirmMessageEnum {
    CONFIRM,
    CANCEL,
    WARNING,
    WARNING_MODIFICATION,
    DELETE,
    DELETE_EQUIPMENT,
    DELETE_SERVICE,
    DELETE_SHIPMENT,
    CLONE_EQUIPMENT,
    DELETE_USER,
    DELETE_MULTIPLE_USERS,
    CONFIRM_PAGE_LEAVE,
    RESEND_VERIFY_EMAIL,

    DELETE_CONTACT,
}
