import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GenericForm } from '@shared/types/generic.form';
import { Address } from '@domain/shared/address';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { CountrySelectorComponent } from '@shared/components/country-selector/country-selector.component';
import { Country } from '@domain/locations/country';

@Component({
	selector: 'app-address-form',
	templateUrl: './address-form.component.html',
	styleUrls: ['./address-form.component.scss'],
	imports: [
		ReactiveFormsModule,
		MatInputModule,
		NgIf,
		MatSelectModule,
		AsyncPipe,
		TranslocoModule,
		NgForOf,
		CountrySelectorComponent,
	],
	standalone: true
})
export class AddressFormComponent {

	@Input() form: FormGroup<GenericForm<Address | undefined>>

	@Input() displayComment: boolean = false;

	@Input() displayCompany: boolean = false;

	get countryCodeControl() {
		return this.form.get('countryCode') as FormControl<string | undefined>;
	}

	onCountryChanged(country: Country | null) {
		this.form.patchValue({
			countryCode: country?.code,
		})
	}
}
