import { Address, OSMAddressResponse } from '@domain/shared/address';
import { AddressActions } from './address.actions.enum';

const PREFIX = '[address] ';

export class OpenSelectAddressDialog {  
	static readonly type = PREFIX + 'Open Select Address Dialog';

	constructor(public callback: (address: Address, parentComponent: any) => void, public parentComponent: any) {}
}

export class SearchNewAddress {
    static readonly type = PREFIX + AddressActions.SEARCH_NEW_ADDRESS;
    constructor(public searchText: string) {}
}

export class SearchNewAddressSuccess {
    static readonly type = PREFIX + AddressActions.SEARCH_NEW_ADDRESS_SUCCESS;
    constructor(public addresses: OSMAddressResponse[]) {}
}

export class SearchNewAddressFailure {
    static readonly type = PREFIX + AddressActions.SEARCH_NEW_ADDRESS_FAILURE;
    constructor(public error: any) {}
}

export class SearchAddressBook {
    static readonly type = PREFIX + AddressActions.SEARCH_ADDRESS_BOOK;
    constructor(public clientId: string, public searchText: string) {}
}

export class SearchAddressBookSuccess {
    static readonly type = PREFIX + AddressActions.SEARCH_ADDRESS_BOOK_SUCCESS;
    constructor(public addresses: Address[]) {}
}

export class SearchAddressBookFailure {
    static readonly type = PREFIX + AddressActions.SEARCH_ADDRESS_BOOK_FAILURE;
    constructor(public error: any) {}
}

export class UpdateAddressLastUse {
    static readonly type = PREFIX + AddressActions.UPDATE_ADDRESS_LAST_USE;
    constructor(public address: Address) {}
}

export class ClearSearchAddressResults {
    static readonly type = PREFIX + AddressActions.CLEAR_SEARCH_ADDRESS_RESULTS;
}