import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { Countries } from "@domain/locations/country";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { Location, LocationFilter, LocationShort } from "@domain/locations/location";
import { PageOption } from "@shared/utils/query/page.options";
import { QueryBuilder, QueryParamType } from "@shared/utils/query/query.builder";
import { RequestParams } from "@shared/utils/query/request.params";

@Injectable({
	providedIn: 'root',
})
export class LocationsService {
	constructor(private http: HttpClient) {}

	getAllCountries(): Observable<Countries> {
		return this.http.get<Countries>(environment.api.bff + '/public/locations/countries');
	}

	searchLocations(options: PageOption, filter?: LocationFilter): Observable<PaginatedEntity<Location>> {
		const params: RequestParams = {
			page: options.page,
			size: options.size || 10,
			sort: options.sort!,
		};

		if (filter) {
			params.q = new QueryBuilder()
				.addParam({ key: 'search', type: QueryParamType.like, value: filter.search })
				.get();
		}

		if (!params.q) {
			delete params.q;
		}

		return this.http.get<PaginatedEntity<Location>>(environment.api.bff + '/locations', {
			params: {
				...params,
			},
		});
	}

	searchPublicLocations(options: PageOption, filter?: LocationFilter): Observable<PaginatedEntity<LocationShort>> {
		const params: RequestParams = {
			page: options.page,
			size: options.size || 10,
			sort: options.sort!,
		};

		if (filter) {
			params.q = new QueryBuilder()
					.addParam({ key: 'search', type: QueryParamType.like, value: filter.search })
					.get();
		}

		if (!params.q) {
			delete params.q;
		}

		return this.http.get<PaginatedEntity<LocationShort>>(environment.api.bff + '/public/locations/ports', {
			params: {
				...params,
			},
		});
	}

	getMyPort() {
		return this.http.get<Location>(environment.api.bff + '/locations/my-port');
	}
}
