@if (address) {
    <div class="address">
        <div class="address__name"> {{ address.name }}
            @if (address.type) {
                <span>( {{ address.type }} )</span>
            }
        </div>
        <div>{{ [address.address1, address.address2, address.city, address.postalCode, address.state, address.countryCode] | concatString }}</div>
    </div>
}
