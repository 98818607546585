export enum UserActions {
    LOGIN_USER = 'User Connection',
    LOGIN_USER_SUCCESS = 'User Connection Success',
    LOGIN_USER_FAILURE = 'User Connection Failed',

    LOAD_USER_SSO_PROFILE_SUCCESS = 'User SSO Profile Load Success',

    USER_LOGOUT = 'User Logout',
    USER_LOGOUT_SUCCESS = 'User Logout Success',

    LOAD_USER_PROFILE = 'Load User Profile',
    LOAD_USER_PROFILE_SUCCESS = 'User Profile Load Success',
    LOAD_USER_PROFILE_FAILURE = 'User Profile Load Failure',

    UPDATE_USER_PROFILE = 'Update User Profile',
    UPDATE_USER_PROFILE_SUCCESS = 'Update User Profile Success',
    UPDATE_USER_PROFILE_FAILURE = 'Update User Profile Failure',

    UPDATE_USER_EMAIL = 'Update User Email',
    UPDATE_USER_EMAIL_SUCCESS = 'Update User Email Success',
    UPDATE_USER_EMAIL_FAILURE = 'Update User Email Failure',

    UPDATE_ORGANIZATION = 'Update Organization',
    UPDATE_ORGANIZATION_SUCCESS = 'Update Organization Success',
    UPDATE_ORGANIZATION_FAILURE = 'Update Organization Failure',

    VALIDATE_UPDATE_USER_EMAIL = 'Validate Update User Email',
    VALIDATE_UPDATE_USER_EMAIL_SUCCESS = 'Validate Update User Email Success',
    VALIDATE_UPDATE_USER_EMAIL_FAILURE = 'Validate Update User Email Failure',

    CONFIRM_NEW_USER_EMAIL = 'Confirm New User Email',
    CONFIRM_NEW_USER_EMAIL_SUCCESS = 'Confirm New User Email Success',
    CONFIRM_NEW_USER_EMAIL_FAILURE = 'Confirm New User Email Failure',

    UPDATE_ONE_USER_PHONE = 'Update One User Phone',
    UPDATE_ONE_USER_PHONE_SUCCESS = 'Update One User Phone Success',
    UPDATE_ONE_USER_PHONE_FAILURE = 'Update One User Phone Failure',

    ADD_ONE_EMPTY_USER_PHONE = 'Add One Empty User Phone',
    ADD_ONE_USER_PHONE = 'Add One User Phone',
    ADD_ONE_USER_PHONE_SUCCESS = 'Add One User Phone Success',
    ADD_ONE_USER_PHONE_FAILURE = 'Add One User Phone Failure',

    DELETE_ONE_EMPTY_USER_PHONE = 'Delete One Empty User Phone',
    DELETE_ONE_USER_PHONE = 'Delete One User Phone',
    DELETE_ONE_USER_PHONE_SUCCESS = 'Delete One User Phone Success',
    DELETE_ONE_USER_PHONE_FAILURE = 'Delete One User Phone Failure',

    LOAD_USER_PROFILE_COMPLETION = 'Load User Profile Completion',
    LOAD_USER_PROFILE_COMPLETION_SUCCESS = 'Load User Profile Completion Success',
    LOAD_USER_PROFILE_COMPLETION_FAILURE = 'Load User Profile Completion Failure',

    OPEN_AVATAR_DIALOG = 'Open Avatar Dialog',
    CLOSE_AVATAR_DIALOG = 'Close Avatar Dialog',

    UPLOAD_AVATAR = 'Upload User Avatar',
    UPLOAD_AVATAR_SUCCESS = 'Upload User Avatar Success',
    UPLOAD_AVATAR_FAILURE = 'Upload User Avatar Failure',

    SET_TEMP_AVATAR = 'Set Temp Avatar',
}
