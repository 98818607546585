<div class="search">
	<span class="icon-research"></span>
	<input #search
		   (keypress)="keyPressed($event)"
		   [value]="value"
		   [placeholder]="(placeholder || 'filter.research') | transloco"
		   (input)="searchChanged.next(search.value)" />
	@if (value && value.length > 0 && displayClear) {
		<button class="search__clear" (click)="searchChanged.next('')"><span></span></button>
	}
</div>

