import { HttpClient } from '@angular/common/http';
import {
	provideTransloco,
	Translation,
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	translocoConfig,
	TranslocoLoader,
	TranslocoModule,
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@environment/environment';
import { customMissingHandler } from '@shared/config/missing.handler.transloco';
import { combineLatest } from 'rxjs';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	constructor(private http: HttpClient) {
	}

	getTranslation(lang: string, data?: TranslocoLoaderData) {
		return (!!data?.scope) ?
				// If a scope is provided, only load the translation file for that scope
				this.http.get<Translation>(`/assets/i18n/${lang}.json`) :
				// Else, load the common translation files
				combineLatest([
					this.http.get<Translation>(`/assets/i18n/${lang}.json`),
					this.http.get<Translation>(`/assets/i18n/countries/${lang}.json`),
					this.http.get<Translation>(`/assets/i18n/messages/${lang}.json`),
				], (app, countries, messages) => ({ ...app, countries, messages }));
	}
}

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {

				availableLangs: ['fr'],
				defaultLang: 'fr',
				fallbackLang: 'fr',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
		loader: TranslocoHttpLoader }),
		customMissingHandler,
	],
})
export class TranslocoRootModule {
}
