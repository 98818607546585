import { SHIPMENT_STATE_TOKEN, ShipmentState, ShipmentStateModel } from '@state/shipment/shipment.state';
import { createSelector, Selector } from '@ngxs/store';
import { ShipmentStatusEnum } from '@domain/shipment/shipment-status.enum';
import { Equipment, UnNumber } from '@domain/shipment/equipment';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { PrestationTask } from '@domain/shipment/route/prestation-task';
import { Contact } from "@domain/contact/contact";
import { EquipmentRef } from "@domain/shipment/equipmentRef";
import { CustomerReponse } from "@domain/client/customer";
import { Shipment } from "@domain/shipment/shipment";

export class ShipmentSelectors {
	@Selector([SHIPMENT_STATE_TOKEN])
	static shipments(state: ShipmentStateModel) {
		return state.shipments;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static pageOption(state: ShipmentStateModel) {
		return state.pageOption;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static locations(state: ShipmentStateModel) {
		return state.locations;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static loadingShipment(state: ShipmentStateModel) {
		return state.loadingShipment;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipment(state: ShipmentStateModel) {
		return state.shipment;
	}

	@Selector([ShipmentSelectors.shipment])
	static shipmentId(shipment?: Shipment) {
		return shipment?.id;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentTasks(state: ShipmentStateModel) {
		return [
			...state.shipment?.route?.preCarriages?.flatMap((c) => c.prestations)?.flatMap((p) => p?.tasks).sort((a, b) => (a?.ordering || 0) - (b?.ordering || 0)) || [],
			...state.shipment?.route?.postCarriages?.flatMap((c) => c.prestations)?.flatMap((p) => p?.tasks).sort((a, b) => (a?.ordering || 0) - (b?.ordering || 0)) || [],
		] as PrestationTask[];
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentPortOfLoading(state: ShipmentStateModel) {
		return state.shipment?.shipmentPortOfLoading;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentPortOfDischarge(state: ShipmentStateModel) {
		return state.shipment?.shipmentPortOfDischarge;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static currentEquipments(state: ShipmentStateModel) {
		return state.shipment?.equipments;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static equipmentRef(state: ShipmentStateModel) {
		return state.equipmentRefs;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static equipmentRefs(state: ShipmentStateModel) {
		return state.equipmentRefs?.content;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static routingPlans(state: ShipmentStateModel) {
		return state.routingPlans;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static equipmentRefSearch(state: ShipmentStateModel) {
		return state.refSearch;
	}

	@Selector([ShipmentSelectors.equipmentRefs, ShipmentSelectors.equipmentRefSearch])
	static filteredEquipmentRefs(refs?: EquipmentRef[] | undefined, search?: string) {
		return refs?.filter((r) => r.isoCode?.toLowerCase().includes((search || '').toLowerCase()));
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static unNumbers(state: ShipmentStateModel): PaginatedEntity<UnNumber> | undefined {
		return state.unNumbers;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static lastCurrentEquipmentIndex(state: ShipmentStateModel): number {
		return state.shipment!.equipments!.length - 1;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentCustomer(state: ShipmentStateModel) {
		return state.shipment?.client;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentLoadingType(state: ShipmentStateModel) {
		return state.shipment?.loadingType;
	}

	@Selector([ShipmentSelectors.shipmentCustomer])
	static shipmentCustomerCountryCode(customer?: CustomerReponse) {
		return `countries.code.${customer?.countryCode}`;
	}

	@Selector([ShipmentSelectors.shipmentCustomer])
	static shipmentCustomerSiteAddressCountryCode(customer?: CustomerReponse) {
		return `countries.code.${customer?.siteAddress?.countryCode}`;
	}

	@Selector([ShipmentSelectors.shipmentCustomer])
	static shipmentCustomerBillingAddressCountryCode(customer?: CustomerReponse) {
		return `countries.code.${customer?.billingAddress?.countryCode}`;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentCustomerAddressBook(state: ShipmentStateModel) {
		return state.shipment?.client?.addressBook;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentContacts(state: ShipmentStateModel) {
		return state.shipment?.contacts;
	}

	@Selector([ShipmentSelectors.shipmentContacts])
	static shipmentMainContact(contacts?: Contact[]) {
		return contacts ? contacts[0] : undefined;
	}


	@Selector([SHIPMENT_STATE_TOKEN])
	static equipment(state: ShipmentStateModel) {
		return state.equipment;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static equipments(state: ShipmentStateModel) {
		if (!state.shipment?.equipments) {
			return [];
		}
		return [...state.shipment?.equipments
				.map((equipment: Equipment) => {
					if (!equipment.cargoItems) {
						return equipment;
					}
					return {
						...equipment,
						cargoItems: [...equipment.cargoItems].sort((a, b) => {
							return (a.displayIndex == undefined || b.displayIndex == undefined) ? 0 : (a.displayIndex - b.displayIndex);
						})
					};
				})].sort((a, b) => a.displayIndex - b.displayIndex);
	}

	@Selector([ShipmentSelectors.currentEquipments])
	static groupedContainers(equipments: Equipment[] | undefined) {
		return ShipmentSelectors.groupByKey(equipments!, 'group');
	}

	@Selector([ShipmentSelectors.currentEquipments])
	static groupedMerchandiseContainers(equipments: Equipment[] | undefined) {
		return ShipmentSelectors.groupByKey(equipments!, 'merchandiseGroup');
	}

	static groupedPrePostCarriageContainers(preCarriage: boolean) {
		return createSelector([ShipmentSelectors.currentEquipments], function (equipments: Equipment[]) {
			return ShipmentSelectors.groupByKey(equipments, preCarriage ? 'preCarriageGroup' : 'postCarriageGroup');
		});
	}

	static groupByKey(equipments: Equipment[], key: keyof Pick<Equipment, 'group' | 'merchandiseGroup' | 'preCarriageGroup' | 'postCarriageGroup'>): Map<number, Equipment[]> {
		return [...equipments!]?.reduce((acc, equipment) => {
			const group: number = equipment[key] || 0;
			if (!acc.has(group)) {
				acc.set(group, []);
			}
			//Trouver l'index d'insertion en fonction de displayIndex
			// Insérer l'équipement à l'index approprié
			const insertIndex = equipment.displayIndex === group ? 0 : acc.get(group).length;
			acc.get(group).splice(insertIndex, 0, equipment);
			acc.get(group).sort((a: Equipment, b: Equipment) => a.displayIndex - b.displayIndex);
			return acc;
		}, new Map);
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static colors(state: ShipmentStateModel) {
		return state.containersColors;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static services(state: ShipmentStateModel) {
		return state.shipment?.additionalServices!;
	}

	static serviceWithId(id?: string) {
		return createSelector([SHIPMENT_STATE_TOKEN], (state: ShipmentStateModel) => {
			return id ? state.shipment?.additionalServices?.filter((s) => s.id === id)[0] : undefined;
		});
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static shipmentsSpinner(state: ShipmentStateModel) {
		return state.shipmentSpinner;
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static kanbanDraft(state: ShipmentStateModel) {
		return state.kanbans[state.kanbans.findIndex((kanban) => kanban.status === ShipmentStatusEnum.DRAFT)];
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static kanbanBooked(state: ShipmentStateModel) {
		return state.kanbans[state.kanbans.findIndex((kanban) => kanban.status === ShipmentStatusEnum.BOOKED)];
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static kanbanOtw(state: ShipmentStateModel) {
		return state.kanbans[state.kanbans.findIndex((kanban) => kanban.status === ShipmentStatusEnum.ON_THE_WAY)];
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static kanbanDelivered(state: ShipmentStateModel) {
		return state.kanbans[state.kanbans.findIndex((kanban) => kanban.status === ShipmentStatusEnum.DELIVERED)];
	}

	@Selector([SHIPMENT_STATE_TOKEN])
	static kanbans(state: ShipmentStateModel) {
		return state.kanbans;
	}

	@Selector([ShipmentState])
	static filter(state: ShipmentStateModel) {
		return state.shipmentFilter;
	}

	@Selector([ShipmentState])
	static filterData(state: ShipmentStateModel) {
		return state.shipmentFilterData;
	}
}
