export const environment = {
	production: false,
	keycloak: {
		url: 'https://id-dev.sirius.its-future.biz',
		realm: 'admcs',
		client: 'admcs-public-sirius-client',
	},
	reCaptcha: {
		siteKey: '6Le4p0wmAAAAAHuw_jaokofyTrK4bTJlzeI64NK0',
	},
	api: {
		bff: 'https://bff-dev.sirius.its-future.biz/api',
	},
	app: {
		logoutUrl: 'https://client-dev.sirius.its-future.biz/',
	},
	apiNominatim: 'https://nominatim.openstreetmap.org',
	mapTiles: 'googleWithoutPoi',
	notifications: {
	  pollUnreadSeconds: 30
	}
};
