export interface TaskDocument {
	id: string;
	name: string;
	filename: string;
	fileId: string;
	documentMime: string;
	taskDocumentCategory?: TaskDocumentCategory;
	createdByName: string;
	shipmentId?: string;
	taskId?: string;
	createdBy: string;
	createdOn: string;
}

export enum TaskDocumentCategory {
	CMR = 'CMR',
	INTERCHANGE = 'INTERCHANGE',
	PACKING_LIST = 'PACKING_LIST',
	WEIGHING_TICKET = 'WEIGHING_TICKET',
	DANGEROUS_GOODS = 'DANGEROUS_GOODS',
	MANAGED_TEMPERATURE = 'MANAGED_TEMPERATURE',
	SANITARY_CERTIFICATE = 'SANITARY_CERTIFICATE',
	PROVISIONING_CERTIFICATE = 'PROVISIONING_CERTIFICATE',
	CUSTOMS_EX = 'CUSTOMS_EX',
	CUSTOMS_T1_IMA = 'CUSTOMS_T1_IMA',
	CUSTOMS_ATR = 'CUSTOMS_ATR',
}

export interface UploadDocument {
	name: string;
	category: TaskDocumentCategory;
	taskId: string;
	file: File;
}
