import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskDocument, TaskDocumentCategory } from '@domain/document/document';
import { DocumentsService } from '@services/documents/documents.service';
import { Store } from '@ngxs/store';
import { DownloadDocument } from '@state/tracking/tracking.action';
import { PrePostCarriageWithType } from '@domain/shipment/route/pre-post-carriage';
import { TrackingSelectors } from '@state/tracking/tracking.selector';
import { PrestationTask } from '@domain/shipment/route/prestation-task';

interface Data {
	document: TaskDocument & { task: PrestationTask };
	carriage: PrePostCarriageWithType;
}

@Component({
	selector: 'app-shipment-tracking-view-document-modal',
	templateUrl: './shipment-tracking-view-document-modal.component.html',
	styleUrl: './shipment-tracking-view-document-modal.component.scss',
})
export class ShipmentTrackingViewDocumentModalComponent {
	carriages$ = this.store.select(TrackingSelectors.shipmentTrackingCarriages);

	TaskDocumentCategory = TaskDocumentCategory;

	constructor(
			@Inject(MAT_DIALOG_DATA) private data: Data,
			private ref: MatDialogRef<ShipmentTrackingViewDocumentModalComponent>,
			private store: Store,
	) {
	}

	get document() {
		return this.data.document;
	}

	get carriage() {
		return this.data.carriage;
	}

	close() {
		this.ref.close();
	}

	downloadFile() {
		this.store.dispatch(new DownloadDocument(this.document));
	}
}
