export enum AddressActions {
    SEARCH_NEW_ADDRESS = 'Search New Addresses',
    SEARCH_NEW_ADDRESS_SUCCESS = 'Search New Addresses Success',
    SEARCH_NEW_ADDRESS_FAILURE = 'Search New Addresses Failure',
    SEARCH_ADDRESS_BOOK = 'Search Address Book',
    SEARCH_ADDRESS_BOOK_SUCCESS = 'Search Address Book Succes',
    SEARCH_ADDRESS_BOOK_FAILURE = 'Search Address Book Failure',
    UPDATE_ADDRESS_LAST_USE = 'Update Adress Last Use',
    UPDATE_ADDRESS_LAST_USE_SUCCESS = 'Update Adress Last Use Success',
    UPDATE_ADDRESS_LAST_USE_FAILURE = 'Update Adress Last Use Failure',
    CLEAR_SEARCH_ADDRESS_RESULTS = 'Clear Search Address Results'
}