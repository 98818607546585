import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'app-button',
    templateUrl: './button-custom.component.html',
    styleUrls: ['./button-custom.component.scss'],
    standalone: true,
    imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule],
})
export class ButtonCustomComponent {
    @Input() type: 'default' | 'line' | 'square' | 'icon' | 'black' = 'default';
    @Input() size: 'normal' | 'small' = 'normal';
    @Input() icon: string | undefined;
    @Input() subtype: 'default' | 'alt' | 'gradient' | 'inverted' | 'red' = 'default';
    @Input() disabled = false;
	@Input() buttonClass: string = '';
	@Input() buttonStyle?: { [key: string]: any } | null;

    @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
}
