<div mat-dialog-title>
	<div class="header">
		<h1 class="mb-0">{{ 'tracking.shipments.menu.documents.add' | transloco }}</h1>
		<button (click)="close()" class="header__close">
			<span class="icon-close"></span>
		</button>
	</div>
</div>
<mat-dialog-content class="content">
	<form [formGroup]="documentForm" class="content__left">
		<div class="content__left__field">
			<span>{{ 'tracking.shipments.menu.documents.documentName' | transloco }}</span>
			<mat-form-field appearance="outline" class="no-space-under">
				<input matInput formControlName="name" />
			</mat-form-field>
		</div>
		<div class="content__left__field">
			<span>{{ 'tracking.shipments.menu.documents.documentType' | transloco }}</span>
			<mat-form-field appearance="outline" class="no-space-under">
				<mat-select *transloco="let t; read 'tracking.shipments.menu.documents.categories'" formControlName="category">
					@for (category of TaskDocumentCategory | keyValuePreserveOrder; track category) {
						<mat-option value="{{ category.key }}">{{ t(category.value) }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
		</div>
		<div class="content__left__field">
			<span>{{ 'tracking.shipments.menu.documents.carriage' | transloco }}</span>
			<mat-form-field appearance="outline" class="no-space-under">
				@if (carriages$ | async; as carriages) {
					<mat-select #carriageSelect (selectionChange)="onCarriageChange()">
						@for (carriage of carriages; track carriage.id) {
							<mat-option [value]="carriage">
								<div class="content__left__field__carriage">
									<app-svg-selector svg="truck" [size]="1.5" type="black" />
									<div class="content__left__field__carriage__label">
										<span *transloco="let t; read 'tracking.shipments.prePostCarriage.type'"
											  class="content__left__field__carriage__label__type">
											{{ t(carriage.type) }}
										</span>
										<span class="content__left__field__carriage__label__id" [title]="carriage.id">
										@if (carriage.equipmentNumbers) {
											{{ carriage.equipmentNumbers | joinArray }}
										}
									</span>
									</div>
								</div>
							</mat-option>
						}
						@if (carriageSelect.value; as carriage) {
							<mat-select-trigger>
								<div class="content__left__field__carriage">
									<app-svg-selector svg="truck" [size]="1.5" type="black" />
									<div class="content__left__field__carriage__label">
										<span *transloco="let t; read 'tracking.shipments.prePostCarriage.type'"
											  class="content__left__field__carriage__label__type">
											{{ t(carriage.type) }}
										</span>
										<span class="content__left__field__carriage__label__id">
										@if (carriage.equipmentNumbers) {
											{{ carriage.equipmentNumbers | joinArray }}
										}
									</span>
									</div>
								</div>
							</mat-select-trigger>
						}
					</mat-select>
				}
			</mat-form-field>
		</div>
		@if (selectedCarriage; as carriage) {
			<div class="content__left__field">
				<span>{{ 'tracking.shipments.menu.documents.task' | transloco }}</span>
				<mat-form-field appearance="outline" class="no-space-under">
					<mat-select formControlName="task" #taskSelect>
						@for (task of carriage | prePostCarriageTasks; track task.id) {
							<mat-option [value]="task">
								<div class="content__left__field__task">
									<app-svg-selector svg="truck" size="1.5" type="black" />
									<div class="content__left__field__task__label">
										@if (task.type) {
											<span *transloco="let t; read 'shipment.steps.types'"
												  class="content__left__field__task__label__type">
												{{ t(task.type) }}
											</span>
										}
										<span class="content__left__field__task__label__id">
											{{ task.name }}
										</span>
									</div>
								</div>
							</mat-option>
						}
						@if (taskSelect.value) {
							<mat-select-trigger>
								<div class="content__left__field__task">
									<app-svg-selector svg="truck" size="1.5" type="black" />
									<div class="content__left__field__task__label">
										@if (taskSelect.value.type) {
											<span *transloco="let t; read 'shipment.steps.types'"
												  class="content__left__field__task__label__type">
											{{ t(taskSelect.value.type) }}
										</span>
										}
										<span class="content__left__field__task__label__id">
											{{ taskSelect.value.name }}
										</span>
									</div>
								</div>
							</mat-select-trigger>
						}
					</mat-select>
				</mat-form-field>
			</div>
		}
	</form>
	<div class="content__right">
		<div class="content__right__file" dragDropFile (fileDrop)="onFileDrop($event)">
			@if (file.value; as file) {
				<span>{{ file.name }}</span>
				<app-button (clicked)="uploadFile()" icon="upload">{{ 'app.upload' | transloco }}</app-button>
				<app-button (clicked)="removeFile()" subtype="alt" icon="delete">{{ 'app.cancel' | transloco }}</app-button>
			} @else {
				<app-svg-selector svg="upload" type="monochrome" size="6" />
				<input #fileInput
					   type="file"
					   accept="application/pdf, image/png, image/jpeg, image/gif, image/webp, image/tiff, video/mp4, video/quicktime, video/mpeg, video/x-ms-wmv, video/webm, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
					   (input)="onFileInput($event)" />
				<span class="content__right__file__text"
					  [innerHTML]="'tracking.shipments.menu.documents.dragAndDrop' | transloco">
				</span>
				<app-button (clicked)="fileInput.click()">{{ 'tracking.shipments.menu.documents.import' | transloco }}</app-button>
			}
		</div>
		@if (!file.value) {
			<div class="content__right__info">
				<app-svg-selector svg="shipper-hint" category="specific" type="shipper" size="1.5" />
				<span>{{ 'tracking.shipments.menu.documents.information' | transloco }}</span>
			</div>
		}
	</div>
</mat-dialog-content>
