import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AddressBook, Customer, CustomerFilter, CustomerReponse } from "@domain/client/customer";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { PageOption } from "@shared/utils/query/page.options";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { QueryBuilder, QueryParamType } from "@shared/utils/query/query.builder";
import { Contact } from "@domain/contact/contact";
import { Address } from "@domain/shared/address";
import { RequestParams } from "@shared/utils/query/request.params";

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {
  }

  create(toCreate: Customer): Observable<Customer> {
    return this.http.post(environment.api.bff + '/clients', toCreate)
  }


  createContact(clientId: string, toCreate: Contact): Observable<Contact> {
    return this.http.post(environment.api.bff + `/clients/${clientId}/contacts`, toCreate)
  }

  findAll(options: PageOption, customerFilter: CustomerFilter): Observable<PaginatedEntity<CustomerReponse>> {
    const parametres: RequestParams = {
      page: options.page || 0,
      size: options.size || 10,
    }

    if (customerFilter) {
      parametres.q = new QueryBuilder()
        .addParam({ key: 'search', type: QueryParamType.like, value: customerFilter.search })
        .addParam({ key: 'searchName', type: QueryParamType.like, value: customerFilter.searchName })
        .addParam({ key: 'countryCode', type: QueryParamType.equal, value: customerFilter.countryFilter })
        .addParam({ key: 'personType', type: QueryParamType.equal, value: customerFilter.personType })
        .get()
    }

    if(!parametres.q){
      delete parametres.q
    }

    if (options.sort) {
      parametres.sort = options.sort;
    }

    if (options.format) {
      parametres.format = options.format;
    }

    return this.http.get<PaginatedEntity<CustomerReponse>>(environment.api.bff + '/clients', {
      params: {
        ...parametres
      }
    });
  }

  get (id: string): Observable<CustomerReponse> {
    return this.http.get<CustomerReponse>(environment.api.bff + `/clients/${id}`);
  }

  update(id: string, customer: Partial<Customer>): Observable<CustomerReponse> {
	  return this.http.patch<CustomerReponse>(environment.api.bff + `/clients/${id}`, customer);
  }

  addAddressToBook(address: Address, customerId: string): Observable<AddressBook> {
    return this.http.post<AddressBook>(environment.api.bff + `/clients/${customerId}/book`, address);
  }

  delete(id: string) {
	  	return this.http.delete(environment.api.bff + `/clients/${id}`);
  }
}
