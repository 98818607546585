import { Component, Input } from '@angular/core';
import { Address } from '@domain/shared/address';
import { ConcatStringPipe } from '../../../pipes/concat-string.pipe';

@Component({
    selector: 'app-address-item',
    standalone: true,
    templateUrl: './address-item.component.html',
    styleUrl: './address-item.component.scss',
    imports: [ConcatStringPipe]
})
export class AddressItemComponent {
    
    @Input() public address: Address | undefined;

}
