<ng-container class="upload-avatar-modal" *transloco="let t; read: 'account.profile.modal'">
    <div class="upload-avatar-modal__header">
        <h2 mat-dialog-title>{{ t('title') }}</h2>
        <button aria-label="close modal" (click)="closeModal()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="upload-avatar-modal__body">
        <div
            class="upload-avatar-modal__body__content"
            id="drop_zone"
            (dragover)="dragOverHandler($event)"
            (drop)="dropHandler($event)"
            *ngIf="!base64Image; else imagePreview"
        >
            <div class="upload-avatar-modal__body__content__import">
                <img src="assets/images/illustrations/black-colored/upload.svg" alt="upload" />
                <p class="body-1">{{ t('content') }}</p>
                <label for="upload-avatar-input" class="upload-avatar-modal__body__content__import__label">
                    <span>{{ t('button') }}</span>
                    <input
                        id="upload-avatar-input"
                        type="file"
                        accept="image/png, image/jpeg"
                        (change)="inputHandler($event)"
                    />
                </label>
            </div>
            <div class="upload-avatar-modal__body__content__information" [class.shake]="shake">
                <img src="assets/images/specific/shipper/shipper-hint.svg" alt="shipper-helper" />
                <p class="caption">{{ t('information') }}</p>
            </div>
        </div>
        <ng-template #imagePreview>
            <div class="d-flex flex-column align-items-center gap-3">
                <app-profile-picture
                    type="large"
                    [src]="base64Image"
                    *ngIf="data.imageForm === 'circle'; else rect"
                ></app-profile-picture>
                <ng-template #rect>
                    <div class="upload-avatar-modal__body__preview">
                        <div>
                            <img [src]="base64Image" alt="preview" />
                        </div>
                    </div>
                </ng-template>
                <div class="upload-avatar-modal__body__buttons">
                    <app-button (click)="uploadAvatar()">{{ 'app.save' | transloco }}</app-button>
                    <app-button subtype="alt" (click)="cancelUpload()">{{ 'app.cancel' | transloco }}</app-button>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
