import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
	Equipment,
	EquipmentCreate,
	EquipmentDetailPatch,
	EquipmentPatch,
	UnNumber,
	UnNumberFilter
} from "@domain/shipment/equipment";
import { environment } from "@environment/environment";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { QueryBuilder, QueryParamType } from "@shared/utils/query/query.builder";

@Injectable({
	providedIn: 'root',
})
export class EquipmentService {
	constructor(private http: HttpClient) {
	}

	getEquipments(shipmentId: string): Observable<Equipment[]> {
		return this.http.get<Equipment[]>(environment.api.bff + "/shipments/" + shipmentId + "/equipments");
	}

	getEquipment(shipmentId: string, equipmentId: string): Observable<Equipment> {
		return this.http.get<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments/" + equipmentId);
	}

	createEquipment(equipment: EquipmentCreate, shipmentId: string): Observable<Equipment> {
		return this.http.post<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments", equipment)
	}

	deleteEquipment(equipmentId: string, shipmentId: string): Observable<any> {
		return this.http.delete(environment.api.bff + "/shipments/" + shipmentId + "/equipments/" + equipmentId)
	}

	patchEquipment(equipmentId: string, shipmentId: string, patch: EquipmentPatch) {
		return this.http.patch<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments/" + equipmentId, patch)
	}

	patchEquipmentGrouped(equipmentId: string, shipmentId: string, patch: EquipmentPatch) {
		return this.http.patch<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId, patch)
	}

	groupEquipmentMerchandise(shipmentId: string, equipmentId: string, group: number) {
		return this.http.patch<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId + "/group/" + group, {})
	}

	patchEquipmentDetail(equipmentId: string, shipmentId: string, patch: EquipmentDetailPatch, preCarriage: boolean): Observable<Equipment> {
		return this.http.patch<Equipment>(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId + '/details', patch, {
			params: {
				preCarriage: preCarriage.toString()
			}
		})
	}

	getUnNumbers(filter?: UnNumberFilter): Observable<PaginatedEntity<UnNumber>> {
		let q: string | undefined;
		if (filter) {
			q = new QueryBuilder()
					.addParam({ key: 'imoClass', type: QueryParamType.equal, value: filter.imoClass })
					.addParam({ key: 'unNumber', type: QueryParamType.like, value: filter.unNumber })
					.get()
		}

		return this.http.get<PaginatedEntity<UnNumber>>(environment.api.bff + "/shipments/unnumbers", {
			params: {
				q: q != null ? q : ''
			}
		})
	}
}
