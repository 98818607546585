import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, StateToken } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs';
import { UserContactGroup, UserContactGroupFilter } from '@domain/address-book/address-book';
import { PageOption } from '@shared/utils/query/page.options';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { GroupContactService } from '@services/address-book/group-contact.service';
import { LoadGroupContacts, LoadGroupContactsSuccess, UpdateGroupFilter } from './contact-group.actions';
import { LoadContactsFailure } from '../user-contact/user-contact.actions';

export class GroupContactStateModel {
    groups?: PaginatedEntity<UserContactGroup>;
    pageOption: PageOption;
    groupFilter: UserContactGroupFilter;
}

export const GROUP_CONTACT_STATE_TOKEN: StateToken<GroupContactStateModel> = new StateToken<GroupContactStateModel>(
    'groupContact',
);

@State<GroupContactStateModel>({
    name: GROUP_CONTACT_STATE_TOKEN,
    defaults: {
        pageOption: {
            size: 0,
            page: 0,
            format: 'FULL',
            sort: '',
        },
        groupFilter: {
            name: '',
        },
    },
})
@Injectable()
export class GroupContactState {
    translationScope = 'address-book';

    constructor(private _userContactService: GroupContactService, private store: Store) {}

    @Action(LoadGroupContacts)
    loadGroups(ctx: StateContext<GroupContactStateModel>) {
        const pageOption = ctx.getState().pageOption;
        const groupFilter = ctx.getState().groupFilter;
        return this._userContactService.findAllGroups(pageOption, groupFilter).pipe(
            map((groups) => {
                ctx.dispatch(new LoadGroupContactsSuccess(groups));
            }),
            catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadContactsFailure(error.error.errorCode))),
        );
    }

    @Action(LoadGroupContactsSuccess)
    loadGroupsSuccess(ctx: StateContext<GroupContactStateModel>, { groups }: LoadGroupContactsSuccess) {
        ctx.patchState({
            groups: groups,
        });
    }

    @Action(UpdateGroupFilter)
    updateGroupFilter(ctx: StateContext<GroupContactStateModel>, { userContactGroupFilter }: UpdateGroupFilter) {
        ctx.patchState({
            groupFilter: userContactGroupFilter,
        });
        ctx.dispatch(new LoadGroupContacts());
    }
}
