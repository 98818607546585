import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { Phone } from '@domain/user/user';
import { FormArray, FormGroup } from '@angular/forms';
import { GenericForm } from '@shared/types/generic.form';
import { Contact } from '@domain/contact/contact';
import { CustomerUtils } from '@features/private/customer/customer.utils';
import { GenderEnum } from '@domain/contact/gender.enum';
import {
	AddContactToCurrentCustomer,
	CreateContact,
	CreateContactSuccess,
} from '@state/customer/customer.actions';

export interface CustomerInfo {
	id?: string;
	countryCode?: string;
	name?: string;
}

@Component({
	selector: 'app-create-contact',
	templateUrl: './create-contact.component.html',
	styleUrls: ['./create-contact.component.scss'],
})
export class CreateContactComponent implements OnInit, OnDestroy {
	contactForm: FormGroup<GenericForm<Contact | undefined>> =
			CustomerUtils.createContactFormGroup();

	genderEnum: typeof GenderEnum = GenderEnum;

	private ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
			public dialogRef: MatDialogRef<CreateContactComponent>,
			@Inject(MAT_DIALOG_DATA) public customerData: CustomerInfo,
			private store: Store,
			private actions$: Actions,
	) {}

	ngOnInit(): void {
		this.actions$
				.pipe(
						ofActionSuccessful(CreateContactSuccess, AddContactToCurrentCustomer),
						takeUntil(this.ngUnsubscribe),
				)
				.subscribe(() => this.dialogRef.close(true));
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	addPhoneNumber() {
		this.contactPhones.push(CustomerUtils.createPhoneNumberFormGroup());
	}

	removePhoneNumber(index: number) {
		this.contactPhones.removeAt(index);
	}

	get contactPhones() {
		return this.contactForm.get('phones') as FormArray<
				FormGroup<GenericForm<Phone | undefined>>
		>;
	}

	get country() {
		return `countries.code.${this.customerData.countryCode}`;
	}

	cancelCreate(): void {
		this.dialogRef.close(false);
	}

	addContact() {
		if (this.contactForm.invalid) {
			this.contactForm.markAllAsTouched();
			return;
		}
		if (this.customerData.id) {
			this.store.dispatch(
					new CreateContact(
							this.customerData.id,
							this.contactForm.value as Contact,
					),
			);
		} else {
			this.store.dispatch(
					new AddContactToCurrentCustomer(this.contactForm.value as Contact),
			);
		}
	}
}
