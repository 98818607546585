<div mat-dialog-title>
	<span>{{ 'MODAL.ERROR.TITLE.' + data.title | transloco }}</span>
	<button class="btn-close" mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</div>
<div mat-dialog-content>
	<div [innerHTML]="'MODAL.ERROR.BODY.' + data.body | transloco"></div>
</div>
<div mat-dialog-actions class="float-right">
	<button class="aamf-btn" mat-dialog-close>{{ 'APP.OK' | transloco}}</button>
</div>
