<div mat-dialog-title>
	<span>{{ 'customer.details.delete.title' | transloco }}</span>
</div>
<div mat-dialog-content>
	<div class="d-flex flex-column align-items-center gap-1">
		<img src="assets/images/illustrations/monochrome/warning.svg" alt="warning" width="160" height="160" />
		<span>{{ 'customer.details.delete.warning' | transloco }}</span>
	</div>
</div>
<div mat-dialog-actions>
	<app-button (clicked)="closeModal(true)">{{ 'app.confirm' | transloco }}</app-button>
	<app-button subtype="alt" (clicked)="closeModal(false)">{{ 'app.cancel' | transloco }}</app-button>
</div>
