import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Equipment, LoadingTypeEnum } from "@domain/shipment/equipment";
import { DeleteEquipmentConfirm, PatchEquipment } from "@state/shipment/shipment.action";
import { Store } from "@ngxs/store";
import { TranslocoPipe } from "@ngneat/transloco";
import { Observable } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { ShipmentSelectors } from "@state/shipment/shipment.selectors";
import { AsyncPipe, SlicePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
	selector: 'app-shipment-container-chip',
	standalone: true,
	imports: [
		TranslocoPipe,
		MatTooltipModule,
		CdkOverlayOrigin,
		CdkConnectedOverlay,
		AsyncPipe,
		FormsModule,
		SlicePipe
	],
	templateUrl: './shipment-container-chip.component.html',
	styleUrl: './shipment-container-chip.component.scss'
})
export class ShipmentContainerChipComponent implements OnInit {

	@Input() container: Equipment | undefined;

	@Input() actions: boolean = true;

	@Input() otherMenusOpen: Observable<number>;

	@Output() toggleMenuEvent: EventEmitter<number> = new EventEmitter<number>();

	@Input() minified: boolean = false;

	colors$: Observable<string[]> = this.store.select(ShipmentSelectors.colors);
	loadingType$ = this.store.select(ShipmentSelectors.shipmentLoadingType);

	LoadingTypeEnum = LoadingTypeEnum;

	editMode = false;

	menuOpen = false;

	containerNumber: string = '';

	constructor(private store: Store, private destroyRef: DestroyRef) {
	}

	ngOnInit() {
		this.otherMenusOpen?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((open) => {
			if (open !== this.container?.displayIndex) {
				this.menuOpen = false;
			}
		});
		this.containerNumber = this.container?.containerNumber || '';
	}

	delete(): void {
		this.store.dispatch(new DeleteEquipmentConfirm(this.container!))
	}

	toggleMenu(): void {
		this.menuOpen = !this.menuOpen;
		this?.toggleMenuEvent.emit(this.container?.displayIndex);
	}

	saveName() {
		this.editMode = false;
		this.store.dispatch(new PatchEquipment({...this.container, containerNumber: this.containerNumber}))
	}

}
