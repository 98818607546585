import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserLoadProfile, UserLogin } from '@state/user/user.actions';
import { KeycloakService } from 'keycloak-angular';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private readonly keycloak: KeycloakService, private store: Store) {
	}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		if (!this.keycloak.isLoggedIn() || this.keycloak.isTokenExpired()) {
			this.keycloak.login();
		}
		return this.store.dispatch(new UserLogin()).pipe(
				switchMap(() => {
					return this.store.dispatch(new UserLoadProfile());
				}),
				map(() => {
					return this.keycloak.isLoggedIn();
				}),
		);
	}
}
