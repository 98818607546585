import { Selector } from '@ngxs/store';
import { GroupContactState, GroupContactStateModel } from './contact-group.state';

export class GroupContactSelector {
    @Selector([GroupContactState])
    static groups(state: GroupContactStateModel) {
        return state.groups;
    }

    @Selector([GroupContactState])
    static groupFilter(state: GroupContactStateModel) {
        return state.groupFilter;
    }
}
