import { CrudApiService } from '@services/generic/crud-api.service';
import { HttpClient } from '@angular/common/http';
import { ObjectAccessPipe } from "@shared/pipes/object-access.pipe";
import { PageOption } from "@shared/utils/query/page.options";
import { RequestParams } from "@shared/utils/query/request.params";
import { Observable } from "rxjs";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { QueryBuilder } from "@shared/utils/query/query.builder";
import { Filter } from "@shared/utils/query/filter";
import { environment } from '@environment/environment';

export abstract class SearchableCrudApiService<
    TYPE extends {id?: ID},
    ID,
    FILTER_TYPE extends Filter
> extends CrudApiService<TYPE, ID> {
    constructor(public override http: HttpClient, typeConstructor: Function, apiUrl: string, public pipeAccess: ObjectAccessPipe) {
        super(http, typeConstructor, apiUrl);
    }

    findAll(options: PageOption, filter?: FILTER_TYPE): Observable<PaginatedEntity<TYPE>> {
        const params: RequestParams = {
            page: options.page,
            size: options.size || 10,
            sort: options.sort,
			format: options.format
        };

        if (filter) {
            const query = new QueryBuilder();

            for (const value of Object.values(filter)) {
				if (value) {
					query.addParam(value);
				}
            }

            params.q = query.get();
        }

        if (!params.q) {
            delete params.q;
        }

		if(!params.format) {
			delete params.format;
		}

        return this.http.get<PaginatedEntity<TYPE>>(`${environment.api.bff}/${this.apiUrl}`, {
            params: {
                ...params
            }
        });
    }
}
