
export interface QueryParam {
    key: string;
    type: QueryParamType;
    value?: string | string[] | undefined;
}

export enum QueryParamType {
    like = '~',
    equal = ':',
    greater = '>',
    lower = '<',
}

export class QueryBuilder {
    queryArray: QueryParam[] = [];

    get(): string {
        if (this.queryArray.length <= 0) {
            return '';
        }
        const queryList: string[] = [];
        this.queryArray.forEach(it => {
			if(it.value === undefined || !it.key || !it.type) return;
            if (Array.isArray(it.value)) {
                queryList.push(it.key + it.type + '[' + it.value.join(',') + ']');
            } else {
                queryList.push(it.key + it.type + it.value);
            }
        });
        return queryList.join(',');
    }

    addParam(param: QueryParam): QueryBuilder {
        if (param?.value && param.value.length > 0) {
            this.queryArray.push(param);
        }
        return this;
    }
}
