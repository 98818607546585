import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetDeviceType } from '@state/global/global.actions';
import { Device } from '@state/global/global.state';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event.target) {
			this.checkDeviceSize(event.target.innerWidth);
		}
	}
	constructor(private store: Store) {}

	ngOnInit(): void {
		this.checkDeviceSize(window.innerWidth);
	}

	checkDeviceSize(width: number) {
		const device: Device = {
			mobile: false,
			desktopShort: false,
			desktop: false,
		};
		if (width >= 992) {
			device.desktop = true;
		} else if (width >= 768 && width < 992) {
			device.desktop = true;
			device.desktopShort = true;
		} else if (width < 768) {
			device.mobile = true;
		}
		this.store.dispatch(new SetDeviceType(device));
	}
}
