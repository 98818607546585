import { TranslocoService } from "@ngneat/transloco";
import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from "luxon";

@Pipe({
	name: 'localizedDate',
	pure: false,
	standalone: true
})
export class LocalizedDatePipe implements PipeTransform {

	constructor(
			private translocoService: TranslocoService
	) {
	}

	transform(value?: string, format?: string): any {
		if (value) {
			return DateTime.fromISO(value).setLocale(this.translocoService.getActiveLang()).toFormat(format || 'dd LLL yyyy  HH:mm');
		}
	}
}
