import { Pipe, PipeTransform } from '@angular/core';
import { PrePostCarriage } from '@domain/shipment/route/pre-post-carriage';
import { PrestationTask } from '@domain/shipment/route/prestation-task';

@Pipe({
	name: 'prePostCarriageTasks',
})
export class PrePostCarriageTasksPipe implements PipeTransform {
	transform(value: PrePostCarriage): PrestationTask[] {
		return value.prestations?.flatMap(p => p.tasks)?.filter(Boolean) as PrestationTask[] ?? [];
	}
}
