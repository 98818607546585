import { Pipe, PipeTransform } from '@angular/core';
import { KeyValuePipe } from '@angular/common';

@Pipe({
	name: 'keyValuePreserveOrder',
	standalone: true,
})
export class KeyValuePreserveOrderPipe implements PipeTransform {
	constructor(private keyValuePipe: KeyValuePipe) {
	}

	transform<K extends string | number | symbol, V>(value: Record<K, V>) {
		return this.keyValuePipe.transform(value, () => 0);
	}
}
