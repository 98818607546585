<ng-container>
	<h1 mat-dialog-title>  {{ 'address.newAddress' | transloco }}</h1>
	<div mat-dialog-content>
		<div class="d-flex justify-content-center">
			<app-address-form [form]="addressForm" [displayCompany]="true"></app-address-form>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-button (click)="cancel()"> {{ 'app.cancel' | transloco }}</button>
		<app-button (clicked)="addAddress()" [disabled]="addressForm.invalid" subtype="gradient">
			{{ 'app.create' | transloco }}
		</app-button>
	</div>
</ng-container>
