<ng-container>
	<h1 mat-dialog-title>  {{ 'shipment.errors' | transloco }}</h1>
	<div mat-dialog-content>
		<h3> {{ 'shipment.shipment_not_valid' | transloco}}</h3>
		<div>
			<ul>
				<ng-container *ngFor="let error of data.errors">
				<li>
					{{ 'modal.info.shipments.errors.' + error | transloco }}
				</li>
				</ng-container>
			</ul>
		</div>
	</div>
	<div mat-dialog-actions>
		<app-button subtype="gradient" mat-dialog-close>{{ 'app.ok' | transloco }}</app-button>
	</div>
</ng-container>
