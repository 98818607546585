import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Injectable()
export abstract class UnsubscribeOnDestroyComponent implements OnDestroy {
	private destroy$: Subject<boolean> = new Subject();

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	subscribe<T>(observable: Observable<T>, observer?: (value: T) => void): void {
		observable.pipe(takeUntil(this.destroy$)).subscribe(observer);
	}
}
