import { UserContact, UserContactFilter, UserContactGroup } from '@domain/address-book/address-book';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { UserContactActions } from './user-contact.actions.enum';

const PREFIX = '[user-contacts] ';

export class LoadContacts {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACTS;
    constructor() {}
}

export class LoadContactsSuccess {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACTS_SUCCESS;
    constructor(public contacts: PaginatedEntity<UserContact>) {}
}

export class LoadContactsFailure {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACTS_FAILURE;
    constructor(public error: any) {}
}

export class LoadContact {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACT;
    constructor(public contactId: string) {}
}

export class LoadContactSuccess {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACT_SUCCESS;
    constructor(public contact: UserContact) {}
}

export class LoadContactFailure {
    static readonly type = PREFIX + UserContactActions.LOAD_CONTACT_FAILURE;
    constructor(public error: any) {}
}

export class UpdateContactSearch {
    static readonly type = PREFIX + UserContactActions.UPDATE_CONTACT_SEARCH;
    constructor(public userContactFilter: UserContactFilter) {}
}

export class CreateContact {
    static readonly type = PREFIX + UserContactActions.CREATE_CONTACT;
    constructor(public contact: UserContact) {}
}

export class CreateContactSuccess {
    static readonly type = PREFIX + UserContactActions.CREATE_CONTACT_SUCCESS;
    constructor(public contact: UserContact) {}
}

export class CreateContactFailure {
    static readonly type = PREFIX + UserContactActions.CREATE_CONTACT_FAILURE;
    constructor(public error: any) {}
}

export class UpdateContact {
    static readonly type = PREFIX + UserContactActions.UPDATE_CONTACT;
    constructor(public contactId: string, public contact: UserContact) {}
}

export class UpdateContactSuccess {
    static readonly type = PREFIX + UserContactActions.UPDATE_CONTACT_SUCCESS;
    constructor(public contact: UserContact) {}
}

export class UpdateContactFailure {
    static readonly type = PREFIX + UserContactActions.UPDATE_CONTACT_FAILURE;
    constructor(public error: any) {}
}

export class DeleteContactConfirm {
    static readonly type = PREFIX + UserContactActions.DELETE_CONTACT_CONFIRM;
    constructor(public contact: UserContact) {}
}

export class DeleteContact {
    static readonly type = PREFIX + UserContactActions.DELETE_CONTACT;
    constructor(public contactId: string) {}
}

export class DeleteContactSuccess {
    static readonly type = PREFIX + UserContactActions.DELETE_CONTACT_SUCCESS;
    constructor() {}
}

export class DeleteContactFailure {
    static readonly type = PREFIX + UserContactActions.DELETE_CONTACT_FAILURE;
    constructor(public error: any) {}
}

export class SwitchViewMode {
    static readonly type = PREFIX + UserContactActions.SWITCH_VIEW_MODE;
    constructor(public viewMode: 'card' | 'list') {}
}

export class UpdateCurrentContact {
    static readonly type = PREFIX + UserContactActions.UPDATE_CURRENT_CONTACT;
    constructor(public contact: UserContact) {}
}

export class OpenAssignGroupModal {
    static readonly type = PREFIX + UserContactActions.OPEN_ASSIGN_GROUP_MODAL;
    constructor() {}
}

export class AssignGroupToContact {
    static readonly type = PREFIX + UserContactActions.ASSIGN_GROUP_TO_CONTACT;
    constructor(public groups: UserContactGroup[]) {}
}

export class AssignGroupToContactSuccess {
    static readonly type = PREFIX + UserContactActions.ASSIGN_GROUP_TO_CONTACT_SUCCESS;
    constructor(public groups: UserContactGroup[]) {}
}
