export enum CustomerActionsEnum {

  LOAD_CUSTOMERS = 'Load Customers',
  LOAD_CUSTOMERS_SUCCESS = 'Load Customers Success',
  LOAD_CUSTOMERS_FAILURE = 'Load Customers Failure',

  CREATE_CUSTOMER = 'Create Customer',
  CREATE_CUSTOMER_SUCCESS = 'Create Customer Success',
  CREATE_CUSTOMER_FAILURE = 'Create Customer Failure',

  CREATE_CONTACT = 'Create Contact',
  CREATE_CONTACT_SUCCESS = 'Create Contact Success',
  CREATE_CONTACT_FAILURE = 'Create Contact Failure',

  OPEN_ADDRESS_DIALOG = 'Open Address Dialog',

  ADD_ADDRESS_TO_BOOK = 'Add Addres to Book',
  ADD_ADDRESS_TO_BOOK_SUCCESS = 'Add Addres to Book Success',
  ADD_ADDRESS_TO_BOOK_FAILURE = 'Add Addres to Book Failure',

  OPEN_ADD_CONTACT_DIALOG = 'Open Add Contact Dialog',
  ADD_CONTACT_TO_CURRENT_CUSTOMER = 'Add Contact To Current Customer',
  REMOVE_CONTACT_FROM_CURRENT_CUSTOMER = 'Remove Contact From Current Customer',
  UPDATE_CUSTOMER_SEARCH = 'Update Customer Search',
  UPDATE_CUSTOMER_FILTER = 'Update Customer Filter',
}
