import { Pipe } from '@angular/core';

@Pipe({
	name: 'joinArray',
	standalone: true,
})
export class JoinArrayPipe {
	transform(value: any[], separator: string = ', '): string {
		return value.join(separator);
	}
}
