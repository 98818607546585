import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { ReferentialsStateSelector } from '@state/referentials/referentials.selectors';
import { Country } from '@domain/locations/country';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-country-selector',
	standalone: true,
	imports: [CommonModule, MatInputModule, MatSelectModule, TranslocoModule, ReactiveFormsModule],
	templateUrl: './country-selector.component.html',
	styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent {
	@Input() value?: Country;
	@Input() hideLabel?: boolean;
	@Input('no-space-under') noSpaceUnder?: boolean;
	@Input() required?: boolean = false;
	@Input() errors?: ValidationErrors | null;
	@Input() control?: FormControl<string | undefined>;

	@Output() countryChanged = new EventEmitter<Country | null>();

	@ViewChild('select') select: MatSelect;

	countries$ = this.store.select(ReferentialsStateSelector.countries);

	constructor(private store: Store) {}

	onSelectionChange(country: Country) {
		if (!country) this.reset();
		this.countryChanged.emit(country);
	}

	reset() {
		this.select.value = null;
	}
}
