import {Prestation} from '@domain/shipment/route/prestation';
import { Eta } from '@domain/shipment/route/eta';
import { DateTime } from 'luxon';
import { Shipment } from '@domain/shipment/shipment';

export interface PrePostCarriage {
	id?: string;
	equipmentIds?: string[];
	equipmentNumbers?: string[];
	prestations?: Prestation[];
	eta?: Eta;
	etaHistory?: Eta[];
}

export interface PrePostCarriageWithType extends PrePostCarriage {
	type: PrePostCarriageType;
}

export interface PrePostCarriageToPort extends PrePostCarriageWithType {
	shipment: Shipment;
}

export interface PrePostCarriagesToPort {
	date: string,
	carriages: PrePostCarriageToPort[]
}

export enum PrePostCarriageType {
	PRE_CARRIAGE = 'PRE_CARRIAGE',
	MARITIM = 'MARITIM',
	POST_CARRIAGE = 'POST_CARRIAGE'
}
