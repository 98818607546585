import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@environment/environment";
import { CargoItem } from "@domain/shipment/cargoItem";

@Injectable({
  providedIn: 'root',
})
export class CargoItemsService {
  constructor(private http: HttpClient) {
  }

  createCargoItems(ci: CargoItem, shipmentId: string, equipmentId: string): Observable<CargoItem> {
    return this.http.post<CargoItem>(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId + "/cargo", ci)
  }
  deleteCargoItems(shipmentId: string, equipmentId: string, cargoItemsId: string): Observable<any> {
    return this.http.delete<CargoItem>(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId + "/cargo/" + cargoItemsId)
  }
  patchCargoItems(shipmentId: string, equipmentId: string, cargoItemsId: string, patch: CargoItem) {
    return this.http.patch(environment.api.bff + "/shipments/" + shipmentId + "/equipments-grouped/" + equipmentId + "/cargo/" + cargoItemsId, patch)
  }
}
