<ng-container>
	<h1 mat-dialog-title> {{ 'customer.newContact' | transloco }} </h1>
	<div mat-dialog-content *ngIf="shipment$ | async as shipment" class="overflow-hidden">
		<div class="owner d-flex align-items-center">
			<span> <span class="fw-bolder"> {{ shipment?.client?.name }} </span> {{ shipment?.client?.countryCode
				}}</span>
		</div>

		<form [formGroup]="contactForm">
			<div class="d-flex justify-content-between align-items-baseline">
				<span class="icon-add-contact d-block text-black fs-5 fw-bold mt-lg-1 me-lg-2"></span>
				<mat-form-field appearance="outline">
					<input formControlName="firstname" [placeholder]="'customer.firstname' | transloco" matInput>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<input formControlName="lastname" [placeholder]="'customer.lastname' | transloco" matInput>
				</mat-form-field>

				<mat-form-field appearance="outline">
					<input formControlName="mail" matInput [placeholder]="'customer.mail' | transloco">
				</mat-form-field>

				<app-phone-form [label]="false" [appearance]="'outline'" [form]="phoneFormGroup"
								[chooseType]="false"></app-phone-form>

				<app-button [disabled]="contactForm.invalid" type="line" (clicked)="addContact()"><span
						  class="icon-add"></span></app-button>
			</div>
		</form>

		<hr>

		<span class="fw-bold"> {{ 'shipment.details.team' | transloco }} </span>
		<div *ngIf="shipment$ | async as shipment" class="contact-list">
			<div class="row" [class.selected]="selectedIds.includes(contact!.id!)"
				 *ngFor="let contact of shipment?.client?.contacts;let i=index" (click)="selectContact(contact.id!)">

				<div class="col-xl-1 col-12 text-lg-start">
					<span class="icon-contact"></span>
				</div>
				<div class="col-xl-4 col-12 text-lg-start">
					{{ contact.firstname}} {{ contact.lastname}}
				</div>
				<div class="col-xl-3 col-12 text-lg-center">
					{{ contact.mail}}
				</div>
				<div class="col-xl-3 col-12 text-lg-end">
					{{ contact.phones![0].number! }}
				</div>
				<div *ngIf="selectedIds.includes(contact!.id!)" class="col-xl-1 col-12 text-lg-end">
					<span class="icon-check"></span>
				</div>
			</div>
		</div>

	</div>
	<div mat-dialog-actions>
		<app-button (clicked)="cancel()" type="line">
			{{ 'app.cancel' | transloco }}
		</app-button>
		<app-button (clicked)="save()" [disabled]="selectedIds.length === 0" subtype="gradient">
			{{ 'app.save' | transloco }}
		</app-button>
	</div>
</ng-container>
