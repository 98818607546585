import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, finalize, forkJoin, map, Observable, of } from 'rxjs';
import { Shipment, Shipments } from '@domain/shipment/shipment';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { ShipmentService } from '@services/shipments/shipment.service';
import { PageOption } from '@shared/utils/query/page.options';
import {
	ChangeDisplayedShipments,
	CloseTrackingMenu,
	CollapseContact,
	DownloadDocument,
	ExpandContact,
	GetShipmentTracking,
	GetShipmentTrackingFailure,
	GetShipmentTrackingSuccess,
	LoadAllTrackingShipments,
	LoadCarriage,
	LoadCarriageFailure,
	LoadCarriageSuccess,
	LoadIncidentCategories,
	LoadIncidentCategoriesFailure,
	LoadIncidentCategoriesSuccess,
	LoadShipmentTrackingRelatedInfos,
	LoadShipmentTrackingRelatedInfosFailure,
	LoadShipmentTrackingRelatedInfosSuccess,
	LoadTrackingShipment,
	LoadTrackingShipmentFailure,
	LoadTrackingShipments,
	LoadTrackingShipmentsFailure,
	LoadTrackingShipmentsSuccess,
	LoadTrackingShipmentSuccess,
	OpenAddDocumentModal,
	OpenDocumentModal,
	ResetDocumentFilters,
	ResetTrackingMenu,
	RetryUploadTaskDocument,
	SearchContact,
	SetActiveTask,
	SetActiveTrackingMenu,
	SetDocumentSearch,
	SetExpandedCarriage,
	ToggleCarriage,
	ToggleDocumentSearchCarriageType,
	ToggleDocumentSearchType,
	UpdateShipmentEquipmentFilter,
	UpdateShipmentsTrackingPage,
	UpdateTrackingShipmentFilter,
	UpdateTrackingShipmentsSort,
	UploadTaskDocument,
	UploadTaskDocumentFailure,
	UploadTaskDocumentSuccess,
} from './tracking.action';
import { ShipmentFilter, ShipmentFilterUpdate } from '@domain/shipment/shipment.filter';
import { QueryParam, QueryParamType } from '@shared/utils/query/query.builder';
import { ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { append, iif, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ShipmentTracking } from '@domain/shipment/shipment-tracking';
import { PrestationTask, TaskStateEnum } from '@domain/shipment/route/prestation-task';
import { ShipmentTrackingStatusEnum } from '@domain/shipment/ShipmentTrackingStatusEnum';
import { ShipmentTrackingRelatedInfos } from '@domain/shipment/shipment.tracking.related.infos';
import { PaginatedEntity } from '@shared/types/paginated.entity';
import { ShipmentRoute } from '@domain/shipment/route/shipment-route';
import {
	TrackingMenuKey,
} from '@features/private/tracking/shipment-tracking/tracking-menu/shipment-tracking-menu.component';
import { PrePostCarriage, PrePostCarriageType } from '@domain/shipment/route/pre-post-carriage';
import { TaskDocument, TaskDocumentCategory, UploadDocument } from '@domain/document/document';
import { ModalService } from '@services/modal.service';
import { DocumentsService } from '@services/documents/documents.service';
import { IncidentCategory } from "@domain/shipment/incident";

export interface TrackingStateModel {
	[ShipmentTrackingStatusEnum.CLOSED]: {
		shipments?: Shipments,
		pageOption: PageOption,
	},
	[ShipmentTrackingStatusEnum.ONGOING]: {
		shipments?: Shipments,
		pageOption: PageOption,
	},
	[ShipmentTrackingStatusEnum.PLANNED]: {
		shipments?: Shipments,
		pageOption: PageOption,
	},
	currentDisplayedShipments: ShipmentTrackingStatusEnum;
	shipmentFilter: ShipmentFilter;
	shipmentSpinner: boolean;
	shipment?: Shipment;
	shipmentEquipmentFilter: string[];
	trackingRelatedInfos?: ShipmentTrackingRelatedInfos;
	shipmentTracking?: ShipmentTracking[];
	shipmentTrackingLoading?: boolean;
	activeTask?: PrestationTask;
	loadingShipment?: boolean;
	expandedCarriage?: string
	trackingMenuOpen?: boolean;
	activeMenuKey?: TrackingMenuKey;
	expandedContact?: string;
	contactSearch: string;
	documentFilters: {
		search: string;
		prePostCarriageType: PrePostCarriageType[];
		documentType: TaskDocumentCategory[];
	},
	taskDocumentToUpload?: UploadDocument & {
		uploading: boolean;
		error: boolean;
		success: boolean;
	};
	prePostCarriage?: PrePostCarriage;
	incidentCategories?: IncidentCategory[];
}

const initialState: TrackingStateModel = {
	ONGOING: {
		pageOption: {
			size: 10,
			page: 0,
			sort: 'name',
			format: 'FULL',
		},
	},
	CLOSED: {
		pageOption: {
			size: 10,
			page: 0,
			sort: 'name',
			format: 'FULL',
		},
	},
	PLANNED: {
		pageOption: {
			size: 10,
			page: 0,
			sort: 'name',
			format: 'FULL',
		},
	},
	shipmentSpinner: false,
	currentDisplayedShipments: ShipmentTrackingStatusEnum.ONGOING,
	shipmentEquipmentFilter: [],
	shipmentFilter: {
		search: {
			key: 'search',
			value: undefined,
			type: QueryParamType.like,
		},
		customers: {
			key: 'clientId',
			value: [],
			type: QueryParamType.equal,
		},
		state: {
			key: 'state',
			value: ShipmentTrackingStatusEnum.ONGOING,
			type: QueryParamType.equal,
		},
		destination: {
			key: 'dest',
			value: [],
			type: QueryParamType.equal,
		},
		dateAfter: {
			key: 'createdOnAfter',
			value: undefined,
			type: QueryParamType.greater,
		},
		dateBefore: {
			key: 'createdOnBefore',
			value: undefined,
			type: QueryParamType.lower,
		},
	},
	contactSearch: '',
	documentFilters: {
		search: '',
		prePostCarriageType: [],
		documentType: [],
	}
}

export const TRACKING_STATE_TOKEN: StateToken<TrackingStateModel> = new StateToken<TrackingStateModel>('tracking');

@State<TrackingStateModel>({
	name: TRACKING_STATE_TOKEN,
	defaults: initialState,
})
@Injectable()
export class TrackingState {
	translationScope: string = 'shipments';

	constructor(
			private _shipmentService: ShipmentService,
			private modalService: ModalService,
			private documentsService: DocumentsService,
	) {
	}

	@Action(LoadAllTrackingShipments)
	loadAllShipments(ctx: StateContext<TrackingStateModel>) {
		const onGoingPage = ctx.getState()[ShipmentTrackingStatusEnum.ONGOING]!.pageOption;
		const deliveredPage = ctx.getState()[ShipmentTrackingStatusEnum.CLOSED]!.pageOption;
		const preparingPage = ctx.getState()[ShipmentTrackingStatusEnum.PLANNED]!.pageOption;
		const filter = ctx.getState().shipmentFilter;


		return forkJoin<Record<number, Observable<PaginatedEntity<Shipment>>>>([
			this._shipmentService.findAllMyShipments(onGoingPage, {
				...filter,
				state: { ...filter.state, value: ShipmentTrackingStatusEnum.ONGOING } as QueryParam
			}),
			this._shipmentService.findAllMyShipments(preparingPage, {
				...filter,
				state: { ...filter.state, value: ShipmentTrackingStatusEnum.PLANNED } as QueryParam
			}),
			this._shipmentService.findAllMyShipments(deliveredPage, {
				...filter,
				state: { ...filter.state, value: ShipmentTrackingStatusEnum.CLOSED } as QueryParam
			})]).pipe(
				map((shipments) => {
					ctx.dispatch(new LoadTrackingShipmentsSuccess(shipments[0], ShipmentTrackingStatusEnum.ONGOING));
					ctx.dispatch(new LoadTrackingShipmentsSuccess(shipments[1], ShipmentTrackingStatusEnum.PLANNED));
					return ctx.dispatch(new LoadTrackingShipmentsSuccess(shipments[2], ShipmentTrackingStatusEnum.CLOSED));
				}),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadTrackingShipmentsFailure(error.error.errorCode))),
		)
	}

	@Action(LoadTrackingShipments)
	loadShipments(ctx: StateContext<TrackingStateModel>) {
		const page = ctx.getState()[ctx.getState().currentDisplayedShipments]!.pageOption;
		const filter = ctx.getState().shipmentFilter;


		return this._shipmentService.findAllMyShipments(page, {
			...filter,
			state: { ...filter.state, value: ctx.getState().currentDisplayedShipments } as QueryParam
		}).pipe(map((shipments) => ctx.dispatch(new LoadTrackingShipmentsSuccess(shipments, ctx.getState().currentDisplayedShipments))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadTrackingShipmentsFailure(error.error.errorCode))),
		)
	}

	@Action(LoadTrackingShipmentsSuccess)
	loadShipmentsSuccess(ctx: StateContext<TrackingStateModel>, {
		shipments,
		type,
		clear
	}: LoadTrackingShipmentsSuccess) {
		if (!clear) {
			return ctx.setState(
					patch({
						[type]: patch({
							shipments: patch({
								content: append(shipments.content),
								numberOfElements: ctx.getState()[type]!.shipments!.numberOfElements + shipments.numberOfElements,
								size: ctx.getState()[type]!.shipments!.size + shipments.numberOfElements,
								first: shipments.first,
								last: shipments.last,
							}),
							pageOption: patch({
								page: shipments.number,
							}),
						}),
						shipmentSpinner: false,
					}),
			);
		} else {
			return ctx.patchState({
				[type]: {
					shipments: shipments,
					pageOption: ctx.getState()[type]!.pageOption,
				},
				shipmentSpinner: false,
			});
		}
	}

	@Action(LoadTrackingShipmentsFailure)
	loadShipmentsFailure(ctx: StateContext<TrackingStateModel>, { error }: LoadTrackingShipmentsFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_shipments_failure',
							level: MessageLevel.ERROR,
						},
						'shipments',
				),
		);
	}

	@Action(LoadShipmentTrackingRelatedInfos)
	loadShipmentTrackingRelatedInfos(ctx: StateContext<TrackingStateModel>) {
		return this._shipmentService.getTrackingRelatedInfos().pipe(
				map((infos) => ctx.dispatch(new LoadShipmentTrackingRelatedInfosSuccess(infos))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadShipmentTrackingRelatedInfosFailure(error.error.errorCode))),
		);
	}

	@Action(LoadShipmentTrackingRelatedInfosSuccess)
	loadShipmentTrackingRelatedInfosSuccess(ctx: StateContext<TrackingStateModel>, { infos }: LoadShipmentTrackingRelatedInfosSuccess) {
		ctx.patchState({
			trackingRelatedInfos: infos,
		});
	}

	@Action(LoadShipmentTrackingRelatedInfosFailure)
	loadShipmentTrackingRelatedInfosFailure(ctx: StateContext<TrackingStateModel>, { error }: LoadShipmentTrackingRelatedInfosFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_shipment_tracking_related_infos_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(SetExpandedCarriage)
	setExpandedCarriage(ctx: StateContext<TrackingStateModel>, { id }: SetExpandedCarriage) {
		ctx.patchState({
			expandedCarriage: id
		});
	}

	@Action(ToggleCarriage)
	toggleCarriage(ctx: StateContext<TrackingStateModel>, { id }: ToggleCarriage) {
		const state = ctx.getState();
		if (state.expandedCarriage === id) {
			ctx.patchState({
				expandedCarriage: undefined
			})
			return;
		}
		ctx.patchState({
			expandedCarriage: id
		})
		const route: ShipmentRoute | undefined = ctx.getState().shipment!.route;
		const carriages = [...(route?.preCarriages || []), ...(route?.postCarriages || [])];
		const carriage = carriages.find(c => c.id === id);
		if (carriage) {
			const tasks = carriage.prestations?.map(p => p.tasks).flat();
			const ongoingTask = tasks?.find((task?: PrestationTask) => task?.state?.state === TaskStateEnum.STARTED || task?.state?.state === TaskStateEnum.PAUSED)
		}
		return;
	}

	@Action(ChangeDisplayedShipments)
	changeDisplayedShipments(ctx: StateContext<TrackingStateModel>, { type }: ChangeDisplayedShipments) {
		return ctx.patchState({
			currentDisplayedShipments: type,
		});
	}

	@Action(LoadTrackingShipment)
	loadShipment(ctx: StateContext<TrackingStateModel>, { shipmentId }: LoadTrackingShipment) {
		var shipment = ctx.getState().shipment;
		if (shipment?.id === shipmentId) {
			return of(shipment);
		}
		ctx.patchState({
			loadingShipment: true,
			expandedCarriage: undefined
		})
		return this._shipmentService.get(shipmentId).pipe(
				map((shipment) => ctx.dispatch(new LoadTrackingShipmentSuccess(shipment))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadTrackingShipmentFailure(error.error.errorCode))),
				finalize(() => ctx.patchState({
					loadingShipment: false,
				})),
		);
	}

	@Action(LoadTrackingShipmentSuccess)
	loadShipmentSuccess(ctx: StateContext<TrackingStateModel>, { shipment }: LoadTrackingShipmentSuccess) {
		ctx.patchState({
			shipment: shipment,
			shipmentEquipmentFilter: shipment.equipments?.map(e => e.id!) || [],
		});
	}

	@Action(LoadTrackingShipmentFailure)
	loadShipmentFailure(ctx: StateContext<TrackingStateModel>, { error }: LoadTrackingShipmentFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_shipment_failure',
							level: MessageLevel.ERROR,
						},
						'shipments',
				),
		);
	}

	@Action(UpdateShipmentEquipmentFilter)
	updateShipmentEquipmentFilter(ctx: StateContext<TrackingStateModel>, { filter }: UpdateShipmentEquipmentFilter) {
		const expandedCarriage = ctx.getState().expandedCarriage;
		if (expandedCarriage) {
			const route: ShipmentRoute | undefined = ctx.getState().shipment!.route;
			const carriages = [...(route?.preCarriages || []), ...(route?.postCarriages || [])];
			const carriage: PrePostCarriage | undefined = carriages.find(c => c.id === expandedCarriage);
			if (carriage && !carriage.equipmentIds?.some(e => filter.includes(e))) {
				ctx.patchState({
					expandedCarriage: undefined
				})
			}
		}
		ctx.patchState({
			shipmentEquipmentFilter: filter,
		});
	}

	@Action(UpdateTrackingShipmentFilter)
	updateShipmentsFilter(ctx: StateContext<TrackingStateModel>, { filter }: UpdateTrackingShipmentFilter) {
		for (const [key, value] of Object.entries(filter!)) {
			ctx.patchState({
				shipmentFilter: {
					...ctx.getState().shipmentFilter,
					[key]: {
						...ctx.getState().shipmentFilter[key]!,
						value,
					},
				},
			});
		}
		return ctx.dispatch(new LoadAllTrackingShipments());
	}

	@Action(UpdateTrackingShipmentsSort)
	updateShipmentsSort(ctx: StateContext<TrackingStateModel>, { sort, update }: UpdateTrackingShipmentsSort) {
		const state = ctx.getState();
		ctx.patchState({
			[ctx.getState().currentDisplayedShipments]: {
				shipments: ctx.getState()[ctx.getState().currentDisplayedShipments].shipments,
				pageOption: {
					...state[state.currentDisplayedShipments]?.pageOption,
					page: 0,
					sort: sort,
				},
			}
		});
		if (update) {
			return ctx.dispatch(new LoadTrackingShipments());
		}
		return;
	}

	@Action(UpdateShipmentsTrackingPage)
	updateShipmentsPage(ctx: StateContext<TrackingStateModel>, { page, size }: UpdateShipmentsTrackingPage) {
		const state = ctx.getState();
		ctx.patchState({
			[state.currentDisplayedShipments]: {
				pageOption: {
					...state[state.currentDisplayedShipments]?.pageOption,
					page: page,
					size: size,
				},
			}
		});
		return ctx.dispatch(new LoadTrackingShipments());
	}

	@Action(GetShipmentTracking)
	getShipmentTracking(ctx: StateContext<TrackingStateModel>, { shipmentId }: GetShipmentTracking) {
		ctx.patchState({
			shipmentTracking: undefined,
			shipmentTrackingLoading: true
		})
		return this._shipmentService.getShipmentTracking(shipmentId).pipe(
				map((tracking) => ctx.dispatch(new GetShipmentTrackingSuccess(tracking))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new GetShipmentTrackingFailure(error.error.errorCode))),
				finalize(() => ctx.patchState({
					shipmentTrackingLoading: false
				})),
		);
	}

	@Action(GetShipmentTrackingSuccess)
	getShipmentTrackingSuccess(ctx: StateContext<TrackingStateModel>, { tracking }: GetShipmentTrackingSuccess) {
		ctx.patchState({
			shipmentTracking: tracking,
		});
	}

	@Action(GetShipmentTrackingFailure)
	getShipmentTrackingFailure(ctx: StateContext<TrackingStateModel>, { error }: GetShipmentTrackingFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'get_tracking_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(SetActiveTask)
	setActiveTask(ctx: StateContext<TrackingStateModel>, { task }: SetActiveTask) {
		return ctx.patchState({
			activeTask: task,
		});
	}

	@Action(SetActiveTrackingMenu)
	setActiveTrackingMenu(ctx: StateContext<TrackingStateModel>, { menu }: SetActiveTrackingMenu) {
		return ctx.patchState({
			activeMenuKey: menu,
			trackingMenuOpen: true,
		});
	}

	@Action(ExpandContact)
	expandContact(ctx: StateContext<TrackingStateModel>, { contactId }: ExpandContact) {
		return ctx.patchState({
			expandedContact: contactId,
		});
	}

	@Action(CollapseContact)
	collapseContact(ctx: StateContext<TrackingStateModel>, { contactId }: CollapseContact) {
		return ctx.patchState({
			expandedContact: contactId === ctx.getState().expandedContact ? undefined : ctx.getState().expandedContact,
		});
	}

	@Action(SearchContact)
	searchContact(ctx: StateContext<TrackingStateModel>, { search }: SearchContact) {
		return ctx.patchState({
			contactSearch: search,
		});
	}

	@Action(CloseTrackingMenu)
	closeTrackingMenu(ctx: StateContext<TrackingStateModel>) {
		return ctx.patchState({
			trackingMenuOpen: false,
		});
	}

	@Action(ResetTrackingMenu)
	resetTrackingMenu(ctx: StateContext<TrackingStateModel>) {
		return ctx.patchState({
			activeMenuKey: undefined,
			trackingMenuOpen: false,
			documentFilters: {
				search: '',
				prePostCarriageType: [],
				documentType: [],
			},
		});
	}

	@Action(SetDocumentSearch)
	setDocumentSearch(ctx: StateContext<TrackingStateModel>, { search }: SetDocumentSearch) {
		return ctx.setState(patch({
			documentFilters: patch({
				search,
			}),
		}));
	}

	@Action(ToggleDocumentSearchType)
	toggleDocumentSearchType(ctx: StateContext<TrackingStateModel>, { type }: ToggleDocumentSearchType) {
		if (ctx.getState().documentFilters.documentType.includes(type)) {
			return ctx.setState(patch({
				documentFilters: patch({
					documentType: removeItem((t) => t === type),
				}),
			}));
		}
		return ctx.setState(patch({
			documentFilters: patch({
				documentType: append([type]),
			}),
		}));
	}

	@Action(ToggleDocumentSearchCarriageType)
	toggleDocumentSearchCarriageType(ctx: StateContext<TrackingStateModel>, { type }: ToggleDocumentSearchCarriageType) {
		if (ctx.getState().documentFilters.prePostCarriageType.includes(type)) {
			return ctx.setState(patch({
				documentFilters: patch({
					prePostCarriageType: removeItem((t) => t === type),
				}),
			}));
		}
		return ctx.setState(patch({
			documentFilters: patch({
				prePostCarriageType: append([type]),
			}),
		}));
	}

	@Action(OpenAddDocumentModal)
	openAddDocumentModal(_ctx: StateContext<TrackingStateModel>) {
		return this.modalService.addDocument();
	}

	@Action(ResetDocumentFilters)
	resetDocumentFilters(ctx: StateContext<TrackingStateModel>) {
		return ctx.setState(patch({
			documentFilters: patch({
				prePostCarriageType: [],
				documentType: [],
			}),
		}));
	}

	@Action(UploadTaskDocument)
	uploadTaskDocument(ctx: StateContext<TrackingStateModel>, { document }: UploadTaskDocument) {
		const shipmentId = ctx.getState().shipment?.id;
		if (!shipmentId) return;
		ctx.patchState({
			taskDocumentToUpload: {
				...document,
				uploading: true,
				error: false,
				success: false,
			},
		});
		return this.documentsService.uploadTaskDocument(shipmentId, document).pipe(
				map((doc) => ctx.dispatch(new UploadTaskDocumentSuccess(doc))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UploadTaskDocumentFailure(error))),
		);
	}

	@Action(UploadTaskDocumentSuccess)
	uploadTaskDocumentSuccess(ctx: StateContext<TrackingStateModel>, { document }: UploadTaskDocumentSuccess) {
		return ctx.setState(patch({
			shipment: patch({
				route: patch({
					preCarriages: iif(
							Boolean, // null check for ngxs
							this.addDocumentToPrePostCarriage(document),
					),
					postCarriages: iif(
							Boolean, // null check for ngxs
							this.addDocumentToPrePostCarriage(document),
					),
				}),
			}),
			taskDocumentToUpload: patch({
				uploading: false,
				error: false,
				success: true,
			}),
		}));
		/*return ctx.dispatch(new ShowMessage({
			text: 'upload_document_success',
			level: MessageLevel.SUCCESS,
		}, 'documents'));*/
	}

	@Action(RetryUploadTaskDocument)
	retryUploadTaskDocument(ctx: StateContext<TrackingStateModel>) {
		const document = ctx.getState().taskDocumentToUpload;
		const shipmentId = ctx.getState().shipment?.id;
		if (!document || !document.error || !shipmentId) return;
		ctx.setState(patch({
			taskDocumentToUpload: patch({
				uploading: true,
				error: false,
				success: false,
			})
		}))
		return this.documentsService.uploadTaskDocument(shipmentId, document).pipe(
				map((doc) => ctx.dispatch(new UploadTaskDocumentSuccess(doc))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new UploadTaskDocumentFailure(error))),
		);
	}

	@Action(UploadTaskDocumentFailure)
	uploadTaskDocumentFailure(ctx: StateContext<TrackingStateModel>) {
		ctx.setState(patch({
			taskDocumentToUpload: patch({
				uploading: false,
				error: true,
				success: false,
			}),
		}));
	}

	@Action(OpenDocumentModal)
	openDocumentModal(_ctx: StateContext<TrackingStateModel>, { document, carriage }: OpenDocumentModal) {
		return this.modalService.openDocument(document, carriage);
	}

	@Action(DownloadDocument)
	downloadDocument(_ctx: StateContext<TrackingStateModel>, { document }: DownloadDocument) {
		return this.documentsService.downloadFile(document.fileId, document.filename);
	}

	@Action(LoadCarriage)
	loadCarriage(ctx: StateContext<TrackingStateModel>, { carriageId, shipmentId }: LoadCarriage) {
		ctx.patchState({
			prePostCarriage: undefined,
		})
		return this._shipmentService.getCarriage(shipmentId || ctx.getState().shipment!.id!, carriageId).pipe(
				map((carriage) => ctx.dispatch(new LoadCarriageSuccess(carriage))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadCarriageFailure(error.error.errorCode))),
		);
	}

	@Action(LoadCarriageSuccess)
	loadCarriageSuccess(ctx: StateContext<TrackingStateModel>, { carriage }: LoadCarriageSuccess) {
		ctx.patchState({
			prePostCarriage: carriage,
		});
	}

	@Action(LoadCarriageFailure)
	loadCarriageFailure(ctx: StateContext<TrackingStateModel>, { error }: LoadCarriageFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_carriage_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	@Action(LoadIncidentCategories)
	loadIncidentCategories(ctx: StateContext<TrackingStateModel>) {
		return this._shipmentService.getIncidentCategories().pipe(
				map((categories) => ctx.dispatch(new LoadIncidentCategoriesSuccess(categories))),
				catchError((error: HttpErrorResponse) => ctx.dispatch(new LoadIncidentCategoriesFailure(error))),
		);
	}

	@Action(LoadIncidentCategoriesSuccess)
	loadIncidentCategoriesSuccess(ctx: StateContext<TrackingStateModel>, { categories }: LoadIncidentCategoriesSuccess) {
		ctx.patchState({
			incidentCategories: categories,
		});
	}

	@Action(LoadIncidentCategoriesFailure)
	loadIncidentCategoriesFailure(ctx: StateContext<TrackingStateModel>, { error }: LoadIncidentCategoriesFailure) {
		return ctx.dispatch(
				new ShowMessage(
						{
							text: 'load_incident_categories_failure',
							level: MessageLevel.ERROR,
						},
						this.translationScope,
				),
		);
	}

	private addDocumentToPrePostCarriage(document: TaskDocument) {
		return updateItem<PrePostCarriage>(
				(p) => p?.prestations?.some((pre) => pre?.tasks?.some((t) => t?.id === document.taskId)) ?? false,
				patch({
					prestations: updateItem(
							(p) => p.tasks?.some((t) => t.id === document.taskId) ?? false,
							patch({
								tasks: updateItem(
										(t) => t.id === document.taskId,
										patch({
											documents: append([document]),
										}),
								),
							}),
					)
				})
		)
	}
}
