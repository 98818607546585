import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { TranslocoPipe } from "@ngneat/transloco";
import { AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, LowerCasePipe } from "@angular/common";
import { QuoteType } from "@features/private/shipment/shipment-quotes/shipment-quotes.component";
import { SvgSelectorComponent } from "@shared/components/svg-selector/svg-selector.component";
import { RoutingPlan } from "@domain/shipment/routing.plan";
import { SumPipe } from "@shared/pipes/sum.pipe";
import { ShipmentSelectors } from "@state/shipment/shipment.selectors";
import { Actions, ofActionSuccessful, Store } from "@ngxs/store";
import { Shipment } from "@domain/shipment/shipment";
import { RoutingStepType } from "@domain/shipment/routing.step";
import { PatchShipmentStatus, SelectQuote, SelectQuoteSuccess } from "@state/shipment/shipment.action";
import { ShipmentStatusEnum } from "@domain/shipment/shipment-status.enum";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Navigate } from "@ngxs/router-plugin";

@Component({
	selector: 'app-shipment-quote',
	standalone: true,
	imports: [
		TranslocoPipe,
		CurrencyPipe,
		SvgSelectorComponent,
		SumPipe,
		JsonPipe,
		AsyncPipe,
		DatePipe,
		DecimalPipe,
		LowerCasePipe
	],
	templateUrl: './shipment-quote.component.html',
	styleUrl: './shipment-quote.component.scss'
})
export class ShipmentQuoteComponent implements OnInit {

	@Input() plan: RoutingPlan;

	shipment$ = this.store.select<Shipment | undefined>(ShipmentSelectors.shipment)
	shipmentId$ = this.store.select<string | undefined>(ShipmentSelectors.shipmentId)

	shipmentId: string | undefined;

	routingStepTypes: typeof RoutingStepType = RoutingStepType;
	quoteTypeEnum: typeof QuoteType = QuoteType;

	constructor(private store: Store, private destroyRef: DestroyRef, private actions$: Actions) {
	}

	ngOnInit(): void {
       		this.shipmentId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(id => this.shipmentId = id);
    }

	selectQuote(planId: string): void {
		this.store.dispatch(new SelectQuote(planId));
		this.actions$.pipe(ofActionSuccessful(SelectQuoteSuccess), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.store.dispatch(new Navigate(['/tracking', 'shipment', this.shipmentId]));
		});
	}
}
