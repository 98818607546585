import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ButtonCustomComponent } from '../input/button/button-custom/button-custom.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ShipmentSelectors } from '@state/shipment/shipment.selectors';
import { Observable, distinctUntilChanged } from 'rxjs';
import { AddressBook } from '@domain/client/customer';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { AsyncPipe } from '@angular/common';
import { Address } from '@domain/shared/address';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddressSelectionComponent } from './address-selection/address-selection.component';
import { AddressCreationComponent } from './address-creation/address-creation.component';
import { AddressConfirmationComponent } from './address-confirmation/address-confirmation.component';
import { SearchAddressBook, SearchNewAddress } from '@state/address-book/address/address.actions';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchInputComponent } from '../input/search-input/search-input.component';
import { AddressSelector } from '@state/address-book/address/address.selector';
import { AddAddressToBook, AddAddressToBookSuccess } from '@state/customer/customer.actions';

@Component({
    selector: 'app-address-selector',
    standalone: true,
    templateUrl: './address-selector.component.html',
    styleUrl: './address-selector.component.scss',
    imports: [
        MatIconModule,
        ButtonCustomComponent,
        TranslocoModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        AsyncPipe,
        AddressSelectionComponent,
        AddressCreationComponent,
        AddressConfirmationComponent,
        ReactiveFormsModule,
        SearchInputComponent
    ]
})
export class AddressSelectorComponent {

    Step = Step;

    addressType: string;
    newAddressName: string;
    searchInputText = '';

	addresses$: Observable<AddressBook | undefined> = this.store.select<AddressBook | undefined>(
			ShipmentSelectors.shipmentCustomerAddressBook,
	);
    addressesResult: Address[] = [];
    newAddressesResult: Address[] = [];
    selectedAddress: Address | undefined;
    
    step: Step = Step.SELECTION;    
    previousStep: Step | undefined;

	searchNewAddressResult$ = this.store.select(AddressSelector.searchNewAddresses);
	searchInAddressBookResult$ = this.store.select(AddressSelector.searchAddressBookResultSorted);
    customerId: string | undefined;

    constructor(public dialogRef: MatDialogRef<AddressSelectorComponent>,
            private store: Store,
            public transloco: TranslocoService,
            private actions$: Actions) {
        this.addresses$.pipe(takeUntilDestroyed()).subscribe(addresses => {
            this.addressesResult = [];
            if (addresses?.siteAddress) {
                this.addressesResult.push({...addresses.siteAddress, type: this.transloco.translate('address.siteAddress')});
            }
            if (addresses?.billingAddress) {
                this.addressesResult.push({...addresses.billingAddress, type: this.transloco.translate('address.billingAddress')});
            }
            if (addresses?.addressBook) {
                this.addressesResult.push(...addresses.addressBook);
            }
        });
        this.searchInAddressBookResult$.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe(addresses => {
            this.addressesResult = addresses;
            if (this.addressesResult.length > 0) {
                this.selectedAddress = this.addressesResult[0];
            }
        });
        this.searchNewAddressResult$.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe(newAddresses => {
            this.newAddressesResult = newAddresses;
            if (this.newAddressesResult.length > 0) {
                this.selectedAddress = this.newAddressesResult[0];
            }
        });
        this.actions$.pipe(
                ofActionSuccessful(AddAddressToBookSuccess),
                takeUntilDestroyed()
            ).subscribe((response) => {
                if (this.selectedAddress && !this.selectedAddress.id) {
                    // this part extract the id give to the new address from the address book response
                    // this is id will be used for the update last use address endpoint
                    const addressesEqualToMyNewAddress = response.addressBook.addressBook.filter(address => {
                        return address.name === this.selectedAddress?.name
                                && address.address1 === this.selectedAddress?.address1
                                && address.city === this.selectedAddress?.city;
                    });
                    if (addressesEqualToMyNewAddress.length > 0) {
                        this.selectedAddress.id = addressesEqualToMyNewAddress[0].id;
                    }
                }
                this.dialogRef.close(this.selectedAddress);
            });
        this.customerId = this.store.selectSnapshot(ShipmentSelectors.shipmentCustomer)?.id;
        if (this.customerId) {
            // init all address book
            this.store.dispatch(new SearchAddressBook(this.customerId, ''));
        }
    }

    performSearch(searchText: string | undefined) {
        if (searchText != this.searchInputText || this.previousStep != this.step) {
            this.searchInputText = searchText ?? '';
            this.previousStep = this.step;
            this.selectedAddress = undefined;
            switch(this.step) {
                case Step.SELECTION:
                    if (this.customerId) {
                        this.store.dispatch(new SearchAddressBook(this.customerId, this.searchInputText));
                    }
                    break;
                case Step.CREATION:
                    this.store.dispatch(new SearchNewAddress(this.searchInputText));
                    break;
            }
        }      
    }

    closeModal() {
        this.dialogRef.close();
    }

    submit() {
        switch (this.step) {
            case Step.SELECTION:
                this.dialogRef.close(this.selectedAddress); 
                break;
            case Step.CREATION:
                this.moveToStep(Step.CONFIRMATION, false);
                break;            
            case Step.CONFIRMATION:
                if (this.selectedAddress) {
                    this.selectedAddress.name = this.newAddressName;
                }
                if (this.selectedAddress && this.customerId) {
                    this.store.dispatch(new AddAddressToBook(this.selectedAddress, this.customerId));
                }
                break;
        }    
    }

    moveToStep(newStep: Step, clearSelectedAddress: boolean) {
        if (clearSelectedAddress) {
            this.selectedAddress = undefined;
        }
        switch(newStep) {
            case Step.SELECTION:
                if (this.addressesResult.length > 0) {
                    this.selectedAddress = this.addressesResult[0];
                }
                break;
            case Step.CREATION:
                if (this.newAddressesResult.length > 0) {
                    this.selectedAddress = this.newAddressesResult[0];
                }
                break;
        }
        this.previousStep = this.step;
        this.step = newStep;
        if (newStep == Step.CREATION && this.searchInputText) {
            this.performSearch(this.searchInputText);
        }
    }

    setSelectedAddress($event: Address | undefined) {
        this.selectedAddress = $event;
    }
    
    onNewAddressNameChange($event: string) {
        this.newAddressName = $event;
    }
}

export enum Step {
    SELECTION,
    CREATION,
    CONFIRMATION
}
