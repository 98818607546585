import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address, OSMAddressResponse } from '@domain/shared/address';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AddressService {

    apiUrl = 'address';
    
    constructor(public http: HttpClient) {}

    searchNewAddress(searchText: string): Observable<OSMAddressResponse[]> {
        return this.http.get(`${environment.api.bff}/${this.apiUrl}/simple-search`, {params: {searchText: searchText}}) as Observable<OSMAddressResponse[]>;
    }

    searchInAddressBook(clientId: string, searchText: string): Observable<Address[]> {
        return this.http.get(`${environment.api.bff}/clients/${clientId}/book/search`, {params: {searchText: searchText}}) as Observable<Address[]>;
    }
    
    updateAddressLastUse(address: Address) {
        return this.http.patch(`${environment.api.bff}/${this.apiUrl}/update-address-last-use/${address.id}`, {});
    }
}