<div class="quotes">
	<div class="quotes__header">
		<div class="quotes__header__headline">
			<h1 class="mb-0"> {{ shipment?.name }} <span class="quotes__header__headline__client">/ {{ shipment?.client?.name}} </span> <span class="quotes__header__headline__status">{{ 'tracking.shipments.state.SELECTING_QUOTE' | transloco  }}</span></h1>
			<app-button (clicked)="modifyDemand()" class="quotes__header__headline__button" type="black">{{ 'quote.modifyDemand' | transloco}}</app-button>
		</div>
		<div class="quotes__header__presentation">{{ 'quote.presentation' | transloco}}</div>
	</div>
	<div class="quotes__body">
		<div class="quotes__body__list flex-md-row flex-column">
			@if (plans$ | async; as plans) {
				@for (plan of plans; track plan) {
					<app-shipment-quote [plan]="plan"></app-shipment-quote>
				}
			}


			<div class="quotes__body__list__price">
				{{ 'quote.optimalPrice' | transloco}}
			</div>
		</div>

	</div>

</div>
