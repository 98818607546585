/**
 * Error message enum
 */
export enum ModalErrorMessageEnum {
	ERROR,
	BAD_CREDENTIALS,
	INVALID_TOKEN,
	NON_AUTH,
	AUTH_EXP
}
