<div class="quote">

	<div class="quote__scores">
		<div class="quote__scores__pollution">
			<span class="quote__scores__pollution__value">{{ plan.co2 | number:'2.0' }}</span>
			<span class="quote__scores__pollution__unit">kg CO2</span>
		</div>
		@if (plan.mark != undefined) {
			<div class="quote__scores__mark" [class.quote__scores__mark__green]="plan.mark > 4">
				@if (plan.mark < 3) {
					<span class="quote__scores__mark__icon__sad"></span>
				} @else if (plan.mark >= 3 && plan.mark < 4.5) {
					<span class="quote__scores__mark__icon__neutral"></span>
				} @else {
					<span class="quote__scores__mark__icon__happy"></span>
				}
				<div class="quote__scores__mark__value">
					<span>{{ plan.mark }}</span>/5
				</div>
			</div>
		}

	</div>
	<div class="quote__title">
		@switch (plan.type) {
			@case (quoteTypeEnum.ECOLOGICAL) {
				<app-svg-selector category="specific" type="icons" svg="ecological" size="3.5"></app-svg-selector>
				<span class="d-block"> {{ ('quote.types.' + plan.type) | transloco }}</span>
			}
			@case (quoteTypeEnum.ECONOMICAL) {
				<app-svg-selector category="specific" type="icons" svg="economical" size="3.5"></app-svg-selector>
				<span class="d-block"> {{ ('quote.types.' + plan.type) | transloco }}</span>
			}
			@case (quoteTypeEnum.PERSONALIZED) {
				<app-svg-selector category="specific" type="icons" svg="customize" size="3.5"></app-svg-selector>
				<span class="d-block"> {{ ('quote.types.' + plan.type) | transloco }}</span>
			}
			@default {
				<app-svg-selector category="specific" type="icons" svg="economical" size="3.5"></app-svg-selector>
				<span class="d-block"> {{ ('quote.types.' + quoteTypeEnum.ECONOMICAL) | transloco }}</span>
			}
		}
	</div>
	<div class="quote__path">
		@if (shipment$ | async; as shipment) {
			<div class="quote__path__start">
				<span class="quote__path__start__pin d-block"></span>
				<div class="quote__path__start__data">
					@if (shipment.preCarriage) {
						<span class="quote__path__start__data__title"> {{ shipment.equipments![0].equipmentPickup?.address?.city }}</span>
						<span class="quote__path__start__data__value"> {{ $any(shipment.equipments![0].equipmentPickup?.date) | date:'dd-MM-YYYY' }}</span>
					} @else {
						@if (shipment.maritimRoute) {
							<span class="quote__path__start__data__title"> {{ shipment.shipmentPortOfLoading?.location?.name }}</span>
							<span class="quote__path__start__data__value"> {{ $any(shipment.shipmentPortOfLoading?.date) | date:'dd-MM-YYYY' }}</span>
						} @else {
							<span class="quote__path__start__data__title"> {{ shipment.shipmentPortOfDischarge?.location?.name }}</span>
							<span class="quote__path__start__data__value"> {{ $any(shipment.shipmentPortOfDischarge?.date) | date:'dd-MM-YYYY' }}</span>
						}
					}
				</div>
			</div>
			<div class="quote__path__travel">
				@for (step of plan.content; track step) {
					@if (step.type === routingStepTypes.BOAT) {
						<div>
							<app-svg-selector category="illustrations" type="standalone" svg="vessel"/>
							{{ step.from.name }}
						</div>
						<div>
							<app-svg-selector category="illustrations" type="standalone" svg="vessel"/>
							{{ step.to.name }}
						</div>
					} @else {
						<div>
							<app-svg-selector category="illustrations" type="standalone" [svg]="step.type! | lowercase"/>
						</div>
					}
				}
			</div>
			<div class="quote__path__end">
				<span class="quote__path__end__pin d-block"></span>
				<div class="quote__path__end__data">
					@if (shipment.postCarriage) {
						<span class="quote__path__end__data__title"> {{ shipment.equipments![0].equipmentDelivery?.address?.city }}</span>
						<span class="quote__path__end__data__value"> {{ $any(shipment.equipments![0].equipmentDelivery?.date) | date:'dd-MM-YYYY' }}</span>
					} @else {
						@if (shipment.maritimRoute) {
							<span class="quote__path__end__data__title"> {{ shipment.shipmentPortOfDischarge?.location?.name }}</span>
							<span class="quote__path__end__data__value"> {{ $any(shipment.shipmentPortOfDischarge?.date) | date:'dd-MM-YYYY' }}</span>
						} @else {
							<span class="quote__path__end__data__title"> {{ shipment.shipmentPortOfLoading?.location?.name }}</span>
							<span class="quote__path__end__data__value"> {{ $any(shipment.shipmentPortOfLoading?.date) | date:'dd-MM-YYYY' }}</span>
						}
					}
				</div>
			</div>
		}
	</div>
	<div class="quote__price">
		<div class="quote__price__value">
			<span class="required">
					{{ plan.price | currency:'EUR':'symbol':'1.0-0' }}
			</span>
		</div>
		<div class="quote__price__taxes">{{ 'quote.taxesIncludedShort' | transloco }}</div>
	</div>
	<button class="quote__select" (click)="selectQuote(plan.id!)">
		{{ 'quote.selectQuote' | transloco }}
	</button>
</div>
