import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from "@angular/material/icon";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
	selector: 'app-modal-error',
	templateUrl: './modal-error.component.html',
	styleUrls: ['./modal-error.component.scss'],
	imports: [
		MatIconModule,
		MatDialogModule,
		TranslocoModule
	],
	standalone: true
})
export class ModalErrorComponent {

	constructor(public dialogRef: MatDialogRef<ModalErrorComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}
}
