import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Contact } from '@domain/contact/contact';
import { PhoneTypeEnum } from '@domain/contact/phone.type.enum';
import { Shipment } from '@domain/shipment/shipment';
import { Phone } from '@domain/user/user';
import { CustomerUtils } from '@features/private/customer/customer.utils';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { GenericForm } from '@shared/types/generic.form';
import { CreateContact, CreateContactSuccess } from '@state/customer/customer.actions';
import { LoadCountries } from '@state/referentials/referentials.actions';
import { LoadShipment, PatchShipment, PatchShipmentSuccess } from '@state/shipment/shipment.action';
import { ShipmentSelectors } from '@state/shipment/shipment.selectors';
import { Observable, take } from 'rxjs';
import { emailValidator } from '@shared/validators/email.validator';

@Component({
    selector: 'app-edit-shipment-contact',
    templateUrl: './edit-shipment-contact.component.html',
    styleUrls: ['./edit-shipment-contact.component.scss'],
})
export class EditShipmentContactComponent implements OnInit {
    shipment$: Observable<Shipment | undefined> = this.store.select(ShipmentSelectors.shipment);

    contactForm: FormGroup<GenericForm<Contact>> = new FormGroup<GenericForm<Contact>>({
        firstname: new FormControl<string | null>(null),
        lastname: new FormControl<string | null>(null),
        mail: new FormControl<string | null>(null, emailValidator),
        phones: new FormArray<FormGroup<GenericForm<Phone | undefined>>>(
            [CustomerUtils.createPhoneNumberFormGroup({ type: PhoneTypeEnum.WORK })],
            [Validators.minLength(1), Validators.maxLength(1)],
        ),
    });

    selectedIds: string[];

    clientId: string;

    id: string;

    constructor(
        public dialogRef: MatDialogRef<EditShipmentContactComponent>,
        private store: Store,
        private actions$: Actions,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new LoadCountries());

        this.shipment$.pipe(take(1)).subscribe((s) => {
            this.phones.at(0)?.patchValue({ countryCode: s?.client?.countryCode });
            this.selectedIds = s?.contacts!.map((c) => c.id!)!;
            this.clientId = s?.client?.id!;
            this.id = s?.id!;
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.store.dispatch(new PatchShipment({ contactIds: this.selectedIds }));
        this.actions$
            .pipe(ofActionSuccessful(PatchShipmentSuccess), take(1))
            .subscribe(() => this.dialogRef.close(true));
    }

    addContact() {
		let contact = this.contactForm.value as Contact;
        this.store.dispatch(new CreateContact(this.clientId, contact));
        this.actions$
            .pipe(ofActionSuccessful(CreateContactSuccess), take(1))
            .subscribe(() => this.store.dispatch(new LoadShipment(this.id)));
    }

    get phones() {
        return this.contactForm.get('phones') as FormArray;
    }

    get phoneFormGroup(): FormGroup {
        return this.phones.at(0) as FormGroup;
    }

    selectContact(id: string) {
        if (this.selectedIds.includes(id)) {
            this.selectedIds = this.selectedIds.filter((i) => i != id);
        } else {
            this.selectedIds.push(id);
        }
    }
}
