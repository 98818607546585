import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TaskDocument, UploadDocument } from '@domain/document/document';
import { environment } from '@environment/environment';
import { map } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root',
})
export class DocumentsService {
	constructor(private http: HttpClient) {}

	uploadTaskDocument(shipmentId: string, document: UploadDocument) {
		const { name, category, taskId, file } = document;
		const formData = new FormData();
		formData.append('file', file);
		formData.append(
				'documentDto',
				new Blob(
						[JSON.stringify({
							name,
							filename: file.name,
							taskDocumentCategory: category
						})],
						{ type: 'application/json' }
				),
		);
		formData.append('taskDocumentCategory', category);
		return this.http.post<TaskDocument>(environment.api.bff + `/shipments/${shipmentId}/tasks/${taskId}/documents`, formData);
	}

	downloadFile(fileId: string, filename: string) {
		return this.http.get(environment.api.bff + `/documents/${fileId}`, { responseType: 'blob' }).pipe(
				map(data => {
					saveAs(data, filename);
					return true;
				})
		);
	}
}
