import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Application, Organization, Phone, User } from '@domain/user/user';
import { map, Observable } from 'rxjs';
import { UpdateEmail } from '@domain/user/updateEmail';
import { SearchableCrudApiService } from "@services/generic/searchable-crud-api.service";
import { ObjectAccessPipe } from "@shared/pipes/object-access.pipe";
import { UsersFilter } from "@domain/user/users.filter";
import { RegisterRequest } from '@domain/register/register';
import { PageOption } from "@shared/utils/query/page.options";
import { PaginatedEntity } from "@shared/types/paginated.entity";
import { AvailabilityEnum } from "@domain/user/availability.enum";
import { Availability } from "@domain/user/Availability";
import { Timezone } from '@domain/user/timezone';
import { NotificationPreference, UserPreferences } from '@domain/user/user-preferences';

@Injectable({
	providedIn: 'root',
})
export class UserService extends SearchableCrudApiService<User, string, UsersFilter> {
	constructor(public override http: HttpClient, public override pipeAccess: ObjectAccessPipe) {
		super(http, User, `user`, pipeAccess);
	}

	resendVerifyEmail(userId: string): Observable<void> {
		return this.http.post<void>(`${environment.api.bff}/user/${userId}/resend-verify`, null);
	}

	register(request: RegisterRequest): Observable<boolean> {
		return this.http.post<boolean>(environment.api.bff + '/public/user/register', request);
	}

	checkCredentials(email?: string, phoneNumber?: string): Observable<Availability> {
		let params = new HttpParams();
		if (email) {
			params = params.set('email', email);
		}
		if (phoneNumber) {
			params = params.set('phoneNumber', phoneNumber);
		}
		return this.http.get<Availability>(environment.api.bff + '/user/check-credentials', {
			params
		})
	}

	confirm(code: string): Observable<boolean> {
		const params = new HttpParams().set('code', code);
		return this.http.post<boolean>(environment.api.bff + '/public/user/register/confirm', null, { params });
	}

	resendConfirm(code: string): Observable<boolean> {
		const params = new HttpParams().set('code', code);
		return this.http.post<boolean>(environment.api.bff + '/public/user/register/resend', null, { params });
	}
	syncUser(): Observable<User> {
		return this.http.post<User>(environment.api.bff + `/user/sync`, null)
	}

	deleteMultipleUsers(ids: string[]): Observable<void> {
		return this.http.post<void>(environment.api.bff + `/user/delete-users`, ids)
	}

	getLanguages(): Observable<string[]> {
		return this.http.get<string[]>(environment.api.bff + '/user/languages');
	}

	getPhoneTypes(): Observable<string[]> {
		return this.http.get<string[]>(environment.api.bff + '/user/phoneTypes');
	}

	getPhoneVisibilities(): Observable<string[]> {
		return this.http.get<string[]>(environment.api.bff + '/users/phoneVisibilities');
	}

	getUsersList(options: PageOption): Observable<PaginatedEntity<User>> {
		return this.http.get<PaginatedEntity<User>>(environment.api.bff + '/users', {
			params: {
				page: options.page,
				size: options.size || 10,
				sort: 'lastname',
			},
		});
	}

	getOneUser(userId: string): Observable<User> {
		return this.http.get<User>(environment.api.bff + `/users/${userId}`);
	}

	getUserProfile(userId: string): Observable<User> {
		return this.http.get<User>(environment.api.bff + `/users/${userId}`);
	}

	getUserProfileCompletion(): Observable<number> {
		return this.http.get<number>(environment.api.bff + '/user/profileCompletion');
	}

	updateUserEmail(userId: string, newEmail: string): Observable<void> {
		return this.http.patch<void>(environment.api.bff + `/users/${userId}/email`,
				{ email: newEmail });
	}

	updateUserProfile(user: User): Observable<User> {
		return this.http.put<User>(environment.api.bff + `/user/${user.id}`, user);
	}

    updateOneUserPhone(userId: string, phone: Phone): Observable<Phone> {
        return this.http.put<Phone>(environment.api.bff + `/users/${userId}/phones/${phone.id}`, phone);
    }

    updateOrganization(organization: Organization): Observable<Organization> {
        return this.http.put<Organization>(environment.api.bff + `/organizations/${organization.id}`, organization);
    }

    uploadAvatar(userId: string, avatar: string): Observable<User> {
        return this.http.post<User>(environment.api.bff + `/user/${userId}/upload-avatar`, {
            avatar: avatar,
        });
    }

	addOneUserPhone(userId: string, phone: Phone): Observable<Phone> {
		return this.http.post<Phone>(environment.api.bff + `/users/${userId}/phones`, phone);
	}

	deleteOneUserPhone(userId: string, phoneId: string): Observable<void> {
		return this.http.delete<void>(environment.api.bff + `/users/${userId}/phones/${phoneId}`);
	}

	validateUserEmail(code: string): Observable<UpdateEmail> {
		return this.http.get<UpdateEmail>(environment.api.bff + `/users/validate-update-email/${code}`);
	}

	confirmNewUserEmail(code: string): Observable<UpdateEmail> {
		return this.http.get<UpdateEmail>(environment.api.bff + `/users/confirm-new-email/${code}`);
	}

	getTimezones(): Observable<Timezone[]> {
		return this.http.get<Timezone[]>(environment.api.bff + '/user/timezones');
	}

	getHomePages(): Observable<string[]> {
		return this.http.get<string[]>(environment.api.bff + '/user/home-pages');
	}

	getPreferences(): Observable<UserPreferences> {
		return this.http.get<UserPreferences>(environment.api.bff + '/user/preferences');
	}

	updatePreferences(preferences: Partial<UserPreferences>): Observable<UserPreferences> {
		return this.http.patch<UserPreferences>(environment.api.bff + '/user/preferences', preferences);
	}

	checkDuns(duns: string): Observable<boolean> {
		return this.http.get<boolean>(environment.api.bff + `/public/user/check-duns/${duns}`);
	}
	
	addNotificationsPreferences(notificationsPreferences: NotificationPreference[]): Observable<UserPreferences> {
		return this.http.post<UserPreferences>(environment.api.bff + '/user/preferences/notifications', notificationsPreferences);
	}

	deleteNotificationsPreferences(notificationsPreferences: NotificationPreference[]): Observable<UserPreferences> {
		return this.http.delete<UserPreferences>(environment.api.bff + '/user/preferences/notifications', {body: notificationsPreferences});
	}
}
