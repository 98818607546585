import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule } from "@ngneat/transloco";
import { MatIconModule } from "@angular/material/icon";

@Component({
	selector: 'app-modal-info',
	templateUrl: './modal-info.component.html',
	styleUrls: ['./modal-info.component.scss'],
	imports: [
		MatDialogModule,
		TranslocoModule,
		MatIconModule
	],
	standalone: true
})
export class ModalInfoComponent {

	constructor(public dialogRef: MatDialogRef<ModalInfoComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	closeModal(): void {
		this.dialogRef.close(null);
	}
}
