import { Directive, HostBinding, Input } from '@angular/core';
import { PrestationTask, TaskTypeEnum } from '@domain/shipment/route/prestation-task';
import { MarkersTaskUtils } from '@shared/utils/leaflet/markers.utils';

export enum PrePostCarriageTransportType {
	TRAIN = 'train',
	ROAD = 'truck',
	SEA = 'vessel',
	BARGE = 'barge',
}

@Directive({
	selector: 'img[prePostCarriageIcon]',
	standalone: true,
})
export class PrePostCarriageIconDirective {

	@Input() set tasks(tasks: PrestationTask[] | undefined) {
		if (tasks) {
			const transportTask = MarkersTaskUtils.getTransportTask(tasks);
			if (transportTask?.type) {
				this._type = transportTask.type;
			}
		}
	}

	private _type: TaskTypeEnum;

	get type() {
		return this._type;
	}

	_imageType: 'b' | 'b-g';

	get imageType() {
		return this._imageType;
	}

	@Input() set imageType(value: 'b' | 'b-g') {
		this._imageType = value;
	}

	@HostBinding('alt')
	get elementAlt(): string {
		return this.type ? this.type.toString() : '';
	}

	@HostBinding('src')
	get elementSrc(): string {
		return `/assets/images/illustrations/standalone/${MarkersTaskUtils.getTransportMode(this.type)}.svg`;
	}

}
