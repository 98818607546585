import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

export abstract class CrudApiService<TYPE extends { id?: ID }, ID> {

	protected typeConstructor: any;

	protected apiUrl: string;

	/**
	 * @param http .
	 * @param typeConstructor Classe associée au service
	 * @param apiUrl Url de l'api (sans slash initial)
	 */
	constructor(public http: HttpClient, typeConstructor: Function, apiUrl: string) {
		this.apiUrl = apiUrl;
		this.typeConstructor = typeConstructor;
	}

	create(object: TYPE): Observable<TYPE> {
		return this.http.post<TYPE>(`${environment.api.bff}/${this.apiUrl}`, object)
	}

	get(id: ID): Observable<TYPE> {
		return this.http.get<TYPE>(`${environment.api.bff}/${this.apiUrl}/${id}`);
	}

	update(id: ID, object: TYPE): Observable<TYPE> {
		return this.http.put<TYPE>(`${environment.api.bff}/${this.apiUrl}/${id}`, object);
	}

	delete(id: ID): Observable<boolean> {
		return this.http.delete<boolean>(`${environment.api.bff}/${this.apiUrl}/${id}`);
	}

	patch(id: ID, object: TYPE): Observable<TYPE> {
		return this.http.patch<TYPE>(`${environment.api.bff}/${this.apiUrl}/${id}`, object);
	}

	createOrUpdate(object: TYPE): Observable<TYPE> {
		return !object.id
				? this.create(object)
				: this.update(object.id!, object);
	}
}
