@if (colors$ | async; as colors) {
	<div class="chip" [style.background]="editMode ? null : colors[container?.displayIndex! % colors.length]"
		 [class.chip__minified]="minified" [class.chip__edit]="editMode" [class.chip__action-less]="!actions">

		@if (container && (!container?.dangerous && !container.refrigerated) || (loadingType$ | async) == LoadingTypeEnum.LCL) {
			<span class="chip__icon" [class.chip__icon__edit]="editMode"></span>
		} @else if (container?.dangerous && container?.refrigerated) {
			<span [matTooltip]="'shipment.details.equipment.dangerousReeferTooltip' | transloco"
				  class="chip__icon__danger-reefer" [class.chip__icon__danger-reefer__edit]="editMode"></span>
		} @else if (container?.dangerous) {
			<span [matTooltip]="'shipment.details.equipment.dangerousTooltip' | transloco" class="chip__icon__dangerous"
				  [class.chip__icon__dangerous__edit]="editMode"></span>
		} @else if (container?.refrigerated) {
			<span [matTooltip]="'shipment.details.equipment.reeferTooltip' | transloco" class="chip__icon__reefer"
				  [class.chip__icon__reefer__edit]="editMode"></span>
		}

		@if ((loadingType$ | async) != LoadingTypeEnum.LCL) {
			<div class="chip__text" [class.chip__text__minified]="minified">
				<input class="chip__text__input" (keydown.enter)="saveName()" [(ngModel)]="containerNumber"
					   [class.d-none]="!editMode" #input [placeholder]="'ISO'">
				<span class="chip__text__span" [class.d-none]="editMode">
					@if(container && container.containerNumber) {
						@if (container.containerNumber?.length! > 32) {
							{{ (container.containerNumber | slice:0:32) }} 
							<span [title]="container.containerNumber">...</span>
						} @else {
							{{ container.containerNumber }}
						}
					} @else {
						 {{ 'shipment.details.containerIndex' | transloco: { index: container?.displayIndex! + 1 } }}
					}
				</span>
			</div>
			@if (!minified && actions) {
				<button class="chip__close" (click)="toggleMenu()" cdkOverlayOrigin
						#trigger="cdkOverlayOrigin"></button>
				<ng-template cdkConnectedOverlay
							[cdkConnectedOverlayOrigin]="trigger"
							[cdkConnectedOverlayOpen]="menuOpen"
							(overlayOutsideClick)="toggleMenu()">
					<div class="chip__menu" [class.chip__menu__open]="menuOpen">
						@if (!editMode) {
							<button (click)="editMode = true; input.focus()"><span
									class="chip__menu__ref"></span> {{ 'shipment.details.equipment.addRef' | transloco }}
							</button>
						}
						@if (container && container.displayIndex && container.displayIndex > 0) {
							<button (click)="delete()"><span
									class="chip__menu__delete"></span> {{ 'shipment.details.equipment.deleteContainer' | transloco }}
							</button>
						}
					</div>
				</ng-template>
			}
		}

	</div>
}

