import { Selector } from '@ngxs/store';
import { ADDRESS_STATE_TOKEN, AddressStateModel } from './address.state';
import { Address, OSMAddressResponse } from '@domain/shared/address';
import { ConcatStringPipe } from '@shared/pipes/concat-string.pipe';

export class AddressSelector {

    @Selector([ADDRESS_STATE_TOKEN])
    static searchNewOSMAddresses(state: AddressStateModel): OSMAddressResponse[] {
        return state.searchNewAddressesResult;
    }

    @Selector([AddressSelector.searchNewOSMAddresses])
    static searchNewAddresses(osmAddresses: OSMAddressResponse[]): Address[] {
        const concatStringPipe = new ConcatStringPipe();
        return [...osmAddresses].map(newAddress => {
            return {
                displayName: newAddress.display_name,
                address1: concatStringPipe.transform([newAddress.address.house_number, newAddress.address.road]),
                city: newAddress.address.city,
                postalCode: newAddress.address.postcode,
                countryCode: newAddress.address.country_code?.toUpperCase(),
                state: newAddress.address.state,
                position: { type:'Point', coordinates: [newAddress.lon, newAddress.lat] }
            } as Address;
        });
    }

    @Selector([ADDRESS_STATE_TOKEN])
    static searchAddressBookResult(state: AddressStateModel): Address[] {
        return state.searchAddressBookResult;
    }
    @Selector([AddressSelector.searchAddressBookResult])
    static searchAddressBookResultSorted(addresses: Address[]): Address[] {
        // sort first by lastUse and after by name
        return [...addresses].sort((a, b) => {
            if (b.lastUse && a.lastUse) {
                return b.lastUse.diff(a.lastUse).milliseconds;
            } else if (b.lastUse) {
                return 1;
            } else if (a.lastUse) {
                return -1;
            } else {
                if (b.name && a.name) {
                    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
                }
                return 0;
            }
        });
    }
}