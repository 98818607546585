<ng-container *transloco="let t">
<div mat-dialog-title>
    <span class="title">{{ 'modal.confirm.title.' + data.title | transloco }}</span>
</div>
<div mat-dialog-content>
	<img src="assets/images/illustrations/monochrome/warning.svg"
		 alt="warning"
		 width="140"
		 height="140"
		 class="d-block mx-auto mb-4">
	<span class="body">
		{{ 'modal.confirm.body.' + data.body | transloco:data.params}}
	</span>
</div>
<div mat-dialog-actions>
	<!--<button mat-raised-button color="warn" (click)="closeModal(true)">
		{{ 'app.confirm' | transloco}}
	</button>
	<button mat-raised-button color="accent"  (click)="closeModal(false)">
		{{ 'app.cancel' | transloco}}
	</button>-->
	<app-button type="default" subtype="default" (clicked)="closeModal(true)">
		{{ 'app.confirm' | transloco }}
	</app-button>
	<app-button type="default" subtype="alt" (clicked)="closeModal(false)">
		{{ 'app.cancel' | transloco }}
	</app-button>
</div>
</ng-container>
