import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TaskDocumentCategory, UploadDocument } from '@domain/document/document';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { TrackingSelectors } from '@state/tracking/tracking.selector';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ShowMessage } from '@state/global/global.actions';
import { MessageLevel } from '@state/global/global.state';
import { PrestationTask } from '@domain/shipment/route/prestation-task';
import { MatSelect } from '@angular/material/select';
import { UploadTaskDocument, UploadTaskDocumentSuccess } from '@state/tracking/tracking.action';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PrePostCarriageWithType } from '@domain/shipment/route/pre-post-carriage';

@Component({
	selector: 'app-shipment-tracking-menu-add-document-modal',
	templateUrl: './shipment-tracking-add-document-modal.component.html',
	styleUrl: './shipment-tracking-add-document-modal.component.scss',
})
export class ShipmentTrackingAddDocumentModalComponent {
	@ViewChild('carriageSelect') carriageSelect?: MatSelect;
	@ViewChild('taskSelect') taskSelect?: MatSelect;

	carriages$ = this.store.select(TrackingSelectors.shipmentTrackingCarriages);

	documentForm = new FormGroup({
		name: new FormControl<string | null>('', Validators.required),
		category: new FormControl<TaskDocumentCategory | null>(null, Validators.required),
		task: new FormControl<PrestationTask | null>(null, Validators.required),
		file: new FormControl<File | null>(null, Validators.required),
	});

	TaskDocumentCategory = TaskDocumentCategory;

	constructor(
			private dialog: MatDialogRef<ShipmentTrackingAddDocumentModalComponent>,
			private store: Store,
			// private actions$: Actions,
	) {
		/*this.actions$.pipe(takeUntilDestroyed(), ofActionSuccessful(UploadTaskDocumentSuccess))
				.subscribe(() => this.close());*/
	}

	get file() {
		return this.documentForm.controls.file;
	}

	get selectedCarriage() {
		return (this.carriageSelect?.value as PrePostCarriageWithType) || null;
	}

	onCarriageChange() {
		if (this.taskSelect) {
			this.taskSelect.value = null;
			this.documentForm.controls.task.markAsPristine();
			this.documentForm.controls.task.markAsUntouched();
		}
	}

	onFileDrop(files: File[]) {
		if (this.file.value) return;
		if (!files?.length) return;
		if (files.length > 1) {
			this.store.dispatch(new ShowMessage({
				text: 'multiple_files_provided',
				level: MessageLevel.ERROR,
			}, 'documents'));
		}
		this.documentForm.patchValue({ file: files[0] });
	}

	onFileInput(e: Event) {
		const files = (e.target as HTMLInputElement).files;
		if (!files?.length) return;
		if (files.length > 1) {
			this.store.dispatch(new ShowMessage({
				text: 'multiple_files_provided',
				level: MessageLevel.ERROR,
			}, 'documents'));
		}
		this.documentForm.patchValue({ file: files.item(0) });
	}

	removeFile() {
		this.documentForm.patchValue({ file: null });
	}

	uploadFile() {
		if (this.documentForm.invalid) {
			this.documentForm.markAllAsTouched();
			return;
		}
		const uploadDocument = {
			...this.documentForm.value,
			taskId: this.documentForm.value.task?.id,
		} as UploadDocument;
		this.store.dispatch(new UploadTaskDocument(uploadDocument));
		this.close();
	}

	close() {
		this.dialog.close();
	}
}
