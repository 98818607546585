import { Selector } from '@ngxs/store';
import { USER_STATE_TOKEN, UserStateModel } from '@state/user/user.state';
import { Organization, RoleEnum } from '@domain/user/user';
import { ActivityTypeEnum } from "@domain/user/activity-type.enum";
import { NotificationCategoryEnum, NotificationMediaEnum, NotificationPreference, NotificationPreferenceRow } from '@domain/user/user-preferences';

export class UserStateSelector {
    @Selector([USER_STATE_TOKEN])
    static userSSOProfile(state: UserStateModel) {
        return state.userSSOProfile;
    }

	@Selector([USER_STATE_TOKEN])
	static userRoles(state: UserStateModel) {
		return state.userProfile.roles;
	}

    @Selector([USER_STATE_TOKEN])
    static isLoggedIn(state: UserStateModel) {
        return state.isLoggedIn;
    }

    @Selector([USER_STATE_TOKEN])
    static userOrganization(state: UserStateModel): Organization | undefined {
        return state.userProfile.organization;
    }

	@Selector([USER_STATE_TOKEN])
	static attribuablesRoles(state: UserStateModel) {
		const attribuablesRoles = [];
		if(state.userProfile.organization?.type?.includes(ActivityTypeEnum.PORT_OPERATOR)) {
			attribuablesRoles.push(RoleEnum.PORT_OPERATOR);
		}
		if(state.userProfile.organization?.type?.includes(ActivityTypeEnum.TRUCK_OPERATOR)) {
			attribuablesRoles.push(RoleEnum.TRUCK_DRIVER);
		}
        if(state.userProfile.organization?.type?.includes(ActivityTypeEnum.ORGANIZATION_OPERATOR)) {
            attribuablesRoles.push(RoleEnum.ORGANIZATOR);
        }
		attribuablesRoles.push(RoleEnum.COMPANY_ADMIN);
		attribuablesRoles.push(RoleEnum.COLLABORATOR);
		return attribuablesRoles;
	}

    @Selector([USER_STATE_TOKEN])
    static userProfile(state: UserStateModel) {
        return state.userProfile;
    }

    @Selector([USER_STATE_TOKEN])
    static emailValidationStatus(state: UserStateModel) {
        return state.emailValidationStatus;
    }

    @Selector([USER_STATE_TOKEN])
    static confirmNewEmailStatus(state: UserStateModel) {
        return state.confirmNewEmailStatus;
    }

    @Selector([USER_STATE_TOKEN])
    static userProfileCompletion(state: UserStateModel) {
        return state.profileCompletion;
    }

    @Selector([USER_STATE_TOKEN])
    static preferences(state: UserStateModel) {
        return state.preferences;
    }

    @Selector([USER_STATE_TOKEN])
    static organization(state: UserStateModel) {
        return state.userProfile.organization;
    }

    @Selector([USER_STATE_TOKEN])
    static notificationsPreferencesAsListOfNotificationPreferenceRow(state: UserStateModel): NotificationPreferenceRow[] {
	    var result: NotificationPreferenceRow[] = [];
        if (!state.preferences?.notificationsPreferences) {
            return result;
        }
	    Object.values(NotificationCategoryEnum).forEach(category => {
    		result.push(new NotificationPreferenceRow(category, Object.values(NotificationMediaEnum).map(media => {
			    return state.preferences!.notificationsPreferences.some((notifPref: NotificationPreference) => notifPref.category == category && notifPref.media == media);
		    })));
	    });
	    return result;
    }
}
