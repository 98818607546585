import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-address-confirmation',
    standalone: true,
    imports: [
        TranslocoModule,
        MatFormFieldModule,
        FormsModule
    ],
    templateUrl: './address-confirmation.component.html',
    styleUrl: './address-confirmation.component.scss'
})
export class AddressConfirmationComponent {
    @Input() addressString: string | undefined;
    newAddressName = '';
    regex = new RegExp('^[\\w \'_äáàâéèêëìíîïòóôüù-]*$');
    error = false;

    @Output() newAddressNameChange = new EventEmitter<string>();

    onNewAddressNameChange($event: Event) {
        if (this.newAddressName.length > 128 || !this.regex.test(this.newAddressName)) {
            this.error = true;
            this.newAddressNameChange.emit(undefined);
        } else {            
            this.error = false;
            this.newAddressNameChange.emit(this.newAddressName);
        }
    }

}
