export enum UserContactActions {
    LOAD_CONTACTS = 'Get Contacts',
    LOAD_CONTACTS_SUCCESS = 'Get Contacts Success',
    LOAD_CONTACTS_FAILURE = 'Get Contacts Failure',

    UPDATE_CONTACT_SEARCH = 'Update User Contact Search',

    CREATE_CONTACT = 'Add Contact',
    CREATE_CONTACT_SUCCESS = 'Add Contact Success',
    CREATE_CONTACT_FAILURE = 'Add Contact Failure',

    UPDATE_CONTACT = 'Update Contact',
    UPDATE_CONTACT_SUCCESS = 'Update Contact Success',
    UPDATE_CONTACT_FAILURE = 'Update Contact Failure',

    DELETE_CONTACT_CONFIRM = 'Delete Contact Confirm',
    DELETE_CONTACT = 'Delete Contact',
    DELETE_CONTACT_SUCCESS = 'Delete Contact Success',
    DELETE_CONTACT_FAILURE = 'Delete Contact Failure',

    LOAD_CONTACT = 'Get Contact',
    LOAD_CONTACT_SUCCESS = 'Get Contact Success',
    LOAD_CONTACT_FAILURE = 'Get Contact Failure',

    UPDATE_CURRENT_CONTACT = 'Update Current Contact',

    SWITCH_VIEW_MODE = 'SWITCH_VIEW_MODE',

    OPEN_ASSIGN_GROUP_MODAL = 'OPEN_ASSIGN_GROUP_MODAL',

    ASSIGN_GROUP_TO_CONTACT = 'ASSIGN_GROUP',
    ASSIGN_GROUP_TO_CONTACT_SUCCESS = 'ASSIGN_GROUP_SUCCESS',
}
