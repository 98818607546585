import { CountUserBookings, CountUserBookingsSuccess, CountUserBookingsFailure, AddOneToCountUserBookings, MinusOneToCountUserBookings } from './dashboard.actions';
import { Injectable } from '@angular/core';
import { MessageLevel } from '@state/global/global.state';
import { ShipmentService } from '@services/shipments/shipment.service';
import { ShowMessage } from '@state/global/global.actions';
import { StateToken, State, Action, StateContext, Store } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError, of } from 'rxjs';
import { UserStateSelector } from '@state/user/user.selectors';
import { patch } from '@ngxs/store/operators';

export interface DashboardStateModel {
    nbBookings: number;
}

export const DASHBOARD_STATE_TOKEN: StateToken<DashboardStateModel> = new StateToken<DashboardStateModel>('dashboard');

@State<DashboardStateModel>({
    name: DASHBOARD_STATE_TOKEN,
    defaults: {
        nbBookings: 0,
    },
})
@Injectable()
export class DashboardState {
    constructor(private _shipmentService: ShipmentService, private store: Store) {}

    //#region Count User Bookings
    @Action(CountUserBookings)
    countUserBookings(ctx: StateContext<DashboardStateModel>) {
        const nbUserBookings: number | undefined = ctx.getState().nbBookings;
        if (nbUserBookings) return;
        const profile = this.store.selectSnapshot(UserStateSelector.userProfile);
        if (profile?.id) {
            return this._shipmentService.countUserBookings(profile?.id).pipe(
                map((count) => {
                    ctx.dispatch(new CountUserBookingsSuccess(count));
                }),
                catchError((error: HttpErrorResponse) =>
                    ctx.dispatch(new CountUserBookingsFailure(error.error.errorCode)),
                ),
            );
        } else {
            return of();
        }
    }

    @Action(CountUserBookingsSuccess)
    countUserBookingsSuccess(ctx: StateContext<DashboardStateModel>, { nbBookings }: CountUserBookingsSuccess) {
        ctx.patchState({
            nbBookings: nbBookings,
        });
    }

    @Action(CountUserBookingsFailure)
    countUserBookingsFailure(ctx: StateContext<DashboardStateModel>, { error }: CountUserBookingsFailure) {
        return ctx.dispatch(
            new ShowMessage(
                {
                    text: 'count_user_bookings_failure',
                    level: MessageLevel.ERROR,
                },
                'profile',
            ),
        );
    }

    @Action(AddOneToCountUserBookings)
    addOneToCountUserBooking(ctx: StateContext<DashboardStateModel>) {
        const nbUserBookings: number | undefined = ctx.getState().nbBookings;
        if (nbUserBookings) {
            ctx.setState(
				patch({
						nbBookings: nbUserBookings + 1,
					}),
				);
        }
    }

    @Action(MinusOneToCountUserBookings)
    minusOneToCountUserBooking(ctx: StateContext<DashboardStateModel>) {
        const nbUserBookings: number | undefined = ctx.getState().nbBookings;
        if (nbUserBookings) {
            ctx.setState(
				patch({
						nbBookings: nbUserBookings - 1,
					}),
				);
        }
    }

    //#endregion
}
