import { PaginatedEntity } from '@shared/types/paginated.entity';
import { NotificationFilter, NotificationResponse, UnreadNotifications } from '@domain/notifications/notifications';
import { PageOption } from "@shared/utils/query/page.options";

const PREFIX = '[Notifications] ';

export class LoadLatestNotifications {
	static readonly type = PREFIX + 'Load Latest Notifications';
}

export class LoadLatestNotificationsSuccess {
	static readonly type = PREFIX + 'Load Latest Notifications Success';

	constructor(public notifications: PaginatedEntity<NotificationResponse>) {}
}

export class LoadLatestNotificationsFailure {
	static readonly type = PREFIX + 'Load Latest Notifications Failure';

	constructor(public error: any) {}
}

export class GetUnreadNotitications {
	static readonly type = PREFIX + 'Get Unread Notifications';
}

export class GetUnreadNotiticationsSuccess {
	static readonly type = PREFIX + 'Get Unread Notifications Success';
	constructor(public unreadNotifications: UnreadNotifications) {}
}

export class GetUnreadNotiticationsFailure {
	static readonly type = PREFIX + 'Get Unread Notifications Failure';
	constructor(public error: any) {}
}

export class MarkNotificationsAsRead {
	static readonly type = PREFIX + 'Mark Notifications As Read';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsReadSuccess {
	static readonly type = PREFIX + 'Mark Notifications As Read Success';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsReadFailure {
	static readonly type = PREFIX + 'Mark Notifications As Read Failure';

	constructor(public error: any) {}
}

export class MarkAllNotificationsAsRead {
	static readonly type = PREFIX + 'Mark All Notifications As Read';
}

export class MarkAllNotificationsAsReadSuccess {
	static readonly type = PREFIX + 'Mark All Notifications As Read Success';
}

export class MarkAllNotificationsAsReadFailure {
	static readonly type = PREFIX + 'Mark All Notifications As Read Failure';

	constructor(public error: any) {}
}

export class MarkNotificationsAsUnread {
	static readonly type = PREFIX + 'Mark Notifications As Unread';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsUnreadSuccess {
	static readonly type = PREFIX + 'Mark Notifications As Unread Success';

	constructor(public ids: string[]) {}
}

export class MarkNotificationsAsUnreadFailure {
	static readonly type = PREFIX + 'Mark Notifications As Unread Failure';

	constructor(public error: any) {}
}

export class UpdateNotificationFilter {
	static readonly type = PREFIX + 'Update Notification Filter';

	constructor(public filter: NotificationFilter) {}

}

export class LoadNotifications {
	static readonly type = PREFIX + 'Load Notifications';

	constructor(public replace: boolean = false) {}
}

export class LoadNotificationsSuccess {
	static readonly type = PREFIX + 'Load Notifications Success';

	constructor(public notifications: PaginatedEntity<NotificationResponse>, public replace: boolean = false ) {}
}

export class LoadNotificationsFailure {
	static readonly type = PREFIX + 'Load Notifications Failure';

	constructor(public error: any) {}
}

export class UpateNotificationsPageOption {
	static readonly type = PREFIX + 'Update Notifications Page Option';

	constructor(public pageOption: PageOption) {}
}
