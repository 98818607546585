export enum ShipmentAction {
    LOAD_SHIPMENTS = 'Load Shipments',
    LOAD_SHIPMENTS_SUCCESS = 'Shipments Load Success',
    LOAD_SHIPMENTS_FAILURE = 'Shipments Load Failed',
    CHANGE_SHIPMENTS_SPINNER = 'Change Shipments Spinner',

    LOAD_SHIPMENTS_KANBANS = 'Load Shipments Kanbans',
    LOAD_SHIPMENTS_KANBAN = 'Load Shipments Kanban',
    LOAD_SHIPMENTS_KANBAN_MOBILE = 'Load Shipments Kanban Mobile',
    LOAD_SHIPMENTS_KANBAN_SUCCESS = 'Load Shipments Kanban Success',
    LOAD_SHIPMENTS_KANBAN_FAILURE = 'Load Shipments Kanban Failed',

    CHANGE_KANBAN_SPINNER = 'Change Kanban Spinner',

    LOAD_SHIPMENT = 'Load Shipment',
    LOAD_SHIPMENT_SUCCESS = 'Shipment Load Success',
    LOAD_SHIPMENT_FAILURE = 'Shipment Load Failed',

    CREATE_SHIPMENT = 'Create Shipment',
    CREATE_SHIPMENT_SUCCESS = 'Shipment Create Success',
    CREATE_SHIPMENT_FAILURE = 'Shipment Create Failed',

    UPDATE_SHIPMENT = 'Update Shipment',
    UPDATE_SHIPMENT_SUCCESS = 'Shipment Update Success',
    UPDATE_SHIPMENT_FAILURE = 'Shipment Update Failed',

    DELETE_SHIPMENT_CONFIRM = 'Delete Shipment Confirm',
    DELETE_SHIPMENT = 'Delete Shipment',
    DELETE_SHIPMENT_SUCCESS = 'Shipment Delete Success',
    DELETE_SHIPMENT_FAILURE = 'Shipment Delete Failed',

    PATCH_SHIPMENT = 'Patch Shipment',
    PATCH_SHIPMENT_SUCCESS = 'Shipment Patch Success',
    PATCH_SHIPMENT_FAILURE = 'Shipment Patch Failed',
    PATCH_SHIPMENT_TYPE = 'Patch Shipment Type',
    PATCH_SHIPMENT_TYPE_SUCCESS = 'Shipment Type Patch Success',
    PATCH_SHIPMENT_TYPE_FAILURE = 'Shipment Type Patch Failed',

    LOAD_EQUIPMENT_REF = 'Load Equipment Refs',
    LOAD_EQUIPMENT_REF_SUCCESS = 'Load Equipment Refs Succcess',
    LOAD_EQUIPMENT_REF_FAILURE = 'Load Equipment Refs Failure',

    LOAD_UN_NUMBERS = 'Load Un Numbers',
    LOAD_UN_NUMBERS_SUCCESS = 'Load Un Numbers Succcess',
    LOAD_UN_NUMBERS_FAILURE = 'Load Un Numbers Failure',

    UPDATE_UN_NUMBER_FILTER = 'Update Un Number Filter',

    CREATE_EQUIPMENT = 'Create Equipment',
    CREATE_EQUIPMENT_SUCCESS = 'Equipment create Success',
    CREATE_EQUIPMENT_FAILURE = 'Equipment create Failed',
    DELETE_EQUIPMENT_CONFIRM = 'Equipment Delete Confirm',
    DELETE_EQUIPMENT = 'Delete Equipment',
    DELETE_EQUIPMENT_SUCCESS = 'Equipment Delete Success',
    DELETE_EQUIPMENT_FAILURE = 'Equipment Delete Failed',
    PATCH_EQUIPMENT = 'Patch Equipment',
    PATCH_EQUIPMENT_SUCCESS = 'Equipment Patch Success',
    PATCH_EQUIPMENT_FAILURE = 'Equipment Patch Failed',

    CLONE_EQUIPMENT_CONFIRM = 'Equipment Clone Confirm',
    CLONE_EQUIPMENT = 'Equipment Clone',

    PATCH_EQUIPMENT_DETAIL = 'Patch Equipment Detail',
    PATCH_EQUIPMENT_DETAIL_SUCCESS = 'Equipment Detail Patch Success',
    PATCH_EQUIPMENT_DETAIL_FAILURE = 'Equipment Detail Patch Failed',

    CREATE_CARGO_ITEMS = 'Create Cargo Items',
    CREATE_CARGO_ITEMS_SUCCESS = 'CargoItems create Success',
    CREATE_CARGO_ITEMS_FAILURE = 'CargoItems create Failed',
    DELETE_CARGO_ITEMS_FORM = 'Delete CargoItems Form',
    DELETE_CARGO_ITEMS = 'Delete CargoItems',
    DELETE_CARGO_ITEMS_SUCCESS = 'CargoItems Delete Success',
    DELETE_CARGO_ITEMS_FAILURE = 'CargoItems Delete Failed',
    PATCH_CARGO_ITEMS = 'Patch CargoItems',
    PATCH_CARGO_ITEMS_SUCCESS = 'CargoItems Patch Success',
    PATCH_CARGO_ITEMS_FAILURE = 'CargoItems Patch Failed',

    UPDATE_SHIPMENT_PAGE = 'Update Shipment Page',
    UPDATE_SHIPMENT_SORT = 'Update Shipment Sort',

    LOAD_FILTER_LOCATION = 'Load Filter Location',
    LOAD_FILTER_CUSTOMER = 'Load Filter Customer',
    UPDATE_SHIPMENT_FILTER_DATA = 'Update Shipment Filter Data',
    UPDATE_SHIPMENT_FILTER = 'Update Shipment Filter',

    RESET_SHIPMENT_CLIENT = 'Reset Shipment Client',
    OPEN_ADD_CONTACT_TO_SHIPMENT_CUSTOMER = 'Open Add Contact To Current Shipment Customer Dialog',

    CREATE_DOUBLE_TRUCKING = 'Create Double Trucking',
    CREATE_DOUBLE_TRUCKING_SUCCESS = 'Create Double Trucking Success',
    CREATE_DOUBLE_TRUCKING_FAILURE = 'Create Double Trucking Failure',

    UPDATE_DOUBLE_TRUCKING = 'Update Double Trucking',
    UPDATE_DOUBLE_TRUCKING_SUCCESS = 'Update Double Trucking Success',
    UPDATE_DOUBLE_TRUCKING_FAILURE = 'Update Double Trucking Failure',

    DELETE_DOUBLE_TRUCKING_CONFIRM = 'Delete Double Trucking Confirm',
    DELETE_DOUBLE_TRUCKING = 'Delete Double Trucking',
    DELETE_DOUBLE_TRUCKING_SUCCESS = 'Delete Double Trucking Success',
    DELETE_DOUBLE_TRUCKING_FAILURE = 'Delete Double Trucking Failure',
}
