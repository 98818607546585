<div class="body">
    <div class="subtitle">
        {{ 'address.recentAddress' | transloco }}
    </div>
    <div class="body__result">
        @if ((addresses && addresses.length > 0)) {
            <mat-selection-list (selectionChange)="setSelectedAddress($event)" [multiple]="false" #matAddressList>
                @for(address of addresses; track address) {
                    <mat-list-option [selected]="address == addresses[0]" [value]="address">
                        <app-address-item [address]="address"></app-address-item>
                    </mat-list-option>
                }
            </mat-selection-list>
        } @else {
            <div class="no-result-text" [innerHTML]="'address.noResult' | transloco: {search: searchInputText}">
            </div>
        }
        <app-button class="new-address-btn" (clicked)="addAddress()">{{ 'address.newAddress' | transloco}}</app-button>
    </div>
</div>
